import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { shippingStateOptions, deliveryStateAbbreviations } from "../../_helpers/shipping-state-options";
import { connect } from "react-redux";
import { memberActions, newMemberActions } from "../../_actions";
import get from "lodash/get";
import "./styles.css";

const ShippingAddressInfo = ({
  dispatch,
  homeStreet,
  shippingStreet,
  homeCity,
  shippingCity,
  homeState,
  shippingState,
  homeZipCode,
  shippingZipCode,
  signup,
  required,
  deliveryType
}) => {
  const [shippingSameAsHome, setShippingSameAsHome] = useState(false);

  useEffect(() => {
    if (!shippingSameAsHome) {
      return;
    }
    signup === "profile"
      ? dispatch(
          memberActions.updateShippingStreet(
            shippingSameAsHome ? homeStreet : shippingStreet
          )
        )
      : dispatch(
          newMemberActions.setShippingStreet(
            shippingSameAsHome ? homeStreet : shippingStreet
          )
        );
    signup === "profile"
      ? dispatch(
          memberActions.updateShippingCity(
            shippingSameAsHome ? homeCity : shippingCity
          )
        )
      : dispatch(
          newMemberActions.setShippingCity(
            shippingSameAsHome ? homeCity : shippingCity
          )
        );
    // Check if we can ship to the state found in their home address, 
    (shippingStateOptions.some((state) => {
      return state.value === homeState;
    }) ? 
      signup === "profile"
        ? dispatch(
            memberActions.updateShippingState(
              shippingSameAsHome ? homeState : shippingState
            )
          )
        : dispatch(
            newMemberActions.setShippingState(
              shippingSameAsHome ? homeState : shippingState
            )
          )
    : signup === "profile" // If the state does not pass, reset to choosing a new state and fail validation
        ? dispatch(memberActions.updateShippingState(""))
        : dispatch(newMemberActions.setShippingState(""))
    );
    signup === "profile"
      ? dispatch(
          memberActions.updateShippingZip(
            shippingSameAsHome ? homeZipCode : shippingZipCode
          )
        )
      : dispatch(
          newMemberActions.setShippingZip(
            shippingSameAsHome ? homeZipCode : shippingZipCode
          )
        );
  }, [
    shippingSameAsHome,
    dispatch,
    homeStreet,
    shippingStreet,
    homeCity,
    shippingCity,
    homeState,
    shippingState,
    homeZipCode,
    shippingZipCode,
    signup
  ]);

  const asterisk = deliveryType === 'Delivery' ? '*' : '';

  const sameAsHome = () => {
    setShippingSameAsHome(!shippingSameAsHome);
  };

  return (
    <div>
      <h2 className="log-title">Shipping Address</h2>

      <Form.Check
        style={{ margin: "1em 0" }}
        checked={shippingSameAsHome}
        type="checkbox"
        id="same-as-home"
        label="Same as Home Address"
        onChange={() => sameAsHome()}
      />
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          {`Street${asterisk}`}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="textarea"
            rows="2"
            value={shippingStreet || ""}
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateShippingStreet(e.target.value))
                : dispatch(newMemberActions.setShippingStreet(e.target.value))
            }
            name="street"
            required={required}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid street.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          {`City${asterisk}`}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            value={shippingCity || ""}
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateShippingCity(e.target.value))
                : dispatch(newMemberActions.setShippingCity(e.target.value))
            }
            type="text"
            name="city"
            required={required}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          {`State${asterisk}`}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            pattern=".+"
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateShippingState(e.target.value))
                : dispatch(newMemberActions.setShippingState(e.target.value))
            }
            value={shippingState || ""}
            required={required}
          >
            {shippingStateOptions.map(state => (
              <option key={state.id} value={state.value}>
                {state.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please provide a valid state. We only ship to {deliveryStateAbbreviations()}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          {`Zip Code${asterisk}`}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
            type="input"
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateShippingZip(e.target.value))
                : dispatch(newMemberActions.setShippingZip(e.target.value))
            }
            value={shippingZipCode || ""}
            name="zipCode"
            required={required}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid zip code.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </div>
  );
};

ShippingAddressInfo.defaultProps = {
  signup: "profile"
};

function mapStateToProps(state, ownProps) {
  const { member, newMember } = state;

  const homeAddress =
    ownProps.signup === "profile"
      ? get(member, "data.homeAddress", "")
      : get(newMember, "data.homeAddress", "");

  const shipAddress =
    ownProps.signup === "profile"
      ? get(member, "data.shippingAddress", "")
      : get(newMember, "data.shippingAddress", "");

  const deliveryType = get(member, "data.deliveryType", "");

  const homeStreet = get(homeAddress, "street", "");
  const homeCity = get(homeAddress, "city", "");
  const homeSt = get(homeAddress, "state", "");
  const homeZipCode = get(homeAddress, "zipCode", "");
  const shippingStreet = get(shipAddress, "street", "");
  const shippingCity = get(shipAddress, "city", "");
  const shippingSt = get(shipAddress, "state", "");
  const shippingZipCode = get(shipAddress, "zipCode", "");
  return {
    homeStreet,
    homeCity,
    homeState: homeSt,
    homeZipCode,
    shippingStreet,
    shippingCity,
    shippingState: shippingSt,
    shippingZipCode,
    deliveryType,
  };
}

const connectedShippingAddressInfo = connect(mapStateToProps)(
  ShippingAddressInfo
);
export { connectedShippingAddressInfo as ShippingAddressInfo };
