import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import get from "lodash/get";
import SubscribeInput from "../SubscribeInput";
import { marketingActions, alertActions } from "../../_actions";
import { appConstants, emailRegex, zipRegex } from "../../_constants";
import LoadingSpinners from "../../components/LoadingSpinners";
import "./styles.scoped.scss";

const ExitPopup = ({
    dispatch,
    loading,
    subscribeObj,
    initShow,
    display,
    pid
}) => {
    const [show, setShow] = useState(initShow);
    const handleClose = () => {
        setShow(false);
        display(false);
    }

    useEffect(() => {
        setShow(show);
        display(show);
    }, [show]);

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");
    const [zip, setZip] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [subscribed, setSubscribed] = useState(false);

    var {search} = useLocation();
    var urlParams = new URLSearchParams(search);
    const utm_source = urlParams.get('utm_source');
    const utm_medium = urlParams.get('utm_medium');
    const utm_campaign = urlParams.get('utm_campaign');
    const utm_content = urlParams.get('utm_content');

    const userExistsInSF = async (email) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "email": email }),
        };
        const response = await fetch(
            `${appConstants.localApiUrl}members/search`,
            requestOptions
        );
        const json = await response.json();
        return json.with?.some(member => member.Email !== '');
    };

    const handleSubmit = async (event) => {
        const form = document.forms['marketing-subscribe'];
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const alreadyExists = await userExistsInSF(email);
            
            if (!alreadyExists) {
                subscribeObj.member.FirstName = first;
                subscribeObj.member.LastName = last;
                subscribeObj.member.MailingPostalCode = zip;
                subscribeObj.member.Email = email;
                subscribeObj.member.CHW_UTM_Source__c = utm_source;
                subscribeObj.member.CHW_UTM_Medium__c = utm_medium;
                subscribeObj.member.CHW_UTM_Campaign__c = utm_campaign;
                subscribeObj.member.CHW_UTM_Content__c = utm_content;
                subscribeObj.member.CHW_Promotion__c = pid;
                dispatch(marketingActions.subscribe(subscribeObj));
                setSubscribed(true);
            } else if (alreadyExists) {
                dispatch(
                    alertActions.error(
                        "Oops. It looks like you’re already signed up for our emails."
                    )
                );
            }
        }
    };

    useEffect(() => {
        if (validated) {
            setValidated(false);
        }
    }, [first, last, email, zip]);

    return (
        <>
            <Modal
                id="exit-popup"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                style={{
                    backgroundColor: "rgba(50, 50, 50, 0.3)",
                }} 
            >
                <Modal.Header
                    style={{
                        borderLeft: "1px solid #424242",
                        borderRight: "1px solid #424242",
                        borderTop: "1px solid #424242",
                        borderBottom: "none",
                        alignItems: "right",
                        justifyContent: "right",
                        display: "flex",
                    }}
                    className="m-0 p-0"
                >
                    <button 
                        className="close mr-0 pt-1 mt-0" 
                        data-dismiss="modal" 
                        aria-label="Close" 
                        onClick={handleClose}
                        style={{
                            color: "black",
                            float: "right",
                            fontSize: "2.5rem"
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body
                    style={{
                        borderLeft: "1px solid #424242",
                        borderRight: "1px solid #424242",
                        borderBottom: "1px solid #424242",
                    }}
                    className="mt-0 pt-0"
                >
                    {subscribed ? (
                        <>
                        <Row className="pt-0 justify-content-center my-0">
                            <Col lg md={8} className="pt-0 text-center"
                            style={{
                                fontWeight: "400",
                                lineHeight: "1",
                                textTransform: "none",
                                letterSpacing: "0em",
                                fontFamily: "'Oswald', serif",
                                fontStyle: "normal",
                                fontSize: "3rem",
                                color: "black",
                                textTransform: "capitalize",
                            }}>
                                {/*Thank You {first}!*/}
                                Success
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 10, offset: 1 }} className="text-center my-0">
                                <p>
                                    {/*You have successfully subscribed to Coopers Hawk emails! We'll be in touch*/}
                                    Thank you for subscribing, {first}. We'll be in touch!
                                </p>
                            </Col>
                        </Row>
                        </>
                    ) : (
                        <>
                        <Row className="pt-0 justify-content-center my-0">
                            <Col lg md={8} className="pt-0 text-center"
                            style={{
                                fontWeight: "400",
                                lineHeight: "1",
                                textTransform: "none",
                                letterSpacing: "0em",
                                fontFamily: "'Oswald', serif",
                                fontStyle: "normal",
                                fontSize: "1.5rem",
                                color: "black",
                            }}>
                                Not ready to become a Wine Club Member?
                            </Col>
                        </Row>
                        <Row className="justify-content-center mb-4">
                            <Col lg md={8} className="text-center"
                            style={{
                                fontWeight: "400",
                                lineHeight: "1",
                                textTransform: "none",
                                letterSpacing: "0em",
                                fontFamily: "'Oswald', serif",
                                fontStyle: "normal",
                                fontSize: "1.5rem",
                                color: "#906f47",
                            }}>
                                Sign up for Cooper's Hawk emails! 
                            </Col>
                        </Row>
                        <Form noValidate validated={validated} id="marketing-subscribe">
                            <Row className="mb-4 contact-container">
                                {/* First Name */}
                                <Col xl={{ span: 5, offset: 1 }}>
                                    <SubscribeInput
                                        feedback="Please provide a valid first name"
                                        label="First Name"
                                        value={first}
                                        isRequired={true}
                                        onTextChanged={setFirst}
                                        name="firstName"
                                    />
                                </Col>
                                {/* Last Name */}
                                <Col xl={{ span: 5}}>
                                    <SubscribeInput
                                        feedback="Please provide a valid last name"
                                        label="Last Name"
                                        value={last}
                                        isRequired={true}
                                        onTextChanged={setLast}
                                        name="lastName"
                                    />
                                </Col>
                                {/* Email */}
                                <Col xl={{ span: 5, offset: 1 }}>
                                    <SubscribeInput
                                        feedback="Please provide a valid email"
                                        label="Email"
                                        value={email}
                                        isRequired={true}
                                        onTextChanged={setEmail}
                                        name="email"
                                        isValid={emailRegex.test(email)}
                                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                    />
                                </Col>
                                {/* Zip */}
                                <Col xl={{ span: 5 }}>
                                    <SubscribeInput
                                        feedback="Please provide a valid 5 digit zip code"
                                        label="Zip"
                                        value={zip}
                                        isRequired={true}
                                        onTextChanged={setZip}
                                        name="zip"
                                        isValid={zipRegex.test(zip)}
                                        pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
                                        type="input"
                                    />
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col lg={{ span: 10, offset: 1 }} className="text-center my-0 py-0">
                                <p>
                                    Sign up for our emails to get exclusive access to the latest news about our wines, restaurants, special offers and more!
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 10, offset: 1 }} className="text-center my-0 pt-1">
                                <p className="">
                                    Unsubscribe anytime. For more information, see our {" "} <a href="https://chwinery.com/privacy-policy">Privacy Policy</a>.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 4, offset: 4 }} className="email-button text-center my-0">
                                {loading ? (
                                    <LoadingSpinners color="var(--member-site-gold)" spinnerStyle={{ marginTop: 10, marginBottom:10, marginLeft: 'auto', marginRight: 'auto' }}/>
                                ) : (
                                    <Button onClick={(e) => handleSubmit(e)} className="py-2">
                                        SUBSCRIBE
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

function mapStateToProps(state) {
    const { marketingSubscribe } = state;
    const loading = get(marketingSubscribe, "loading", false);
    const subscribeObj = {
        member: {
            CHW_Membership_Type__c: "Potential",
            FirstName: "",
            LastName: "",
            Email: "",
            MailingPostalCode: "",
            CHW_UTM_Source__c: "",
            CHW_UTM_Medium__c: "",
            CHW_UTM_Campaign__c: "",
            CHW_UTM_Content__c: "",
            CHW_Guest__c: true,
            CHW_Promotion__c: "",
        },
        returnUrl: "",
    };

    return {
        loading,
        subscribeObj,
    };
}

const connectedExitPopup = withRouter(connect(mapStateToProps)(
    ExitPopup
));

export { connectedExitPopup as ExitPopup };
