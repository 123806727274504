import { appConstants } from "../_constants";
import { isEsquireSlug } from "../_helpers";
const moment = require('moment');

export const Restaurants = {
  getMenu,
  getRestaurants,
  getRestaurantsNearby,
  getBySlug,
  getHours,
  checkRestaurantDelivery,
};

async function getRestaurantsNearby(lat, long, radius = 100, limit = 20) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${appConstants.oloApiUrl}/restaurants/near?lat=${lat}&long=${long}&radius=${radius}&limit=${limit}&includePrivate=${appConstants.includePrivateLocations}`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      //throw new Error(message || response.status);
      alert(message);
      return message;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function getBySlug(slug) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${appConstants.oloApiUrl}/restaurants/byslug/${slug}?includePrivate=${appConstants.includePrivateLocations}`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      //throw new Error(message || response.status);
      alert(message);
      return message;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function getRestaurants(filterEsquire = false) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`${appConstants.oloApiUrl}/restaurants?includePrivate=${appConstants.includePrivateLocations}`, requestOptions).then(
    async (response) => {
      if (!response.ok) {
        let result;
        try {
          result = await response.json();
        } catch (e) {
          // Couldn't parse the JSON
          // throw new Error(response.status);
          console.log("error", response.status);
          return e;
        }
        const { message } = result;
        // Got valid JSON with error response, use it
        // throw new Error(message || response.status);
        alert(message);
        return message;
      } else{
        // Successful response, parse the JSON and return the data
        const responseJSON = await response.json();
        if(filterEsquire){
          const filteredArr = responseJSON.restaurants.filter(restaurant => isEsquireSlug(restaurant.slug));
          responseJSON.restaurants = filteredArr;
          return responseJSON;
        }
        return responseJSON;
      }
    }
  );
}

async function getMenu(restaurantId) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`${appConstants.oloApiUrl}/restaurants/${restaurantId}/menu`, requestOptions).then(
    async (response) => {
      if (!response.ok) {
        let result;
        try {
          result = await response.json();
        } catch (e) {
          // Couldn't parse the JSON
          //throw new Error(response.status);
          console.log("error", response.status);
          return e;
        }
        const { message } = result;
        // Got valid JSON with error response, use it
        //throw new Error(message || response.status);
        alert(message);
        return message;
      }
      // Successful response, parse the JSON and return the data
      return response.json();
    }
  );
}

async function getHours(restaurantId) {
  const requestOptions = {
    method: "GET",
  };
  let url = new URL(`${appConstants.oloApiUrl}/restaurants/${restaurantId}/calendars`),
    from = moment().format('YYYYMMDD'),
    to = moment().add(7, 'days').format('YYYYMMDD'),
    params = { from, to };
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))


  return fetch(url, requestOptions).then(
    async (response) => {
      if (!response.ok) {
        let result;
        try {
          result = await response.json();
        } catch (e) {
          // Couldn't parse the JSON
          //throw new Error(response.status);
          console.log("error", response.status);
          return e;
        }
        const { message } = result;
        // Got valid JSON with error response, use it
        //throw new Error(message || response.status);
        alert(message);
        return message;
      }
      // Successful response, parse the JSON and return the data
      return response.json();
    }
  );
}


async function checkRestaurantDelivery(
  restaurantId, 
  street,
  city,
  zipcode
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      handoffmode: 'dispatch',
      timewantedmode: 'asap',
      street,
      city,
      zipcode
    }),
  };
  let url = new URL(`${appConstants.oloApiUrl}/restaurants/${restaurantId}/checkdeliverycoverage`);

  return fetch(url, requestOptions).then(
    async (response) => {
      if (!response.ok) {
        let result;
        try {
          result = await response.json();
        } catch (e) {
          // Couldn't parse the JSON
          //throw new Error(response.status);
          console.log("error", response.status);
          return e;
        }
        const { message } = result;
        // Got valid JSON with error response, use it
        //throw new Error(message || response.status);
        alert(message);
        return message;
      }
      // Successful response, parse the JSON and return the data
      return response.json();
    }
  );
}
