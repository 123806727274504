import SecureLS from "secure-ls";


export default class secureStorage {
  constructor() {
    try {
      this.ls = new SecureLS();
    } catch (error) {
      console.log('error: ', error)
    }
  }

  _isSupported = () => {
    try {
      const testKey = "__some_random_key_that_will_not_be_used_";
      this.ls.set(testKey, testKey);
      this.ls.remove(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  get = (key) => {
    if (this._isSupported()) {
      return this.ls.get(key) === '' ? null : this.ls.get(key);
    }
    return "{ \"privateMode\": true }";
  };

  set = (key, value) => {
    if (this._isSupported()) {
      return this.ls.set(key, value);
    }
    return false;
  };

};
