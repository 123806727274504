import React from "react";
import "./styles.scoped.scss";
import closeIcon from "../../../assets/images/close@2x.png";
import {motion} from "framer-motion";
import {ProgressHeader} from "../ProgressHeader";

const ModalContainer = ({children, progressHeader, ...props}) => {
  const onDismiss = () => {document.location.href = "#/"}
  return (
    <div className="outer-container">
      <motion.div
          transition={{ duration: 0.2 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onDismiss}
          id="touch-layer"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(5px)",
          }}
        />

      <div className="modal-container">
        <div className="header-container">
          {progressHeader && <ProgressHeader {...props} />}
        </div>
        <div className="content-container">
          {children}
        </div>
        <div className="header-close">
          <motion.img
            src={closeIcon}
            alt="close icon"
            style={{
              margin: "5px 5px 0 0",
              height: 35,
              width: 35,
              filter: 'drop-shadow(1px 1px 1px #ddd)',
            }}
            onClick={onDismiss}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}
          />
        </div>
      </div>
    </div>
  );
};

export { ModalContainer };