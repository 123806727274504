export const paymentConstants = {
  PAYMENT_FORM_CLEAR: "PAYMENT_FORM_CLEAR",

  POST_PAYMENT_REQUEST: "POST_PAYMENT_REQUEST",
  POST_PAYMENT_SUCCESS: "POST_PAYMENT_SUCCESS",
  POST_PAYMENT_FAILURE: "POST_PAYMENT_FAILURE",

  REMOVE_PAYMENT_REQUEST: "REMOVE_PAYMENT_REQUEST",
  REMOVE_PAYMENT_SUCCESS: "REMOVE_PAYMENT_SUCCESS",
  REMOVE_PAYMENT_FAILURE: "REMOVE_PAYMENT_FAILURE",

  PAYMENT_FORM_REQUEST: "PAYMENT_FORM_REQUEST",
  PAYMENT_FORM_SUCCESS: "PAYMENT_FORM_SUCCESS",
  PAYMENT_FORM_FAILURE: "PAYMENT_FORM_FAILURE",

  OLO_PAYMENT_FORM_REQUEST: "OLO_PAYMENT_FORM_REQUEST",
  OLO_PAYMENT_FORM_SUCCESS: "OLO_PAYMENT_FORM_SUCCESS",
  OLO_PAYMENT_FORM_FAILURE: "OLO_PAYMENT_FORM_FAILURE",

  BILLING_ACCOUNT_REQUEST: "BILLING_ACCOUNT_REQUEST",
  BILLING_ACCOUNT_SUCCESS: "BILLING_ACCOUNT_SUCCESS",
  BILLING_ACCOUNT_FAILURE: "BILLING_ACCOUNT_FAILURE",
  
  CHARGE_MEMBERSHIP_REQUEST: "CHARGE_MEMBERSHIP_REQUEST",
  CHARGE_MEMBERSHIP_SUCCESS: "CHARGE_MEMBERSHIP_SUCCESS",
  CHARGE_MEMBERSHIP_FAILURE: "CHARGE_MEMBERSHIP_FAILURE",

  CLEAR_PAYMENTS: "CLEAR_PAYMENTS",
  CLEAR_OLO_PAYMENT_FORM: "CLEAR_OLO_PAYMENT_FORM",
};
