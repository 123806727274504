import React, { useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import ChTabs from "../../components/ChTabs";
import { connect } from "react-redux";
import get from "lodash/get";
import {
  barrelActions,
  memberActions,
  rewardsActions,
  openBottleActions,
  eventActions,
  newsActions,
  staticContentActions,
} from "../../_actions";
import { store } from "../../_helpers";
import { appConstants } from "../../_constants";
import "./styles.css";
import { PotentialMemberLanding } from "../PotentialMemberLanding";
import LoadingSpinners from "../../components/LoadingSpinners";
import { GiftActivation } from "../GiftActivation";

function idleLogout() {
  var t;
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer; // catches touchscreen presses as well
  window.ontouchstart = resetTimer; // catches touchscreen swipes as well
  window.onclick = resetTimer; // catches touchpad clicks as well
  window.onkeypress = resetTimer;
  window.addEventListener("scroll", resetTimer, true);

  function forceLogout() {
    store.dispatch(memberActions.logout());
  }

  function resetTimer() {
    clearTimeout(t);
    t = setTimeout(forceLogout, appConstants.idleTimeLogout); // time is in milliseconds
  }
}
idleLogout();

const Main = ({
  loggedIn,
  currentTab,
  setCurrentTab,
  dispatch,
  isAdmin,
  memberNumber,
  isPotential,
  isToBeActivated,
  loading,
  history
}) => {
  useEffect(() => {
    tabNumber();
  }, [section]);

  var section = window.location.hash;

  const tabNumber = () => {
    if (section === "#reward") {
      setCurrentTab(1);
    }
    if (section === "#bottles") {
      setCurrentTab(2);
    }
    if (section === "#events") {
      setCurrentTab(3);
    }
    if (section === "#profile") {
      setCurrentTab(4);
    }
    if (section === '#utp') {
      setCurrentTab(5);
    }
  };

  const refreshTabData = (tabNum) => {
    switch (tabNum) {
      case "1":
        // refresh points and rewards
        dispatch(memberActions.getPoints());
        dispatch(rewardsActions.getRewards());
        break;
      case "2":
        // refresh bottles
        dispatch(openBottleActions.getOpenBottles());
        dispatch(barrelActions.getBarrelOrders());
        break;
      case "3":
        // refresh events
        dispatch(eventActions.getEvents());
        break;
      case "4":
        // refresh profile
        dispatch(memberActions.getMember());
        break;
      default:
        // refresh dashboard
        dispatch(newsActions.getNews());
        dispatch(staticContentActions.getStaticContent());
        break;
    }
  };

  return loading ? (
    <LoadingSpinners />
  ) : loggedIn ? (
    <div className="container main">
      {isAdmin && (
        <Link to="/Admin">
          <p
            className="float-right"
            style={{
              color: "red",
              padding: ".5em",
              backgroundColor: "#ffffff22",
            }}
          >
            Impersonating member: {memberNumber}
          </p>
        </Link>
      )}
      {isPotential ? 
        <PotentialMemberLanding />
      :isToBeActivated ? 
        <GiftActivation />
      :(
        <div className="tinted-container">
          <ChTabs
            currentTab={currentTab}
            changeTab={(tabNum) => {
              refreshTabData(tabNum);
              setCurrentTab(tabNum);
            }}
          />
        </div>
      )}
    </div>
  ) : (
    <Redirect to="/" />
  );
};

function mapStateToProps(state) {
  if (state.barrelOrders.data) {
    state.barrelOrders.data.paymentStatus = null;
  }
  const { loggedIn } = state.authentication;
  const { data, loading, isAdmin } = state.member;
  const memberNumber = get(data, "memberNumber", "");
  const isPotential = get(data, "membershipType", "") === "Potential";
  const isToBeActivated = get(data, "status", "") === "To be Activated";

  return {
    isAdmin,
    loggedIn,
    memberNumber,
    isPotential,
    isToBeActivated,
    loading,
  };
}

const connectedMain = connect(mapStateToProps)(Main);
export { connectedMain as Main };
