import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import { SummaryBlockGift } from "../SummaryBlock";
import LoadingSpinners from "../LoadingSpinners";
import UIButton from "../UIButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { newMemberActions } from "../../_actions";
import FadeIn from "react-fade-in";
import get from "lodash/get";
import "./styles.css";

import deliveryIcon from "../../assets/gift-images/icons/icon-delivery@2x.png";
import calendarIcon from "../../assets/gift-images/icons/icon-calendar@2x.png";
import bottleIcon from "../../assets/gift-images/icons/icon-number@2x.png";
import clubIcon from "../../assets/gift-images/icons/icon-glass@2x.png";
import { delivery } from "../../_reducers/restaurants.reducer";

const WineClubInfoGift = ({
  active,
  costChange,
  clubType,
  bottleLevel,
  membershipType,
  deliveryType,
  expirationDate,
  giftLength,
  signup,
  pricingStructure,
  loading,
  dispatch,
  stepForward,
  stepBack,
  showGift,
  giftOk,
  handleCollapse,
}) => {
  const [pricingString, setPricingString] = useState(
    `Gift:${deliveryType}:${clubType}:${bottleLevel}:${giftLength}`
  );
  
  if(!bottleLevel || +bottleLevel > 2) // Set 3 bottle memberships to 1 bottle, since gifts do not support more than 2 bottles.
    dispatch(newMemberActions.setBottleLevel("1"));
  if(!clubType)
    dispatch(newMemberActions.setClubType("Variety"));
  if(!giftLength)
    dispatch(newMemberActions.setGiftLength("3"));
  if(!deliveryType)
    dispatch(newMemberActions.setDeliveryType("Pickup"));

  useEffect(() => {
    setPricingString(`Gift:${deliveryType}:${clubType}:${bottleLevel}:${giftLength}`);
    if (pricingStructure[pricingString]) {
      costChange(pricingStructure[pricingString]);
    }
  }, [
    deliveryType,
    clubType,
    bottleLevel,
    giftLength,
    costChange,
    pricingString,
    pricingStructure,
    signup,
  ]);

  if (loading) {
    return <LoadingSpinners />;
  }
  const canAdvance = () => {
    return (
      clubType !== "" &&
      bottleLevel !== "" &&
      giftLength !== "" 
    );
  };

  return (
    <div className={`${active ? 'signup-active' : 'inactive'}`}>
      <h4 className="section-header">1. Select Gift Membership</h4>
      <Collapse in={active && showGift} onExited={handleCollapse}>
        <div id="collapse-div">
          <FadeIn delay={300}>
            <div className="container expandable" style={{paddingTop: "5px"}}>
              {membershipType === "Founder" ? (
                <Col className="pt-4 pb-2 text-center">
                  <p>
                    {bottleLevel} Bottle {clubType} {membershipType}{" "}
                    {deliveryType} Membership
                  </p>
                </Col>
              ) : membershipType === "Gift" ? (
                <Col className="pt-4 pb-2 text-center">
                  <p>
                    Your {bottleLevel} Bottle {clubType} {membershipType}{" "}
                    {deliveryType} Membership expires on: {expirationDate}
                  </p>
                </Col>
              ) : (
                <>
                  <div className="text-libre" style={{marginBottom: 30}}>
                    Our Wine Club Memberships make great gifts for friends,
                    family members, loved ones or as a corporate gift.
                    Memberships are available in 3, 6, or 12 month periods.
                  </div>
                  <h3 className="text">Select Delivery Type</h3>
                  <Form.Group as={Row} className="py-2">
                    <Col md={6}>
                      <UIButton
                        text="RESTAURANT PICKUP"
                        onSelect={() =>
                          dispatch(newMemberActions.setDeliveryType("Pickup"))
                        }
                        disabled={deliveryType !== "Pickup"}
                      />
                    </Col>
                    <Col md={6}>
                      <UIButton
                        text="QUARTERLY DELIVERY"
                        onSelect={() =>
                          dispatch(newMemberActions.setDeliveryType("Delivery"))
                        }
                        disabled={deliveryType !== "Delivery"}
                      />
                    </Col>
                  </Form.Group>
                  <h3 className="text">Select Bottles Per Month</h3>
                  <Form.Group as={Row} className="py-2" controlId="bottleLevel">
                    <Form.Label as="bottles" />
                    <Col md={6}>
                      <UIButton
                        text="1 BOTTLE PER MONTH"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setBottleLevel("1"))
                        }
                        disabled={bottleLevel !== "1"}
                      />
                    </Col>
                    <Col md={6}>
                      <UIButton
                        text="2 BOTTLES PER MONTH"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setBottleLevel("2"))
                        }
                        disabled={bottleLevel !== "2"}
                      />
                    </Col>
                  </Form.Group>
                  <h3 className="text">
                    Select Length of Membership
                  </h3>
                  <Form.Group as={Row} className="py-2">
                    <Col md={4}>
                      <UIButton
                        text="3 MONTHS"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setGiftLength("3"))
                        }
                        disabled={giftLength !== "3"}
                      />
                    </Col>
                    <Col md={4}>
                      <UIButton
                        text="6 MONTHS"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setGiftLength("6"))
                        }
                        disabled={giftLength !== "6"}
                      />
                    </Col>
                    <Col md={4}>
                      <UIButton
                        text="12 MONTHS"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setGiftLength("12"))
                        }
                        disabled={giftLength !== "12"}
                      />
                    </Col>
                  </Form.Group>
                  <h3 className="text">Select Wine Type</h3>
                  <Form.Group as={Row} className="py-2" controlId="clubType">
                    <Form.Label as="clubs" />
                    <Col md={3}>
                      <UIButton
                        text="VARIETY"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setClubType("Variety"))
                        }
                        disabled={clubType !== "Variety"}
                      />
                    </Col>
                    <Col md={3}>
                      <UIButton
                        text="WHITE"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setClubType("White"))
                        }
                        disabled={clubType !== "White"}
                      />
                    </Col>
                    <Col md={3}>
                      <UIButton
                        text="RED"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setClubType("Red"))
                        }
                        disabled={clubType !== "Red"}
                      />
                    </Col>
                    <Col md={3}>
                      <UIButton
                        text="SWEET"
                        onSelect={(e) =>
                          dispatch(newMemberActions.setClubType("Sweet"))
                        }
                        disabled={clubType !== "Sweet"}
                      />
                    </Col>
                  </Form.Group>
                </>
              )}
              <div className="vert-spacer" />
              <SummaryBlockGift
                price={pricingStructure[pricingString]}
                disabled={!canAdvance()}
                active={active}
              />
              <Form.Group as={Row} className="py-2" controlId="clubType" style={{marginTop: 30}}>
                <Col md={10}></Col>
                <Col md={2}>
                  <UIButton
                    text="Next"
                    onSelect={stepForward}
                    disabled={!canAdvance()}
                    validation={true}
                    aria-controls="collapse-div"
                    customStyle
                  />
                </Col>
              </Form.Group>
            </div>
          </FadeIn>
        </div>
      </Collapse>

      {!showGift && (
        <FadeIn>
          <div>
            <div
              className="big-icon-wrap"
              style={{ justifyContent: "space-around", marginTop: "2em" }}
            >
              <div className="little-icon-wrap">
                <div className="summary-bullet-container">
                  <div className="summary-bullet"></div>
                </div>
                    <div className="summary-text">
                      {deliveryType === "Pickup"
                        ? "Restaurant Pickup "
                        : "Quarterly Delivery "}
                    </div>
              </div>
              <div className="little-icon-wrap">
                <div className="summary-bullet-container">
                  <div className="summary-bullet"></div>
                </div>
                <div className="summary-text">{giftLength} month gift</div>
              </div>
              <div className="little-icon-wrap">
                <div className="summary-bullet-container">
                  <div className="summary-bullet"></div>
                </div>
                <div className="summary-text">
                  {bottleLevel === "1"
                    ? "1 bottle per month"
                    : "2 bottles per month"}
                </div>
              </div>
              <div className="little-icon-wrap">
                <div className="summary-bullet-container">
                  <div className="summary-bullet"></div>
                </div>
                <div className="summary-text">{clubType} Club</div>
              </div>

              <div className="ok-disabled">
                <h5>${pricingStructure[pricingString]}</h5>
              </div>
            </div>
          </div>
        </FadeIn>
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    member,
    newMember,
    staticContent,
    replacementCard,
    paymentForm,
  } = state;
  const url = get(paymentForm, "url", "");
  const loading = get(paymentForm, "loading", false);
  const membershipType = get(member, "data.membershipType", "");
  const price = get(member, "data.costOfMembership", "");
  const memberSignatureOptOut = get(
    member,
    "data.memberSignatureOptOut",
    false
  );
  const giftLength = get(newMember, "data.giftLength", "");
  const clubType =
    ownProps.signup === "profile"
      ? get(member, "data.clubType", "")
      : get(newMember, "data.clubType", "");
  const bottleLevel =
    ownProps.signup === "profile"
      ? get(member, "data.bottleLevel", "")
      : get(newMember, "data.bottleLevel", "");
  const deliveryType =
    ownProps.signup === "profile"
      ? get(member, "data.deliveryType", "")
      : get(newMember, "data.deliveryType", "");

  const expirationDate = get(member, "data.expirationDate", "");

  const cardType = get(
    member,
    "data.storedPaymentAccount.BluePay__Card_Type__c",
    "Card"
  );
  const lastFour = get(
    member,
    "data.storedPaymentAccount.BluePay__Card_Number__c",
    ""
  ).slice(12, 16);
  const expMonth = get(
    member,
    "data.storedPaymentAccount.BluePay__Expiration_Month__c",
    ""
  );
  const expYear = get(
    member,
    "data.storedPaymentAccount.BluePay__Expiration_Year__c",
    ""
  );

  const optOutText = get(staticContent, "data.messages.memberSignature", "");
  const pricingStructure = get(staticContent, "data.pricingStructure", {});
  const cardRequestSuccess = replacementCard.data;
  const cardRequested = replacementCard.requested;

  return {
    memberSignatureOptOut,
    clubType,
    membershipType,
    bottleLevel,
    deliveryType,
    expirationDate,
    cardType,
    lastFour,
    expMonth,
    expYear,
    optOutText,
    cardRequested,
    cardRequestSuccess,
    giftLength,
    pricingStructure,
    url,
    lockedPrice: price,
    loading,
  };
}

const connectedWineClubInfoGift = withRouter(
  connect(mapStateToProps)(WineClubInfoGift)
);
export { connectedWineClubInfoGift as WineClubInfoGift };
