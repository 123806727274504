import React, {useRef, useState, useEffect} from "react";
import { connect } from "react-redux";
import { staticContentActions } from "../../../_actions";
import "./styles.scoped.scss";
import infoIcon from "../../../assets/images/info-gold.png";
import Overlay from "react-bootstrap/Overlay";
import { get } from "lodash";
import exclusiveIcon from "../../../assets/images/wc-icon-exclusive.png";
import tastingsIcon from "../../../assets/images/wc-icon-tastings.png";
import iconBottle1Gray from "../../../assets/images/icon-bottle1-gray.png";
import iconBottle2Gray from "../../../assets/images/icon-bottle2-gray.png";
import iconBottle1White from "../../../assets/images/icon-bottle1-white.png";
import iconBottle2White from "../../../assets/images/icon-bottle2-white.png";
import {ButtonAnimator} from '../../../components';


const WineClubSelection = ({history, match, dispatch, pricingStructure}) => {
  const clubSelection = get(match, "params.clubSelection", "2");
  const [selection, setSelection] = useState(clubSelection || "2");
  const [showInfo, setShowInfo] = useState(false);
  const [pricingString, setPricingString] = useState(`Monthly:Pickup:Variety:${selection}:`);

  useEffect(() => {
    dispatch(staticContentActions.getStaticContent());
  }, []);

  useEffect(() => {
    setPricingString(`Monthly:Pickup:Variety:${selection}:`);
  }, [selection]);

  const target = useRef(null);

  return (
    <div>
      <div className="outer-container">
        <div className="top-container">
          <div className="top-description">
            WINE CLUB OPTION
            <div
              className="club-info-icon-container"
              ref={target}
              onMouseEnter={() => setShowInfo(true)}
              onMouseLeave={() => setShowInfo(false)}
            >
              <img src={infoIcon} className="club-info-icon" alt="info icon" />
              <Overlay target={target.current} show={showInfo} placement="right">
                {({ placement, arrowProps, show: _show, popper, ...otherProps }) => (
                  <div
                    {...otherProps}
                    style={{
                      backgroundColor: 'var(--member-site-gold)',
                      opacity: .9,
                      padding: '4px 12px',
                      color: 'white',
                      borderRadius: 3,
                      zIndex: 100,
                      marginLeft: 7,
                      fontFamily: "Oswald",
                      fontWeight: 200,
                      ...otherProps.style,
                    }}
                  >
                    Select your monthly<br />bottle count.
                  </div>
                )}
              </Overlay>
            </div>
          </div>
          <div className="selection-container">
           
            <div className="selection-box-container" onClick={() => setSelection("2")}>
              <div className={`selection-box ${selection === '2' ? 'club-selected': ''}`}>
                <img style={{marginRight: 30, height: 60}} className="d-none d-md-inline-block" src={selection === '2' ? iconBottle2White : iconBottle2Gray} alt="shaded bottle icon"/>
                <div className="per-month-label-container">
                  <div>2 BOTTLES</div>
                  <div className="per-month-label">PER MONTH</div>
                </div>
              </div>
            </div>
            <div className="selection-box-container" onClick={() => setSelection("1")}>
              <div className={`selection-box ${selection === '1' ? 'club-selected': ''}`}>
                <div>
                  <img style={{marginRight: 30, height: 60}} className="d-none d-md-inline-block" src={selection === '1' ? iconBottle1White : iconBottle1Gray} alt="shaded bottle icon"/>
                  <div className="per-month-label-container">
                    <div>1 BOTTLE</div>
                    <div className="per-month-label">PER MONTH</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-container d-xs-block d-md-block">
          <div className="benefits-container">
            <div className="selection-benefit">
              <img src={exclusiveIcon} style={{width: 16, marginRight: 6, marginBottom: 2}} alt="exclusive icon" />
              <span>{selection === '2' ? "2 Bottles Per Month Variety Wine Club" : "1 Bottle Per Month Variety Wine Club"}</span>
            </div>
            {selection === '2' && (
            <div className="selection-benefit">
              <img src={tastingsIcon} style={{width: 17, marginRight: 6, marginBottom: 3}} alt="tastings icon" />
              <span>2 Monthly Tastings</span>
            </div>)}
            <div className="selection-benefit">
              <img src={tastingsIcon} style={{width: 17, marginRight: 6, marginBottom: 3}} alt="tastings icon" />
              <span>Rewards and Discounts</span>
            </div>
          </div>

          <div className="price-container">
            <div className="price-label">Cost of Membership</div>
            <div className="price-value">${pricingStructure[pricingString]}/MO</div>
          </div>
          <ButtonAnimator>
          <div className="submit" onClick={() => history.push(`/account/${selection}`)}>
            Continue
          </div>
          </ButtonAnimator>
          <div className="selection-benefit" style={{width:'100%', textAlign:'center', marginTop:10}}>Next Step: Account</div>
        </div>
        <div className="bottom-container d-none d-md-none">
        <ButtonAnimator>
          <div className="submit" onClick={() => history.push(`/account/${selection}`)}>
            Continue
          </div>
          </ButtonAnimator>
          <div className="selection-benefit" style={{width:'100%', textAlign:'center', marginTop:10}}>Next Step: Account</div>
        </div>
      </div>
      {/* removed per Leslie feedback 03-22-21 G.D. */}
      {/* <div className="outer-container d-xs-block d-md-none">
        <div className="top-description" style={{marginBottom: 5}}>
          <div style={{padding: 15, color: '#ccc'}}>
            YOUR ACCOUNT
          </div>
        </div>
      </div>
      <div className="outer-container d-xs-block d-md-none">
        <div className="top-description" style={{marginBottom: 5}}>
          <div style={{padding: 15, color: '#ccc'}}>
            PAYMENT DETAILS
          </div>
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { staticContent } = state;
  const pricingStructure = get(staticContent, "data.pricingStructure", {});
  return { pricingStructure };
}

const connectedWineClubSelection = connect(mapStateToProps)(WineClubSelection);

export { connectedWineClubSelection as WineClubSelection };
