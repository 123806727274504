import React from "react";
import Loader from "react-loader-spinner";
import { isThemeEsquire, useWindowDimensions } from "../../_helpers";

const ChoLoader = () => {
  const { width } = useWindowDimensions();
  const isEsquire = isThemeEsquire();
  const dotColor = isEsquire ? 'D6A557' : '735D2F';
  return (
  <Loader
    type="ThreeDots"
    color={`#${dotColor}aa`}
    height={60}
    width={60}
    style={{
      position: "absolute",
      display: "flex",
      alignSelf: "center",
      marginTop: "45vh",
      zIndex: "999999",
      width: width < 700 ? width * 0.3 : width *0.5,
      height: width < 700 ? width * 0.3 : width *0.5,
    }}
  />
  )};

export default ChoLoader;
