import { openBottlesConstants } from "../_constants";

export function openBottles(state = { loading: false }, action) {
  switch (action.type) {
    case openBottlesConstants.OPEN_BOTTLES_REQUEST:
      return {
        loading: true
      };
    case openBottlesConstants.OPEN_BOTTLES_SUCCESS:
      return {
        loading: false,
        data: action.openBottles
      };
    case openBottlesConstants.OPEN_BOTTLES_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
