import React, { useState } from "react";
import { WineConversionItem, ChoLoader } from "../../components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import closeIcon from "../../assets/images/close@2x.png";
import "./styles.css";
import { get } from "lodash";

const BottleConversionModal = ({
  item,
  title,
  message,
  onDismiss,
  className = "",
  bottlesAvailable,
  convertedBottlesCount
}) => {
  const [loader, setLoader] = useState(false);
  const validBottlesCount = Math.max(bottlesAvailable - convertedBottlesCount, 0);

  return (
    <AnimatePresence>
      <motion.div
        className="bc-overlay"
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className={`bc-modal-overlay-inner ${className}`}>
          <div className="bc-modal-title">{title}</div>
          <div className="bc-modal-available-bottles">{validBottlesCount} available</div>
          <div className="content-container">
            <div className="bc-modal-message">{message}</div>
            <WineConversionItem
              data={item}
              didStartConversion={() => setLoader(true)}
              didFinishConversion={() => {
                setLoader(false);
                onDismiss();
              }}
              validBottlesCount={validBottlesCount}
              />
            <motion.img
              src={closeIcon}
              className="bc-close-btn"
              alt="close icon"
              onClick={onDismiss}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}
              />
          </div>
        </div>
      </motion.div>
      {loader && <ChoLoader />}
    </AnimatePresence>
  );
};

function mapStateToProps(state) {
  const {member, basket} = state;
  const memberData = get(member, "data", {});
  const bottlesAvailable = get(memberData, "openOnlineBottles", 0);
  const basketData = get(basket, "data", {});
  const cartItems = get(basketData, "products", []);
  const convertedBottlesCount = cartItems.filter(cartItem => (cartItem.customdata?.startsWith("conversion:") ?? false)).map(item => item.quantity).reduce((a, b) => a + b, 0);

  return {bottlesAvailable, convertedBottlesCount};
}

const connectedBottleConversionModal = connect(mapStateToProps)(
  BottleConversionModal
);
export { connectedBottleConversionModal as BottleConversionModal };

BottleConversionModal.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
};

BottleConversionModal.defaultProps = {
  item: {},
  title: "Use My Wine Club Bottles",
  message:
    "Select the number of full priced bottles of wine you would like to replace with bottles available in your Wine Club account.",
  onDismiss: () => {},
};
