/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import AutoComplete from "react-google-autocomplete";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { appConstants } from "../../_constants";
import { parseAddressComponents } from "../../_helpers/google-address";
import "./styles.css";

const LocationSearch = ({
  segment,
  onSearch,
  onCleared,
  placeholderText,
  value,
  onChange,
}) => {
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState("");
  const [addressComponents, setAddressComponents] = useState("");
  const prevLocationRef = useRef();

  useEffect(() => {
    const prevLocation = prevLocationRef.current;
    if (
      !isEqual(prevLocation, location) &&
      !isEmpty(location) &&
      !isEmpty(address)
    )
      onSearch({ geo: { location, address }, addressComponents });
  }, [location, address]);

  return (
    <div className="search-container">
      <AutoComplete
        value={value}
        onChange={(e) => {
          if (e.target.value === "") {
            setLocation({});
            onCleared();
          } else {
            onChange(e.target.value);
          }
        }}
        fields={[
          "geometry.location",
          "formatted_address",
          "address_components",
        ]}
        apiKey={appConstants.googleMapsApiKey}
        style={{
          height: "40px",
          border: "none",
          padding: "5px",
          width: "99%",
        }}
        onPlaceSelected={(place) => {
          if (place && place.geometry && place.geometry.location) {
            if (segment === 2) {
              try {
                const addressComponents = parseAddressComponents(place);
                setAddressComponents(addressComponents);
              } catch (error) {
                setAddressComponents("");
              }
            }
            let lat = place.geometry.location.lat();
            let lng = place.geometry.location.lng();
            prevLocationRef.current = location;
            setLocation({ lat, lng });
            setAddress(place.formatted_address);
          } else {
            console.log("place-error", place);
          }
        }}
        types={placeholderText === "Your address" ? ["address"] : ["(regions)"]}
        componentRestrictions={{ country: "us" }}
        placeholder={placeholderText}
        type="search"
        onKeyDown={(event) => {
          if (event.key === "Enter" && window.google && window.google.maps) {
            const currentText = event.target.value;
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: currentText }, (results, status) => {
              console.log(results);

              if (
                status == window.google.maps.GeocoderStatus.OK &&
                results &&
                results.length
              ) {
                const place = results[0];
                const firstLocation = place.geometry.location;
                const lat = firstLocation.lat();
                const lng = firstLocation.lng();

                prevLocationRef.current = location;
                setLocation({ lat, lng });
                setAddress(place.formatted_address);
                return false;
              }
            });
          }
        }}
      />
    </div>
  );
};

export default LocationSearch;

LocationSearch.propTypes = {
  onSearch: PropTypes.func,
  onCleared: PropTypes.func,
  placeholderText: PropTypes.string,
};

LocationSearch.defaultProps = {
  onSearch: () => {},
  onCleared: () => {},
  placeholderText: "Your address",
};
