import React, { useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { basketsActions } from "../../_actions";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import littleBottle from "../../assets/images/icon-little-bottle.png";
import circleArrow from "../../assets/images/icon-circle-arrow-right.png";

const CartItem = ({
  isCoversion,
  item,
  index,
  dispatch,
  basketData,
  loggedIn,
  memberData,
  onConvertBottle,
  onEdit,
}) => {
  const [removingItemIdex, setRemovingItemIdex] = useState(-1);

  const onRemoving = (index) => {
    setRemovingItemIdex(index);
  };

  const removeItem = async (item) => {
    await dispatch(basketsActions.deleteProduct(basketData.id, item.id));
    await dispatch(basketsActions.getUpsells(basketData.id));
    setRemovingItemIdex(-1);
  };

  return (
    <div style={{ position: "relative" }}>
      {item.customdata?.startsWith("conversion:") && (
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            bottom: -17,
            left: 5,
            backgroundColor: "#DEDBD6aa",
          }}
        />
      )}
      <div className="cart-divider2" />
      <Row
        className={
          removingItemIdex === index
            ? "removing cart-item-wrap"
            : "cart-item-wrap"
        }
      >
        {removingItemIdex === index && (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              width: "30%",
            }}
          >
            <div
              onClick={() => removeItem(item)}
              style={{
                color: "white",
                width: "100%",
                textAlign: "center",
                padding: ".4em",
                fontFamily: "Oswald",
                cursor: "pointer",
              }}
              className="remove-cart-item-button"
            >
              REMOVE
            </div>
            <div
              onClick={() => setRemovingItemIdex(-1)}
              style={{
                width: "100%",
                textAlign: "center",
                padding: ".4em",
                fontFamily: "Oswald",
                border: "1px solid grey",
                cursor: "pointer",
                marginTop: "5px",
              }}
            >
              CANCEL
            </div>
          </div>
        )}

        <Col
          xs={1}
          className="cart-item-title"
          style={{
            textAlign: "left",
            marginLeft: 2,
          }}
        >
          {item.customdata === "complimentary" ? "" : `${item.quantity}`}
        </Col>

        <Col xs={8} className="pr-1 pl-1">
          <p className="cart-item-title">
            {item.name}
            <span
              style={
                item.customdata?.startsWith("conversion:")
                  ? { fontSize: 14, color: "#6F6C67" }
                  : { fontSize: 14, color: "#212529", fontWeight: 200 }
              }
            >
              {item.customdata?.startsWith("conversion:")
                ? ` - (CLUB BOTTLE${item.quantity !== 1 ? "S" : ""} APPLIED)`
                : ""}
              {item.customdata === "complimentary"
                ? ` - Quantity based on order size`
                : ""}
            </span>
            &nbsp;&nbsp;
            {!isEmpty(item.recipient) && (
              <span className="cart-item-options">({item.recipient})</span>
            )}
          </p>
          <p className="cart-item-options">
            <span>
              {item.choices.map((choice, index) => (
                <span>
                  {choice.cost != 0 ? <strong>Add {choice.name}</strong> : choice.name}

                  {index < item.choices.length - 1 ? ", " : ""}
                </span>
              ))}
            </span>
          </p>
          {!isEmpty(item.specialinstructions) &&
            item.specialinstructions !== "WINE_PAIRING" && (
              <p
                className="cart-item-options light"
                style={{
                  color: item.customdata?.startsWith("conversion:")
                    ? "white"
                    : "black",
                }}
              >
                *{item.specialinstructions}
              </p>
            )}
          {removingItemIdex !== index && (
            <div className="edit-remove-row">
              {item.customdata !== "complimentary" &&
                !item.customdata?.startsWith("conversion:") && (
                  <div className="cart-edit" onClick={() => onEdit(item)}>
                    Edit
                  </div>
                )}{" "}
              <div className="cart-remove" onClick={() => onRemoving(index)}>
                Remove
              </div>
            </div>
          )}
        </Col>
        <Col xs={2} className="item-price p-0">
          ${item.totalcost.toFixed(2)}
          {item.customdata?.startsWith("conversion:") && (
            <img
              src={littleBottle}
              alt="little wine bottle"
              className="little-bottle"
              style={{ marginRight: -15 }}
            />
          )}
        </Col>
      </Row>
      {item.customdata &&
        item.customdata.includes("isWine") &&
        memberData.membershipType && memberData.status &&
        memberData.membershipType !== "Potential" &&
        (memberData.status !== "New" && memberData.status !== "To be Activated") && (
          <Row
            className="cart-item-wrap"
            style={{ marginRight: 30 }}
            onClick={() => onConvertBottle(item)}
          >
            <motion.div
              className="use-bottles"
              whileHover={{
                scale: 1.01,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.99 }}
            >
              <img src={littleBottle} className="little-bottle" alt="little wine bottle" />
              <span className="use-bottles-text">USE MY WINE CLUB BOTTLES</span>
              <img src={circleArrow} className="little-circle-arrow" alt="circle arrow" />
            </motion.div>
          </Row>
        )}
    </div>
  );
};

function mapStateToProps(state) {
  const { basket, member, authentication } = state;
  const basketData = get(basket, "data", {});
  const memberData = get(member, "data", {});
  const loggedIn = get(authentication, "loggedIn", false);
  return { basketData, memberData, loggedIn };
}

const connectedCartItem = connect(mapStateToProps)(CartItem);
export { connectedCartItem as CartItem };
