import { basketsConstants } from "../_constants";
import moment from "moment";

export function basket(
  state = {
    loading: false,
    error: null,
    data: {},
    created: null,
    saved: null,
    lastUpdate: null,
    createdTime: null,
    dispatchPhoneNumber: null,
  },
  action
) {
  switch (action.type) {
    case basketsConstants.UPDATE_PRODUCT_REQUEST:
    case basketsConstants.CREATE_BASKET_REQUEST:
    case basketsConstants.GET_BASKET_REQUEST:
    case basketsConstants.GET_UPSELLS_REQUEST:
    case basketsConstants.ADD_PRODUCTS_REQUEST:
    case basketsConstants.UPDATE_BASKET_REQUEST:
    case basketsConstants.DELETE_PRODUCT_REQUEST:
    case basketsConstants.ADD_TIP_REQUEST:
    case basketsConstants.APPLY_COUPON_REQUEST:
    case basketsConstants.REMOVE_COUPON_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        itemsNotTransferred: [],
        lastUpdate: moment().toISOString(true),
      };
    case basketsConstants.RESET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate: null,
      };
    case basketsConstants.UPDATE_PRODUCT_SUCCESS:
    case basketsConstants.CREATE_BASKET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        created: true,
        data: action.basket,
        createdTime: moment().toISOString(true),
      };
    case basketsConstants.GET_UPSELLS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        upsells: action.upsells,
      };
    case basketsConstants.GET_BASKET_SUCCESS:
    case basketsConstants.ADD_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.basket,
      };
    case basketsConstants.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.basket,
      };
    case basketsConstants.SET_CUSTOM_FIELD_SUCCESS:
    case basketsConstants.UPDATE_BASKET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        saved: true,
        data: action.basket || state.data,
        dispatchPhoneNumber: action.dispatchPhoneNumber
      };
    case basketsConstants.ADD_TIP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        saved: true,
        // data: action.basket,
        //TODO: verify that updating basket or adding tip call does not return basket object
      };

    case basketsConstants.APPLY_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        couponApplied: true,
        data: action.basket,
      };

    case basketsConstants.REMOVE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        couponApplied: false,
        data: action.basket,
      };

    case basketsConstants.UPDATE_BASKET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        saved: true,
        data: action.basket,
        itemsNotTransferred: action.itemsNotTransferred,
      };

    case basketsConstants.UPDATE_PRODUCT_FAILURE:
    case basketsConstants.CREATE_BASKET_FAILURE:
    case basketsConstants.GET_BASKET_FAILURE:
    case basketsConstants.GET_UPSELLS_FAILURE:
    case basketsConstants.ADD_PRODUCTS_FAILURE:
    case basketsConstants.UPDATE_BASKET_FAILURE:
    case basketsConstants.DELETE_PRODUCT_FAILURE:
    case basketsConstants.ADD_TIP_FAILURE:
    case basketsConstants.APPLY_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case basketsConstants.TRANSFER_BASKET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        itemsNotTransferred: [],
      };
    case basketsConstants.TRANSFER_BASKET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        created: true,
        data: action.basket,
        itemsNotTransferred: action.itemsNotTransferred,
        createdTime: moment().toISOString(true),
      };
    case basketsConstants.TRANSFER_BASKET_FAILURE:
      return {
        ...state,
        loading: false,
        created: false,
        error: action.error,
        createdTime: null,
      };
    case basketsConstants.RESET_BASKET:
      return {
        ...state,
        loading: false,
        saved: false,
        created: false,
        error: null,
      };
    case basketsConstants.DESTROY_BASKET:
      return {
        ...state,
        loading: false,
        saved: false,
        created: false,
        error: null,
        data: {},
        createdTime: null,
        couponApplied: false
      };

    default:
      return state;
  }
}
