import { settingsConstants } from "../_constants";
import { basketsActions } from "../_actions";

export const settingsActions = {
  setHandoffType
};

function setHandoffType(type) {
  if(type === 'dispatch') {
    basketsActions.coupon('', false);
  }
  return {
    type: settingsConstants.SETTINGS_UPDATE_SUCCESS,
    handoff: type
  };
}
