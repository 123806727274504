import React, { useEffect } from "react";
import { connect } from "react-redux";
import LoadingSpinners from "../../components/LoadingSpinners";
import { paymentActions, memberActions } from "../../_actions";
import { paymentService } from "../../_services";
import get from "lodash/get";
import { PaymentFrame } from "../../components/PaymentFrame";
import { Redirect, useHistory } from "react-router-dom";
import { history } from "../../_helpers";

import "./styles.css";

const DirectPayment = ({
  dispatch,
  url,
  loading,
  status,
  price,
  loggedIn,
  loggingIn,
  currentTab,
  location,
  changeTab,
}) => {
  const [paymentLoaded, setPaymentLoaded] = React.useState(false);
  const [requested, setRequested] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [paramString, setParamString] = React.useState('');

  let globalHistory = useHistory();

  const request = async (params, approved) => {
    await paymentService.postPayment(JSON.parse(params)).then(res => {
      const member = get(res.with, "member", {});
      const cardNum = get(member, "CHW_Member_Number__c", "unknown");
      const firstName = get(member, "Gift_Giver_First_Name__c", "");
      const bottleLevel = get(member, "CHW_Level__c", "");
      const memberType = get(member, "CHW_Membership_Type__c", "");
      const deliveryType = get(member, "CHW_Delivery__c", "");
      const clubType = get(member, "CHW_Club_Type__c", "");
      const nextChargeOutDate = get(res.with, "nextChargeOutDate", "");
      const nextChargeOutMonth = get(res.with, "chargeOutMonths", "");
      const duration = get(member, "CHW_Months_to_Give__c", "");

      if (approved === "approved") {
        var path = "/main";
        dispatch(memberActions.getMember());
        history.replace({
          pathname: path,
          hash: "#profile",
          state: {
            cardNum,
            firstName,
            bottleLevel,
            memberType,
            deliveryType,
            clubType,
            duration,
            nextChargeOutDate,
            nextChargeOutMonth,
            signupType: "update",
            pid: location?.state?.pid,
          },
        });
        changeTab(4);
      } else if (approved === "error") {
        globalHistory.push("/Error", {from: "DirectPayment"});
      }
    });
  };

  useEffect(() => {
    if (!!message && !!paramString) {
      if (requested) {
        request(paramString, message);
      }
    }
  }, [requested, message, paramString]);

  const reactToData = async data => {
    let dataArr = data.split('|');
    let message = dataArr[0];
    let paramString = dataArr[1];
    // store payment now
    if (paramString) {
      setParamString(paramString);
      setMessage(message);
      setRequested(true);
    }
  };

  // Only load payment form if user is logged in and on the correct tab.
  if (!paymentLoaded && currentTab === 5 && status) {
    if (status === 'Active') {
      dispatch(paymentActions.getPaymentForm('0.01', 'CCUPDATE', '1', 'AUTH'));
      setPaymentLoaded(true);
      setTimeout(() => {
        history.replace({
          pathname: '/DirectPayment',
        });
      }, 500);
      return;
    } else if (status === 'UTP') {
      dispatch(
        paymentActions.getPaymentForm(`${price}`, 'CCUPDATE', '1', 'SALE'),
      );
      setPaymentLoaded(true);
      setTimeout(() => {
        history.replace({
          pathname: '/DirectPayment',
        });
      }, 500);
      return;
    } else {
      setTimeout(() => {
        history.replace({ pathname: '/main', hash: '#profile' });
        changeTab(4);
      }, 500);
    }
  }

  if (loggingIn || loading) {
    return <LoadingSpinners />;
  }
  if (loggedIn) {
    return !!url && !!paymentLoaded ? (
      <div className="container main">
        <h4 style={{ color: "#fff" }}>Update Payment Information</h4>
        <button
          className="float-right"
          onClick={() => {
            dispatch(paymentActions.clearPaymentForm());
            setTimeout(() => {
              history.replace({
                pathname: "/main",
                hash: "#profile",
              });
              changeTab(4);
            }, 500);
          }}
          style={{ marginRight: 13 }}>
          Back
        </button>
        <PaymentFrame
          frameAction={data => (!requested ? reactToData(data) : null)}
          update={true}
          url={url}
        />
      </div>
    ) : (
      <LoadingSpinners />
    );
  }
  return <Redirect to="/" />;
};

function mapStateToProps(state) {
  const { member, paymentForm, authentication } = state;
  const { loggingIn, loggedIn } = authentication;
  const url = get(paymentForm, "url", "");
  const price = get(member, "data.costOfMembership", "");
  const status = get(member, "data.status", "");
  const loading = get(paymentForm, "loading", false);
  return {
    url,
    price,
    loading,
    status,
    loggedIn,
    loggingIn,
  };
}

const connectedDirectPayment = connect(mapStateToProps)(DirectPayment);
export { connectedDirectPayment as DirectPayment };
