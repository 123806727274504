const hostname = window.location.hostname;

export const appConstants = {
  paymentReturnUrl: `https://${hostname}/`,
  oloApiUrl: "https://member.chwinery.com/api/olo",
  spreedlyEnvironment: "DbnPN7mjXh1fkdFSyHhsaNfXvyy",
  localApiUrl: "https://member.chwinery.com/v1/",
  webApiUrl: "https://api.chwinery.com/external/web/api/v1/",
  idleTimeLogout: 3600000, // 1 hour

  formatMemberNumber: function(num) {
    var member_card_number = num.replace(/\D/g, ""); // Digits only
    if (/^287\d{9}$/.test(member_card_number)) {
      // Trim full number 12 digit
      member_card_number = parseInt(member_card_number.substr(3, 8));
    } else if (/^287\d{8}$/.test(member_card_number)) {
      // Trim full number 11 digit
      member_card_number = parseInt(member_card_number.substr(3, 7));
    }
    return member_card_number;
  },

  googleMapsApiKey: "AIzaSyAb9BperokTJrfKaD9Wk9A-BvdGtXCsS9Y",
  reCaptchaSiteKeyMUI: "6Lej1fIcAAAAAN6rnJoQeBmsuCzajYDTlWEV3kzo",
  reCaptchaInvisibleSiteKeyMUI: "6LfDSPIcAAAAALOiKdV13-piKWhGo82IFlOFqURm",
  reCaptchaSiteKeyOLO: "6LcU7PIcAAAAAHbbs3pVmZdlRrc0o6HfV2d991l9",
  memberUrl: "https://member.chwinery.com",
  choloUrl: "https://carryout.chwinery.com",

  pretzelChainId: 338996,
  flatwareChainId: 340282,
  gfPretzelChainId: 395797,
  includePrivateLocations: false,
  cardOnFileEnabled: true,
  couponsEnabled: false,

  hideBread: ['8'],
  hideGFBrewad: ['8'],
  hideUtensils: [],

  ABTASTY_ID: '0a978a74834f98b3e4ebf193f736b07b',

  // global messages
  menuPopup: "To redeem gift cards, click Pay In Restaurant at checkout.",
  locatorPopup: "Delivery is only available at select locations."
};

// OLD "(?!^[0-9]*$)(?!^[a-zA-Z!@#$%^&*()_+=<>?]*$)^([a-zA-Z!@#$%^&*()_+=<>?0-9]{6,15})$";
export const passwordLoginRegex = new RegExp(/^.{8,}$/);
export const passwordRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/);
export const passwordRegexPattern = "^(?=.*[A-Za-z])(?=.*\d).{8,}$";
export const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
export const phoneRegex = new RegExp(/^\D*(\d\D*){10}$/g);
export const zipRegex   = new RegExp(/^(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}/g);
export const minDate = "1700-01-01"; // The minimum date that salesforce allows in date fields.