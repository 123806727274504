import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LoadingSpinners from "../LoadingSpinners";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  memberActions,
  newMemberActions,
  paymentActions
} from "../../_actions";
import get from "lodash/get";
import "./styles.css";
import { logGTMEvent } from "../../_helpers";

const WineClubInfo = ({
  editablePrice,
  costChange,
  memberSignatureOptOut,
  clubType,
  bottleLevel,
  status,
  membershipType,
  deliveryType,
  cardOnFile,
  expirationDate,
  cardType,
  lastFour,
  expMonth,
  expYear,
  optOutText,
  clubInfo,
  cardRequestSuccess,
  cardRequested,
  giftLength,
  signup,
  dispatch,
  pricingStructure,
  loading,
  history,
  lockedPrice,
  isPromotion,
  promotionDescription,
  url
}) => {
  const [showSignatureInfo, setShowSignatureInfo] = useState(false);
  const [showClubInfo, setShowClubInfo] = useState(false);
  const [pricingString, setPricingString] = useState(
    signup === "monthly" || signup === "profile"
      ? `Monthly:${deliveryType}:${clubType}:${bottleLevel}:`
      : `Gift:${deliveryType}:${clubType}:${bottleLevel}:${giftLength}`
  );

  useEffect(() => {
    if (cardRequested) {
      confirmCardReplacement();
    }
  });

  useEffect(() => {
    setPricingString(
      signup === "monthly" || signup === "profile"
        ? `Monthly:${deliveryType}:${clubType}:${bottleLevel}:`
        : `Gift:${deliveryType}:${clubType}:${bottleLevel}:${giftLength}`
    );
    if (pricingStructure[pricingString]) {
      costChange(pricingStructure[pricingString]);
    }
  }, [
    deliveryType,
    clubType,
    bottleLevel,
    giftLength,
    costChange,
    pricingString,
    pricingStructure,
    signup
  ]);

  useEffect(() => {
    if(isPromotion){
      bottleLevel = "2";
      deliveryType = "Pickup";
      clubType = "Variety";
      dispatch(newMemberActions.setBottleLevel(bottleLevel));
      dispatch(newMemberActions.setDeliveryType(deliveryType));
      dispatch(newMemberActions.setClubType(clubType));
    }
  }, [isPromotion]);

  const handleOrderReplacementCard = () => {
    if (
      window.confirm(
        "You are about to order a Replacement Member Card which will arrive at your home address in approximately two weeks. Please contact Member and Guest Services Concierge with questions (708-215-5674)\n\nContinue?"
      )
    ) {
      dispatch(memberActions.postReplacementCard());
    }
  };

  const confirmCardReplacement = () => {
    window.alert(
      cardRequestSuccess === "OK"
        ? "Your Replacement Member Card request was received.\nYour new card will be mailed to you shortly."
        : "Something went wrong with your Replacement Member Card request.\nPlease call Member & Guest Services. (708-215-5674)"
    );
    dispatch(memberActions.confirmReplacementCard());
  };

  if (loading) {
    return <LoadingSpinners />;
  }

  return (
    <div>
      <h2 className="log-title">Wine Club Information
        <div
          style={{ cursor: "pointer", fontSize: "0.5em" }}
          onClick={() => setShowClubInfo(true)}
          className="badge badge-secondary d-inline-block m-1"
          data-target="#wineclub_info_modal"
          data-toggle="modal"
        >
          i
        </div>
      </h2>
      {
        membershipType === "Founder" ? (
          <Col className="pt-4 pb-2 text-center">
            <p>
              {bottleLevel} Bottle {clubType} {membershipType} {deliveryType}{" "}
              Membership
            </p>
          </Col>
        ) : membershipType === "Gift" ? (
          <Col className="pt-4 pb-2 text-center">
            <p>
              Your {bottleLevel} Bottle {clubType} {membershipType} {deliveryType}{" "}
              Membership expires on: {expirationDate}
            </p>
          </Col>
        ) : membershipType === "Supplier" ? (
          <Col className="pt-4 pb-2 text-center">
            <p>
              {clubType} Supplier Club {deliveryType}{" "}
              Membership {expirationDate ? `expires on: ${expirationDate}` : ''}
            </p>
          </Col>
        ) : 
        (
          <>
            <Form.Group as={Row} controlId="cost">
              <Col md={7} sm={7} xs={7}>
                <Form.Label>Cost of Membership:</Form.Label>
              </Col>
              <Col md={5} sm={5} xs={5}>
                <Form.Control
                  value={
                    pricingStructure[pricingString] === undefined
                      ? ""
                      : `$${pricingStructure[pricingString]}`
                  }
                  type="text"
                  name="costOfMembership"
                  readOnly
                />
              </Col>
            </Form.Group>

            {signup === "gift" && (
              <>
                <Form.Group as={Row} className="py-2" controlId="length">
                  <Form.Label as="giftlength" />

                  <Col md={4}>
                    <Form.Check
                      type="radio"
                      value="3"
                      id="winte-club-three-months"
                      label="3 Months"
                      name="giftLengths"
                      onChange={e =>
                        dispatch(newMemberActions.setGiftLength("3"))
                      }
                      checked={giftLength === "3"}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Check
                      type="radio"
                      value="6"
                      id="wine-club-six-months"
                      label="6 Months"
                      name="giftLengths"
                      onChange={e =>
                        dispatch(newMemberActions.setGiftLength("6"))
                      }
                      checked={giftLength === "6"}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Check
                      type="radio"
                      value="12"
                      id="wine-club-twelve-months"
                      label="12 Months"
                      name="giftLengths"
                      onChange={e =>
                        dispatch(newMemberActions.setGiftLength("12"))
                      }
                      checked={giftLength === "12"}
                    />
                  </Col>
                </Form.Group>
              </>
            )}

            <Form.Group as={Row} className="py-2">
              {!isPromotion &&
              <Col md={4} style={{paddingRight: 0, display: "flex", flexDirection: 'row'}}>
                <Form.Check
                  type="radio"
                  value="1"
                  id="one-bottle-per-month"
                  // label="1 Bottle Per Month"
                  name="monthlyBottleOptions"
                  onChange={e =>
                    signup === "profile"
                      ? dispatch(memberActions.updateBottleLevel("1"))
                      : dispatch(newMemberActions.setBottleLevel("1"))
                  }
                  checked={bottleLevel === "1"}
                />
                <label for="one-bottle-per-month">1 Bottle Per Month</label>
              </Col>
              }
              <Col md={isPromotion ? 12 : 4} style={{paddingRight: 0, display: "flex", flexDirection: 'row'}}>
                <Form.Check
                  type="radio"
                  value="2"
                  id="two-bottles-per-month"
                  name="monthlyBottleOptions"
                  onChange={e =>
                    signup === "profile"
                      ? dispatch(memberActions.updateBottleLevel("2"))
                      : dispatch(newMemberActions.setBottleLevel("2"))
                  }
                  checked={bottleLevel === "2"}
                />
                <label for="two-bottles-per-month">{`2 Bottles Per Month ${isPromotion && promotionDescription ? `+ ${promotionDescription}` : ''}`}</label>
              </Col>
              {deliveryType === "Pickup" &&
              <Col md={isPromotion ? 12 : 4} style={{paddingRight: 0, display: "flex", flexDirection: 'row'}}>
                <Form.Check
                  type="radio"
                  value="3"
                  id="three-bottles-per-month"
                  name="monthlyBottleOptions"
                  onChange={e => {
                    if(signup === "profile"){
                      dispatch(memberActions.updateBottleLevel("3"))
                    } else {
                      dispatch(newMemberActions.setBottleLevel("3"));
                      dispatch(newMemberActions.setDeliveryType("Pickup"));
                    }
                  }}
                  checked={bottleLevel === "3"}
                />
                <label for="three-bottles-per-month">{`3 Bottles Per Month ${isPromotion && promotionDescription ? ` + ${promotionDescription}` : ''}`}</label>
              </Col>
              }
            </Form.Group>
            <Form.Group as={Row} className="py-2">
              <Col md={6} style={{display: "flex", flexDirection: 'row'}}>
                <Form.Check
                  type="radio"
                  value="Variety"
                  id="variety"
                  name="clubOptions"
                  onChange={e =>
                    signup === "profile"
                      ? dispatch(memberActions.updateClubType("Variety"))
                      : dispatch(newMemberActions.setClubType("Variety"))
                  }
                  checked={clubType === "Variety"}
                />
                <label for="variety">Variety Club</label>
              </Col>
              <Col md={6} style={{display: "flex", flexDirection: 'row'}}>
                <Form.Check
                  type="radio"
                  value="Red"
                  id="red"
                  name="clubOptions"
                  onChange={e =>
                    signup === "profile"
                      ? dispatch(memberActions.updateClubType("Red"))
                      : dispatch(newMemberActions.setClubType("Red"))
                  }
                  checked={clubType === "Red"}
                />
                <label for="red">Red Club</label>
              </Col>
              <Col md={6} style={{display: "flex", flexDirection: 'row'}}>
                <Form.Check
                  type="radio"
                  value="White"
                  id="white"
                  name="clubOptions"
                  onChange={e =>
                    signup === "profile"
                      ? dispatch(memberActions.updateClubType("White"))
                      : dispatch(newMemberActions.setClubType("White"))
                  }
                  checked={clubType === "White"}
                />
                <label for="white">White Club</label>
              </Col>
              {!isPromotion && (
              <Col md={6} style={{display: "flex", flexDirection: 'row'}}>
                <Form.Check
                  type="radio"
                  value="Sweet"
                  id="sweet"
                  name="clubOptions"
                  onChange={e =>
                    signup === "profile"
                      ? dispatch(memberActions.updateClubType("Sweet"))
                      : dispatch(newMemberActions.setClubType("Sweet"))
                  }
                  checked={clubType === "Sweet"}
                />
                <label for="sweet">Sweet Club</label>
              </Col>
              )}
            </Form.Group>
            {signup !== "profile" && (
              <>
                <Form.Group as={Row} className="py-2">
                  <Col md={6}>
                    <Form.Check
                      type="radio"
                      id="pickup"
                      value="Restaurant"
                      label="In-Restaurant Pickup"
                      name="deliveryOptions"
                      onChange={e =>
                        dispatch(newMemberActions.setDeliveryType("Pickup"))
                      }
                      checked={deliveryType === "Pickup"}
                    />
                  </Col>
                  {(!isPromotion && bottleLevel !== "3") &&
                  <Col md={6}>
                    <Form.Check
                      type="radio"
                      value="Delivery"
                      id="delivery"
                      label={<>Quarterly Shipment <span style={{fontSize: "0.8em"}}>(Select States Only)</span></>}
                      name="deliveryOptions"
                      onChange={e =>
                        dispatch(newMemberActions.setDeliveryType("Delivery"))
                      }
                      checked={deliveryType === "Delivery"}
                    />
                  </Col>
                  }
                </Form.Group>
              </>
            )}
          </>
        )
      }
      {(
        <>
          {(status === "Active" || status === "UTP") && (
            <>
              <Form.Group as={Row} className="justify-content-center">
                <Form.Check
                  type="checkbox"
                  id="member-signature-opt-out"
                  name="optOut"
                  onChange={e => dispatch(memberActions.updateOptOut())}
                  checked={memberSignatureOptOut}
                />
                <label for="member-signature-opt-out">Member Signature Opt Out</label>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowSignatureInfo(true)}
                  className="badge badge-secondary d-inline-block m-1"
                  data-target="#member_signature_modal"
                  data-toggle="modal"
                >
                  ?
                </div>
                <div className="check-subtext">
                  Check the box to disable Member Signature.
                </div>
                <Col sm={12} className="text-center small mt-2">
                  {membershipType !== 'Supplier' &&  <Button
                    className="replacement-card"
                    onClick={() =>
                      status === "Active" ? handleOrderReplacementCard() : {}
                    }
                  >
                    Order a Replacement Member Card for Damaged, Lost, or Stolen
                    Cards
                  </Button> }
                </Col>
                {cardOnFile !== "" && (
                  <h5
                    style={{
                      marginTop: "1em",
                      width: "100%",
                      textAlign: "center",
                      color: "white"
                    }}
                  >
                    <strong>{cardType}</strong> ending in{" "}
                    <strong>{lastFour}</strong> expires on{" "}
                    <strong>
                      {expMonth}/{expYear}
                    </strong>
                  </h5>
                )}
                <Button
                  onClick={() => {
                    logGTMEvent('update_payment_click', {page_title: 'Member Profile', page_path: 'main#profile', page_referrer: `${document.referrer}`})
                    switch (status) {
                      case "Active":
                        dispatch(
                          paymentActions.getPaymentForm(
                            "0.01",
                            "CCUPDATE",
                            "1",
                            "AUTH"
                          )
                        );
                        break;
                      case "UTP":
                        dispatch(
                          paymentActions.getPaymentForm(
                            `${lockedPrice}`,
                            "CCUPDATE",
                            "1",
                            "SALE"
                          )
                        );
                        break;
                      default:
                        dispatch(
                          paymentActions.getPaymentForm(
                            `${editablePrice}`,
                            "CCUPDATE",
                            "1",
                            "SALE"
                          )
                        );
                    }
                    setTimeout(() => {
                      history.push({
                        pathname: "/Payment",
                        state: { signupType: "update", url }
                      });
                    }, 500);
                  }}
                >
                  {cardOnFile === "" ? "Add " : "Update "} Credit Card
                </Button>
              </Form.Group>

              <Modal show={showSignatureInfo} onHide={() => setShowSignatureInfo(false)} centered>
                <Modal.Header closeButton style={{color: "white", backgroundColor: "black"}}>
                  <Modal.Title>Member Signature</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{color: "white", backgroundColor: "black"}}>
                  {optOutText}
                </Modal.Body>
              </Modal>
            </>
          )}
          <Modal show={showClubInfo} onHide={() => setShowClubInfo(false)} centered>
                <Modal.Header closeButton style={{color: "white", backgroundColor: "black"}}>
                  <Modal.Title>Wine Club Information</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{color: "white", backgroundColor: "black"}}>
                  {parse(clubInfo)}
                </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    member,
    newMember,
    staticContent,
    replacementCard,
    paymentForm
  } = state;
  const url = get(paymentForm, "url", "");
  const loading = get(paymentForm, "loading", false);
  const membershipType = get(member, "data.membershipType", "");
  const price = get(member, "data.costOfMembership", "");
  const memberSignatureOptOut = get(
    member,
    "data.memberSignatureOptOut",
    false
  );
  const giftLength = get(newMember, "data.giftLength", "");
  const clubType =
    ownProps.signup === "profile"
      ? get(member, "data.clubType", "Red")
      : get(newMember, "data.clubType", "Red");
  const bottleLevel =
    ownProps.signup === "profile"
      ? get(member, "data.bottleLevel", "")
      : get(newMember, "data.bottleLevel", "1");
  const deliveryType =
    ownProps.signup === "profile"
      ? get(member, "data.deliveryType", "Pickup")
      : get(newMember, "data.deliveryType", "Pickup");

  const expirationDate = get(member, "data.expirationDate", "");

  const cardType = get(
    member,
    "data.storedPaymentAccount.BluePay__Card_Type__c",
    "Card"
  );
  const lastFour = get(
    member,
    "data.storedPaymentAccount.BluePay__Card_Number__c",
    ""
  ).slice(12, 16);
  const expMonth = get(
    member,
    "data.storedPaymentAccount.BluePay__Expiration_Month__c",
    ""
  );
  const expYear = get(
    member,
    "data.storedPaymentAccount.BluePay__Expiration_Year__c",
    ""
  );
  const cardOnFile = get(
    member,
    "data.storedPaymentAccount.Id",
    ""
  );

  const optOutText = get(staticContent, "data.messages.memberSignature", "");
  const clubInfo = get(staticContent, "data.messages.clubInfo", "");
  const pricingStructure = get(staticContent, "data.pricingStructure", {});
  const cardRequestSuccess = replacementCard.data;
  const cardRequested = replacementCard.requested;

  return {
    memberSignatureOptOut,
    clubType,
    membershipType,
    bottleLevel,
    deliveryType,
    cardOnFile,
    expirationDate,
    cardType,
    lastFour,
    expMonth,
    expYear,
    optOutText,
    clubInfo,
    cardRequested,
    cardRequestSuccess,
    giftLength,
    pricingStructure,
    url,
    lockedPrice: price,
    loading
  };
}

const connectedWineClubInfo = withRouter(
  connect(mapStateToProps)(WineClubInfo)
);
export { connectedWineClubInfo as WineClubInfo };
