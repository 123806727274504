import { pickupHistoryConstants } from "../_constants";

export function pickupHistory(state = { loading: false }, action) {
  switch (action.type) {
    case pickupHistoryConstants.PICKUP_HISTORY_REQUEST:
      return {
        loading: true
      };
    case pickupHistoryConstants.PICKUP_HISTORY_SUCCESS:
      return {
        loading: false,
        data: action.pickupHistory
      };
    case pickupHistoryConstants.PICKUP_HISTORY_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
