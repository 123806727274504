import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import './styles.scss';
import grayArrow from '../../assets/images/icon-circle-arrow.png';
import arrowStart from '../../assets/images/icon-circle-arrow-forward.svg';
import arrowStartEsquire from '../../assets/images/icon-circle-arrow-esquire.svg';
import { Restaurants } from '../../_services/restaurants.service';
import { get, find } from 'lodash';
import { isEsquireSlug, isThemeEsquire } from '../../_helpers';

const LocationCard = ({
  data,
  onCardClick,
  highlight,
  innerRef,
  onButtonClick,
  mobileMap,
  isHome,
  handoffMode,
  showMap = false,
  setShowMap = () => {},
}) => {
  const moment = require('moment');
  const [mouseDown, setMouseDown] = useState(false);
  const [calendar, setCalendar] = useState([]);

  const isEsquireWeb = isThemeEsquire();

  const selected = () => {
    setMouseDown(false);
    onCardClick();
  };

  const getTime = (days, open = false) => {
    const OLO_FORMAT = 'YYYYMMDD HH:mm';
    const TIME_LABEL_FORMAT = 'h:mm a';
    const todayM = moment();
    const today = days.find(
      day => moment(day.start, OLO_FORMAT).date() === todayM.date(),
    );
    if (today) {
      return {
        open: moment(today.start, OLO_FORMAT).format(TIME_LABEL_FORMAT),
        close: moment(today.end, OLO_FORMAT).format(TIME_LABEL_FORMAT),
      };
    }
    return null;
  };

  useEffect(() => {
    if (data) {
      Restaurants.getHours(data.id).then(res => {
        setCalendar(res.calendar);
      });
    }
  }, [data]);

  const restaurantHours = find(calendar, {
    type: handoffMode === 'dispatch' ? 'dispatch' : 'curbsidepickup',
  });
  const ranges = get(restaurantHours, 'ranges', []);
  const hours = getTime(ranges);
  const isEsquire = isEsquireSlug(data.slug);
  const highlightClassName = isEsquire ? 'esquire-highlight' : 'highlight';

  return (
    <div className="olo-location-card">
      {isHome && <p className="sub-title">Recent location:</p>}
      <motion.div
        key={data.id}
        ref={innerRef}
        className={
          mobileMap
            ? `card-container mobile ${highlightClassName}`
            : highlight
            ? `card-container ${highlightClassName}`
            : 'card-container'
        }
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={selected}
        transition={{ duration: 0.075 }}
        animate={
          mouseDown
            ? {
                scale: [1, 0.98],
                boxShadow:
                  '-1px -1px -1px -1px rgba(150, 150, 150, 0.2), -1px -1px -1px -1px  rgba(250, 250, 250, 0.1)',
              }
            : {
                scale: [0.98, 1],
                boxShadow:
                  '1px 1px 1px 1px rgba(150, 150, 150, 0.2), 1px 1px 1px 1px  rgba(250, 250, 250, 0.1)',
              }
        }>
        <div className="address-container">
          <div className="card-title">Cooper's Hawk {data.name}</div>
          <div>
            <div className="address-line">{`${data.streetaddress} ${
              data.city
            }, ${data.state}`}</div>
            <div className="address-line">
              {data.iscurrentlyopen && hours
                ? `From ${hours.open} - ${hours.close}`
                : 'Closed'}
            </div>
            {isEsquireWeb && (
              <div
                className="address-line view-btn map-visibiliy-button"
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => {
                  e.stopPropagation();
                  setMouseDown(false);
                  setShowMap(!showMap);
                }}>
                {showMap ? 'Hide Map' : 'Show Map'}
              </div>
            )}
          </div>
        </div>
        <div
          className={`start-container ${
            isEsquire ? 'start-container-esquire' : ''
          }`}
          onMouseDown={e => {
            e.stopPropagation();
            if (highlight) {
              onButtonClick();
            }
            return false;
          }}>
          {highlight && <div className="address-line start">start order</div>}
          <img
            src={
              highlight
                ? isEsquire
                  ? arrowStartEsquire
                  : arrowStart
                : grayArrow
            }
            alt="arrow"
            className="icon"
          />
        </div>
        <div className="icons-container">
          <div className="inner-icons-container">
            {data.milesFromSearch && (
              <div className="address-line miles">{`${data.milesFromSearch.toFixed(
                1,
              )} Miles`}</div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default LocationCard;

LocationCard.propTypes = {
  data: PropTypes.object,
  onCardClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  highlight: PropTypes.bool,
  mobileMap: PropTypes.bool,
};

LocationCard.defaultProps = {
  data: {
    candeliver: false,
    canpickup: true,
    city: 'New York City',
    id: 20638,
    isavailable: true,
    iscurrentlyopen: true,
    latitude: 40.7052622,
    longitude: -74.0132119,
    name: "Cooper's Hawk Winery & Restaurants Demo Vendor",
    productrecipientnamelabel: 'Name:',
    requiresphonenumber: true,
    showcalories: false,
    slug: 'coopers-hawk-winery-restaurants-demo-vendor',
    state: 'NY',
    storename: "Cooper's Hawk Winery & Resturants",
    streetaddress: '26 Broadway',
    telephone: '(555) 555-5555',
    zip: '10004',
  },
  onCardClick: () => {},
  onButtonClick: () => {},
  highlight: false,
  mobileMap: false,
};
