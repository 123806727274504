import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { shippingStateOptions, stateOptions } from "../../_helpers";
import { connect } from "react-redux";
import { newMemberActions } from "../../_actions";
import { minDate } from "../../_constants";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const ShippingDetails = ({
  formIsValid,
  dispatch,
  required,
  shippingFirstName,
  shippingLastName,
  birthday,
  shippingStreet,
  shippingCity,
  shippingState,
  shippingZipCode,
  shippingEmail,
  // giftShipDate,
  signup,
  deliveryType,
  todayDate,
  invalidAge,
  innerWidth,
}) => {
  var today = new Date();
  var dd = (today.getDate() < 10 ? "0" : "") + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? "0" : "") + (today.getMonth() + 1);
  var yyyy = today.getFullYear() - 21;
  const validBirthdate = yyyy + "-" + mm + "-" + dd;
  const [validated, setValidated] = useState(false);
  let formElement;

  useEffect(() => {
    const shippingFilled = (deliveryType === "Pickup") ?
      !isEmpty(shippingFirstName) &&
      !isEmpty(shippingLastName) &&
      !isEmpty(shippingEmail)
      :
      !isEmpty(shippingFirstName) &&
      !isEmpty(shippingLastName) &&
      !isEmpty(shippingEmail) &&
      !isEmpty(shippingStreet) &&
      !isEmpty(shippingCity) &&
      !isEmpty(shippingState) &&
      !isEmpty(shippingZipCode);
    setValidated(shippingFilled);
    formIsValid(formElement.checkValidity() && !invalidAge);
  }, [
    deliveryType,
    dispatch,
    birthday,
    shippingStreet,
    shippingEmail,
    shippingFirstName,
    shippingLastName,
    shippingCity,
    shippingState,
    shippingZipCode,
    signup,
  ]);

  const isPickup = deliveryType === "Pickup";
  const states = isPickup ? stateOptions : shippingStateOptions;

  const validateAge = () => {
    if (birthday > validBirthdate) {
      invalidAge = true;
    }
  };

  const formControlStyle = {
  backgroundColor: 'white',
  color: 'black',
  borderColor: '#000',
  borderRadius: 10,
}

  return (
    <div>
      <h3 className="text">My {isPickup ? '' : 'SHIPPING'} Details</h3>
      <Form
        noValidate
        validated={validated}
        ref={(element) => (formElement = element)}
      >
        <Form.Group as={Row} onSubmit={validateAge()}>
          <Col sm={6}>
            <Form.Label className="input-label" for="shipping-details-first-name">First Name*</Form.Label>
            <Form.Control
            style={formControlStyle}
            id="shipping-details-first-name"
              value={shippingFirstName}
              onChange={(e) =>
                dispatch(newMemberActions.setShippingFirstName(e.target.value))
              }
              type="text"
              color="black"
              name="shippingFirstName"
              required={required}
            />
            <Form.Control.Feedback type="invalid" >
              Please provide a valid first name.
            </Form.Control.Feedback>
          </Col>

          <Col sm={6}>
            <Form.Label className="input-label" for="shipping-details-last-name">Last Name*</Form.Label>
            <Form.Control
            style={formControlStyle}
              value={shippingLastName}
              onChange={(e) =>
                dispatch(newMemberActions.setShippingLastName(e.target.value))
              }
              type="text"
              id="shipping-details-last-name"
              name="shippingLastName"
              required={required}
            />
            <Form.Control.Feedback  type="invalid">
              Please provide a valid last name.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {!isPickup && (
          <>
          <Form.Group as={Row}>
            <Col sm={12}>
              <Form.Label className="input-label" for="shipping-details-street">Street Address*</Form.Label>
              <Form.Control
              style={formControlStyle}
                type="input"
                value={shippingStreet || ""}
                onChange={(e) =>
                  dispatch(newMemberActions.setShippingStreet(e.target.value))
                }
                name="street"
                id="shipping-details-street"
                required={required}
              />
              <Form.Control.Feedback  type="invalid">
                Please provide a valid street.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col sm={8}>
              <Form.Label className="input-label" for="shipping-details-city">City*</Form.Label>
              <Form.Control
              style={formControlStyle}
                value={shippingCity || ""}
                onChange={(e) =>
                  dispatch(newMemberActions.setShippingCity(e.target.value))
                }
                type="text"
                name="city"
                id="shipping-details-city"
                required={required}
              />
              <Form.Control.Feedback  type="invalid">
                Please provide a valid city.
              </Form.Control.Feedback>
            </Col>
            <Col sm={2}>
              <Form.Label className="input-label" for="shipping-details-state">State*</Form.Label>
              <div className="form-control-select-custom-border"/>
              <Form.Control
                style={{...formControlStyle, position: 'relative', bottom: 38, borderColor: 'transparent', borderRightWidth: 10}}
                as="select"
                id="shipping-details-state"
                onChange={(e) =>
                  dispatch(newMemberActions.setShippingState(e.target.value))
                }
                value={shippingState || ""}
                required={required}
              >
                {states.map((state) => (
                  <option key={state.id} value={state.value}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback  type="invalid" className="shift-up">
                Please provide a valid state.
              </Form.Control.Feedback>
            </Col>
            <Col sm={2} style={innerWidth <= 576 ? {position: 'relative', bottom: 32} : {}}>
              <Form.Label className="input-label" for="shipping-details-zipcode">Zip Code*</Form.Label>
              <Form.Control
              style={formControlStyle}
                pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
                type="input"
                onChange={(e) =>
                  dispatch(newMemberActions.setShippingZip(e.target.value))
                }
                value={shippingZipCode || ""}
                name="zipCode"
                id="shipping-details-zipcode"
                required={required}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip code.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          </>
        )}
        <Form.Group as={Row} className={`${!isPickup ? "shift-up" : ""}`}>
          <Col sm={6}>
            <Form.Label className="input-label" for="shipping-details-birthday">Birthdate*</Form.Label>
            <Form.Control
            style={formControlStyle}
              value={birthday}
              onChange={(e) => dispatch(newMemberActions.setShippingBirthday(e.target.value))
              }
              type="date"
              name="birthday"
              id="shipping-details-birthday"
              placeholder="YYYY-MM-DD"
              required={required}
              max={validBirthdate}
              min={minDate}
              isInvalid={invalidAge}
              pattern="\d{4}-\d{2}-\d{2}"
            />
            <Form.Control.Feedback  type="invalid">
              Please provide a valid birthdate.
            </Form.Control.Feedback>
          </Col>
          <Col sm={6}>
            <Form.Label className="input-label" for="shipping-details-email">Email*</Form.Label>
            <Form.Control
            style={formControlStyle}
              value={shippingEmail}
              onChange={(e) =>
                dispatch(newMemberActions.setShippingEmail(e.target.value))
              }
              type="text"
              id="shipping-details-email"
              color="black"
              name="email"
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              required={required}
            />
            <Form.Control.Feedback  type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        {/* {deliveryType === "Delivery" && (
          <>
            <div className="vert-spacer" />
            <Form.Group as={Row} className="justify-content-center">
              <Col sm={"auto"} className="my-auto">
                <Form.Label className="mb-0">
                  Welcome package ship date
                </Form.Label>
              </Col>
              <Col sm={"auto"}>
                <Form.Control
                  value={giftShipDate}
                  onChange={(e) =>
                    dispatch(newMemberActions.setShipDate(e.target.value))
                  }
                  type="date"
                  name="shipDate"
                  required={false}
                  min={todayDate}
                />
              </Col>
            </Form.Group>{" "}
          </>
        )} */}
      </Form>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  var today = new Date();
  var dd = (today.getDate() < 10 ? "0" : "") + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? "0" : "") + (today.getMonth() + 1);
  var yyyy = today.getFullYear();
  const todayDate = yyyy + "-" + mm + "-" + dd;

  const { newMember } = state;
  const shipAddress = get(newMember, "data.shippingAddress", "");
  const shippingFirstName = get(shipAddress, "firstName", "");
  const shippingLastName = get(shipAddress, "lastName", "");
  const birthday = get(shipAddress, "birthday", "");
  const shippingStreet = get(shipAddress, "street", "");
  const shippingCity = get(shipAddress, "city", "");
  const shippingSt = get(shipAddress, "state", "");
  const shippingZipCode = get(shipAddress, "zipCode", "");
  const shippingEmail = get(shipAddress, "email", "");
  // const giftShipDate = get(newMember, "data.shipDate", todayDate);
  const deliveryType = get(newMember, "data.deliveryType", "");

  const invalidAge = false;

  return {
    shippingFirstName,
    shippingLastName,
    birthday,
    shippingStreet,
    shippingCity,
    shippingState: shippingSt,
    shippingZipCode,
    shippingEmail,
    // giftShipDate,
    deliveryType,
    todayDate,
    invalidAge
  };
}

const connectedShippingDetails = connect(mapStateToProps)(ShippingDetails);
export { connectedShippingDetails as ShippingDetailsGift };
