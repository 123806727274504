import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { stateOptions } from "../../_helpers/state-options";
import { connect } from "react-redux";
import { memberActions, newMemberActions } from "../../_actions";
import get from "lodash/get";
import "./styles.css";

const HomeAddressInfoNewUser = ({
  dispatch,
  street,
  city,
  state,
  zipCode,
  signup
}) => {

  const formControlStyle = {
  backgroundColor: 'white',
  color: 'black',
  borderColor: '#000',
  borderRadius: 10,
};

  return (
    <div>
      <h4 className="section-header">Home Address</h4>

      <Form.Group style={{paddingTop: 5}}>
        <Form.Label className="input-label" for="home-address-street">
          Street*
        </Form.Label>
        <Col sm={16}>
          <Form.Control
          style={formControlStyle}
            as="textarea"
            rows="2"
            value={street}
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateHomeStreet(e.target.value))
                : dispatch(newMemberActions.setHomeStreet(e.target.value))
            }
            name="street"
            id="home-address-street"
            required={true}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid street.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group>
        <Form.Label className="input-label" for="home-address-city">
          City*
        </Form.Label>
        <Col sm={16}>
          <Form.Control
          style={formControlStyle}
            value={city}
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateHomeCity(e.target.value))
                : dispatch(newMemberActions.setHomeCity(e.target.value))
            }
            type="text"
            id="home-address-city"
            name="city"
            required={true}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group>
        <Form.Label className="input-label" for="home-address-state">
          State*
        </Form.Label>
        <Col sm={16}>
          <div className="form-control-select-custom-border"/>
          <Form.Control
          style={{...formControlStyle, position: 'relative', bottom: 38, borderColor: 'transparent', borderRightWidth: 10}}
            as="select"
            id="home-address-state"
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateHomeState(e.target.value))
                : dispatch(newMemberActions.setHomeState(e.target.value))
            }
            value={state}
            required={true}
          >
            {stateOptions.map(state => (
              <option key={state.id} value={state.value}>
                {state.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" className="shift-up">
            Please provide a valid state.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group className="shift-up">
        <Form.Label className="input-label" for="home-address-zipcode">
          Zip Code*
        </Form.Label>
        <Col sm={16}>
          <Form.Control
          style={formControlStyle}
            type="input"
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateHomeZip(e.target.value))
                : dispatch(newMemberActions.setHomeZip(e.target.value))
            }
            value={zipCode}
            name="zipCode"
            id="home-address-zipCode"
            required={true}
            pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid zip code.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { member, newMember } = state;

  const homeAddress =
    ownProps.signup === "profile"
      ? get(member, "data.homeAddress", "")
      : get(newMember, "data.homeAddress", "");

  const street = get(homeAddress, "street", "");
  const city = get(homeAddress, "city", "");
  const st = get(homeAddress, "state", "");
  const zipCode = get(homeAddress, "zipCode", "");

  return { street, city, state: st, zipCode };
}

const connectedHomeAddressInfoNewUser = connect(mapStateToProps)(HomeAddressInfoNewUser);
export { connectedHomeAddressInfoNewUser as HomeAddressInfoNewUser };
