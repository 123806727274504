import { subscribeConstants } from "../_constants";

export function marketingSubscribe(
  state = {
    loading: false,
    inSF: false,
    data: {
        address: {
            zip: ""
        },
        birthday: "",
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
        pid: "",
    },
  },
  action
) {
  switch (action.type) {
    case subscribeConstants.CLEAR_MARKETING_SUBSCRIBE:
      return {
        ...state,
        loading: false,
        inSF: false,
        data: {
            address: {
                zip: ""
            },
            birthday: "",
            phone: "",
            email: "",
            firstName: "",
            lastName: "",
            pid: "",
        },
      };
    case subscribeConstants.SUBSCRIBE_REQUEST:
      return {
        ...state,
        loading: true,
        paymentUrl: null,
      };
    case subscribeConstants.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentUrl: action.response.with,
      };
    case subscribeConstants.SUBSCRIBE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case subscribeConstants.SET_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          email: action.email,
        },
      };
    case subscribeConstants.SET_BIRTHDAY:
      return {
        ...state,
        data: {
          ...state.data,
          birthday: action.birthday,
        },
      };

    case subscribeConstants.SET_FIRSTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          firstName: action.firstName,
        },
      };
    case subscribeConstants.SET_LASTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          lastName: action.lastName,
        },
      };
    case subscribeConstants.SET_PHONE:
      return {
        ...state,
        data: {
          ...state.data,
          phone: action.phone,
        },
      };
    case subscribeConstants.SET_ZIP:
      return {
        ...state,
        data: {
          ...state.data,
          address: {
            ...state.data.address,
            zip: action.zip,
          },
        },
      };
    case subscribeConstants.SET_PROMOTION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          pid: action.pid,
        },
      };

    default:
      return state;
  }
}
