import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import BenefitsColumn from "./benefitsColumn";
import Message from "./Message";
import ErrorBanner from "./ErrorBanner";
import get from "lodash/get";
import moment from "moment";
import "./styles.css";
import { SupplierMemberInfo } from "../../components/Forms/supplier-member-info";
import { useRenameDocument } from "../../context/renameDocument";
import { memberService } from '../../_services';
import { memberActions, alertActions } from '../../_actions';
import secureStorage from "../../_helpers/secureStorage";

const WINDOW_OFFSET = 0;
const ERROR_TIME = 6000;

const NewSupplierMemberSignUp = ({
  history,
  member: { supplierInfo, data, contacts },
  authentication: { loggedIn },
  member,
  dispatch
}) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [message, setMessage] = useState('valid');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const inviteCode = urlParams.get('code');
  const reCaptchaRef = useRef();
  const [errorBanner, setErrorBanner] = useState('');
  const [isSignin, setIsSignin] = useState(false);

  window.addEventListener('resize', () => { setInnerWidth(window.innerWidth) });

  const toggleSignin = () => {
    setIsSignin(!isSignin);
  }

  useRenameDocument('Supplier Member Sign Up');

  useEffect(() => {
    dispatch(memberActions.clearAuthErrors());
  }, [])

  useEffect(() => {
    if (inviteCode) checkCode(inviteCode);
  }, [inviteCode])

  useEffect(() => {
    if (loggedIn) {
      setIsSignin(false);
    }
  }, [loggedIn])

  const showError = (error) => {
    setErrorBanner(error);
    window.scrollTo(0, WINDOW_OFFSET);
    setTimeout(() => {
      setErrorBanner('');
    }, ERROR_TIME);
  }



  const checkDate = async (result) => {
    const today = moment();
    if (today.isBefore(result?.programStartDate)) return 'early';
    if (today.isAfter(result?.programEndDate)) return 'expired';
    if (result?.programIsActive && !today.isBefore(result?.programStartDate) && !today.isAfter(result?.programEndDate) && !result?.hasAvailableUses) return 'used';
    if (!result?.programIsActive) return 'inactive';
    return 'valid';
  };

  const checkCode = async (code) => {
    const result = await memberService.checkSupplierCode(code);
    if (result) checkDate(result).then((m) => {
      setMessage(m);
    })
    return result;
  }

  const hasSupplier = async () =>
    contacts?.find(
      ({ CHW_Status__c, CHW_Membership_Type__c }) => CHW_Membership_Type__c === 'Supplier' && CHW_Status__c === 'Active'
    );

  const claim = async (event) => {
    const form = document.forms[0];
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      showError('Please make sure all required fields are filled in and correct');
    } else {
      setLoading(true);
      const result = await checkCode(supplierInfo.code);
      setStartDate(moment(result?.programStartDate).format('MMMM Do'));
      setEndDate(moment(result?.programEndDate).format('MMMM Do'));
      if (result?.success && result?.programIsActive && result?.hasAvailableUses) {
        if (loggedIn) {
          if (await hasSupplier()) {
            setLoading(false);
            setMessage('has_supplier');
            return;
          }
          //we have a logged in user, add supplier membership
          const UUID = contacts.find(contact => contact.Id === data.sfId).CHW_UUID__c;
          var ls = new secureStorage();
          const user = JSON.parse(ls.get("user"));
          const token = get(user, "cognito_token", "");

          const newMemberResult = await memberService.postSupplierMember(supplierInfo, UUID, token);
          if (newMemberResult.includes('SUCCESS')) {
            await dispatch(memberActions.getMembersFromEmail(supplierInfo.email));
            setLoading(false);
            setMessage('congrats');
          }
        } else {
          //not loggged in does email have login?
          try {
            const result = await memberService.login(supplierInfo.email, "bogus0000password", "", {}, true);
          } catch (error) {
            if (error !== "UserDoesNotExist") {
              showError('It appears that you already have an account. Please sign in...');
              setIsSignin(true);
            } else {
              // signup with users/me and get UUID
              const result = await memberService.createCognitoUser(supplierInfo.email, supplierInfo.password);
              if (result.status === 'succeeded') {
                const uuid = result.with;
                const response = await memberService.loginSimple(supplierInfo.email, supplierInfo.password, "", {}, true);
                const cognito_token = response.cognito_token;
                const newResult = await memberService.postSupplierMember(supplierInfo, uuid, cognito_token);
                if (newResult.includes('SUCCESS')) {
                  await memberService.login(supplierInfo.email, supplierInfo.password);
                  await dispatch(memberActions.getMembersFromEmail(supplierInfo.email));
                  setLoading(false);
                  setMessage('congrats');
                }
              } else {
               
                setLoading(false);
                console.log('cognito error');
                alertActions.error('unknown error please try again');
              }
            }
          }
        }
      }
      setLoading(false);
    }
  };


  return (
    <div style={{ backgroundColor: 'white' }}>
      <img className="image-to-be-gradiented" alt=""
        src={innerWidth > 576 ? require('../../assets/images/bbbg-v2.png') : require('../../assets/images/bbbg-v3.png')} />
      <div className="image-gradient" />
      <div className="container f-container pt-5">
        <div className="container form-items-container" style={{ backgroundColor: 'white', margin: 5 }}>
          <Form noValidate validated={validated} autoComplete="off">
            <Row style={{ display: message !== 'valid' ? 'none' : null }}>
              {innerWidth <= 991 &&
                <BenefitsColumn collapsible={true} />
              }
              {errorBanner && <ErrorBanner message={errorBanner} />}
              <SupplierMemberInfo validated={validated} code={inviteCode} loading={loading} isSignin={isSignin} toggleSignin={toggleSignin} />
              {innerWidth > 991 &&
                <BenefitsColumn collapsible={false} />
              }
            </Row>
            <Row style={{ display: message === 'valid' ? 'none' : null }}>
              <Message goBack={() => setMessage('valid')} small={innerWidth <= 991} contacts={contacts}
                type={message} startDate={startDate} endDate={endDate} membership={data} history={history} />
            </Row>
          </Form>
        </div>
        {message === 'valid' && <>
          <Row style={{ alignContent: 'center', justifyContent: 'center' }}>
            <Row style={{ width: '67%' }}>
              <div className="tcs">By claiming your membership, you are accepting the Cooper's Hawk Terms and Conditions and Privacy Policy.</div>
            </Row>
          </Row>
          <Row style={{ alignContent: 'center', justifyContent: 'center', marginTop: 15 }}>
            <Button disabled={loading} onClick={claim} className="button" style={{ width: 260, height: 54 }}>CLAIM MEMBERSHIP</Button>
          </Row>
          <Row style={{ alignContent: 'center', justifyContent: 'center', height: 90 }}>
            <div className="tcs">Please call Member & Guest Services at <a href="tel:7082155674">(708) 215-5674</a> if you have questions.</div>
          </Row>
        </>}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { member, authentication } = state;
  const cognitoStatus = get(authentication, "error", "");
  return {
    authentication,
    member,
    cognitoStatus
  };
}

const connectedNewSupplierMemberSignUp = withRouter(connect(mapStateToProps)(
  NewSupplierMemberSignUp
));

export { connectedNewSupplierMemberSignUp as NewSupplierMemberSignUp };
