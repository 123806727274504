import get from "lodash/get";
import Cookies from "js-cookie";
import {store} from "../_helpers";
import { appConstants } from "../_constants";
const moment = require("moment");

export const Baskets = {
  addCoupon,
  removeCoupon,
  addProducts,
  createBasket,
  deleteProduct,
  getBasket,
  updateHandoffType,
  updateDeliveryAddress,
  updateTimeWanted,
  deleteTimeWanted,
  updateProduct,
  addTip,
  getUpsells,
  setCustomField,
  removeCustomField,
  attachInstructions,
};

async function attachInstructions(guid, payload) {
    const {
      selectedAddress,
      dispatchAptNumber,
      dispatchPhoneNumber,
      specialinstructions,
    } = payload;
    const { streetaddress, city, zipcode } = selectedAddress;
       const result = await updateDeliveryAddress(
          guid,
          streetaddress,
          city,
          zipcode,
          dispatchPhoneNumber,
          dispatchAptNumber,
          specialinstructions
        );
    };

async function addCoupon(guid, customCode=null) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      couponcode: customCode ?? "Member_Discount",
    }),
  };
  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/coupon`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      return message;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function removeCoupon(guid) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/coupon`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      return {
        error: message,
      };
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function deleteProduct(guid, basketProductId) {
  const requestOptions = {
    method: "DELETE",
  };
  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/products/${basketProductId}`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      //  throw new Error(message || response.status);
      return message;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function updateProduct(
  guid,
  basketProductId,
  productid,
  quantity,
  options = "",
  specialinstructions = "",
  recipient = "",
  customdata = "",
  choicecustomfields = ""
) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(
      options === ""
        ? {
            productid,
            quantity,
            specialinstructions,
            recipient,
            customdata,
            choicecustomfields,
          }
        : {
            productid,
            quantity,
            options,
            specialinstructions,
            recipient,
            customdata,
            choicecustomfields,
          }
    ),
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/products/${basketProductId}`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      //  throw new Error(message || response.status);
      return message;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function createBasket(vendorid, transfer = false, guid = "") {
  const requestBody = {
    vendorid,
  };

  try {
    const { authentication } = store.getState();
    const loggedIn = get(authentication, "loggedIn", false);
    const token = Cookies.get('oat');

    if (loggedIn && token) {
      requestBody.authtoken = atob(token);
    }
  } catch (e) {
    console.log('Caught', e);
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${
      transfer ? `${guid}/transfer` : "create"
    }`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      //  throw new Error(message || response.status);
      return message;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function getBasket(guid) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      //throw new Error(message || response.status);
      return message;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function updateHandoffType(guid, type) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      deliverymode: type,
    }),
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/deliverymode`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}


async function updateDeliveryAddress(
  guid,
  streetaddress,
  city,
  zipcode,
  phonenumber,
  building,
  specialinstructions,
  isdefault = false
) {

  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      streetaddress,
      city,
      zipcode,
      phonenumber,
      isdefault,
      building,
      specialinstructions: specialinstructions?.replace(/[<>＜＞«»]/g, ' '),
    }),
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/dispatchaddress`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function updateTimeWanted(guid, timeWanted) {
  const timeWantedM = moment(timeWanted, "YYYYMMDD HH:mm");
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ismanualfire: false,
      year: timeWantedM.year(),
      month: parseInt(timeWantedM.format("M")),
      day: timeWantedM.date(),
      hour: timeWantedM.hour(),
      minute: timeWantedM.minute(),
    }),
  };
  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/timewanted`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function deleteTimeWanted(guid) {
  const requestOptions = {
    method: "DELETE",
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/timewanted`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function addProducts(
  guid,
  productid,
  quantity,
  options = "",
  specialinstructions = "",
  recipient = "",
  customdata = "",
  choicecustomfields = ""
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      productid,
      quantity,
      options,
      specialinstructions,
      recipient,
      customdata,
      choicecustomfields,
    }),
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/products`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      //throw new Error(message || response.status);
      console.log("result", result);
      return result;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function addTip(guid, amount) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      amount,
    }),
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/tip`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        throw new Error(e);
      }
      const { code, message, num } = result;
      throw new Error(message);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function getUpsells(guid) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/upsell`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        console.log("error", response.status);
        return e;
      }
      const { message } = result;
      // Got valid JSON with error response, use it
      //throw new Error(message || response.status);
      return message;
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function setCustomField(basketGuid, data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${basketGuid}/customfields`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        throw new Error(e);
      }
      const { code, message, num } = result;
      throw new Error(message);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function removeCustomField(basketGuid, customFieldId) {
  const requestOptions = {
    method: "DELETE",
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${basketGuid}/customfields/${customFieldId}`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        throw new Error(e);
      }
      const { code, message, num } = result;
      throw new Error(message);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}
