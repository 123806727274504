import { paymentConstants } from "../_constants";

export function paymentForm(state = {
  loading: false,
  bluepayFormLoading: false,
  bluepayUrl: '',
  savedPayments: [],
  loadingNewCard: false,
}, action) {
  switch (action.type) {
    case paymentConstants.PAYMENT_FORM_REQUEST:
      return {
        loading: true
      };
    case paymentConstants.PAYMENT_FORM_SUCCESS:
      return {
        loading: false,
        url: action.data.with
      };
    case paymentConstants.PAYMENT_FORM_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    case paymentConstants.POST_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        loadingNewCard: true,
      };
    case paymentConstants.POST_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        bluepayUrl: '',
      };
    case paymentConstants.POST_PAYMENT_FAILURE:
      return {
        loading: false,
        error: action.error,
      };

    case paymentConstants.PAYMENT_FORM_CLEAR:
      return {
        loading: false,
        url: null
      };

    case paymentConstants.BILLING_ACCOUNT_REQUEST:
      return {
        ...state,
        savedPaymentError: '',
        savedPaymentsLoading: true,
        savedPayments: [],
      };
    case paymentConstants.BILLING_ACCOUNT_SUCCESS:
      return {
        ...state,
        savedPaymentError: '',
        savedPaymentsLoading: false,
        savedPayments: action.data,
        loadingNewCard: false,
      };
    case paymentConstants.BILLING_ACCOUNT_FAILURE:
      return {
        ...state,
        savedPaymentsLoading: false,
        savedPaymentError: action.error
      };
    case paymentConstants.CLEAR_PAYMENTS:
      return {
        ...state,
        savedPayments: [],
      };

    case paymentConstants.OLO_PAYMENT_FORM_REQUEST:
      return {
        ...state,
        bluepayFormLoading: true,
        bluepayUrl: '',
      };
    case paymentConstants.OLO_PAYMENT_FORM_SUCCESS:
      return {
        ...state,
        bluepayFormLoading: false,
        bluepayUrl: action.data.with,
      };
    case paymentConstants.OLO_PAYMENT_FORM_FAILURE:
      return {
        ...state,
        savedPaymentsLoading: false,
        bluepayUrl: '',
      };
    case paymentConstants.CLEAR_OLO_PAYMENT_FORM:
      return {
        ...state,
        bluepayUrl: '',
        loadingNewCard: false,
        bluepayFormLoading: false,
      };
    case paymentConstants.REMOVE_PAYMENT_REQUEST:
      return {
        ...state,
        savedPaymentsLoading: true,
      };
    case paymentConstants.REMOVE_PAYMENT_SUCCESS:
      return {
        ...state,
      };
    case paymentConstants.REMOVE_PAYMENT_FAILURE:
      return {
        ...state,
        savedPaymentsLoading: false,
      };

    default:
      return state;
  }
}
