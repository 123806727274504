import React, {useEffect, useState} from "react";
import "./styles.scoped.scss";
import {CholoInput} from "../../index";
import {Col, Row} from "react-bootstrap";
import {alertActions, memberActions, newMemberActions, paymentActions} from "../../../_actions";
import {connect} from "react-redux";
import LoadingSpinners from "../../LoadingSpinners";
import { get } from "lodash";
import ButtonAnimator from "../../ButtonAnimator";
import { passwordLoginRegex } from "../../../_constants";

const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

const WineClubLogin = ({ member, newMember, isLoading, ...props }) => {
  const { dispatch, history, match } = props;
  const clubSelection = get(match, "params.clubSelection", "1");
  const memberData = get(member, "data", {});
  const memberBirthday = get(memberData, "birthday", "");
  const contactId = get(memberData, "memberId", "");
  const membershipType = get(memberData, "membershipType", "");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (contactId && membershipType !== "Potential" && !isLoading) {
      const timeout = setTimeout(() => {
        dispatch(alertActions.errorWithX("You are already a wine club member!", undefined, () => {
          document.location.href = "#/";
        }));
      }, 2000);

      return () => clearTimeout(timeout);
    } else if (submitted && newMember.paymentUrl && membershipType === "Potential") {
      dispatch(newMemberActions.createCognitoUser(email, password, contactId));
    }
  }, [newMember.paymentUrl, submitted, contactId, membershipType, isLoading]);

  useEffect(() => {
    if (submitted && contactId && memberData.birthday && membershipType === "Potential") {
      history.push(`/payment/${clubSelection}`);
    } else if (submitted && contactId && membershipType === "Potential") {
      history.push(`/account/${clubSelection}`);
    }
  }, [submitted, memberData.birthday, contactId, membershipType]);


  useEffect(() => {
    dispatch(paymentActions.clearOLOPaymentForm());
    dispatch(newMemberActions.clearNewMember());
    dispatch(memberActions.updateSupplierInfo({}))
  }, []);

  let createError = submitted && !isLoading && !newMember.paymentUrl && newMember.potentialMessage;
  createError = createError && createError.charAt(0) + createError.slice(1).toLowerCase();

  const handleSubmit = () => {
    setSubmitted(true);

    if (email && password && emailRegex.test(email) && passwordLoginRegex.test(password)) {
      dispatch(memberActions.login(email, password));
    }
  };

  return (
    <div>
      <div style={{padding: 15}}>
        <div className="step-title" style={{fontSize: "18px", marginTop:15}}>SIGN IN TO YOUR ACCOUNT</div>
        <div className="italic-description">
          Don't have a Cooper's Hawk carryout account?{"  "}
          <a href={`#/wine-club/account/${clubSelection}`} className="join-link">
            Join Now
          </a>
        </div>
        <form
          noValidate
          onSubmit={e => e.preventDefault()}
        >
          <div style={{marginTop:'1em', marginBottom:'1em'}}>
            <CholoInput
              isSmaller
              isValid={emailRegex.test(email)}
              isSubmitted={submitted}
              errorMessage="Please enter a valid email address"
              placeholder="Email address"
              value={email}
              onTextChanged={setEmail}
            />
          </div>
          <div style={{marginTop:'1em', marginBottom:'2em'}}>
            <CholoInput
              isSmaller
              name="pInput"
              isValid={passwordLoginRegex.test(password)}
              isSubmitted={submitted}
              errorMessage="Passwords must have at least 8 characters"
              eyeball
              placeholder="Password"
              value={password}
              onTextChanged={setPassword}
              autoComplete="new-password"
            />
          </div>

          <Row>
            <Col xs={12}>
              {!!createError && <div style={{color: 'red', textAlign: 'center', marginBottom: 20}}>{createError}</div>}
              <ButtonAnimator>
              <div className="submit" onClick={handleSubmit}>
                Log In
                {isLoading && (
                  <LoadingSpinners spinnerStyle={{margin: 0, marginLeft: 10}} />
                )}
              </div>
              </ButtonAnimator>
            </Col>
          </Row>
        </form>
      </div>
      {/*{renderPaymentCard()}*/}
    </div>
  );
};

const  mapStateToProps = state => {
  const { newMember, member, news, events, pickupHistory, openBottles, rewards, points, barrelOrders } = state;
  const isLoading = newMember.loading || member.loading || news.loading || events.loading || pickupHistory.loading || openBottles.loading || rewards.loading || points.loading || barrelOrders.loading;

  return {
    newMember,
    member,
    isLoading,
  };
};

const connectedWineClubLogin = connect(mapStateToProps)(WineClubLogin);

export { connectedWineClubLogin as WineClubLogin };
