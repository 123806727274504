import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import "./styles.scoped.scss";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { shippingStateOptions, stateOptions } from "../../_helpers";
import { memberActions } from "../../_actions"
import { HomeAddressInfo } from "../../components/Forms/home-address-info";
import { ShippingAddressInfo } from "../../components/Forms/shipping-address-info";

const GiftActivation = ({
  dispatch,
  firstName,
  giftLength,
  bottleLevel,
  clubType,
  isDelivery,
  giftActivationObj
}) => {
  // useEffect(() => {
  //   if(!isToBeActivated){
  //     history.replace("/main");
  //   }
  // }, [isToBeActivated])

  const handleSubmit = async (event) => {
    setValidated(true);
    if(formElement.checkValidity() == false){
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      dispatch(memberActions.activateGift(giftActivationObj));
    }
  }

  const [validated, setValidated] = useState(false);
  let formElement;

    return (
    <div>
      <Row className="justify-content-center p-4">
        <Col xs={12} sm={10} lg={7} className="rounded bg-light p-4">
          <h2>Welcome back, <span className="giftFirstName">{firstName}</span></h2>
          <h6 className="desc mt-4 mb-4">
            Activate your gift membership below to enjoy the benefits of your&nbsp;
            {giftLength} month {clubType.toLowerCase()} gift membership 
            and enjoy your {bottleLevel} {bottleLevel == "1" ? "bottle" : "bottles"} 
            &nbsp;of wine a month.
          </h6>
          <Form validated={validated} ref={(element) => (formElement = element)}>
            <HomeAddressInfo signup="profile" />
            {isDelivery && (
              <ShippingAddressInfo signup="profile" required={true}/>
            )}
          </Form>
          <Row className="justify-content-center">
            <Col md={6}>
              <Button className="btn primary-btn" onClick={(e)=>handleSubmit(e)}>ACTIVATE GIFT MEMBERSHIP</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      
    </div>
  );
};

function mapStateToProps(state) {
  const { data } = state.member;
  const firstName = get(data, "firstName", "");
  const giftLength = get(data, "giftLength", "");
  const bottleLevel = get(data, "bottleLevel", "");
  const clubType = get(data, "clubType", "");
  const isDelivery = get(data, "deliveryType", "") === "Delivery";
  const isToBeActivated = get(data, "status", "") === "To be Activated";

  const homeAddress = get(data, "homeAddress", "");
  const street = get(homeAddress, "street", "");
  const city = get(homeAddress, "city", "");
  const st = get(homeAddress, "state", "");
  const zip = get(homeAddress, "zipCode", "");

  const shippingAddress = get(data, "shippingAddress", "");
  const shippingStreet = get(shippingAddress, "street", "");
  const shippingCity = get(shippingAddress, "city", "");
  const shippingState = get(shippingAddress, "state", "");
  const shippingZip = get(shippingAddress, "zipCode", "");

  const giftActivationObj = {
    MailingStreet: street,
    MailingCity: city,
    MailingState: st,
    MailingPostalCode: zip,
    OtherStreet: shippingStreet,
    OtherCity: shippingCity,
    OtherState: shippingState,
    OtherPostalCode: shippingZip,
  }
  
  return {
    firstName,
    giftLength,
    bottleLevel,
    clubType,
    isDelivery,
    isToBeActivated,
    giftActivationObj
  };
}

const connectedGiftActivation = connect(mapStateToProps)(GiftActivation);
export { connectedGiftActivation as GiftActivation };
