import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import "./styles.css";

const Private = ({ history }) => {
  return (
    <div className="container">
      <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
        <h2 class="log-title" id="sorry">
          Sorry!
        </h2>
        <h5 class="log-title mb-sm-4" id="browsing-private">
          It appears that you are browsing in private mode or have cookies blocked.
        </h5>
        <br />
        <h6 class="log-title mb-sm-4" id="cookes-rquired">
          Cookies are required to navigate between pages efficiently and to remember your preferences.
          Unfortunately this site cannot function without cookies enabled.
        </h6>
        <div style={{ height: "1em" }} />
        <Button onClick={() => history.push('/')}>Try again</Button>
        <div style={{ height: "10em" }} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {}

const connectedPrivate = connect(mapStateToProps)(Private);
export { connectedPrivate as Private };
