import React from "react";
import "./styles.scoped.scss";
import {ModalContainer} from "../ModalContainer";
import { connect } from "react-redux";
import { get } from "lodash";
import {Col, Row} from "react-bootstrap";

const UserExists = ({match, history, forgotPass}) => {
  const clubSelection = get(match, "params.clubSelection", "1");
  const name = get(match, "params.name", "");
  const email = get(match, "params.email", "");

  return (
    <ModalContainer>
      <div style={{padding: 30}}>
        <div className="large-text">Sorry, {name}</div>
        <div className="small-text">
        The email you entered is associated with an existing account.
        </div>
       
        <div className="button plain" onClick={forgotPass}> Forgot Password?</div>
      </div>

      <Row>
        <Col xs={12}>
          <div className="submit" onClick={() => history.push(`/signin/${clubSelection}`)}>
            Log into my account
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="submit empty" onClick={() => history.push(`/account/${clubSelection}`)}>
            Sign up with a different email address
          </div>
        </Col>
      </Row>
    </ModalContainer>
  );
};

function mapStateToProps(state) {
  const { member, newMember } = state;
  const memberData = get(member, "data", {});
  return {
    memberData,
  }
}

const connectedUserExists = connect(mapStateToProps)(
  UserExists
);

export { connectedUserExists as UserExists };