import { staticContentConstants } from "../_constants";
import { staticContentService } from "../_services";
import { alertActions } from "./";

export const staticContentActions = {
  getStaticContent
};

function getStaticContent() {
  return dispatch => {
    dispatch(request());
    staticContentService.getStaticContent().then(
      staticContent => {
        dispatch(success(staticContent));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: staticContentConstants.STATIC_CONTENT_REQUEST };
  }
  function success(staticContent) {
    return {
      type: staticContentConstants.STATIC_CONTENT_SUCCESS,
      staticContent
    };
  }
  function failure(error) {
    return { type: staticContentConstants.STATIC_CONTENT_FAILURE, error };
  }
}
