import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { memberActions, newMemberActions, alertActions } from "../../_actions";
import secureStorage from "../../_helpers/secureStorage";
import get from "lodash/get";

import "./styles.css";

const ContactInfoNewUser = ({
  dispatch,
  phone,
  email,
  signup,
  changingEmailBackend,
}) => {
  const emailPattern = /^([a-zA-Z0-9_+\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})/;
  const [changingEmail, setChangingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  useEffect(() => {
    setChangingEmail(false);
    setNewEmail("");
  }, [email]);

  const changeEmail = () => {
    var ls = new secureStorage();
    const user = JSON.parse(ls.get("user"));
    const contactId = get(user, "id", "");
    dispatch(memberActions.changeEmail(newEmail, email, contactId));
    dispatch(alertActions.success(
      `Make sure to use ${newEmail} the next time you sign in to your wine club account.`,
      "Notice Upon Changing your Email"
    ));
  };

  const formControlStyle = {
  backgroundColor: 'white',
  color: 'black',
  borderColor: '#000',
  borderRadius: 10,
}

  return (
    <div>
      <h4 className="section-header">Contact Information</h4>
      <Form.Group style={{paddingTop: 5}}>
        <Form.Label className="input-label" for="contact-info-phone">
          Phone*
        </Form.Label>
        <Col sm={16}>
          <Form.Control
          style={formControlStyle}
            value={phone}
            id="contact-info-phone"
            onChange={(e) => {
              let value = e.target.value;
              let x = value
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
              value = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
              signup === "profile"
                ? dispatch(memberActions.updatePhone(value))
                : dispatch(newMemberActions.setPhone(value));
            }}
            type="tel"
            name="phone"
            pattern="^\D*(\d\D*){10}$"
            required={true}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid 10 digit phone number.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group>
        <Form.Label className="input-label" for="contact-info-email">
          Email*
          {signup === "profile" && (
            <span
              onClick={() => setChangingEmail(true)}
              style={{
                color: "var(--member-site-gold)",
                fontSize: "0.9em",
                textDecoration: "underline",
              }}
            >
              {changingEmail ? "" : " (change)"}
            </span>
          )}
        </Form.Label>
        <Col sm={16}>
          <Form.Control
          style={formControlStyle}
            readOnly={signup === "profile"}
            value={email}
            id="contact-info-email"
            name="email"
            required={true}
            onChange={(e) =>
              signup !== "profile"
                ? dispatch(newMemberActions.setBillingEmail(e.target.value))
                : null
            }
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
          {signup === "profile" && !changingEmail && (
            <Link to="/ResetPassword">
              <Button style={{ marginTop: "1em" }}>Change Password</Button>
            </Link>
          )}
        </Col>
      </Form.Group>
      {changingEmail && (
        <Form.Group as={Row}>
          <Form.Label column sm={4} for="contact-info-new-email">
            New Email:{"   "}
            <span
              onClick={() => setChangingEmail(!changingEmail)}
              style={{
                color: "var(--member-site-gold)",
                fontSize: "0.9em",
                textDecoration: "underline",
              }}
            >
              {changingEmail ? "(cancel)" : " (change)"}
            </span>
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              id="contact-info-new-email"
              disabled={changingEmailBackend}
              isValid={emailPattern.test(newEmail) && newEmail !== email}
              value={newEmail}
              name="newEmail"
              required={false}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
            {changingEmail && (
              <Button
                disabled={
                  !emailPattern.test(newEmail) ||
                  changingEmailBackend ||
                  newEmail === email
                }
                style={{ marginTop: "1em" }}
                onClick={() => changeEmail()}
              >
                {changingEmailBackend ? "Changing..." : "Change Email"}
              </Button>
            )}
          </Col>
        </Form.Group>
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { member, newMember } = state;
  const billing = get(newMember, "data.billingAddress", "");
  const phone =
    ownProps.signup === "profile"
      ? get(member, "data.phone", "")
      : get(newMember, "data.phone", "");
  const email =
    ownProps.signup === "profile"
      ? get(member, "data.email", "")
      : get(billing, "email", "");
  const changingEmail = get(member, "changingEmail", false);

  return { phone, email, changingEmailBackend: changingEmail };
}

const connectedContactInfoNewUser = connect(mapStateToProps)(ContactInfoNewUser);
export { connectedContactInfoNewUser as ContactInfoNewUser };
