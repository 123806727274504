export const subscribeConstants = {
    CLEAR_MARKETING_SUBSCRIBE: "CLEAR_MARKETING_SUBSCRIBE",

    SUBSCRIBE_REQUEST: "SUBSCRIBE_REQUEST",
    SUBSCRIBE_SUCCESS: "SUBSCRIBE_SUCCESS",
    SUBSCRIBE_FAILURE: "SUBSCRIBE_FAILURE",
    
    SET_EMAIL: "SET_EMAIL",
    SET_BIRTHDAY: "SET_BIRTHDAY",
    SET_FIRSTNAME: "SET_FIRSTNAME",
    SET_LASTNAME: "SET_LASTNAME",
    SET_PHONE: "SET_PHONE",
    SET_ZIP: "SET_ZIP",
    SET_PROMOTION_ID: "SET_PROMOTION_ID",
};
