import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import "./styles.css";
import { useRenameDocument } from "../../context/renameDocument";

const ErrorPage = ({ history }) => {

  useRenameDocument("Payment Error");

  const determineRoute = () => {
    if (history.location.state?.from === 'DirectPayment') {
      return history.replace({
        pathname: '/main',
        hash: '#utp',
      });
    } else {
      return history.goBack();
    }
  }

  return (
    <div className="container">
      <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
        <h2 class="log-title" id="error-title">
          Payment Error
        </h2>
        <h6 class="log-title mb-sm-4" id="payment-issue">
          There was an issue with your payment.
        </h6>
        <br />
        <h6 class="log-title mb-sm-4" id="different-card">
          Please use a different card and try again.
        </h6>
        <div style={{ height: "1em" }} />
        <Button onClick={determineRoute}>Proceed to Payment</Button>
        <div style={{ height: "10em" }} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {}

const connectedErrorPage = connect(mapStateToProps)(ErrorPage);
export { connectedErrorPage as ErrorPage };
