import { pickupHistoryConstants } from "../_constants";
import { pickupHistoryService } from "../_services";
import { alertActions } from "./";

export const pickupHistoryActions = {
  getPickupHistory
};

function getPickupHistory() {
  return dispatch => {
    dispatch(request());
    pickupHistoryService.getPickupHistory().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: pickupHistoryConstants.PICKUP_HISTORY_REQUEST };
  }
  function success(result) {
    return {
      type: pickupHistoryConstants.PICKUP_HISTORY_SUCCESS,
      pickupHistory: result.with.pickupHistory
    };
  }
  function failure(error) {
    return { type: pickupHistoryConstants.PICKUP_HISTORY_FAILURE, error };
  }
}
