import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import bagIcon from "../../assets/images/icon-bag.png";
import { motion } from "framer-motion";
import get from "lodash/get";
import "./styles.scoped.scss";

const Bag = ({ qty, onBagClick }) => {
  return (
    <div
      className={`bag-sign-in ${qty > 0 ? "items" : "empty"}`}
      onClick={onBagClick}
    >
      <div className="bag-wrap">
        <Row style={{ display: "flex", alignItems: "center" }}>
          <img src={bagIcon} style={{ maxHeight: 16, marginRight: 12}} alt="bag icon" />
          <div>
            <span className="bag-title">BAG</span>
            {qty > 0 && <span style={{ marginLeft: 6, marginBottom: 0 }}>({qty})</span>}
          </div>
        </Row>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { basket } = state;
  const basketData = get(basket, "data", {});
  if (basketData && basketData.products) {
    let qty = 0;
    basketData.products.forEach(element => {
      qty += element.quantity;
    });
    return { qty };
  }
  return {};
}

const connectedBag = connect(mapStateToProps)(Bag);
export { connectedBag as Bag };

Bag.propTypes = {
  qty: PropTypes.number,
  onBagClick: PropTypes.func
};

Bag.defaultProps = {
  qty: 0,
  onBagClick: () => {}
};
