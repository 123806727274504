import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";

import "./styles.css";
import { useRenameDocument } from "../../context/renameDocument";

const BarrelOrderApproved = ({ barrelReservePickupDate }) => {
  useRenameDocument("Barrel Order Approved");

  return (
    <div className="container">
      <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
        <h2 class="log-title" id="title">
          Approved
        </h2>
        <h6 class="log-title mb-sm-4 mt-sm-3" id="subtext">
          Bottles are available for pickup on <strong>{barrelReservePickupDate}</strong>{" "}
          at your selected Cooper’s Hawk location.
        </h6>
        <Link to="/main">
          <Button className="btn btn-primary">Back to Dashboard</Button>
        </Link>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { staticContent } = state;
  const { barrelReservePickupDate } = staticContent.data;

  return {
    barrelReservePickupDate
  };
}

const connectedBarrelOrderApproved = connect(mapStateToProps)(
  BarrelOrderApproved
);
export { connectedBarrelOrderApproved as BarrelOrderApproved };
