import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SupplierCard from '../../assets/images/supplier-card.png';
import AppStore from '../../assets/images/app-store.png';
import Play from '../../assets/images/google-play.png';
import ThemedBackArrow from "../../components/ThemedBackArrow";
import "./styles.css";
import moment from "moment";


const Message = ({ contacts, small, type, startDate, endDate, history, membership, goBack }) => {
    const [types, setTypes] = useState({
        inactive: {
            title: 'Sorry!',
            message: 'This program is not currently available or the incorrect invite code was entered.',
            title2: 'Download the Cooper\'s Hawk App',
            message2: 'In the meantime you can download the Cooper\'s Hawk app on Google Play or Apple App Store to easily view Wine Club information and more!',
            joinLink: false,
        },
        used: {
            title: 'Sorry!',
            message: 'All available Supplier Club Memberships have been claimed.',
            title2: 'Download the Cooper\'s Hawk App',
            message2: 'In the meantime you can download the Cooper\'s Hawk app on Google Play or Apple App Store to easily view Wine Club information and more!',
            joinLink: false,
        },
        early: {
            title: 'You\'re early!',
            message: 'Check back later to claim your membership.',
            title2: 'Download the Cooper\'s Hawk App',
            message2: 'In the meantime you can download the Cooper\'s Hawk app on Google Play or Apple App Store to easily view Wine Club information and more!',
            joinLink: false,
        },
        expired: {
            title: 'Sorry!',
            message: 'This program has ended. We hope to see you at our Cooper\'s Hawk restaurants soon!',
            title2: 'Download the Cooper\'s Hawk App',
            message2: 'To easily view Wine Club information and more please download the Cooper\'s Hawk app on Google Play or Apple App Store.',
            joinLink: false,
        },
        congrats: {
            title: 'Congratulations, your membership is now active!',
            title2: 'Download the Cooper\'s Hawk App to access your membership card',
            memberName: '',
            memberId: '',
            expiration: '',
            message2: 'You have successfully redeemed your free 3-month Supplier Club Membership! You\'re able to start using your benefits right away. To access your digital member card please download the Cooper\'s Hawk app on Google Play or Apple App Store.',
            joinLink: false,
        },
        has_supplier: {
            title: 'You already have a Supplier Club Membership',
            title2: 'Please see your membership number above',
            memberName: '',
            memberId: '',
            expiration: '',
            message2: 'To access your digital member card please download the Cooper\'s Hawk app on Google Play or Apple App Store.',
            joinLink: false,
        },
    });

    useEffect(() => {
        if(startDate) {
            setTypes({
                ...types,
                early: {
                    ...types.early,
                    message: `Check back ${startDate} to claim your membership.`,
                } 
            })
        }
    }, [startDate]);

    useEffect(() => {
        const contact = contacts?.find(
      ({ CHW_Status__c, CHW_Membership_Type__c }) => CHW_Membership_Type__c === 'Supplier' && CHW_Status__c === 'Active'
    );
        if(membership?.firstName && contacts) {
            setTypes({
                ...types,
                congrats: {
                    ...types.congrats,
                    memberName: `${membership?.firstName} ${membership?.lastName}`,
                    memberId: contact?.CHW_Member_Number__c,
                    expiration: endDate,
                }, 
                has_supplier: {
                    ...types.has_supplier,
                    memberName: `${membership?.firstName} ${membership?.lastName}`,
                    memberId: contact?.CHW_Member_Number__c,
                    expiration: endDate,
                }, 
            })
        }
    },[membership?.firstName, endDate, contacts])

   const join = () => history.replace("/Signup");

    return (
      <Col lg={11}>
        <h1 className="text">{type === 'inactive' && <span className="hover" onClick={goBack}><ThemedBackArrow /></span>}  {types[type]?.title}</h1>
        {(type !== 'congrats' && type !== 'has_supplier') ? <p className={`text ${types[type]?.joinLink ? 'link':''}`} onClick={types[type]?.joinLink ? join : null}>{types[type]?.message}</p> : 
        <div style={{margin: '20px 0'}}>
            <h3>{types[type]?.memberName}</h3>
            <div style={{ flexDirection:'row', margin: '15px 0'}}>
                <span className="small-heading bold">Membership Number</span> 
                <span className="small-heading bold" style={{marginLeft:10}}>{types[type]?.memberId}</span>
            </div>
            <div style={{ flexDirection:'row', margin: '5px 0'}}>
                <span className="small-heading bold" style={{}}>Membership Expiration Date</span> 
                <span className="small-heading bold" style={{marginLeft:10}}>{types[type]?.expiration}</span>
            </div>
        </div>
        }
        {small && <h3 style={{padding: '1em 0'}}>{types[type]?.title2}</h3>}
        <Row style={{ flex: 1 }}>
            <img src={SupplierCard} className="club-card" />
            <div style={{ flex: 1, padding: '1em', alignContent: 'center' }}>
                {!small && <h3>{types[type]?.title2}</h3>}
                <h5>{types[type]?.message2}</h5>
                <span>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.coopershawkwinery.mobileapp&hl=en_US&gl=US"><img src={Play} className="app-store" /></a>
                    <a target="_blank" href="https://apps.apple.com/us/app/cooper-s-hawk/id1303888465"><img src={AppStore} className="app-store ml-4" /></a>
                </span>
            </div>
        </Row>
    </Col>);
    };

export default Message;



