import React from "react";
import "./styles.css";
import get from "lodash/get";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { Row, Button } from "react-bootstrap";
import { memberActions } from "../../_actions";
import { useRenameDocument } from "../../context/renameDocument";

const ResetRequired = ({ email, dispatch }) => {
  // TODO NEED TO REFACTOR ELIMINATE USE OF NEW MEMBER
  const usernameObj = { username: email };

  useRenameDocument("Reset Required");

  const backToLogin = () => {
    dispatch(memberActions.logout());
  };

  return (
    <div>
      <main className="container">
        <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
          <h2 className="log-title">Welcome Back</h2>

          <Row align="center" className="mt-4 mb-4">
            <p>
              Your password has expired. Please update it here. If you have any
              questions, please call Member and Guest Services at{" "}
              <a href="tel:7082155674">(708) 215-5674</a>
            </p>
          </Row>

          <Row align="center" className="mt-4 mb-4">
            <p>
              To update your password, click below to get a verification code
              sent to the email associated with your Wine Club account.
            </p>
          </Row>

          <Row className="justify-content-center">
            <Link to="/NewPassword">
              <Button
                className="btn btn-primary"
                id="send_email"
                onClick={(e) =>
                  dispatch(memberActions.forgotPassword(usernameObj))
                }
              >
                Send me a verification code
              </Button>
            </Link>
          </Row>

          <Row className="justify-content-center mt-4">
            <Link to="/">
              <Button
                className="btn btn-primary"
                onClick={(e) => backToLogin(e)}
              >
                Back to Login
              </Button>
            </Link>
          </Row>

          <div className="text-center my-4">
            Questions? <a href="tel:7082155674">(708) 215-5674</a>
          </div>
        </div>
      </main>
    </div>
  );
};

function mapStateToProps(state) {
  const { member } = state;
  const email = get(member, "data.email", "");

  return { email };
}

const connectedResetRequired = connect(mapStateToProps)(ResetRequired);
export { connectedResetRequired as ResetRequired };
