import React, { useState, useEffect } from "react";
import { alertActions, basketsActions } from "../../_actions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./styles.css";
import wineImage from "../../assets/images/wine-pairing.jpg";
import get from "lodash/get";
import { DropDownSelector } from "../../components";

const WineConversionItem = ({
  data,
  dispatch,
  basketData,
  imagepath,
  menuData,
  didStartConversion,
  didFinishConversion,
  bottlesAvailable,
  validBottlesCount,
  guid
}) => {
  const [productObj, setProductObj] = useState(null);
  const [freeObj, setFreeObj] = useState(null);
  const [bottlesToConvert, setBottlesToConvert] = useState(1);
  const [isBottlesToConvertOpen, setIsBottlesToConvertOpen] = useState(false);

  const maxConversionBottles = Math.min(validBottlesCount, data.quantity);
  const bottlesToConvertOptions = Array.from(Array(maxConversionBottles).keys()).map(number => {
    return {
      label: `Use ${number + 1} bottle${number !== 0 ? "s" : ""} from my Wine Club account`,
      value: number + 1
    };
  });


  if(bottlesToConvertOptions.length && bottlesToConvert > bottlesToConvertOptions.length) {
    setBottlesToConvert(1);
  }

  useEffect(() => {
    for (let i = 0; i < menuData.categories.length; i++) {
      for (let j = 0; j < menuData.categories[i].products.length; j++) {
        if (menuData.categories[i].products[j].id === data.productId) {
          setProductObj(menuData.categories[i].products[j]);
          break;
        }
      }
    }
  }, [productObj]);

  useEffect(() => {
    for (let i = 0; i < menuData.categories.length; i++) {
      for (let j = 0; j < menuData.categories[i].products.length; j++) {
        const freeWineId = findFreeWine(data)?.id ?? "";
        if (menuData.categories[i].products[j].id === freeWineId) {
          setFreeObj(menuData.categories[i].products[j]);
          break;
        }
      }
    }
  }, [freeObj]);

  const [updateButtonEnabled, setUdateButtonEnabled] = useState(true);
  const removeItem = async (item) => {
    await dispatch(basketsActions.deleteProduct(basketData.id, item.id));
  };

  const convert = async () => {
    const freeProduct = findFreeWine(data);
    if (!freeProduct) {
      alert('Cannot find free wine');
      return;
    }

    if(!validBottlesCount) {
      dispatch(alertActions.oloerror("Sorry, you have used all of your available bottles in your cart. Please update your cart and try again."));
      return;
    }

    setUdateButtonEnabled(false);
    didStartConversion();
    const freeProductId = freeProduct?.id;
    const freeProductName = freeProduct?.name;
    const freeProductSfId = freeProduct?.sfId ?? "";
    if (bottlesToConvert === data.quantity) {
      await removeItem(data);
      await dispatch(
        basketsActions.addProducts(
          get(basketData, "id"),
          freeProductId,
          freeProductName,
          data.quantity,
          "",
          "",
          "",
          `conversion:${freeProductSfId}`
        )
      );
    } else if (bottlesToConvert < data.quantity) {
        await dispatch(
          basketsActions.updateProduct(
            guid,
            data.id,
            data.productId,
            data.quantity - bottlesToConvert,
            "",
            data.specialinstructions,
            data.recipient,
            data.customdata
          )
        );
        await dispatch(
          basketsActions.addProducts(
            get(basketData, "id"),
            freeProductId,
            freeProductName,
            bottlesToConvert,
            "",
            "",
            "",
            `conversion:${freeProductSfId}`
          )
        );
      }
    didFinishConversion();
  };

  const findFreeWine = (item) => {
    let chainId, isSweet;
    if (item.customdata) {
      chainId = item.customdata.split(":")[1].split(",")[0];
      isSweet = item.customdata.split(",")[1];
    } else return;

    const swapWines = menuData.categories.find(
      (category) =>
        category.id === 33329 || category.name.includes("Wine-Member-Swap")
    );

    if (chainId && swapWines) {
      let freeProducts = get(swapWines, "products", []);
      for (let i = 0; i < freeProducts.length; i++) {
        if (freeProducts[i].metadata) {
          let metaObject = freeProducts[i].metadata.reduce(
            (obj, item) =>
            Object.assign(obj, { [item.key]: item.value.split(",") }),
            {}
          );
          freeProducts[i].fullPriceId = metaObject.fullPriceId;
          freeProducts[i].sfId = metaObject.sfId;
        }
      }
      let freeProduct = freeProducts.find(
        (product) =>
          product.fullPriceId && product.fullPriceId.includes(chainId)
      );
      return freeProduct;
    }
  };
  return !productObj ? null : (
    <>
      <Row className="wci-wrap">
        <Col className="wci-image-col" xl={4} lg={4} md={6} s={10} xs={12}>
          <img
            src={`${imagepath}${productObj.imagefilename}`}
            alt="wine"
            className="wci-img"
          />
        </Col>
        <Col
          style={{ backgroundColor: "white", padding: 20, alignSelf: 'stretch' }}
        >
          <div className="wci-item-name">{productObj.name}</div>
          <div className="wci-item-description">{productObj.description}</div>
        </Col>
        <Col xl={1} lg={1} md={12} s={12} xs={12}>
          <div className="wci-cost">
            <div className="cost-container">
              <span className="strikethru">${productObj.cost.toFixed(2)}</span>
              {/* <span className="per-bottle">per bottle</span> */}
            </div>
            {freeObj && (<div className="cost-container">
                <span>${freeObj.cost.toFixed(2)}</span>
                <span className="per-bottle">per bottle</span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div className="dropdown-container">
        { bottlesToConvertOptions.length !== 0 &&
          <DropDownSelector
          value={bottlesToConvert}
          items={bottlesToConvertOptions}
          onClick={(_) => setIsBottlesToConvertOpen(!isBottlesToConvertOpen)}
          onChange={(item) => {
            setBottlesToConvert(item);
            setIsBottlesToConvertOpen(false);
          }}
          isOpen={isBottlesToConvertOpen}
          containerClass="dropdown-cc"
          />
        }
      </div>
      <motion.div
        style={{
          pointerEvents: !updateButtonEnabled ? "none" : "",
          opacity: updateButtonEnabled ? 1.0 : 0.5,
        }}
        onClick={convert}
        className="wci-use-btn"
        whileHover={{
          scale: 1.02,
          transition: { duration: 0.1 },
        }}
        whileTap={{ scale: 0.98 }}
      >
        USE MY WINE CLUB BOTTLES
      </motion.div>
    </>
  );
};

function mapStateToProps(state) {
  const { basket, menu, member } = state;
  const basketData = get(basket, "data", {});
  const menuData = get(menu, "data", {});
  let { imagepath } = menuData;
  const memberData = get(member, "data", {});
  const bottlesAvailable = get(memberData, "openOnlineBottles", 0);
  const guid = get(basketData, "id", "");
  return { basketData, menuData, imagepath, bottlesAvailable, guid };
}

const connectedWineConversionItem = connect(mapStateToProps)(
  WineConversionItem
);
export { connectedWineConversionItem as WineConversionItem };

WineConversionItem.propTypes = {
  data: PropTypes.object,
  didStartConversion: PropTypes.func,
  didFinishConversion: PropTypes.func,
};

WineConversionItem.defaultProps = {
  didStartConversion: () => {},
  didFinishConversion: () => {},
  data: {},
};
