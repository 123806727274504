export const getImageUrl = (images, groupname) => {
  if (!images) {
    return "";
  }
  let imageUrl = images[0].filename;
  images.forEach((i) => {
    if (i.groupname.includes(groupname)) {
      imageUrl = i.filename;
    }
  });
  return imageUrl;
};
