import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import eyeIconCH from "../../assets/images/icon-eyeball.png";
import eyeIconSlashedCH from "../../assets/images/icon-eyeball-slash.png";
import Form from "react-bootstrap/Form";
import { minDate } from "../../_constants";
import { connect } from "react-redux";
import { alertActions, memberActions } from '../../_actions';
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { memberService } from '../../_services';
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import "./styles.css";



const SupplierMemberInfo = ({ toggleSignin, isSignin, loading = false, validated, code, dispatch, member: { supplierInfo, data }, authentication: { loggedIn } }) => {
  const [infoObject, setInfoObject] = useState({ code });
  const [pass1Hidden, setPass1Hidden] = useState(true);
  const [pass2Hidden, setPass2Hidden] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  var today = new Date();
  var dd = (today.getDate() < 10 ? "0" : "") + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? "0" : "") + (today.getMonth() + 1);
  var yyyy = today.getFullYear() - 21;
  const validBirthdate = yyyy + "-" + mm + "-" + dd;
  let invalidAge = false;
  const history = useHistory();


  const validateAge = () => {
    if (infoObject.dob > validBirthdate) {
      invalidAge = true;
    }
  };

  const formControlStyle = {
    backgroundColor: 'white',
    color: 'black',
    borderColor: '#000',
    borderRadius: 10,
  };


  const readOnlyGrey = '#eaeaea';

  useEffect(() => {
    if (loggedIn && data && isMounted)  {
      const { firstName, lastName, email, birthday, phone, homeAddress } = data;
      const { zipCode } = homeAddress;
      setInfoObject({ ...infoObject, 
        firstName: firstName || infoObject.firstName || '', 
        lastName: lastName || infoObject.lastName || '', 
        email: email || infoObject.email || '',
         dob: birthday || infoObject.dob || '', 
         phone:phone || infoObject.phone || '', 
         zipCode: zipCode || infoObject.zipCode || ''});
      setIsMounted(true);
    }
  }, [loggedIn, data]);

  useEffect(() => {
    if (infoObject && isMounted)
      dispatch(memberActions.updateSupplierInfo(infoObject));
      setIsMounted(true);
  }, [infoObject]);

  const toggle = (field) => {
    switch (field) {
      case 1:
        setPass1Hidden(!pass1Hidden);
        break;
      default:
        setPass2Hidden(!pass2Hidden)
        break;
    }
  }


  const signIn = async () => {
    try {
      const loginResult = await memberService.login(infoObject.email, infoObject.password, "",);
    } catch (error) {
      dispatch(alertActions.oloerror('Login or password incorrect or user not found. Please try again...'))
    }
  }

  const showInfo = () => {
    dispatch(alertActions.olosuccess(
      'Cooper\'s Hawk values all employees of our partners. Your employer provided code will grant you access to a free three month membership. If you do not have access to your invite code, please reach out to your program coordinator.', 'Supplier Club Information'));
  }

  const forgotPass = () => {
    dispatch(memberActions.updateSupplierInfo({...infoObject, resettingPassword: true}));
    history.push("/ForgotPassword");
  }

  return isSignin ? (
    <Col lg={6}>
      <div className="signup-active pr-3 pl-3 pt-4">
        <h4 className="section-header">Sign In</h4>

        <Form.Group>
          <Form.Label className="input-label" for="supplier-email">
            Email*
          </Form.Label>
          <Col sm={16}>
            <Form.Control
              readOnly={loggedIn}
              style={{ ...formControlStyle, backgroundColor: loggedIn ? readOnlyGrey : 'white' }}
              value={infoObject.email}
              id="supplier-email"
              name="supplier-email"
              required
              onChange={(e) => {
                setInfoObject({ ...infoObject, email: e.target.value })
              }}
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group style={{ paddingTop: 5 }}>
          <Form.Label className="input-label" for="supplier-password">
            Password*
          </Form.Label>
          <Col sm={16}>
            <div style={{ position: 'relative' }}>
              <Form.Control
                value={infoObject.password}
                style={formControlStyle}
                id="supplier-password"
                required
                onChange={(e) => {
                  setInfoObject({ ...infoObject, password: e.target.value })
                }}
                type={pass1Hidden ? "password" : "text"}
                pattern="^(?=.*[A-Za-z])(?=.*\d).{8,}$" />
              <a style={{ position: 'absolute', right: validated ? 35 : 10, top: 6 }} onClick={() => toggle(1)}>
                <img src={pass1Hidden ? eyeIconCH : eyeIconSlashedCH} style={{ height: 17, cursor: 'pointer' }} />
              </a>
            </div>
            <small className="form-text text-muted" id="password_hint">
              At least eight characters, including letters and numbers.
            </small>
            <Form.Control.Feedback type="invalid">
              Please provide a password with at least eight characters
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <div className="forgot-password link" onClick={forgotPass}>
          Forgot your Password?
        </div>
        <Form.Group style={{ paddingTop: 5 }}>
          <Row style={{ alignContent: 'center', justifyContent: 'center', marginTop: 15 }}>
            <Button disabled={loading} className="button" style={{ width: 260, height: 54 }} onClick={signIn}>SIGN IN</Button>
          </Row>
          <Row style={{ padding: 15, marginTop: 15 }}>
            <span className="text-libre">Don't have an account? <span className="underline pointer" onClick={toggleSignin}>Create an account</span></span>
          </Row>
        </Form.Group>
      </div>
      {loading && <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#ffffffaa', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader
          type="ThreeDots"
          color="#a47f51aa"
          height={5}
          width={5}
          radius={5}
        />
      </div>}
    </Col>
  ) :
    (
      <Col lg={6}>
        <div className="signup-active pr-3 pl-3 pt-4">
          <h4 className="section-header">Member Information</h4>
          <Form.Group style={{ paddingTop: 5 }} >
            <Form.Label className="input-label" for="new-user-first-name">
              First Name*
            </Form.Label>
            <Col sm={16}>
              <Form.Control
                readOnly={loggedIn}
                style={{ ...formControlStyle, backgroundColor: loggedIn ? readOnlyGrey : 'white' }}
                value={infoObject.firstName}
                id="new-user-first-name"
                onChange={(e) => {
                  setInfoObject({ ...infoObject, firstName: e.target.value })
                }}
                type="text"
                name="firstName"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid first name.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label className="input-label" for="new-user-last-name">
              Last Name*
            </Form.Label>
            <Col sm={16}>
              <Form.Control
                readOnly={loggedIn}
                style={{ ...formControlStyle, backgroundColor: loggedIn ? readOnlyGrey : 'white' }}
                value={infoObject.lastName}
                id="new-user-last-name"
                onChange={(e) => {
                  setInfoObject({ ...infoObject, lastName: e.target.value })
                }}
                type="text"
                name="lastName"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid last name.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group onSubmit={validateAge()}>
            <Form.Label className="input-label" for="new-user-dob">
              Birthdate*
            </Form.Label>
            <Col sm={16}>
              <Form.Control
                readOnly={!!data.dob}
                style={{ ...formControlStyle, backgroundColor: !!data.dob ? readOnlyGrey : 'white' }}
                value={infoObject.dob || data.dob}
                onChange={(e) => {
                  setInfoObject({ ...infoObject, dob: e.target.value })
                }}
                type="date"
                id="new-user-dob"
                name="dob"
                placeholder="YYYY-MM-DD"
                max={validBirthdate}
                min={minDate}
                isInvalid={validated && (invalidAge || !infoObject.dob)}
                pattern="\d{4}-\d{2}-\d{2}"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid birthdate.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label className="input-label">
              Zip Code*
            </Form.Label>
            <Col sm={16}>
              <Form.Control
                style={formControlStyle}
                pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
                type="input"
                onChange={(e) => {
                  setInfoObject({ ...infoObject, zipCode: e.target.value })
                }}
                value={infoObject.zipCode}
                name="zipCode"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip code.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <h4 className="section-header">Contact Information</h4>
          <Form.Group style={{ paddingTop: 5 }}>
            <Form.Label className="input-label" for="contact-info-phone">
              Phone*
            </Form.Label>
            <Col sm={16}>
              <Form.Control
                style={formControlStyle}
                value={infoObject.phone}
                id="contact-info-phone"
                onChange={(e) => {
                  let value = e.target.value;
                  let x = value?.replace(/\D/g, "")?.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setInfoObject({ ...infoObject, phone: value })
                }}
                type="tel"
                name="phone"
                pattern="^\D*(\d\D*){10}$"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid 10 digit phone number.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label className="input-label" for="supplier-email">
              Email*
            </Form.Label>
            <Col sm={16}>
              <Form.Control
                readOnly={loggedIn}
                style={{ ...formControlStyle, backgroundColor: loggedIn ? readOnlyGrey : 'white' }}
                value={infoObject.email}
                id="supplier-email"
                name="supplier-email"
                required
                onChange={(e) => {
                  setInfoObject({ ...infoObject, email: e.target.value })
                }}
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {!loggedIn && <>
            <h4 className="section-header">Set Password</h4>
            <Form.Group style={{ paddingTop: 5 }}>
              <Form.Label className="input-label" for="password">
                Password*
              </Form.Label>
              <Col sm={16}>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    value={infoObject.password}
                    style={formControlStyle}
                    id="password"
                    required
                    onChange={(e) => {
                      setInfoObject({ ...infoObject, password: e.target.value })
                    }}
                    type={pass1Hidden ? "password" : "text"}
                    pattern="^(?=.*[A-Za-z])(?=.*\d).{8,}$"
                    isInvalid={validated && !infoObject?.password?.match(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a password with at least eight characters including letters and numbers.
                  </Form.Control.Feedback>
                  <a style={{ position: 'absolute', right: validated ? 35 : 10, top: 6 }} onClick={() => toggle(1)}>
                    <img src={pass1Hidden ? eyeIconCH : eyeIconSlashedCH} style={{ height: 17, cursor: 'pointer' }} />
                  </a>
                </div>
                <small className="form-text text-muted" id="password_hint">
                  At least eight characters, including letters and numbers.
                </small>
              </Col>
            </Form.Group>
            <Form.Group style={{ paddingTop: 5 }}>
              <Form.Label className="input-label" for="password2">
                Confirm Password*
              </Form.Label>
              <Col sm={16}>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    value={infoObject.password2}
                    style={formControlStyle}
                    id="password2"
                    required
                    onChange={(e) => {
                      setInfoObject({ ...infoObject, password2: e.target.value })
                    }}
                    type={pass2Hidden ? "password" : "text"}
                    pattern={infoObject.password}
                    isInvalid={validated && infoObject.password !== infoObject.password2} />
                  <Form.Control.Feedback type="invalid">
                    passwords do not match
                  </Form.Control.Feedback>
                  <a style={{ position: 'absolute', right: validated ? 35 : 10, top: 6 }} onClick={() => toggle(2)}>
                    <img src={pass2Hidden ? eyeIconCH : eyeIconSlashedCH} style={{ height: 17, cursor: 'pointer' }} />
                  </a>
                </div>
              </Col>
            </Form.Group>
          </>}
          <Form.Group style={{ paddingTop: 5 }}>
            <Form.Label className="input-label hover" for="code" onClick={showInfo}>
              Invite Code*  <img alt="" src={require('../../assets/images/icon-info-line.png')} className="icon" />
            </Form.Label>
            <Col sm={16}>
              <Form.Control
                value={infoObject.code}
                style={formControlStyle}
                id="code"
                required
                onChange={(e) => {
                  setInfoObject({ ...infoObject, code: e.target.value })
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please double check the code.
              </Form.Control.Feedback>
            </Col>
            <p />
            {!loggedIn && <span className="text-libre">Already have an account? <span className="underline pointer" onClick={toggleSignin}>Sign in</span></span>}
          </Form.Group>
        </div>
        {loading && <div style={{
          position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
          backgroundColor: '#ffffffaa', display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
          <Loader
            type="ThreeDots"
            color="#a47f51aa"
            height={5}
            width={5}
            radius={5}
          />
        </div>}
      </Col>
    );
};

function mapStateToProps(state, ownProps) {
  const { authentication, member } = state;

  return { authentication, member };
}

const connectedSupplierMemberInfo = connect(mapStateToProps)(SupplierMemberInfo);
export { connectedSupplierMemberInfo as SupplierMemberInfo };

