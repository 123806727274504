import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import ReCAPTCHA from "react-google-recaptcha";
import UIButton from "../UIButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { newMemberActions } from "../../_actions";
import { stateOptions } from "../../_helpers/state-options";
import { appConstants } from "../../_constants";
import { minDate } from "../../_constants";
import isEmpty from "lodash/isEmpty";
import FadeIn from "react-fade-in";

import get from "lodash/get";

import "./styles.css";

const BillingInfo = ({
  active,
  costChange,
  dispatch,
  invalidAge,
  birthdate,
  billingStreet,
  billingCity,
  billingZipCode,
  billingFirstName,
  billingLastName,
  billingEmail,
  billingPhone,
  billingState,
  deliveryType,
  pricingStructure,
  clubType,
  bottleLevel,
  stepForward,
  stepBack,
  showBilling,
  handleCollapse,
  isPromotion,
  promotionTCMessage,
  promotionTCUrl,
  reCaptchaRefMonthly,
  reCaptchaError,
  setReCaptchaError

}) => {
  const [pricingString, setPricingString] = useState(
    `Monthly:${deliveryType}:${clubType}:${bottleLevel}:`
  );
  const [validated, setValidated] = useState(false);
  const [billingValid, setBillingValid] = useState(false);

  let formElement;

  var today = new Date();
  var dd = (today.getDate() < 10 ? "0" : "") + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? "0" : "") + (today.getMonth() + 1);
  var yyyy = today.getFullYear() - 21;
  const validBirthdate = yyyy + "-" + mm + "-" + dd;

  useEffect(() => {
    setPricingString(`Monthly:${deliveryType}:${clubType}:${bottleLevel}:`);
    if (pricingStructure[pricingString]) {
      costChange(pricingStructure[pricingString]);
    }
  }, [
    deliveryType,
    clubType,
    bottleLevel,
    costChange,
    pricingString,
    pricingStructure,
  ]);

  useEffect(() => {
    const billingFilled = 
      !isEmpty(birthdate) &&
      !isEmpty(billingFirstName) &&
      !isEmpty(billingLastName) &&
      !isEmpty(billingStreet) &&
      !isEmpty(billingCity) &&
      !isEmpty(billingState) &&
      !isEmpty(billingZipCode);

    setValidated(billingFilled);
    setBillingValid(formElement.checkValidity());
  }, [
    birthdate,
    billingStreet,
    billingCity,
    billingZipCode,
    billingFirstName,
    billingLastName,
    billingEmail,
    billingPhone,
    billingState,
  ]);

  const canAdvance = () => {
    return billingValid;
  };

  const validateAge = () => {
    if (birthdate > validBirthdate) {
      invalidAge = true;
    }
  };

  return (
    <div className="inactive">
      <h4>2. Member Information</h4>
      <Collapse in={active && showBilling} onExited={handleCollapse}>
        <div id="collapse-div">
          <FadeIn>
            <div
              className="container"
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <Form
                noValidate
                validated={validated}
                ref={(element) => (formElement = element)}
                style={{ fontFamily: "Oswald", fontSize: 18 }}
              >
                <div className="text">
                  YOUR DETAILS
                </div>
                <Row>
                  <Col sm={5} className="field">
                    <Form.Control
                      value={billingFirstName}
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingFirstName(e.target.value)
                        )
                      }
                      type="text"
                      placeholder="First name"
                      name="recipientFirstName"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid first name.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={5} className="field">
                    <Form.Control
                      value={billingLastName}
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingLastName(e.target.value)
                        )
                      }
                      type="text"
                      placeholder="Last name"
                      name="recipientLastName"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid last name.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={2} className="field">
                    <Form.Control
                      className={ birthdate ? "" : "nonfill-field" }
                      value={birthdate}
                      onChange={(e) =>dispatch(newMemberActions.setBirthday(e.target.value))}
                      type="date"
                      name="birthday"
                      required
                      min={minDate}
                      max={validBirthdate}
                      isInvalid={invalidAge}
                      pattern="\d{4}-\d{2}-\d{2}"
                      style={{paddingRight: "0"}}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid birthdate.
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} className="field">
                    <Form.Control
                      value={billingEmail}
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingEmail(e.target.value)
                        )
                      }
                      type="text"
                      name="email"
                      placeholder="Email address"
                      required={true}
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={6} className="field">
                    <Form.Control
                      value={billingPhone}
                      onChange={(e) => {
                        let value = e.target.value;
                        let x = value
                          .replace(/\D/g, "")
                          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        value = !x[2]
                          ? x[1]
                          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                        dispatch(
                          newMemberActions.setBillingPhone(value)
                        );
                      }}
                      type="tel"
                      name="billingPhone"
                      placeholder="Phone number"
                      required={true}
                      pattern="^\D*(\d\D*){10}$"
                      // pattern="^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                      />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid phone number.
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <div className="text desktop-only" style={{margin: "1em 1em 0 0"}}>
                  HOME ADDRESS
                </div>
                <Row>
                <Col sm={5} className="field">
                    <Form.Control
                      type="input"
                      value={billingStreet}
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingStreet(e.target.value)
                        )
                      }
                      name="street"
                      placeholder="Street address"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid street.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={3} className="field">
                    <Form.Control
                      value={billingCity}
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingCity(e.target.value)
                        )
                      }
                      type="text"
                      name="city"
                      placeholder="City"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid city.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={2} className="field">
                    <Form.Control
                      as="select"
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingState(e.target.value)
                        )
                      }
                      value={billingState}
                      className={billingState ? "" : "nonfill-field"}
                      placeholder="State"
                      required={true}
                    >
                      {stateOptions.map((state) => (
                        <option key={state.id} value={state.value}>
                          {state.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid state.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={2} className="field">
                    <Form.Control
                      pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
                      type="input"
                      onChange={(e) =>
                        dispatch(newMemberActions.setBillingZip(e.target.value))
                      }
                      value={billingZipCode}
                      name="zipCode"
                      placeholder="Zip code"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid zip code.
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                {deliveryType === "Pickup" && (
                  <FadeIn>
                    <div className="inactive expandable">
                      <div className="total-block ok">
                        <div>
                          <h3>Total amount due:</h3>
                          <h5>
                            ${pricingStructure[pricingString]}
                            <span className="step-summary-text">
                              /month
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </FadeIn>
                )}
                <div className="hrule" />
                {isPromotion && 
                  <div>
                    <p>
                      {promotionTCMessage}{" "}
                      <a 
                        href={promotionTCUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>.
                    </p>
                  </div>
                }
                {deliveryType === "Pickup" &&
                  <div>
                    <p>
                      By clicking Proceed to Payment, you are accepting the <a href="https://chwinery.com/wine-club/terms-conditions">Terms and Conditions</a> of the Cooper's Hawk Wine Club and our <a href="https://chwinery.com/privacy-policy">Privacy Policy</a>.
                    </p>
                  </div>
                }
               {deliveryType === "Pickup" &&
               <div id="giftReCaptchaContainer">
              {/* Don't agree with removing this. Bad UX experience
              <Col md={2}>
                <UIButton text="BACK" onSelect={stepBack} />
                </Col>*/}
              <div style={{marginBottom: "1rem"}}>
                <div className={`reCaptchaContainer ${reCaptchaError && "reCaptchaContainerError"}`}>
                  <ReCAPTCHA
                    sitekey={appConstants.reCaptchaSiteKeyMUI}
                    ref={reCaptchaRefMonthly}
                    theme="dark"
                    onChange={(value) => {
                      if(value) {
                        setReCaptchaError("");
                      }
                    }}
                  />
                </div>
                {reCaptchaError && (
                  <div className="reCaptchaErrorMessage" id="monthlyReCaptchaErrorMessage">
                    {reCaptchaError}
                  </div>
                )}
              </div>
              </div>}
               <Row className="py-2">
                  {/* Don't agree with removing this. Bad UX experience
                  <Col md={2}>
                    <UIButton text="BACK" onSelect={stepBack} />
                    </Col>*/}
                  <Col md={9}></Col>
                  <Col md={3}>
                    <UIButton
                      onSelect={stepForward}
                      disabled={!canAdvance()}
                      validation={true}
                      text={deliveryType === "Pickup" ? "CONTINUE TO PAYMENT" : "NEXT STEP"}
                      aria-controls="collapse-div"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </FadeIn>
        </div>
      </Collapse>
      {!showBilling && (
        <FadeIn>
          <div className="step-icon-wrap">
            {billingEmail !== "" && (
              <>
                <div className="little-icon-wrap">
                  <div className="summary-bullet-container">
                    <div className="summary-bullet"></div>
                  </div>
                  <div className="summary-text">
                    {billingFirstName}&nbsp;
                    {billingLastName} 
                  </div>
                </div>
                <div className="little-icon-wrap">
                  <div className="summary-bullet-container">
                    <div className="summary-bullet"></div>
                  </div>
                  <div className="summary-text">
                      {billingPhone}
                  </div>
                </div>
                <div className="little-icon-wrap">
                  <div className="summary-bullet-container">
                    <div className="summary-bullet"></div>
                  </div>
                  <div className="summary-text">
                    {billingEmail} 
                  </div>
                </div>
              </>
            )}
          </div>
        </FadeIn>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { newMember, staticContent } = state;

  const deliveryType = get(newMember, "data.deliveryType", "");

  const invalidAge = false;
  const birthdate = get(newMember, "data.birthday", "");
  const billingAddress = get(newMember, "data.billingAddress", "");
  const billingFirstName = get(billingAddress, "firstName", "");
  const billingLastName = get(billingAddress, "lastName", "");
  const billingEmail = get(billingAddress, "email", "");
  const billingPhone = get(billingAddress, "phone", "");
  const billingStreet = get(billingAddress, "street", "");
  const billingCity = get(billingAddress, "city", "");
  const billingState = get(billingAddress, "state", "");
  const billingZipCode = get(billingAddress, "zipCode", "");
  const clubType = get(newMember, "data.clubType", "");
  const bottleLevel = get(newMember, "data.bottleLevel", "");

  const pricingStructure = get(staticContent, "data.pricingStructure", {});

  return {
    invalidAge,
    birthdate,
    billingEmail,
    billingPhone,
    billingFirstName,
    billingLastName,
    billingStreet,
    billingCity,
    billingZipCode,
    billingState,
    deliveryType,
    pricingStructure,
    clubType,
    bottleLevel
  };
}

const connectedBillingInfo = withRouter(connect(mapStateToProps)(BillingInfo));
export { connectedBillingInfo as BillingInfoMonthly };
