import { stateOptions } from './state-options';

// States that allow delivery for CH. "Default" is the 'State Options' option in dropdowns.
const deliveryStateList = ["default", "AK", "AZ", "CT", "FL", "IL", "IN", "MD", "MI", "MO", "NE", "NH", "ND", "OH", "OR", "VA", "WV", "WI", "WY"];

// Get full objects. { name, value, id, abbreviation, ...}
export const shippingStateOptions = stateOptions.filter((state)=>{return deliveryStateList.includes(state.abbreviation)});

// Get only delivery state abbreviations as a comma separated string.
export const deliveryStateAbbreviations = () => {
    const stateList = shippingStateOptions.slice(1).map((state) => {
      return state.abbreviation;
    })

    return stateList.join(", ").concat(".");
  }