export const memberConstants = {
  CHANGE_EMAIL_REQUEST: "MEMBER_CHANGE_EMAIL_REQUEST",
  CHANGE_EMAIL_SUCCESS: "MEMBER_CHANGE_EMAIL_SUCCESS",
  CHANGE_EMAIL_FAILURE: "MEMBER_CHANGE_EMAIL_FAILURE",

  POSTMEMBER_REFERRALCODE_REQUEST: 'POSTMEMBER_REFERRALCODE_REQUEST',
  POSTMEMBER_REFERRALCODE_SUCCESS: 'POSTMEMBER_REFERRALCODE_SUCCESS',
  POSTMEMBER_REFERRALCODE_FAILURE: 'POSTMEMBER_REFERRALCODE_FAILURE',


  SET_EMAIL: "SET_EMAIL",

  ADMIN: "MEMBER_ADMIN",

  INACTIVE: "MEMBER_TO_BE_ACTIVATED",
  NO_CONTACTS: "MEMBER_NO_CONTACTS",

  LOGIN_REQUEST: "MEMBER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "MEMBER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "MEMBER_LOGIN_FAILURE",

  LOGOUT: "MEMBER_LOGOUT",

  GETPOINTS_REQUEST: "MEMBER_GETPOINTS_REQUEST",
  GETPOINTS_SUCCESS: "MEMBER_GETPOINTS_SUCCESS",
  GETPOINTS_FAILURE: "MEMBER_GETPOINTS_FAILURE",

  FORGOT_PASSWORD_REQUEST: "MEMBER_FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "MEMBER_FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "MEMBER_FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "MEMBER_RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "MEMBER_RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "MEMBER_RESET_PASSWORD_FAILURE",

  GETMEMBER_REQUEST: "MEMBER_GETMEMBER_REQUEST",
  GETMEMBER_SUCCESS: "MEMBER_GETMEMBER_SUCCESS",
  GETMEMBER_FAILURE: "MEMBER_GETMEMBER_FAILURE",

  SWITCH_MEMBER_REQUEST: "MEMBER_SWITCH_MEMBER_REQUEST",
  SWITCH_MEMBER_SUCCESS: "MEMBER_SWITCH_MEMBER_SUCCESS",
  SWITCH_MEMBER_FAILURE: "MEMBER_SWITCH_MEMBER_FAILURE",

  GETMEMBERS_FROM_EMAIL_REQUEST: "MEMBER_GETMEMBERS_FROM_EMAIL_REQUEST",
  GETMEMBERS_FROM_EMAIL_SUCCESS: "MEMBER_GETMEMBERS_FROM_EMAIL_SUCCESS",
  GETMEMBERS_FROM_EMAIL_FAILURE: "MEMBER_GETMEMBERS_FROM_EMAIL_FAILURE",

  POSTMEMBER_REQUEST: "MEMBER_POSTMEMBER_REQUEST",
  POSTMEMBER_SUCCESS: "MEMBER_POSTMEMBER_SUCCESS",
  POSTMEMBER_FAILURE: "MEMBER_POSTMEMBER_FAILURE",

  BOTTLE_PICKUP_REQUEST: "MEMBER_BOTTLE_PICKUP_REQUEST",
  BOTTLE_PICKUP_SUCCESS: "MEMBER_BOTTLE_PICKUP_SUCCESS",
  BOTTLE_PICKUP_FAILURE: "MEMBER_BOTTLE_PICKUP_FAILURE",

  REPLACEMENT_CARD_REQUEST: "MEMBER_REPLACEMENT_CARD_REQUEST",
  REPLACEMENT_CARD_SUCCESS: "MEMBER_REPLACEMENT_CARD_SUCCESS",
  REPLACEMENT_CARD_FAILURE: "MEMBER_REPLACEMENT_CARD_FAILURE",
  REPLACEMENT_CARD_CONFIRM: "MEMBER_REPLACEMENT_CARD_CONFIRM",

  NEW_PASSWORD_REQUIRED: "MEMBER_NEW_PASSWORD_REQUIRED",
  SET_LOGIN_DESTINATION: "SET_LOGIN_DESTINATION",

  UPDATE_OPTOUT: "MEMBER_UPDATE_OPTOUT",
  UPDATE_CLUBTYPE: "MEMBER_UPDATE_CLUBTYPE",
  UPDATE_BOTTLELEVEL: "MEMBER_UPDATE_BOTTLELEVEL",
  UPDATE_FIRSTNAME: "MEMBER_UPDATE_FIRSTNAME",
  UPDATE_LASTNAME: "MEMBER_UPDATE_LASTNAME",
  UPDATE_BIRTHDAY: "MEMBER_UPDATE_BIRTHDAY",
  UPDATE_SECONDARY_FIRSTNAME: "MEMBER_UPDATE_SECONDARY_FIRSTNAME",
  UPDATE_SECONDARY_LASTNAME: "MEMBER_UPDATE_SECONDARY_LASTNAME",
  UPDATE_SECONDARY_BIRTHDAY: "MEMBER_UPDATE_SECONDARY_BIRTHDAY",
  UPDATE_EMAIL: "MEMBER_UPDATE_EMAIL",
  UPDATE_PHONE: "MEMBER_UPDATE_PHONE",
  UPDATE_HOME_STREET: "MEMBER_UPDATE_HOME_STREET",
  UPDATE_HOME_CITY: "MEMBER_UPDATE_HOME_CITY",
  UPDATE_HOME_STATE: "MEMBER_UPDATE_HOME_STATE",
  UPDATE_HOME_ZIP: "MEMBER_UPDATE_HOME_ZIP",
  UPDATE_SHIPPING_STREET: "MEMBER_UPDATE_SHIPPING_STREET",
  UPDATE_SHIPPING_CITY: "MEMBER_UPDATE_SHIPPING_CITY",
  UPDATE_SHIPPING_STATE: "MEMBER_UPDATE_SHIPPING_STATE",
  UPDATE_SHIPPING_ZIP: "MEMBER_UPDATE_SHIPPING_ZIP",

  ACTIVATE_GIFT_REQUEST: "MEMBER_ACTIVATE_GIFT_REQUEST",
  ACTIVATE_GIFT_SUCCESS: "MEMBER_ACTIVATE_GIFT_SUCCESS",
  ACTIVATE_GIFT_FAILURE: "MEMBER_ACTIVATE_GIFT_FAILURE",
  UPDATE_GIFT_LENGTH: "MEMBER_UPDATE_GIFT_LENGTH",
  UPDATE_PICKUP_TYPE: "MEMBER_UPDATE_PICKUP_TYPE",

  TOGGLE_MEMBER_HEADER: "TOGGLE_MEMBER_HEADER",

  GET_OR_CREATE_REQUEST: "GET_OR_CREATE_REQUEST",
  GET_OR_CREATE_SUCCESS: "GET_OR_CREATE_SUCCESS",
  GET_OR_CREATE_FAILURE: "GET_OR_CREATE_FAILURE",

  SHOW_WINECLUB_MODAL: "SHOW_WINECLUB_MODAL",
  HIDE_WINECLUB_MODAL: "HIDE_WINECLUB_MODAL",

  CLEAR_AUTH_ERRORS: "MEMBER_CLEAR_AUTH_SUCCESS",
  UPDATE_SUPPLIER:'UPDATE_SUPPLIER',
};
