import React from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import closeIcon from "../../assets/images/close@2x.png";
import "./styles.css";

const Modal = ({ title, message, onDismiss, className = "" }) => {
  return (
    <>
      <div className={`modal-overlay-inner ${className}`}>
        <div className="modal-title">{title}</div>
        <div className="modal-message">{message}</div>
        <motion.img
          src={closeIcon}
          alt="close button icon"
          className="close-btn"
          onClick={onDismiss}
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 0.9 }}
        />
      </div>
    </>
  );
};

export default Modal;

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
};

Modal.defaultProps = {
  title: "Alert",
  message: "",
  onDismiss: () => {},
};
