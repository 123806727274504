import React from "react";
import get from "lodash/get";
import { appConstants } from "../../_constants";
import formatDate from "../../_helpers/format-date";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import size from "lodash/size";

import "./styles.css";

const BarrelOrders = ({ barrelOrders, style }) => {
  if (size(barrelOrders, 0) === 0) {
    return null;
  }
  return (
    <div style={style}>
      <Row className="justify-content-center">
        <Col md={12}>
          <h4>Barrel Reserve Preorders</h4>
        </Col>

        <Col lg={11} className="visible-desktop">
          <Table borderless size="sm" className="my-md-4">
            <thead>
              <tr>
                <th>Date</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              {barrelOrders.map((order, index) => (
                <tr key={index}>
                  <td>{formatDate(order.orderDate)}</td>
                  <td>
                    <Row fill className="justify-content-center">
                      <Col md={2} align="left" className="pl-0">
                        {order.bottleCount}{" "}
                        {order.bottleCount === 1 ? " Bottle " : "Bottles"}{" "}
                      </Col>
                      <Col md={5} align="center" className="p-0">
                        {order.distributionMethod}-{order.pickupLocation}
                      </Col>
                      <Col md={2} align="right" className="pr-0">
                        ({order.totalCost})
                      </Col>
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>

        <Col className="visible-device">
          <Row className="preorder-header mt-1">
            <Col xs={6} align="center" className="pr-0">
              <h6>Date</h6>
            </Col>
            <Col xs={6} align="center" className="pl-0">
              <h6>Order</h6>
            </Col>
          </Row>
          {barrelOrders.map((order, index) => (
            <Card.Body px={0} key={index} className="preorder-card">
              <Row>
                <Col xs={6} className="my-auto p-0" align="center">
                  <Card.Subtitle>
                    <span className="text-light align-center">
                      {formatDate(order.orderDate)}
                    </span>
                  </Card.Subtitle>
                </Col>
                <Col xs={6} className="my-auto p-0">
                  <Card.Text align="center" className="preorder-details">
                    {" "}
                    {order.bottleCount}{" "}
                    {order.bottleCount === 1 ? " Bottle " : "Bottles"}{" "}
                    {order.distributionMethod}-{order.pickupLocation} <br />(
                    {order.totalCost})
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          ))}
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { barrelOrders } = state;
  const orderData = barrelOrders.data;
  return {
    barrelOrders: get(orderData, "orderHistory", ""),
  };
}

const connectedBarrelOrders = connect(mapStateToProps)(BarrelOrders);
export { connectedBarrelOrders as BarrelOrders };
