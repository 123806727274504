import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { Redirect } from "react-router-dom";
import LoadingSpinners from "../../components/LoadingSpinners";
import { WineClubInfoGift } from "../../components/Forms/wineclub-info-gift";
import { DeliveryOptions } from "../../components/Forms/delivery-options-gift";
import { BillingInfo } from "../../components/Forms/billing-info-gift";
import { alertActions, newMemberActions, staticContentActions } from "../../_actions";
import { appConstants } from "../../_constants";
import FadeIn from "react-fade-in";
import "./styles.css";
import { logGTMEvent } from "../../_helpers";
import { useRenameDocument } from "../../context/renameDocument";

const NewGiftMemberSignUp = ({
  dispatch,
  giftObject,
  giftStep,
  mailToFriend,
  mailToFriendObject,
  loading,
  paymentUrl,
  clubType,
  deliveryType,
  billingPhone,
  bottleLevel,
  giftLength,
  pricingStructure,
  signup,
}) => {
  const [price, setPrice] = useState("");
  const [showGift, setShowGift] = useState(giftStep === "1");
  const [giftOk, setGiftOk] = useState(false);
  const [showDelivery, setShowDelivery] = useState(giftStep === "2");
  const [deliveryOk, setDeliveryOk] = useState(false);
  const [showBilling, setShowBilling] = useState(giftStep === "3");
  const [billingOk, setBillingOk] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {setInnerWidth(window.innerWidth)});
  const [gtmEvent, setGtmEvent] = useState({});
  const [pricingString, setPricingString] = useState(
    `Gift:${deliveryType}:${clubType}:${bottleLevel}:${giftLength}`
  );

  useRenameDocument('Gift Member Sign Up')

  useEffect(() => {
    setPricingString(`Gift:${deliveryType}:${clubType}:${bottleLevel}:${giftLength}`);
    if (pricingStructure[pricingString]) {
      setGtmEvent({
        items: [
          {
        item_id: pricingString,
        item_name: `Gift ${bottleLevel} Bottle`,
        number_of_bottles: bottleLevel,
        subscription_length: `${giftLength} Months`,
        item_variant: clubType,
        price: `${pricingStructure[pricingString]}`,
        quantity: 1,
          }
        ],
        shipping: `${pricingStructure[pricingString] - pricingStructure[`Gift:Pickup:${clubType}:${bottleLevel}:${giftLength}`]}`,
        delivery_type: deliveryType,
        membership_type: 'Gift',
        value: pricingStructure[pricingString],
      })
    }
  }, [deliveryType,
    clubType,
    bottleLevel,
    giftLength,
    pricingString,
    pricingStructure,
    signup])

  const reCaptchaRef = useRef();
  const [reCaptchaError, setReCaptchaError] = useState("");

  const handleSubmit = () => {
    setReCaptchaError("");
    if (giftStep === "2") {
      dispatch(newMemberActions.setGiftStep("3"));
    } else {
      const reCaptchaToken = reCaptchaRef.current.getValue();
      if(reCaptchaToken){
        mailToFriendObject.member.CHW_Cost_of_Membership__c = price;
        giftObject.member.CHW_Cost_of_Membership__c = price;
        const signUpObject = mailToFriend ? mailToFriendObject : giftObject;
        signUpObject.reCaptchaToken = reCaptchaToken;
        dispatch(
          newMemberActions.signup(signUpObject)
        );
      } else {
        setReCaptchaError("Complete the ReCAPTCHA checkbox above");
      }
    }
  };

  useEffect(() => {
    dispatch(staticContentActions.getStaticContent());
  }, []);

  useEffect(() => {
    switch (giftStep) {
      case "1": return logGTMEvent("wine_club_step_view", {page_location: 'https://member.chwinery.com/NewGiftMemberSignUp', page_title: "Wine Club Info Gift", page_path: "/WineClubInfoGift", step_name: "Select Gift Membership Step"}, {membership_type: "Gift"});
      case "2": return logGTMEvent("wine_club_step_view", {page_location: 'https://member.chwinery.com/NewGiftMemberSignUp', page_title: "Select Gift Recipient", page_path: "/SelectGiftRecipient", step_name: "Select Gift Recipient Step"}, {membership_type: "Gift"});
      case "3": return logGTMEvent("wine_club_step_view", {page_location: 'https://member.chwinery.com/NewGiftMemberSignUp', page_title: "Billing Information", page_path: "/BillingInformation", step_name: "Billing Info Step"}, {membership_type: "Gift"});
    }
  }, [giftStep]);

  useEffect(() => {
    if (paymentUrl === "ADDRESS_VERIFICATION_FAILURE") {
      dispatch(
        alertActions.error(
          "Shipping address invalid. Please update your delivery options."
        )
      );
      dispatch(newMemberActions.setGiftStep("2"));
    }
  }, [loading]);

  const handleCollapse = () => {
    if (giftStep === "1") {
      setTimeout(function() {
        setShowGift(true);
        setShowBilling(false);
        setShowDelivery(false);
      }, 200);
    }
    if (giftStep === "2") {
      setTimeout(function() {
        setShowDelivery(true);
        setShowBilling(false);
        setShowGift(false);
      }, 200);
    }
    if (giftStep === "3") {
      setTimeout(function() {
        setShowBilling(true);
        setShowDelivery(false);
        setShowGift(false);
      }, 200);
    }
  };

  const canStep = (step) => {
    switch(step) {
      case "1":
        return (giftStep !== "1" && clubType !== "") ? true : false;
      case "2":
        return (giftStep !== "2" && deliveryType !== "") ? true : false;
      case "3":
        return (giftStep !== "3" && billingPhone !== "") ? true : false;
    }
  };

  if (loading) {
    return <LoadingSpinners />;
  }

  const paymentUrlOk = !paymentUrl?.includes("FAILURE");

  return !loading && paymentUrl && paymentUrlOk ? (
    <Redirect to={{ pathname: "/Payment", state: { signupType: "gift", newStyle: true, gtmEvent } }} />
  ) : (
    <div style={{backgroundColor: 'white'}}>
      <img className="image-to-be-gradiented" alt="" src={innerWidth > 576 ? require('../../assets/images/bbbg-v2.png') : require('../../assets/images/bbbg-v3.png')} />
            <div className="image-gradient" />
    <div className="container form-container">
      <div className="container form-items-container">
      <FadeIn>
      <h1 className="join-header">Join the Wine Club </h1>
        <div
          className={`${canStep("1") ? "toggleable" : ""}`}
          onClick={() => canStep("1") && dispatch(newMemberActions.setGiftStep("1"))}
        >
          <WineClubInfoGift
            costChange={(price) => setPrice(price)}
            active={giftStep === "1"}
            stepBack={() => dispatch(newMemberActions.setGiftStep("1"))}
            innerWidth={innerWidth}
            stepForward={() => {
              logGTMEvent("wine_club_step_complete", {page_title: "Wine Club Info Gift", page_path: "/WineClubInfoGift", step_name: "Select Gift Membership Step"}, {membership_type: "Gift"});
              dispatch(newMemberActions.setGiftStep("2"));
              setGiftOk(true);
            }}
            handleCollapse={() => handleCollapse()}
            showGift={showGift}
            giftOk={giftOk}
          />
        </div>
      </FadeIn>
      <br />

      <FadeIn>
        <div
          className={`${canStep("2") ? "toggleable" : ""}`}
          onClick={() => canStep("2") && dispatch(newMemberActions.setGiftStep("2"))}
        >
          <DeliveryOptions
            costChange={(str) => {}}
            active={giftStep === "2"}
            stepBack={() => dispatch(newMemberActions.setGiftStep("1"))}
            innerWidth={innerWidth}
            stepForward={() => {
              logGTMEvent("wine_club_step_complete", {page_title: "Select Gift Recipient", page_path: "/SelectGiftRecipient", step_name: "Select Gift Recipient Step"}, {membership_type: "Gift"});
              handleSubmit();
              setDeliveryOk(true);
            }}
            handleCollapse={() => handleCollapse()}
            showDelivery={showDelivery}
            deliveryOk={deliveryOk}
          />
        </div>
      </FadeIn>
      <br />

      <FadeIn>
        <div
          className={`${canStep("3") ? "toggleable" : ""}`}
          onClick={() => canStep("3") && dispatch(newMemberActions.setGiftStep("3"))}
        >
          <BillingInfo
            costChange={(str) => {}}
            active={giftStep === "3"}
            stepBack={() => dispatch(newMemberActions.setGiftStep("2"))}
            stepForward={() => {
              logGTMEvent("wine_club_step_complete", {page_title: "Billing Information", page_path: "/BillingInformation", step_name: "Billing Info Step"}, {membership_type: "Gift"});
              handleSubmit();
              setBillingOk(true);
            }}
            innerWidth={innerWidth}
            handleCollapse={() => handleCollapse()}
            showBilling={showBilling}
            billingOk={billingOk}
            reCaptchaRef={reCaptchaRef}
            reCaptchaError={reCaptchaError}
            setReCaptchaError={setReCaptchaError}
            // stepForward={() =>
            //   console.log(
            //     mailToFriend ? mailToFriendObject : giftObject,
            //     mailToFriend
            //   )
            // }
          />
        </div>
      </FadeIn>
      </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  var today = new Date();
  var dd = (today.getDate() < 10 ? "0" : "") + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? "0" : "") + (today.getMonth() + 1);
  var yyyy = today.getFullYear();
  const todayDate = yyyy + "-" + mm + "-" + dd;
  const { newMember, staticContent } = state;
  const paymentUrl = get(newMember, "paymentUrl", "");
  const loading = get(newMember, "loading", false);
  const giftStep = get(newMember, "data.giftStep", 1);
  const mailToFriend = get(newMember, "data.sendTo", "me") === "friend";
  const shippingAddress = get(newMember, "data.shippingAddress", {});
  const billingAddress = get(newMember, "data.billingAddress", {});
  const recipientAddress = get(newMember, "data.recipientAddress", {});
  const shippingAsBilling = get(newMember, "data.shippingAsBilling", false);
  const deliveryType = get(newMember, "data.deliveryType", "");
  const clubType = get(newMember, "data.clubType", "");
  const bottleLevel = get(newMember, "data.bottleLevel", "");
  const giftLength = get(newMember, "data.giftLength", "");
  const pricingStructure = get(staticContent, "data.pricingStructure", {});
  const signup = get(newMember, "data.signup", false);
  const billingPhone = get(billingAddress, "phone", "");
  
  const mailRecipient = deliveryType === "Pickup" ? false : true;

  const giftGiverDetails = !shippingAsBilling
    ? get(newMember, "data.billingAddress", {})
    : get(newMember, "data.shippingAddress", {});

  if(deliveryType === 'Pickup'){
    giftGiverDetails.street = get(billingAddress, "street", "");
    giftGiverDetails.city = get(billingAddress, "city", "");
    giftGiverDetails.state = get(billingAddress, "state", "");
    giftGiverDetails.zipCode = get(billingAddress, "zipCode", "");
  }

  // to friend
  const mailToFriendObject = {
    member: {
      FirstName: `${get(recipientAddress, "firstName", "")}`,
      LastName: `${get(recipientAddress, "lastName", "")}`,
      Birthdate: `${get(recipientAddress, "birthday", "")}`,
      CHW_Membership_Type__c: "Gift",
      CHW_Charge__c: "Immediately",
      CHW_Club_Type__c: get(newMember, "data.clubType", "Variety"),
      CHW_Level__c: get(newMember, "data.bottleLevel", "1"),
      CHW_Delivery__c: get(newMember, "data.deliveryType", ""),
      CHW_Months_to_Give__c: `${get(newMember, "data.giftLength", "3")}`,
      //
      Phone: get(newMember, "data.phone", ""),
      Email: `${get(newMember, "data.email", "")}`,
      // phone and email of recipient arent asked for on recipient details
      MailingStreet: `${get(newMember, "data.recipientAddress.street", "")}`,
      MailingCity: `${get(newMember, "data.recipientAddress.city", "")}`,
      MailingState: `${get(newMember, "data.recipientAddress.state", "")}`,
      MailingPostalCode: `${get(
        newMember,
        "data.recipientAddress.zipCode",
        ""
      )}`,
      OtherStreet: `${get(newMember, "data.recipientAddress.street", "")}`,
      OtherCity: `${get(newMember, "data.recipientAddress.city", "")}`,
      OtherState: `${get(newMember, "data.recipientAddress.state", "")}`,
      OtherPostalCode: `${get(newMember, "data.recipientAddress.zipCode", "")}`,
      Gift_Giver_First_Name__c: `${get(giftGiverDetails, "firstName", "")}`,
      Gift_Giver_Last_Name__c: `${get(giftGiverDetails, "lastName", "")}`,
      Gift_Giver_Phone__c: get(giftGiverDetails, "phone", ""),
      Gift_Giver_Email__c: `${get(giftGiverDetails, "email", "")}`,
      Gift_Giver_Billing_Street__c: `${get(giftGiverDetails, "street", "")}`,
      Gift_Giver_Billing_City__c: `${get(giftGiverDetails, "city", "")}`,
      Gift_Giver_Billing_State__c: `${get(giftGiverDetails, "state", "")}`,
      Gift_Giver_Billing_Zip__c: `${get(giftGiverDetails, "zipCode", "")}`,
      Personalized_Message__c: `${get(newMember, "data.giftMessage", "")}`,
      Gift_Ship_Date__c: `${get(newMember, "data.shipDate", todayDate)}`,
      Mail_Recipient__c: mailRecipient,
      CHW_Gift_Given__c: true
    },
    returnUrl: `${appConstants.paymentReturnUrl}payment-success.htm`,
  };

  // to me
  const giftObject = {
    member: {
      FirstName: `${get(shippingAddress, "firstName", "")}`,
      LastName: `${get(shippingAddress, "lastName", "")}`,
      Birthdate: `${get(shippingAddress, "birthday", "")}`,
      CHW_Membership_Type__c: "Gift",
      CHW_Charge__c: "Immediately",
      CHW_Club_Type__c: get(newMember, "data.clubType", "Variety"),
      CHW_Level__c: get(newMember, "data.bottleLevel", "1"),
      CHW_Delivery__c: get(newMember, "data.deliveryType", ""),
      CHW_Months_to_Give__c: `${get(newMember, "data.giftLength", "3")}`,
      Phone: get(billingAddress, "phone", ""),
      Email: `${get(shippingAddress, "email", "")}`,
      Gift_Giver_First_Name__c: `${get(giftGiverDetails, "firstName", "")}`,
      Gift_Giver_Last_Name__c: `${get(giftGiverDetails, "lastName", "")}`,
      Gift_Giver_Phone__c: get(billingAddress, "phone", ""),
      Gift_Giver_Email__c: `${get(giftGiverDetails, "email", "")}`,
      Gift_Giver_Billing_Street__c: `${get(giftGiverDetails, "street", "")}`,
      Gift_Giver_Billing_City__c: `${get(giftGiverDetails, "city", "")}`,
      Gift_Giver_Billing_State__c: `${get(giftGiverDetails, "state", "")}`,
      Gift_Giver_Billing_Zip__c: `${get(giftGiverDetails, "zipCode", "")}`,
      MailingStreet: `${get(shippingAddress, "street", "")}`,
      MailingCity: `${get(shippingAddress, "city", "")}`,
      MailingState: `${get(shippingAddress, "state", "")}`,
      MailingPostalCode: `${get(shippingAddress, "zipCode", "")}`,
      OtherStreet: `${get(shippingAddress, "street", "")}`,
      OtherCity: `${get(shippingAddress, "city", "")}`,
      OtherState: `${get(shippingAddress, "state", "")}`,
      OtherPostalCode: `${get(shippingAddress, "zipCode", "")}`,
      Gift_Ship_Date__c: `${get(newMember, "data.shipDate", todayDate)}`,
      Mail_Recipient__c: mailRecipient,
      CHW_Gift_Given__c: false
    },
    returnUrl: `${appConstants.paymentReturnUrl}payment-success.htm`,
  };

  return {
    mailToFriend,
    mailToFriendObject,
    giftObject,
    loading,
    paymentUrl,
    giftStep,
    clubType,
    deliveryType,
    billingPhone,
    giftLength,
    bottleLevel,
    pricingStructure,
    signup,
  };
}

const connectedNewGiftMemberSignUp = connect(mapStateToProps)(
  NewGiftMemberSignUp
);
export { connectedNewGiftMemberSignUp as NewGiftMemberSignUp };
