import { newMemberConstants } from "../_constants";

export function newMember(
  state = {
    loading: false,
    paymentUrl: null,
    potentialEmail: null,
    data: {
      shippingAsBilling: false,
      deliveryType: "Pickup",
      giftStep: "1",
      monthlyStep: "1"
    },
  },
  action
) {
  switch (action.type) {
    case newMemberConstants.CLEAR_PAYMENT_FORM:
      return {
        ...state,
        paymentUrl: null,
      };
    case newMemberConstants.CLEAR_NEW_MEMBER:
      return {
        ...state,
        potentialMessage: "",
        potentialMemberId: "",
        potentialCreated: false,
        loading: false,
        data: {
          ...state.data,
          billingAddress: {},
          homeAddress: {},
          birthday: "",
          phone: "",
          shippingAsBilling: false,
          deliveryType: "Pickup",
          giftStep: "1",
          monthlyStep: "1",
        },
      };
    case newMemberConstants.COGNITO_REQUEST:
      return {
        ...state,
        paymentUrl: null,
        loading: true,
      };
    case newMemberConstants.COGNITO_SUCCESS:
      return {
        ...state,
        paymentUrl: null,
        loading: false,
        data: action.response,
      };
    case newMemberConstants.COGNITO_FAILURE:
      return {
        ...state,
        paymentUrl: null,
        loading: false,
        error: action.error,
      };

    case newMemberConstants.GETMEMBERS_FROM_LASTCARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case newMemberConstants.GETMEMBERS_FROM_LASTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.with,
        error: null,
      };
    case newMemberConstants.GETMEMBERS_FROM_LASTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case newMemberConstants.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        paymentUrl: null,
      };
    case newMemberConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentUrl: action.response.with.message,
        data: {
          ...state.data,
          Id: action.response.with.recordId,
        },
      };
    case newMemberConstants.SIGNUP_FAILURE:
      return {
        ...state,
        paymentUrl: null,
        loading: false,
        error: action.error,
      };

    case newMemberConstants.CONVERT_POTENTIAL_MONTHLY_REQUEST:
      return {
        ...state,
        loading: true,
        paymentUrl: null,
      };
    case newMemberConstants.CONVERT_POTENTIAL_MONTHLY_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentUrl: action.response.with,
      };
    case newMemberConstants.CONVERT_POTENTIAL_MONTHLY_FAILURE:
      return {
        ...state,
        paymentUrl: null,
        loading: false,
        error: action.error,
      };

    case newMemberConstants.POTENTIAL_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        potentialCreated: false,
        potentialMessage: null,
      };
    case newMemberConstants.POTENTIAL_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        potentialCreated: action.response.with.success,
        potentialMessage: action.response.with.message,
        potentialMemberId: action.response.with.recordId,
      };
    case newMemberConstants.POTENTIAL_SIGNUP_FAILURE:
      return {
        ...state,
        paymentUrl: null,
        loading: false,
        potentialCreated: false,
        potentialMessage: "There was an issue with creating your account",
        error: action.error,
      };
    case newMemberConstants.SET_CLUBTYPE:
      return {
        ...state,
        data: {
          ...state.data,
          clubType: action.clubType,
        },
      };
    case newMemberConstants.SET_BOTTLELEVEL:
      return {
        ...state,
        data: {
          ...state.data,
          bottleLevel: action.bottleLevel,
        },
      };
    case newMemberConstants.SET_SHIPPING_FIRSTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            firstName: action.firstName,
          },
        },
      };
    case newMemberConstants.SET_SHIPPING_LASTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            lastName: action.lastName,
          },
        },
      };

    case newMemberConstants.SET_SHIPPING_BIRTHDAY:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            birthday: action.birthday,
          },
        },
      };

    case newMemberConstants.SET_POTENTIAL_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          potentialEmail: action.email,
        },
      };

    case newMemberConstants.SET_SHIPPING_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            email: action.email,
          },
        },
      };
    case newMemberConstants.SET_BIRTHDAY:
      return {
        ...state,
        data: {
          ...state.data,
          birthday: action.birthday,
        },
      };

    case newMemberConstants.SET_RECIPIENT_FIRSTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          recipientAddress: {
            ...state.data.recipientAddress,
            firstName: action.firstName,
          },
        },
      };
    case newMemberConstants.SET_RECIPIENT_LASTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          recipientAddress: {
            ...state.data.recipientAddress,
            lastName: action.lastName,
          },
        },
      };

    case newMemberConstants.SET_RECIPIENT_BIRTHDAY:
    return {
      ...state,
      data: {
        ...state.data,
        recipientAddress: {
          ...state.data.recipientAddress,
          birthday: action.birthday,
        },
      },
    };

    case newMemberConstants.SET_PHONE:
      return {
        ...state,
        data: {
          ...state.data,
          phone: action.phone,
        },
      };
    case newMemberConstants.SET_HOME_STREET:
      return {
        ...state,
        data: {
          ...state.data,
          homeAddress: {
            ...state.data.homeAddress,
            street: action.street,
          },
        },
      };
    case newMemberConstants.SET_HOME_CITY:
      return {
        ...state,
        data: {
          ...state.data,
          homeAddress: {
            ...state.data.homeAddress,
            city: action.city,
          },
        },
      };
    case newMemberConstants.SET_HOME_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          homeAddress: {
            ...state.data.homeAddress,
            state: action.state,
          },
        },
      };
    case newMemberConstants.SET_HOME_ZIP:
      return {
        ...state,
        data: {
          ...state.data,
          homeAddress: {
            ...state.data.homeAddress,
            zipCode: action.zip,
          },
        },
      };

    case newMemberConstants.SET_BILLING_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          billingAddress: {
            ...state.data.billingAddress,
            email: action.email,
          },
        },
      };

    case newMemberConstants.SET_BILLING_PHONE:
      return {
        ...state,
        data: {
          ...state.data,
          billingAddress: {
            ...state.data.billingAddress,
            phone: action.phone,
          },
        },
      };

    case newMemberConstants.SET_BILLING_FIRSTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          billingAddress: {
            ...state.data.billingAddress,
            firstName: action.firstName,
          },
        },
      };
    case newMemberConstants.SET_BILLING_LASTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          billingAddress: {
            ...state.data.billingAddress,
            lastName: action.lastName,
          },
        },
      };

    case newMemberConstants.SET_BILLING_STREET:
      return {
        ...state,
        data: {
          ...state.data,
          billingAddress: {
            ...state.data.billingAddress,
            street: action.street,
          },
        },
      };
    case newMemberConstants.SET_BILLING_CITY:
      return {
        ...state,
        data: {
          ...state.data,
          billingAddress: {
            ...state.data.billingAddress,
            city: action.city,
          },
        },
      };
    case newMemberConstants.SET_BILLING_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          billingAddress: {
            ...state.data.billingAddress,
            state: action.state,
          },
        },
      };
    case newMemberConstants.SET_BILLING_ZIP:
      return {
        ...state,
        data: {
          ...state.data,
          billingAddress: {
            ...state.data.billingAddress,
            zipCode: action.zip,
          },
        },
      };

    case newMemberConstants.SET_RECIPIENT_STREET:
      return {
        ...state,
        data: {
          ...state.data,
          recipientAddress: {
            ...state.data.recipientAddress,
            street: action.street,
          },
        },
      };
    case newMemberConstants.SET_RECIPIENT_CITY:
      return {
        ...state,
        data: {
          ...state.data,
          recipientAddress: {
            ...state.data.recipientAddress,
            city: action.city,
          },
        },
      };
    case newMemberConstants.SET_RECIPIENT_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          recipientAddress: {
            ...state.data.recipientAddress,
            state: action.state,
          },
        },
      };
    case newMemberConstants.SET_RECIPIENT_ZIP:
      return {
        ...state,
        data: {
          ...state.data,
          recipientAddress: {
            ...state.data.recipientAddress,
            zipCode: action.zip,
          },
        },
      };
    case newMemberConstants.SET_RECIPIENT_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          recipientAddress: {
            ...state.data.recipientAddress,
            email: action.email,
          },
          email: action.email,
        },
      };

    case newMemberConstants.SET_SHIPPING_STREET:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            street: action.street,
          },
        },
      };
    case newMemberConstants.SET_SHIPPING_CITY:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            city: action.city,
          },
        },
      };
    case newMemberConstants.SET_SHIPPING_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            state: action.state,
          },
        },
      };
    case newMemberConstants.SET_SHIPPING_ZIP:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            zipCode: action.zip,
          },
        },
      };

    case newMemberConstants.SET_GIFT_LENGTH:
      return {
        ...state,
        data: {
          ...state.data,
          giftLength: action.giftLength,
        },
      };
    case newMemberConstants.SET_DELIVERY_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          deliveryType: action.deliveryType,
        },
      };

    case newMemberConstants.SET_GIFT_MESSAGE:
      return {
        ...state,
        data: {
          ...state.data,
          giftMessage: action.giftMessage,
        },
      };

    case newMemberConstants.SET_SHIP_DATE:
      return {
        ...state,
        data: {
          ...state.data,
          shipDate: action.shipDate,
        },
      };

    case newMemberConstants.SET_GIFT_STEP:
      return {
        ...state,
        data: {
          ...state.data,
          giftStep: action.giftStep,
        },
      };

    case newMemberConstants.SET_MONTHLY_STEP:
      return {
        ...state,
        data: {
          ...state.data,
          monthlyStep: action.monthlyStep,
        },
      };
    
    case newMemberConstants.SET_CUSTOMER_ID:
      return {
        ...state,
        data: {
          ...state.data,
          customerId: action.customerId,
        },
      };
      
    case newMemberConstants.SET_SEND_TO:
      return {
        ...state,
        data: {
          ...state.data,
          sendTo: action.sendTo,
        },
      };

    case newMemberConstants.SET_SHIPPING_AS_BILLING:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAsBilling: action.shippingAsBilling,
        },
      };
    
    case newMemberConstants.SET_PROMOTION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          pid: action.pid,
        },
      };

      case newMemberConstants.SET_SAME_AS_BILLING:
      return {
        ...state,
        data: {
          ...state.data,
          sameAsBilling: action.sameAsBilling,
        },
      };

    default:
      return state;
  }
}
