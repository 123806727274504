import React, { Children } from 'react';
import GoldPlusImg from '../../assets/images/gold-plus.svg';
import EsqGoldPlus from '../../assets/images/gold-plus-esquire.svg';
import { connect } from 'react-redux';
import { isThemeEsquire } from '../../_helpers';
import { get } from 'lodash/get';
import { createUseStyles } from 'react-jss';
import { themes } from '../../_helpers';

const useThemedButtonStyles = createUseStyles({
  button: {
    backgroundColor: props => props.backgroundColor,
    color: 'white',
    minWidth: '200px',
    width: '100%',
    minHeight: '60px',
    fontFamily: `'Oswald', sans-serif`,
    fontWeight: '500',
    fontSize: '1.1em',
    letterSpacing: '1px',
    height: '100%',
    borderRadius: '4px',
    border: 'none',
  },
});

const GoldPlus = ({ isEsquire, onClick, children, disabled }) => {
  const theme = isEsquire
    ? themes.esquire.buttonTheme
    : themes.default.buttonTheme;
  const classes = useThemedButtonStyles(theme);
  return (
    <button onClick={onClick} className={classes.button} disabled={disabled}>
      {children}
    </button>
  );
};

const mapStateToProps = (state, ownProps) => {
  let isEsquire = isThemeEsquire();

  return {
    onClick: ownProps.onClick,
    className: ownProps.className,
    isEsquire,
    children: ownProps.children,
  };
};

export default connect(mapStateToProps)(GoldPlus);
