import { rewardsConstants } from "../_constants";

export function rewards(state = { loading: false }, action) {
  switch (action.type) {
    case rewardsConstants.REWARDS_REQUEST:
      return {
        loading: true
      };
    case rewardsConstants.REWARDS_SUCCESS:
      return {
        loading: false,
        data: action.rewards.with
      };
    case rewardsConstants.REWARDS_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
