import React, { useState } from "react";
import { connect } from "react-redux";
import { memberActions } from "../../_actions";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "./styles.css";

const SetPasswordFromTemp = ({ dispatch, loggedIn }) => {
  const [userName, setUserName] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [newPass, setNewPass] = useState("");

  const postLogin = () => {
    if (userName && tempPass && newPass) {
      dispatch(memberActions.login(userName, tempPass, newPass));
    } else {
      toast.warn("Missing or incorrect inputs...");
    }
  };

  return loggedIn ? (
    <Redirect to="/main" />
  ) : (
    <div>
      <main className="container">
        <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
          <h2 className="log-title">Set Password</h2>
          <p>
            You are using a temporary password, please sign in again including a
            new and different password.
          </p>

          <div className="form-group">
            <label for="email">Email</label>
            <input
              value={userName}
              onInput={(e) => setUserName(e.target.value)}
              autoFocus="autofocus"
              className="form-control"
              id="email"
              placeholder="Email"
              required="required"
              type="email"
            />
            <div className="invalid-feedback" id="email_error">
              Please provide a valid email
            </div>
          </div>

          <div className="form-group">
            <label for="password">Password</label>
            <input
              value={tempPass}
              onInput={(e) => setTempPass(e.target.value)}
              className="form-control"
              id="password"
              placeholder="Password"
              required="required"
              type="password"
            />
            <small className="form-text text-muted" id="password_hint">
              At least eight characters, including letters and numbers.
            </small>
            <div className="invalid-feedback" id="password_help">
              Please provide a password with at least eight characters
            </div>
          </div>
          <div className="form-group">
            <label for="password2">New Password</label>
            <input
              value={newPass}
              onInput={(e) => setNewPass(e.target.value)}
              className="form-control"
              id="password2"
              placeholder="Confirm New Password"
              required="required"
              type="password"
            />
            <div className="invalid-feedback" id="password_help2">
              Passwords must match
            </div>
          </div>
          <div className="log-line clearfix mt-sm-4">
            <button
              className="btn btn-primary"
              id="reset_password"
              onClick={() => postLogin()}
              type="button"
            >
              Sign In
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

function mapStateToProps(state) {
  const { loggingIn, loggedIn } = state.authentication;
  return {
    loggingIn,
    loggedIn,
  };
}

const connectedSetPasswordFromTemp = connect(mapStateToProps)(
  SetPasswordFromTemp
);
export { connectedSetPasswordFromTemp as SetPasswordFromTemp };
