import React from 'react';
import PropTypes from 'prop-types';
import { LocationCard } from '../';
import './styles.css';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { isEsquireLocation } from '../../_helpers';

const LocationList = ({
  locations,
  onCardClick,
  selected,
  innerRefs,
  onButtonClick,
  handoffMode,
  showMap = false,
  setShowMap = () => {},
  isEsquire = false,
}) => {
  const locationsToShow = isEsquire
    ? locations.filter(l => isEsquireLocation(l))
    : locations;
  return (
    <>
      {locationsToShow.map((item, i) => (
        <LocationCard
          innerRef={innerRefs[i]}
          key={item.id}
          data={item}
          highlight={isEqual(item, selected)}
          onCardClick={() => onCardClick(item)}
          onButtonClick={() => onButtonClick(item)}
          handoffMode={handoffMode}
          showMap={showMap}
          setShowMap={setShowMap}
        />
      ))}
      <div className="foot" />
    </>
  );
};

function mapStateToProps(state) {
  const { restaurantSelected } = state;
  return {
    selected: restaurantSelected.data,
  };
}

const connectedLocationList = connect(mapStateToProps)(LocationList);
export { connectedLocationList as LocationList };

LocationList.propTypes = {
  onButtonClick: PropTypes.func,
  onCardClick: PropTypes.func,
  segment: PropTypes.number,
};

LocationList.defaultProps = {
  onButtonClick: () => {},
  onCardClick: () => {},
  segment: 0,
};
