import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import "./styles.css";
import Blank from "../../assets/images/blank.png";
import addBtn from "../../assets/images/gold-plus-carryout.svg";
import addBtnEsquire from "../../assets/images/gold-plus.svg";
import { isThemeEsquire } from "../../_helpers";

const UpsellItem = ({ data, onUpsellClick, imagePath }) => {
  const imgSrc =
    data && data.images && `${imagePath}${data.images[1].filename}`;

  const isEsquire = isThemeEsquire();

  const onClick = () => {
    onUpsellClick();
  };
  return (
    <Row
      key={data.id}
      className="upsell-item-wrap"
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.1 },
      }}
      whileTap={{ scale: 0.95 }}
    >
      <Col lg={3} m={3} s={3} xs={3} style={{ padding: 0 }}>
        <img src={imgSrc || Blank} className="upsell-img" alt="product image"/>
      </Col>
      <Col>
        <div className="upsell-item-name">{data.name}</div>
        <div className="upsell-description flex-container">
          {data.description}
        </div>
      </Col>

      <Col lg={2} m={2} s={2} xs={2}>
        <motion.img
          src={isEsquire ? addBtnEsquire : addBtn}
          className="float-right plus-icon"
          style={{ marginTop: 20 }}
          onClick={onClick}
          whileHover={{
            scale: 1.05,
            transition: { duration: 0.1 },
          }}
          whileTap={{ scale: 0.95 }}
        />
        <div className="upsell-price">${data.cost.toFixed(2)}</div>
      </Col>
    </Row>
  );
};

export default UpsellItem;

UpsellItem.propTypes = {
  data: PropTypes.object,
};

UpsellItem.defaultProps = {
  data: {},
};
