import { useDispatch } from 'react-redux'
import { get, some } from "lodash";
import { basketsActions } from "../_actions";
import { alertActions } from "../_actions/alert.actions";
import { appConstants } from "../_constants";

export const useComplimentaryProducts = ({menuData, basketData, loading, ...props}) => {
  const dispatch = useDispatch();
  
  // Get complimentary items from menu to determine product ID at this location
  const categories = get(menuData, "categories", []);
  const compCat = categories.find(cat => some(cat.metadata, {key: "isComplimentary", value: "1"}));
  const compProducts = compCat ? compCat.products : [];
  const pretzelMenuItem = compProducts.find(item => item.chainproductid === appConstants.pretzelChainId);
  const gfPretzelMenuItem = compProducts.find(item => item.chainproductid === appConstants.gfPretzelChainId);
  const flatwareMenuItem = compProducts.find(item => item.chainproductid === appConstants.flatwareChainId);

  // Check basket for complimentary items
  const cartItems = get(basketData, "products", []);
  const pretzelBasketItem = pretzelMenuItem && cartItems.find(item => item.productId === pretzelMenuItem.id);
  const gfPretzelBasketItem = gfPretzelMenuItem && cartItems.find(item => item.productId === gfPretzelMenuItem.id);
  const flatwareBasketItem = flatwareMenuItem && cartItems.find(item => item.productId === flatwareMenuItem.id);
  
  // Add an item to the basket
  const addComplimentaryItem = (itemId, name) => {
    // Add complimentary item to basket
    dispatch(
      basketsActions.addProducts(
        basketData.id,
        itemId,
        name,
        1,
        "",
        "",
        "",
        "complimentary",
      )
    )
  };

  const toggleItem = (menuItem, basketItem) => {
    if (loading) {
      return;
    } else if (basketItem) {
      dispatch(basketsActions.deleteProduct(basketData.id, basketItem.id));
    } else if (menuItem) {
      addComplimentaryItem(menuItem.id, menuItem.name);
    } else {
      dispatch(alertActions.error("Could not find item in basket or menu."));
    }
  };

  // Do not show complimentary items in cart
  // const filteredItems = cartItems.filter(item => item.customdata !== "complimentary");
  // const filteredItems = cartItems.filter(item => item.id !== pretzelId && item.id !== flatwareId);

  return {
    gfPretzelBread: gfPretzelBasketItem,
    pretzelBread: pretzelBasketItem,
    flatware: flatwareBasketItem,
    cartItems,
    toggleFlatware: () => toggleItem(flatwareMenuItem, flatwareBasketItem),
    togglePretzelBread: () => toggleItem(pretzelMenuItem, pretzelBasketItem),
    toggleGfPretzelBread: () => toggleItem(gfPretzelMenuItem, gfPretzelBasketItem),
  };
};