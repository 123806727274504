import React from "react";
import "./styles.scoped.scss";
import get from "lodash/get";

const ProgressHeader = ({title, steps = [], activeStep, history, match}) => {
  const clubSelection = get(match, "params.clubSelection", "1");

  return (
    <div className="outer-container">
      <div className="title">{title}</div>
      <div className="step-container">
        {steps.map((step, stepNum) => (
          <div
            className={`step ${stepNum === activeStep ? 'active' : ''} ${stepNum < activeStep ? 'visited' : ''}`}
            onClick={() => stepNum < activeStep && history.push(`/${steps[stepNum].slug}/${clubSelection}`)}
          >
            <div className="step-label">{stepNum + 1}. {step.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { ProgressHeader };
