// Ideally we'd make this an enum and type these but adding typescript is it's own discussion
export const CSS_VARIABLES = {
  linkColor: '--link-color',
  navTabActive: '--nav-tab-active',
  categoryFontFamily: '--category-font-family',
  titleFontFamily: '--title-font-family',
  titleFontStyle: '--title-font-style',
  itemNameColor: '--item-name-color',
  menuItemBackgroundColor: '--menu-item-background-color',
  descriptionFontFamily: '--description-font-family',
  headerBackgroundColor: '--header-background-color',
  menuItemBannerFont: '--menu-item-banner-font',
  headerCartBackgroundColor: '--header-cart-background-color',
  scrollContainerBackgroundColor: '--scroll-container-background-color',
  menuItemBannerColor: '--menu-item-banner-background-color',
  menuItemBannerTextTransform: '--menu-item-banner-text-transform',
  headerLetterSpacing: '--header-letter-spacing',
  cartAddressLineFont: '--cart-address-line-font-family',
  legacy075LetterSpacing: '--legacy-075-letter-spacing',
  legacy084LetterSpacing: '--legacy-084-letter-spacing',
  legacy111LetterSpacing: '--legacy-111-letter-spacing',
  legacy125LetterSpacing: '--legacy-125-letter-spacing',
  legacy175LetterSpacing: '--legacy-175-letter-spacing',
  legacy150LetterSpacing: '--legacy-150-letter-spacing',
  buttonDarkHoverColor: '--button-dark-hover-color',
  toastFontWeight: '--legacy-toast-font-weight',
  cartRemoveButtonColor: '--cart-remove-button-color',
  memberSiteGold: '--member-site-gold',
  carryoutSiteGold: '--carryout-site-gold',
  wineclubSignupGold: '--wineclub-signup-gold',
}

const esquireOverrides = {
  cssVars: {
    [CSS_VARIABLES.linkColor]: '#D6A557',
    [CSS_VARIABLES.navTabActive]: '#29b6f6',
    [CSS_VARIABLES.itemNameColor]: '#171717',
    [CSS_VARIABLES.menuItemBackgroundColor]: '#FFF',
    [CSS_VARIABLES.categoryFontFamily]: `'Josefin Sans'`,
    [CSS_VARIABLES.titleFontFamily]: `'Josefin Sans'`,
    [CSS_VARIABLES.titleFontStyle]: `normal`,
    [CSS_VARIABLES.descriptionFontFamily]: `'Alegreya'`,
    [CSS_VARIABLES.menuItemBannerFont]: `'Josefin Sans'`,
    [CSS_VARIABLES.headerBackgroundColor]: `#262626`,
    [CSS_VARIABLES.headerCartBackgroundColor]: `#D6A557`,
    [CSS_VARIABLES.scrollContainerBackgroundColor]: `#FFFFFF`,
    [CSS_VARIABLES.menuItemBannerColor]: `#032f37`,
    [CSS_VARIABLES.menuItemBannerTextTransform]: `none`,
    [CSS_VARIABLES.headerLetterSpacing]: `none`,
    [CSS_VARIABLES.legacy125LetterSpacing]: `none`,
    [CSS_VARIABLES.cartAddressLineFont]: `'Alegreya'`,
    [CSS_VARIABLES.legacy075LetterSpacing]: `none`,
    [CSS_VARIABLES.legacy084LetterSpacing]: `none`,
    [CSS_VARIABLES.legacy111LetterSpacing]: `none`,
    [CSS_VARIABLES.legacy175LetterSpacing]: `none`,
    [CSS_VARIABLES.legacy150LetterSpacing]: `none`,
    [CSS_VARIABLES.buttonDarkHoverColor]: `#C29143`,
    [CSS_VARIABLES.toastFontWeight]: `bold`,
    [CSS_VARIABLES.cartRemoveButtonColor]: `#6e0909`,

  },
  buttonTheme: {
    backgroundColor: '#D6A557',
  },
};

export const themes = {
  default: {
    cssVars: {
      // Defaults also need to be defined in src/index.css
      [CSS_VARIABLES.linkColor]: '#906f47',
      [CSS_VARIABLES.navTabActive]: '#906f47',
      [CSS_VARIABLES.titleFontFamily]: `Oswald`,
      [CSS_VARIABLES.categoryFontFamily]: `Playfair Display`,
      [CSS_VARIABLES.titleFontStyle]: `italic`,
      [CSS_VARIABLES.itemNameColor]: '#212529',
      [CSS_VARIABLES.menuItemBackgroundColor]: '#fff',
      [CSS_VARIABLES.descriptionFontFamily]: `Oswald`,
      [CSS_VARIABLES.headerBackgroundColor]: `#242423`,
      [CSS_VARIABLES.menuItemBannerFont]: `'Oswald', sans-serif`,
      [CSS_VARIABLES.headerCartBackgroundColor]: `#906f47`,
      [CSS_VARIABLES.scrollContainerBackgroundColor]: `#f5f4f0`,
      [CSS_VARIABLES.menuItemBannerColor]: `#6f6c67`,
      [CSS_VARIABLES.menuItemBannerTextTransform]: `uppercase`,
      [CSS_VARIABLES.headerLetterSpacing]: `1px`,
      [CSS_VARIABLES.legacy125LetterSpacing]: `1.25px`,
      [CSS_VARIABLES.cartAddressLineFont]: `'Oswald', sans-serif`,
      [CSS_VARIABLES.legacy075LetterSpacing]: `0.75px`,
      [CSS_VARIABLES.legacy084LetterSpacing]: `0.84px`,
      [CSS_VARIABLES.legacy111LetterSpacing]: `1.11px`,
      [CSS_VARIABLES.legacy175LetterSpacing]: `1.75px`,
      [CSS_VARIABLES.legacy150LetterSpacing]: `1.5px`,
      [CSS_VARIABLES.buttonDarkHoverColor]: `#906f47`,
      [CSS_VARIABLES.toastFontWeight]: `500`,
      [CSS_VARIABLES.cartRemoveButtonColor]: `#E13D3D`,
      [CSS_VARIABLES.memberSiteGold]: '#906f47',
      [CSS_VARIABLES.carryoutSiteGold]: '#906f47',
      [CSS_VARIABLES.wineclubSignupGold]: '#906f47',
    },
    buttonTheme: {
      backgroundColor: '#906f47',
    },
  },
  esquire: esquireOverrides,
};
