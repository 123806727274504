import React, { useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./styles.css";

const SegmentedSelector = ({
  segmentLabels,
  selectedIndex,
  onSelect,
  containerClass = "",
  segmentClass = ""
}) => {
  const [thisIndex, setThisIndex] = useState(selectedIndex);

  const segmentChange = index => {
    setThisIndex(index);
    onSelect(index);
  };

  return (
    <Row className={`selector-container ${containerClass}`}>
      {segmentLabels.map((label, index) => (
        <motion.div
          key={label}
          transition={{ duration: 0.1 }}
          animate={
            thisIndex == index
              ? {
                  scale: [0.95, 1.05, 1],
                }
              : {
                  scale: [1.05, 0.95, 1],
                }
          }
          className={`segment ${segmentClass} ${thisIndex == index ? 'segment-selected' : ''} `} //Use Double Equals or it will break 
          onClick={() => segmentChange(index)}
        >
          <span
            className={
              thisIndex == index ? "segmentLabel selected" : "segmentLabel"
            }
          >
            {label}
          </span>
        </motion.div>
      ))}
    </Row>
  );
};

export default SegmentedSelector;

SegmentedSelector.propTypes = {
  segmentLables: PropTypes.arrayOf(PropTypes.string),
  selectedIndex: PropTypes.number,
  onSelect: PropTypes.func
};

SegmentedSelector.defaultProps = {
  segmentLabels: ["pickup", "curbside", "delivery"],
  selectedIndex: 0,
  onSelect: () => {}
};
