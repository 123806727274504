import { combineReducers } from "redux";
import { authentication } from "./authentication.reducer";
import { member } from "./member.reducer";
import { newMember } from "./new-member.reducer";
import { marketingSubscribe } from "./marketing-subscribe.reducer";
import { points } from "./points.reducer";
import { alert } from "./alert.reducer";
import { events } from "./events.reducer";
import { openBottles } from "./open-bottles.reducer";
import { pickupHistory } from "./pickup-history.reducer";
import { news } from "./news.reducer";
import { rewards } from "./rewards.reducer";
import { memberConstants } from "../_constants/member.constants";
import { staticContent } from "./static-content.reducer";
import { replacementCard } from "./replacement-card.reducer";
import { paymentForm } from "./payment.reducer";
import { barrelOrders } from "./barrel.reducer";
import { adminSearch } from "./admin.reducer";
import {
  restaurants,
  restaurantSelected,
  restaurantsNearby,
  restaurant,
  menu,
  hours,
  delivery,
} from "./restaurants.reducer";
import { basket } from "./baskets.reducer";
import { modifiers } from "./products.reducer";
import { settings } from "./settings.reducer";
import { order } from "./orders.reducer";

const appReducer = combineReducers({
  adminSearch,
  authentication,
  barrelOrders,
  events,
  member,
  newMember,
  marketingSubscribe,
  paymentForm,
  points,
  alert,
  openBottles,
  pickupHistory,
  news,
  rewards,
  staticContent,
  replacementCard,
  restaurants,
  restaurantSelected,
  restaurantsNearby,
  restaurant,
  menu,
  delivery,
  hours,
  modifiers,
  basket,
  settings,
  order,
});

const rootReducer = (state, action) => {
  if (action.type === memberConstants.LOGOUT) {
    state.authentication = undefined;
    state.member = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
