import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { barrelActions } from "../../_actions";
import LoadingSpinners from "../../components/LoadingSpinners";
import get from "lodash/get";
import "./styles.css";
import { BarrelOrders } from "../../tab-content/bottles/barrel-orders";
import { useRenameDocument } from "../../context/renameDocument";

const BarrelReserve = ({
  history,
  dispatch,
  locations,
  homePickupLocation,
  barrelPromotionState,
  barrelReservePickupDate,
  barrelStandardPrice,
  barrelMagnumPrice,
  loading,
  paymentStatus,
  subtotal,
  tax,
  total,
  mag,
  reg,
  lastFour,
  cardType,
  memberStatus,
  name,
  isloggedIn
}) => {
  const [smallerBottles, setSmallerBottles] = useState(0);
  const [largerBottles, setLargerBottles] = useState(0);
  const [location, setLocation] = useState("home-location");
  const [homeLocation, setHomeLocation] = useState(homePickupLocation.value);
  const [otherLocation, setOtherLocation] = useState(homePickupLocation.value);

  useRenameDocument("Barrel Reserve");

  /*eslint-disable */
  useEffect(() => {
    if(isloggedIn) dispatch(barrelActions.getBarrelOrders());
  }, []);

  useEffect(() => {
    if(isloggedIn) calculateShipping();
  }, [smallerBottles, largerBottles, location, otherLocation]);
  /*eslint-enable */

  const calculateShipping = () => {
    let thisLocation =
      location === "home-location" ? homeLocation : otherLocation;
    if (homeLocation === "none") {
      thisLocation = otherLocation;
    }
    dispatch(
      barrelActions.calculateCost(thisLocation, smallerBottles, largerBottles)
    );
  };

  const postOrder = () => {
    let thisLocation =
      location === "home-location" ? homeLocation : otherLocation;
    if (homeLocation === "none") {
      thisLocation = otherLocation;
    }
    dispatch(
      barrelActions.postOrder(thisLocation, smallerBottles, largerBottles)
    );
  };

  const handleSubmit = event => {
    if (invalidTotal()) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      postOrder();
    }
  };

  const invalidTotal = () => {
    const total = smallerBottles + largerBottles;
    return total === 0 || smallerBottles > 100 || largerBottles > 100;
  };

  const formatNumber = number => {
    /*eslint-disable */
    if (number == 0) {
      return 0;
    }
    /*eslint-enable */
    return number.toString().replace(/^0+/, "");
  };

  // Must be logged into an active member with a card on file
  const eligibleForSale =
    (memberStatus === "Active" || memberStatus === "New") &&
    barrelPromotionState !== "INACTIVE" && lastFour && isloggedIn;

  if (paymentStatus === "APPROVED") {
    return <Redirect to="/BarrelOrderApproved" />;
  }

  if (loading) {
    return <LoadingSpinners />;
  }

  return (
    <div className="container">
      <button
        style={{ marginLeft: "2em" }}
        className="btn btn-outline-light"
        onClick={() => history.goBack()}
      >
        &larr; back
      </button>
      <h4
        className="float-right"
        style={{ marginRight: "2em", color: "white", borderBottom: "none" }}
      >
        {`Hello ${name}!`}
      </h4>
      <div className="row justify-content-center" style={{ margin: 0 }}>
        <img
          alt="br-logo"
          className="br-logo"
          src="https://chwinery.com/communities/br_seal.png"
        />
      </div>
      <BarrelOrders style={{ marginTop: "1em" }} />
      <div  
        className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 rounded bg-light innner-box"
        style={{ marginTop: "1em" }}
      >
        <h2 className="log-title" id="title">
          Barrel Reserve
        </h2>

        {!eligibleForSale && (
          <Row className="justify-content-center">
            {barrelPromotionState === "DEACTIVATED" && (
              <Col xs={12} align="center">
                <p>
                  Thank you for visiting. Our {new Date().getFullYear()} Barrel Reserve is sold out.
                  Please <Link to="/main">click here</Link> to go to our
                  homepage.{" "}
                </p>
              </Col>
            )}
            {isloggedIn && memberStatus !== "Active" && memberStatus !== "New" && (
              <Col xs={12} align="center">
                <p>
                  Thank you for your interest in our {new Date().getFullYear()} Barrel Reserve. Please
                  visit your online profile to update your account information.{" "}
                </p>
              </Col>
            )}
            {/* An active member without a card on file, e.g. a gift member */}
            {isloggedIn && !lastFour && memberStatus == "Active" && (
              <Col xs={12} align="center">
                <p>
                  Thank you for your interest in our {new Date().getFullYear()} Barrel Reserve. Please
                  visit your online profile to update your payment information.{" "}
                </p>
              </Col>
            )}
            {!isloggedIn && (
              <Col xs={12} align="center">
                <p>
                  Thank you for your interest in our {new Date().getFullYear()} Barrel Reserve. Please{" "}
                  <Link to="/BarrelLogin">sign in</Link> to your wine club membership to view this year's Barrel Reserve.{" "}
                </p>
                <p>Not a member? Sign up <Link to="/">here</Link>.</p>
              </Col>
            )}
            <Col xs={12} align="center">
              If you have any questions, please contact our Member and Guest
              Services at <a href="tel:7082155674">(708) 215-5674</a>.
            </Col>
          </Row>
        )}

        {eligibleForSale && (
          <>
            <h6
              className="log-title mb-sm-4"
              id="subtext"
              style={{ lineHeight: "180%" }}
            >
              A premium blend using our best barrels
              <br />
              Bottles Available for pickup {barrelReservePickupDate}
            </h6>
            <Form noValidate validated={false}>
              <Form.Group>
                <Form.Label for="no_bottles">750ml Bottles (${barrelStandardPrice})</Form.Label>
                {reg > 0 ? (
                  <InputGroup>
                    <Form.Control
                      disabled={loading}
                      aria-describedby="inputGroupAppend"
                      className="inputs"
                      id="smaller_bottles"
                      max="100"
                      min="0"
                      required="required"
                      type="number"
                      value={formatNumber(smallerBottles)}
                      isInvalid={smallerBottles > 100}
                      onChange={e => {
                        setSmallerBottles(
                          e.target.value === "" ? 0 : e.target.value
                        );
                      }}
                    />
                    <InputGroup.Append>
                      <Button
                        disabled={loading}
                        className="minus-button"
                        size="lg"
                        variant="outline-secondary"
                        onClick={() =>
                          smallerBottles > 0 &&
                          setSmallerBottles(smallerBottles - 1)
                        }
                      >
                        -
                      </Button>
                      <Button
                        disabled={loading}
                        className="plus-button"
                        size="lg"
                        variant="outline-secondary"
                        onClick={() =>
                          setSmallerBottles(Number(smallerBottles) + 1)
                        }
                      >
                        +
                      </Button>
                    </InputGroup.Append>
                    <div className="invalid-feedback">
                      Total can not exceed 100 bottles.
                    </div>
                  </InputGroup>
                ) : (
                  <div>
                    <h6 style={{ color: "red" }}>Sold Out</h6>
                    <br />
                  </div>
                )}
              </Form.Group>

              <Form.Group>
                <label for="no_magnum">1.5L Magnum Bottles (${barrelMagnumPrice})</label>
                {mag > 0 ? (
                  <InputGroup>
                    <Form.Control
                      disabled={loading}
                      className="inputs"
                      id="no_magnum"
                      max="100"
                      min="0"
                      required="required"
                      type="number"
                      value={formatNumber(largerBottles)}
                      isInvalid={largerBottles > 100}
                      onChange={e =>
                        setLargerBottles(
                          e.target.value === "" ? 0 : e.target.value
                        )
                      }
                    />
                    <InputGroup.Append>
                      <Button
                        disabled={loading}
                        className="minus-button"
                        size="lg"
                        variant="outline-secondary"
                        onClick={() =>
                          largerBottles > 0 &&
                          setLargerBottles(largerBottles - 1)
                        }
                      >
                        -
                      </Button>
                      <Button
                        disabled={loading}
                        className="plus-button"
                        size="lg"
                        variant="outline-secondary"
                        onClick={() =>
                          setLargerBottles(Number(largerBottles) + 1)
                        }
                      >
                        +
                      </Button>
                    </InputGroup.Append>
                    <div className="invalid-feedback">
                      Total can not exceed 100 bottles.
                    </div>
                  </InputGroup>
                ) : (
                  <div>
                    <h6 style={{ color: "red" }}>Sold Out</h6>
                    <br />
                  </div>
                )}
              </Form.Group>
              {homeLocation !== "none" && (
                <Form.Group>
                  <Form.Label for="no_bottles">Delivery Options</Form.Label>
                  <Form.Control
                    disabled={loading}
                    as="select"
                    value={location}
                    onChange={e =>
                      setLocation(
                        location === "other-location"
                          ? "home-location"
                          : "other-location"
                      )
                    }
                  >
                    <option value="home-location">
                      {`Pickup at ${homePickupLocation.text}`}
                    </option>
                    <option value="other-location">
                      Pickup at other location [Select]
                    </option>
                  </Form.Control>
                </Form.Group>
              )}
              {(location === "other-location" || homeLocation === "none") && (
                <Form.Group>
                  <Form.Label for="no_bottles">Pickup Location</Form.Label>
                  <Form.Control
                    disabled={loading}
                    as="select"
                    value={otherLocation}
                    onChange={e => setOtherLocation(e.target.value)}
                  >
                    {locations.map(location => (
                      <option value={location.value}>{location.text}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              <Row className="text-right">
                <Col xs={8}>Subtotal:</Col>
                <Col xs={4}>$ {subtotal}</Col>
                <Col xs={8}>Tax:</Col>
                <Col xs={4}>$ {tax}</Col>
                <Col xs={8}>Total Cost:</Col>
                <Col xs={4}>$ {total}</Col>
              </Row>

              <div style={{ height: "1em" }} />
              <Row align="center">
                <Col xs={12}>
                  <Button
                    disabled={
                      invalidTotal() ||
                      loading ||
                      (homeLocation === "none" && otherLocation === "none")
                    }
                    onClick={e => handleSubmit(e)}
                  >
                    {loading ? "Working..." : "Reserve Bottles"}
                  </Button>
                  {loading && (
                    <Row className="spinner thisSpinner">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </Row>
                  )}
                </Col>
              </Row>
            </Form>
            <Row className="justify-content-center mt-4">
              <Col xs={12} align="center">
                <p className="small">
                  Your {cardType} ending in {lastFour} will be charged
                </p>
              </Col>
            </Row>{" "}
            <Row className="justify-content-center">
              <Col xs={12} align="center">
                Questions? <a href="tel:7082155674">(708) 215-5674</a>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { authentication, staticContent, member, newMember, barrelOrders } = state;
  const loading = get(barrelOrders, "loading", false) || get(authentication, "loading", false) || get (staticContent, "loading", false) || get(member, "loading", false) || get(newMember, "loading", false);
  
  const { data } = newMember;
  const homeLocationId = get(member.data, "homeLocationId", 0);
  const lastFour = get(
    member.data,
    "storedPaymentAccount.BluePay__Card_Number__c",
    ""
  ).slice(12, 16);
  const cardType = get(
    member.data,
    "storedPaymentAccount.BluePay__Card_Type__c",
    "Card"
  );
  const memberStatus = get(member.data, "status", "Inactive");
  const name = get(member.data, "firstName", "");
  const email = get(data, "email", "");
  const paymentStatus = get(barrelOrders.data, "paymentStatus", "");
  const locations = get(staticContent.data, "locations", []);
  const barrelReserveOrder = get(barrelOrders.data, "barrelReserveOrder", "");
  const remaining = get(barrelOrders.data, "remaining", {});
  const mag = get(remaining, "mag", 0);
  const reg = get(remaining, "reg", 0);
  const barrelPromotionState = get(
    staticContent.data,
    "barrelPromotionState",
    ""
  );
  const barrelReservePickupDate = get(
    staticContent.data,
    "barrelReservePickupDate",
    ""
  );
  const barrelStandardPrice = get(
    staticContent.data,
    "barrelStandardPrice",
    ""
  );
  const barrelMagnumPrice = get(
    staticContent.data,
    "barrelMagnumPrice",
    ""
  );

  let locationsArray = Object.keys(locations).map(data => {
    return { text: data, value: locations[data] };
  });
  const home = locationsArray.find(x => x.value === homeLocationId);
  if (!home) {
    locationsArray.unshift({
      value: "none",
      text: "Choose your pickup location"
    });
  }
  const subtotal = get(barrelReserveOrder, "subtotal", 0).toFixed(2);
  const tax = get(barrelReserveOrder, "tax", 0).toFixed(2);
  const total = get(barrelReserveOrder, "total", 0).toFixed(2);
  const isloggedIn = get(authentication, "loggedIn", false);
  return {
    mag,
    reg,
    subtotal,
    tax,
    total,
    paymentStatus,
    loading,
    email,
    barrelPromotionState,
    barrelReservePickupDate,
    barrelStandardPrice,
    barrelMagnumPrice,
    locations: locationsArray,
    homePickupLocation: home
      ? home
      : {
          value: "none",
          text: "none"
        },
    lastFour,
    cardType,
    memberStatus,
    name,
    isloggedIn
  };
}

const connectedBarrelReserve = connect(mapStateToProps)(BarrelReserve);
export { connectedBarrelReserve as BarrelReserve };
