import React, { useEffect, useState } from "react";
import "./styles.css";
import get from "lodash/get";
import { Redirect } from "react-router-dom";
import { passwordRegexPattern } from "../../_constants/app.constants";
import { connect } from "react-redux";
import { Col, Form, Button } from "react-bootstrap";
import { memberActions } from "../../_actions";
import { useRenameDocument } from "../../context/renameDocument";

const NewPassword = ({ email, dispatch, passwordUpdated, isAdmin, fromSupplier, inviteCode }) => {
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  useRenameDocument("New Password");

  const [verificationCode, setVerificationCode] = useState("");

  const passwordObj = {
    username: email,
    password: password,
    verification_code: verificationCode,
  };

  const handleSubmit = (event) => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      dispatch(memberActions.resetPassword(passwordObj));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  if (isAdmin) {
    return <Redirect to="/Admin" />;
  }

  return passwordUpdated ? (
    fromSupplier ? <Redirect to={`/NewSupplierMemberSignUp?code=${inviteCode}`} /> : <Redirect to="/main" />
  ) : (
    <>
      <main className="container">
        <Col
          xs={12}
          sm={10}
          lg={6}
          className="offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light"
        >
          <h1 className="log-title">New Password</h1>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label for="verification-code">Verification Code</Form.Label>
              <Form.Control
                value={verificationCode}
                id="verification-code"
                placeholder="Verification Code"
                required
                onChange={(e) => {
                  let str = e.target.value;
                  setVerificationCode(str.replace(/\s/g, ""));
                }}
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                Please provide the verification code sent to your email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label for="password">New Password</Form.Label>
              <Form.Control
                id="password"
                placeholder="Password"
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                pattern="^(?=.*[A-Za-z])(?=.*\d).{8,}$" // no idea why this works but importing the pattern does not
                                                        // should be using passwordRegexPattern from constants
              />
              <small className="form-text text-muted" id="password_hint">
                At least eight characters, including letters and numbers.
              </small>
              <Form.Control.Feedback type="invalid">
                Please provide a password with at least eight characters
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label for="password2">Confirm New Password</Form.Label>
              <Form.Control
                value={password2}
                id="password2"
                placeholder="Confirm New Password"
                required
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
                type="password"
                pattern={password}
                onKeyPress={(e) => handleKeyPress(e)}
              />
              <Form.Control.Feedback type="invalid">
                Passwords must match
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
          <div className="log-line clearfix mt-sm-4">
            <Button
              className="btn btn-primary"
              id="new_password"
              onClick={(e) => handleSubmit(e)}
            >
              Set New Password
            </Button>
          </div>
          <div className="text-center my-5">
            Questions? <a href="tel:7082155674">(708) 215-5674</a>
          </div>
        </Col>
      </main>
    </>
  );
};

function mapStateToProps(state) {
  const { member } = state;
  const passwordUpdated = get(state.authentication, "passwordUpdated", false);
  const user = get(state.authentication, "user", {});
  const isAdmin = get(user, "is_admin", false);
  const email = get(member, "email", "");
  const supplierInfo = get(member, 'supplierInfo');
  const fromSupplier = get(supplierInfo, 'resettingPassword');
  const inviteCode = get(supplierInfo, 'code');
  return { email, passwordUpdated, isAdmin, fromSupplier, inviteCode };
}

const connectedNewPassword = connect(mapStateToProps)(NewPassword);
export { connectedNewPassword as NewPassword };
