import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import get from "lodash/get";
import { memberActions } from "../../_actions";

import "./styles.css";
import { useRenameDocument } from "../../context/renameDocument";

const ForgotPassword = ({ email, dispatch }) => {
  const [validated, setValidated] = useState(false);

  useRenameDocument("Forgot Password");

  const usernameObj = { username: email };

  const handleSubmit = (event) => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      dispatch(memberActions.forgotPassword(usernameObj));
    }
  };

  return (
    <div>
      <main className="container">
        <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
          <h2 className="log-title">Password Reset</h2>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>
                <i>
                  Please provide the email address used to sign up for your
                  membership.
                </i>
              </Form.Label>
              <Form.Control
                autoFocus="autofocus"
                className="form-control"
                id="email"
                placeholder="Email"
                required
                type="email"
                onChange={(e) =>
                  dispatch(memberActions.setEmail(e.target.value))
                }
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email
              </Form.Control.Feedback>
            </Form.Group>
          </Form>

          <div className="log-line clearfix mt-sm-4">
            <Link to="/NewPassword">
              <Button
                className="btn btn-primary"
                id="send_email"
                onClick={(e) => handleSubmit(e)}
              >
                Send verification email
              </Button>
            </Link>
          </div>
          <div className="text-center my-5">
            Questions? <a href="tel:7082155674">(708) 215-5674</a>
          </div>
        </div>
      </main>
    </div>
  );
};

function mapStateToProps(state) {
  const { member } = state;
  const email = get(member, "email", "");

  return { email };
}

const connectedForgotPassword = connect(mapStateToProps)(ForgotPassword);
export { connectedForgotPassword as ForgotPassword };
