import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../_reducers";
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
  // Root?
  key: "root",
  storage,
  blacklist: ['order'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware = createLogger();

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

const store = createStore(
  persistedReducer,
  /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  )
);

let persistor = persistStore(store);

export { store, persistor };
