import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import './styles.css';
import { logGTMEvent } from '../../_helpers';
import { useRenameDocument } from '../../context/renameDocument';

const Signup = () => {

  useRenameDocument('Join The Wine Club')

  useEffect(() => {
    logGTMEvent('wine_club_form_start', {
      page_location: 'https://member.chwinery.com/NewMonthlyMemberSignUp',
      page_title: 'Join The Club',
      page_path: '/NewMonthlyMemberSignUp',
    });
  }, []);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {setInnerWidth(window.innerWidth)});

  let history = useHistory();
  return (
    <div>
      <img className='image-to-be-gradiented' alt="" src={innerWidth <= 576 && require('../../assets/images/bbbg-v3.png')} />
            <div className="image-gradient" style={{height: innerWidth <= 576 ? undefined : '91.3vh'}} />
    <div className="container">
      <div className="row p-2 p-sm-5">
      <h1 className="signup-header offset-sm-1 offset-lg-3" style={{marginTop: 100}}>Join the Wine Club </h1>
        <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 bg-white shadow">
          <Form className='signup-form'>
            <Row>
              <Col md={12}>
                <h2 className='signup-title'>Sign Up</h2>
                <h6 className="details" style={{marginBottom: 20, width: '80%'}}>
                  What kind of Membership would you like?
                </h6>
                <br />
              </Col>

              <Col md={6} sm={6} xs={6}>
                <div className='signup-button' tabIndex={0} onClick={() => {
                    history.push("/NewMonthlyMemberSignUp");
                  }}>
                    <div className='signup-button-text'>
                      MONTHLY
                    </div>
                </div>
              </Col>
              <Col md={6} sm={6} xs={6}>
                  <div className='signup-button' tabIndex={0} onClick={() => {
                    history.push("/NewGiftMemberSignUp");
                  }}>
                    <div className='signup-button-text'>
                      GIFT
                    </div>
                  </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default withRouter(Signup);
