import { basketsActions, paymentActions } from "./";
import { ordersConstants } from "../_constants";
import { Orders } from "../_services/orders.service";
import { memberService } from "../_services/member.service";
import { memberActions } from "../_actions";
import { get } from "lodash";
import { pushGTM, store } from "../_helpers";

export const ordersActions = {
  validateOrder,
  submitOrder,
  submitOrderByProxy,
  simulatePayment,
  setOrderContactInfo,
  refreshOrderByGuid,
  refreshOrderByRef,
  refreshDeliveryStatus,
  getBillingSchemes,
  reset
};

function validateOrder(basketGuid) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const data = await Orders.validateOrder(basketGuid);
      dispatch(success(data));
      return data;
    } catch (e) {
      dispatch(failure(e.message));
    }
    return null;
  };

  function request() {
    return {
      type: ordersConstants.VALIDATE_ORDER_REQUEST,
    };
  }

  function success(result) {
    return {
      type: ordersConstants.VALIDATE_ORDER_SUCCESS,
      data: result,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.VALIDATE_ORDER_FAILURE,
      error
    };
  }
}

function submitGTM(paymentRequest, products, basket) {
  const tax = paymentRequest.taxes.reduce((acc, val) => acc + val.tax, 0);
  const coupon = paymentRequest[0] && paymentRequest[0].description;

  const { restaurant, member } = store.getState();
  const affiliation = get(restaurant, "data.name", "");
  const memberId = get(member, "data.memberNumber", "");
  const memberType = get(member, "data.membershipType", "");

  const gtmObj = {
    id: paymentRequest.id,
    option: "card",
    revenue: paymentRequest.total,
    tax,
    shipping: 0,
    discountAmount: paymentRequest.discount,
    affiliation,
  };
  let memberObj;

  if (memberId || memberType) {
    memberObj = {
      number: memberId,
      type: memberType,
    }
  }

  if (coupon) {
    gtmObj.coupon = coupon;
  }

  pushGTM('purchase', 'transactionSuccess', products, gtmObj, basket, memberObj);
}

function submitOrder(basketGuid, data) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const { basket } = store.getState();
      const products = get(basket, "data.products", []);

      const response = await Orders.submitOrder(basketGuid, data);
      const { posreferenceresponse } = data;
      submitGTM(response, products, basket);
      dispatch(paymentActions.clearOLOPaymentForm());
      const bpResponse = await memberService.bottlePickup({
        checkNumber: posreferenceresponse
      });
      dispatch(basketsActions.destroyBasket());
      if(bpResponse?.with.success){
        await dispatch(memberActions.getMember());
      }
      dispatch(success(response));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return {
      type: ordersConstants.SUBMIT_ORDER_REQUEST,
    };
  }

  function success(result) {
    return {
      type: ordersConstants.SUBMIT_ORDER_SUCCESS,
      data: result,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.SUBMIT_ORDER_FAILURE,
      error
    };
  }
}

function submitOrderByProxy(basketGuid, data) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const { basket } = store.getState();
      const products = get(basket, "data.products", []);

      const response = await Orders.submitOrderByProxy(basketGuid, data);
      const {transaction} = response.with;
      submitGTM(JSON.parse(transaction.response.body), products, basket);
      const bpResponse = await memberService.bottlePickup({
        checkNumber: 0
      });
      dispatch(basketsActions.destroyBasket());
      dispatch(paymentActions.clearOLOPaymentForm());
      if(bpResponse?.with.success){
        await dispatch(memberActions.getMember());
      }
      dispatch(success(response.with));
    } catch (e) {
      console.log("Caught", e.message)
      dispatch(failure(e.message || 'Could not place order. Please try again.'));
    }
  };

  function request() {
    return {
      type: ordersConstants.SUBMIT_PROXY_ORDER_REQUEST,
    };
  }

  function success(result) {
    return {
      type: ordersConstants.SUBMIT_PROXY_ORDER_SUCCESS,
      data: result,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.SUBMIT_PROXY_ORDER_FAILURE,
      error
    };
  }
}

function simulatePayment(basketGuid, data) {
  const {
    firstName,
    lastName,
    email,
    phone
  } = data;
  return async (dispatch) => {
    dispatch({ type: ordersConstants.SIMULATE_PAYMENT_REQUEST });

    setTimeout(() => {
      dispatch({
        type: ordersConstants.SIMULATE_PAYMENT_SUCCESS,
        data: {
          firstname: firstName,
          lastname: lastName,
          emailaddress: email,
          contactnumber: phone
        }
      })
    }, 2000);
  };
}

function setOrderContactInfo(data) {
  return async (dispatch) => {
    const {
      cardnumber,
      ...otherFields
    } = data;
    dispatch({
      type: ordersConstants.SET_ORDER_CONTACT_INFO,
      data: {
        ...otherFields,
        cardlastfour: cardnumber ? cardnumber.substring(cardnumber.length - 4) : '',
      }
    });
  }
}

function refreshOrderByGuid(guid) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const data = await Orders.refreshOrderByGuid(guid);
      if (get(data, "deliverymode") === "dispatch") {
        dispatch(refreshDeliveryStatus(guid));
      }

      dispatch(success(data));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return { type: ordersConstants.REFRESH_ORDER_REQUEST };
  }

  function success(result) {
    return {
      type: ordersConstants.REFRESH_ORDER_SUCCESS,
      data: result,
    };
  }

  function failure(error) {
    return { type: ordersConstants.REFRESH_ORDER_FAILURE, error };
  }
}

function refreshOrderByRef(ref) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const data = await Orders.refreshOrderByRef(ref);
      dispatch(success(data));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return { type: ordersConstants.REFRESH_ORDER_REQUEST };
  }

  function success(result) {
    return {
      type: ordersConstants.REFRESH_ORDER_SUCCESS,
      data: result,
    };
  }

  function failure(error) {
    return { type: ordersConstants.REFRESH_ORDER_FAILURE, error };
  }
}

function refreshDeliveryStatus(guid) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const data = await Orders.refreshDeliveryStatus(guid);
      dispatch(success(data.deliveries));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return { type: ordersConstants.REFRESH_DELIVERY_STATUS_REQUEST };
  }

  function success(result) {
    return {
      type: ordersConstants.REFRESH_DELIVERY_STATUS_SUCCESS,
      data: result,
    };
  }

  function failure(error) {
    return { type: ordersConstants.REFRESH_DELIVERY_STATUS_FAILURE, error };
  }
}

function getBillingSchemes(guid) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Orders.getBillingSchemes(guid);
    if (
      data.error ||
      data.hasOwnProperty("message") ||
      typeof data === "string"
    ) {
      dispatch(failure(data.code === 200 ? data.message : data.error));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: ordersConstants.GET_BILLING_SCHEMES_REQUEST };
  }

  function success(result) {
    return {
      type: ordersConstants.GET_BILLING_SCHEMES_SUCCESS,
      data: result?.billingschemes,
    };
  }

  function failure(error) {
    return { type: ordersConstants.GET_BILLING_SCHEMES_FAILURE, error };
  }
}

function reset() {
  return {
    type: ordersConstants.RESET_ORDER
  };
}
