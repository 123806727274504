import React, { useState, useEffect, useRef } from "react";
import {
  Header,
  CholoInput,
  ChoLoader,
  Footer,
  MultiModal,
} from "../../components";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { getQueryParam } from "../../_helpers/parse-query-params";
import infoIcon from "../../assets/images/icon-info.png";
import get from "lodash/get";
import {
  newMemberActions,
  memberActions,
  alertActions,
  basketsActions,
} from "../../_actions";
import "./styles.scoped.css";
import { appConstants, passwordLoginRegex } from "../../_constants";
import { Link } from "react-router-dom";
import { isThemeEsquire, pushGTM } from "../../_helpers";
import secureStorage from "../../_helpers/secureStorage";
import ThemedBackArrow from "../../components/ThemedBackArrow";
import { useRenameDocument } from "../../context/renameDocument";


const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

const LoginOlo = ({
  contacts,
  dispatch,
  history,
  location,
  email,
  potentialPhone,
  potentialEmail,
  newLoading,
  loggingIn,
  loggedIn,
  error,
  signinError,
  loginDestination,
  potentialCreated,
  contactInfo,
  memberData,
  basketData,
  settings,
  contactId,
}) => {
  useRenameDocument("Log in");
  const mounted = useRef(false);
  const handoffPreference = get(settings, "handoff");
  const continueAsType = getQueryParam(location.search, "continueAsType");
  const [continueAs, setContinueAs] = useState(
    continueAsType !== null ? continueAsType : ""
  );
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [emailValid, setEmailValid] = useState(emailRegex.test(email));
  const [potentialEmailValid, setPotentialEmailValid] = useState(
    emailRegex.test(potentialEmail)
  );
  const [potentialPhoneValid, setPotentialPhoneValid] = useState(false);
  const [passValid, setPassValid] = useState(false);
  const [pass2Valid, setPass2Valid] = useState(false);
  const [firstValid, setFirstValid] = useState(
    continueAsType === "new" ? !!contactInfo.firstname : false
  );
  const [first, setFirst] = useState(
    continueAsType === "new" ? contactInfo.firstname : ""
  );
  const [lastValid, setLastValid] = useState(
    continueAsType === "new" ? !!contactInfo.lastname : false
  );
  const [last, setLast] = useState(
    continueAsType === "new" ? contactInfo.lastname : ""
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showMultiModal, setShowMultiModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(true);
  const [agreed, setAgreed] = useState(false);

  const isEsquire = isThemeEsquire();

  useEffect(() => {
    const basketProducts = get(basketData, "products", []);
    pushGTM("checkout", "Checkout", basketProducts, {step: 6});
  }, []);

  useEffect(() => {
    if (mounted.current && !newLoading && potentialCreated) {
      // potential user created, add to cognito -
      dispatch(newMemberActions.createCognitoUser(potentialEmail, password, contactId));
    } else {
      mounted.current = true;
    }
  }, [potentialCreated]);

  useEffect(() => {
    if (loggedIn) {
      if (contacts.length > 1) {
        setShowMultiModal(true);
      } else {
        continueAsGuest();
      }
    }
  }, [loggedIn]);

  const multiModalDismiss = () => {
    // logout
    dispatch(memberActions.logout());
    setShowMultiModal(false);
  };

  const postLogin = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!emailValid) {
      return;
    }
    if (!passValid) {
      return;
    }
    const test = new secureStorage();
      // if localstorage is available then initiate login
      if (Object.keys(test).includes('ls')) {
        await dispatch(memberActions.login(email, password));
      } else {
        // otherwise show message
        history.push('/Private');
      }
  };

  const onBackToBag = () => {
    history.goBack();
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const coupon = async () => {
    if (memberData) {
      const isCurrentMember =
        memberData.membershipType !== "Potential" &&
        memberData.status === "Active";
      const isDelivery = handoffPreference === 'dispatch';
      if (isCurrentMember && !isDelivery) {
        await dispatch(basketsActions.coupon(basketData.id, true));
      }
      return;
    }
  };

  const continueAsGuest = () => {
    if (loginDestination) {
      coupon().then(() => {
        history.push(loginDestination);
      });
    } else {
      onBackToBag();
    }
  };

  const userExists = async (email) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${appConstants.localApiUrl}users/user/${email}`,
      requestOptions
    );
    const json = await response.json();
    return json.with;
  };

  const maskPhone = (value) => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    value = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    return value;
  };

  const handleSubmit = async () => {
    if (
      !firstValid ||
      !lastValid ||
      !potentialEmailValid ||
      !potentialPhoneValid ||
      !passValid ||
      !pass2Valid ||
      password !== password2
    ) {
      setIsSubmitted(true);
      return;
    } else if (!agreed) {
      dispatch(
        alertActions.errorWithX(
          "Please read and agree to the Privacy Policy and Terms of Use before submitting."
        )
      );
      setIsSubmitted(true);
      return;
    } else {
      setIsSubmitted(true);
      setIsLoading(true);
      const exists = await userExists(potentialEmail);
      if (!exists) {
        await dispatch(
          newMemberActions.signupPotential({
            member: {
              FirstName: first,
              LastName: last,
              CHW_Membership_Type__c: "Potential",
              Email: potentialEmail,
              Phone: potentialPhone,
              HasOptedOutOfEmail: !subscribed,
              CHW_Guest__c: false
            },
          })
        );
        setIsSubmitted(true);
      } else {
        dispatch( 
          alertActions.error(null, 
          <span> 
            Email is associated to existing account, please go to{" "} 
            <a className="checkout-as-guest" href="/login-olo"> 
            login
            </a>{" "}     
            screen. 
          </span> 
          )  
        ); 
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <AnimatePresence>
        <div className="big-wrap">
          <Header onBagClick={onBackToBag} hideLogin />

          <Col className="little-wrap">
            <div className="back-to-my-bag" onClick={onBackToBag}>
              <ThemedBackArrow className="icon-back" width="20px" alt="back to bag icon"/>
              <p className="back-to-my-bag-text">Back to my bag</p>
            </div>
            <Col xl={7} lg={8} md={9} sm={10} xs={12}>
              {continueAs === "" && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="checkout">Checkout</div>
                  <div className="inner-wrap">
                    <Button
                      className="button"
                      onClick={() => setContinueAs("member")}
                    >
                      LOG IN
                    </Button>
                    <Button
                      className="button inverted"
                      onClick={() => setContinueAs("new")}
                    >
                      CREATE AN ACCOUNT
                    </Button>
                    <Button className="button plain" onClick={continueAsGuest}>
                      Checkout as Guest
                    </Button>
                      <div>
                        <br/>
                        *Wine Club Members: You can use your Wine Club log in credentials here.
                        If you have not set up your account online, <a href="https://member.chwinery.com/CreateNewSignin">click here </a>
                        to create your online credentials before using that to log in.
                      </div>
                  </div>
                </motion.div>
              )}
              {continueAs === "member" && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <h2 className="checkout">Log in to your account</h2>
                  <form
                    className="inner-wrap"
                    noValidate
                    onSubmit={!loggingIn ? postLogin : null}
                  >
                    <CholoInput
                      isValid={emailValid}
                      isSubmitted={isSubmitted}
                      errorMessage="Please enter a valid email address"
                      placeholder="Email Address"
                      value={email}
                      onTextChanged={(val) => {
                        const value = val.trim();
                        setEmailValid(emailRegex.test(value));
                        dispatch(memberActions.setEmail(value));
                      }}
                    />
                    <CholoInput
                      name="pInput"
                      isValid={passValid}
                      isSubmitted={isSubmitted}
                      errorMessage="Passwords must have at least 8 characters and contain at least one number digit"
                      className="password"
                      eyeball
                      placeholder="Password"
                      value={password}
                      onTextChanged={(value) => {
                        setPassValid(passwordLoginRegex.test(value));
                        setPassword(value);
                      }}
                      autoComplete="new-password"
                    />

                    <Row className="horizontal-edges">
                      <Link to="/forgotpassword-olo">
                        <div className="button plain lefty">
                          Forgot Password?
                        </div>
                      </Link>
                      <Button
                        className="button pad"
                        disabled={loggingIn}
                        onClick={!loggingIn ? postLogin : null}
                        type="submit"
                      >
                        LOG IN
                      </Button>
                    </Row>
                    <div className="divider" />
                    <Row className="horizontal-edges">
                      <Col>
                        <div>
                          <strong>Wine Club Members</strong>: You can use your Wine Club log in credentials here.
                          If you have not set up your account online, <a className="checkout-as-guest" href="https://member.chwinery.com/CreateNewSignin">click here</a>
                          &nbsp;to create your online credentials before using that to log in.
                        </div>
                        <div>
                          <br/>
                          Had an online ordering account prior to January 5, 2021 without a Wine Club Membership?&nbsp;
                          <span className="checkout-as-guest" onClick={() => {
                            setPassword("");
                            setContinueAs("new");
                          }}>
                            Create a new account
                          </span>
                          <br/>
                          <br/>
                          Not a Wine Club Member and don’t have an account?&nbsp;
                          <span className="checkout-as-guest" onClick={() => {
                            setPassword("");
                            setContinueAs("new");
                          }}>
                            Create an account
                          </span>
                          &nbsp;for easy checkout.
                        </div>
                      </Col>
                    </Row>
                  </form>
                </motion.div>
              )}
              {continueAs === "new" && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="checkout">Create your account</div>
                  <div className="inner-wrap">
                    <Row>
                      <Col xs={12} s={12} med={5} lg={6} xl={6}>
                        <CholoInput
                          id="firstName"
                          name="firstName"
                          isValid={firstValid}
                          isSubmitted={isSubmitted}
                          errorMessage="Please enter your first name"
                          placeholder="First name"
                          value={first}
                          onTextChanged={(value) => {
                            setFirst(value);
                            setFirstValid(value !== "");
                          }}
                          autoComplete="given-name"
                        />
                      </Col>
                      <Col xs={12} s={12} med={5} lg={6} xl={6}>
                        <CholoInput
                          id="lastName"
                          name="lastName"
                          isValid={lastValid}
                          isSubmitted={isSubmitted}
                          errorMessage="Please enter your last name"
                          placeholder="Last name"
                          value={last}
                          onTextChanged={(value) => {
                            setLast(value);
                            setLastValid(value !== "");
                          }}
                          autoComplete="family-name"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} s={12} med={5} lg={6} xl={6}>
                        <CholoInput
                          isValid={potentialEmailValid}
                          isSubmitted={isSubmitted}
                          errorMessage="Please enter a valid email address"
                          placeholder="Email Address"
                          value={potentialEmail}
                          onTextChanged={(val) => {
                            const value = val.trim();
                            setPotentialEmailValid(emailRegex.test(value));
                            dispatch(newMemberActions.setPotentialEmail(value));
                          }}
                          autoComplete="off"
                        />
                      </Col>
                      <Col xs={12} s={12} med={5} lg={6} xl={6}>
                        <CholoInput
                          isValid={potentialPhoneValid}
                          isSubmitted={isSubmitted}
                          errorMessage="Please enter area code and phone number, numbers only"
                          placeholder="Phone Number xxx-xxx-xxxx"
                          value={maskPhone(potentialPhone)}
                          onTextChanged={(value) => {
                            let changedPhone = value.replace(/\D/g, "").substr(0, 10);
                            dispatch(newMemberActions.setPhone(changedPhone));
                            (changedPhone.length === 10) ?
                              setPotentialPhoneValid(true):
                              setPotentialPhoneValid(false);
                            }
                          }
                          autoComplete="tel"
                        />
                      </Col>
                    </Row>
                    <CholoInput
                      name="pInput"
                      isValid={passValid}
                      isSubmitted={isSubmitted}
                      errorMessage=""
                      eyeball
                      placeholder="Password"
                      value={password}
                      onTextChanged={(value) => {
                        setPassValid(passwordLoginRegex.test(value));
                        setPassword(value);
                      }}
                      autoComplete="new-password"
                    />

                    <CholoInput
                      isValid={pass2Valid && password === password2}
                      isSubmitted={isSubmitted}
                      errorMessage={
                        password != password2 ? "Passwords must match" : ""
                      }
                      eyeball
                      placeholder="Confirm password"
                      value={password2}
                      onTextChanged={(value) => {
                        setPass2Valid(passwordLoginRegex.test(value));
                        setPassword2(value);
                      }}
                      autoComplete="new-password"
                    />

                    <Row className="horizontal-edges password-info">
                      <Row className="instruction-container">
                        <label class="tooltipPassword">
                          <img src={infoIcon} className="info-icon" alt="info icon" />
                          <span class="tooltipPasswordText">
                            &nbsp;Passwords must have at least 8 characters and contain
                            at least one number.
                          </span>
                        </label>
                      </Row>
                    </Row>

                    <Row className="horizontal-edges mt-md-2 mt-sm-0">
                    <Row className="instruction-container-checkbox pl-3">
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="checkout custom-control-input"
                          checked={subscribed}
                          onChange={(e) => setSubscribed(!subscribed)}
                          id="subscribeCheckbox"
                        />
                        <label
                          className="custom-control-label"
                          for="subscribeCheckbox"
                        >
                          Send me emails with news and offers from Cooper’s Hawk.
                        </label>
                      </div>

                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="checkout custom-control-input"
                          checked={agreed}
                          onChange={(e) => setAgreed(!agreed)}
                          id="agreedCheckbox"
                        />
                        <label
                          className="custom-control-label"
                          for="agreedCheckbox"
                        >
                          I have read and agreed to the{" "}
                          <a
                            href="https://chwinery.com/privacy-policy"
                            target="_blank"
                            className="footer-item footerCheckout"
                          >
                            <span>Privacy Policy</span>
                          </a>
                          {" "}and 
                          <a
                            href="https://chwinery.com/user-agreement"
                            target="_blank"
                            className="footer-item footerCheckout"
                          >
                            <span>Terms of Use</span>
                          </a>.
                        </label>
                      </div>
                      </Row>
                      <Button
                        className="button align-top"
                        onClick={handleSubmit}
                      >
                        CREATE ACCOUNT
                      </Button>
                    </Row>
                    <div className="divider" />
                    <Row className="horizontal-edges">
                      <Col>
                        <Row onClick={() => setContinueAs("member")}>
                          <div className="lefty dont-have">
                            Already have an account?
                          </div>
                          <div className="checkout-as-guest">Login</div>
                        </Row>
                      </Col>
                      <Col />
                    </Row>
                  </div>
                </motion.div>
              )}
            </Col>
          </Col>
          <Footer />
          {showMultiModal && (
            <MultiModal
              onDismiss={multiModalDismiss}
              onContinue={continueAsGuest}
            />
          )}
          {(loggingIn || isLoading) && <ChoLoader />}
        </div>
      </AnimatePresence>
    </>
  );
};

function mapStateToProps(state) {
  const { loggingIn, loggedIn, error, signinError } = state.authentication;
  const { member, basket, newMember, order, settings } = state;
  const contacts = get(member, "contacts", []);
  const newLoading = get(newMember, "loading", false);
  const potentialEmail = get(newMember, "data.potentialEmail", "");
  const email = get(member, "email", "");
  const potentialPhone = get(newMember, "data.phone", "")
  const potentialCreated = get(newMember, "potentialCreated", false);
  const contactId = get(newMember, "potentialMemberId", false);
  const loginDestination = get(member, "loginDestination");
  const memberData = get(member, "data");
  const basketData = get(basket, "data");
  const contactInfo = get(order, "contactInfo") || {};

  return {
    contacts,
    email,
    potentialPhone,
    newLoading,
    potentialEmail,
    loggingIn,
    loggedIn,
    memberData,
    basketData,
    error,
    signinError,
    loginDestination,
    potentialCreated,
    contactInfo,
    settings,
    contactId,
  };
}

const connectedLoginOlo = withRouter(connect(mapStateToProps)(LoginOlo));
export { connectedLoginOlo as LoginOlo };
