import React from "react";
import Row from "react-bootstrap/Row";

import "./styles.css";

const LoadingSpinners = ({
  currentTab,
  changeTab,
  events,
  openBottles,
  pickupHistory,
  spinnerStyle = {},
  color = "f8f9fa",
}) => {
  return (
    <div>
      <Row className="spinner" style={spinnerStyle}>
        <div className="bounce1" style={{backgroundColor: color}}></div>
        <div className="bounce2" style={{backgroundColor: color}}></div>
        <div className="bounce3" style={{backgroundColor: color}}></div>
      </Row>
    </div>
  );
};

export default LoadingSpinners;
