import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import "./styles.css";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";

import { memberActions } from "../../_actions";
import LoadingSpinners from "../../components/LoadingSpinners";
import { useRenameDocument } from "../../context/renameDocument";

const BarrelLogin = ({
  dispatch,
  loggingIn,
  loggedIn,
  error,
  shouldResetPassword,
  hasResetPassword,
  locations,
}) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [err, setError] = useState("");

  const postLogin = (event) => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      dispatch(memberActions.login(userName, password));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      postLogin(e);
    }
  };

  return loggedIn ? (
    !isEmpty(locations) ? (
      <Redirect to="/BarrelReserve" />
    ) : (
      <LoadingSpinners />
    )
  ) : shouldResetPassword && !hasResetPassword ? (
    <Redirect to="/ResetRequired" />
  ) : (
    <div className="container">
      <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
        <Form noValidate validated={validated}>
          <input type="hidden" />
          <h2 className="log-title">Barrel Reserve Sign In</h2>
          <h6 className="log-title mb-sm-4">
            with your Wine Club Member Account
          </h6>
          <Form.Group>
            <Form.Control
              value={userName}
              aria-label="barrel login email"
              onInput={(e) => setUserName(e.target.value)}
              onChange={(e) => dispatch(memberActions.setEmail(e.target.value))}
              autoFocus="autofocus"
              id="email"
              placeholder="Email"
              required="required"
              type="email"
              isInvalid={error === "UserDoesNotExist"}
            />
            <Form.Control.Feedback type="invalid">
              This email address is not associated with a Wine Club member
              account.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              value={password}
              onInput={(e) => setPassword(e.target.value)}
              id="password"
              placeholder="Password"
              required="required"
              type="password"
              isInvalid={error === "InvalidLogin"}
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <Form.Control.Feedback type="invalid">
              The password is incorrect. Enter a correct password and try again.
            </Form.Control.Feedback>
          </Form.Group>
          {err === "no-contacts" && (
            <p className="login-error">
              Unable to log in. Please call member &amp; guest services for
              assistance at <strong>(708) 215-5674</strong>. Code: 1000
            </p>
          )}
          {err === "inactive" && (
            <p className="login-error">
              This account has not been activated. Please call member &amp;
              guest services for assistance at <strong>(708) 215-5674</strong>. 
            </p>
          )}
          <div className="list-group visible my-3" id="member_select"></div>
          <div className="log-line clearfix">
            <div className="float-left">
              <Button
                disabled={loggingIn}
                className="btn btn-primary"
                onClick={(e) => (!loggingIn ? postLogin(e) : null)}
                type="button"
              >
                {loggingIn ? "Logging in…" : "Sign In"}
              </Button>
              <div className="clearfix"></div>
            </div>
            <div className="float-right">
              <Link className="forgot-password" to="/ForgotPassword">
                Forgot your Password?
              </Link>
            </div>
          </div>
          <br />
          <Form.Group className="mt-3">
            <p className="text-left">
              Existing Wine Club Members must{" "}
              <Link to="/CreateNewSignin">create new sign in credentials</Link>.
            </p>
            <br />
            <p className="text-left">
              Want to become a Wine Club Member?{" "}
              <Link to="/Signup">Sign up</Link>.
            </p>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    loggingIn,
    loggedIn,
    shouldResetPassword,
    hasResetPassword,
    error,
    signinError,
  } = state.authentication;
  const locations = get(state.staticContent.data, "locations", []);

  return {
    locations,
    loggingIn,
    loggedIn,
    shouldResetPassword,
    hasResetPassword,
    error,
    signinError,
  };
}

const connectedBarrelLogin = connect(mapStateToProps)(BarrelLogin);
export { connectedBarrelLogin as BarrelLogin };
