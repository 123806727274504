import React, { useEffect, useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import closeIcon from '../../assets/images/close@2x.png';
import './styles.scoped.scss';
import flatwareGray from '../../assets/images/flatware-gray.png';
import flatwareGold from '../../assets/images/flatware-gold.png';
import flatwareGoldEsquire from '../../assets/images/flatware-gold-esquire.svg';
import breadGray from '../../assets/images/bread-gray.png';
import breadGold from '../../assets/images/bread-gold.png';
import breadGoldEsquire from '../../assets/images/bread-gold-esquire.svg';
import { isThemeEsquire, pushGTM } from '../../_helpers';
import breadGrayEsquire from '../../assets/images/bread-gray-esquire.svg';
import { appConstants } from '../../_constants';
import { useSelector } from 'react-redux';
import { store } from '../../_helpers';

const addOnOption = ({ icon, label, isSelected, onClick, loading }) => {
  const actionText = isSelected ? 'Remove' : 'Add';

  return (
    <div className="addon-container" onClick={onClick}>
      <div className={`addon-inner ${isSelected && 'selected'}`}>
        <div className="left-container">
          <div className="icon-container">
            <img src={icon} className="addon-icon" alt="add-on icon" />
          </div>
          {label}
        </div>
        <div
          className={`action-text ${isSelected && 'selected'} ${loading &&
            'loading'}`}>
          <span>{actionText}</span>
        </div>
      </div>
    </div>
  );
};

const AddonModal = ({
  onCheckout,
  onDismiss,
  togglePretzelBread,
  toggleGfPretzelBread,
  toggleFlatware,
  hasPretzelBread,
  hasGfPretzelBread,
  hasFlatware,
  loading,
  basketData,
}) => {
  useEffect(() => {
    const basketProducts = get(basketData, 'products', []);
    pushGTM('checkout', 'Checkout', basketProducts, { step: 5 });
  }, []);

  const isEsquire = isThemeEsquire();

  const extref = store.getState()?.restaurant?.data?.extref || null;

  const showAddons = useMemo(
    () => ({
      showBread: !appConstants.hideBread.includes(extref),
      showGFBread: !appConstants.hideGFBrewad.includes(extref),
      showUtensils: !appConstants.hideUtensils.includes(extref),
    }),
    [extref, appConstants],
  );

  return (
    <AnimatePresence>
      <motion.div
        className="multi-overlay"
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <div
          onClick={onDismiss}
          id="touch-layer"
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        />
        <div
          className="addon-overlay-inner"
          style={loading ? { backgroundColor: '#eee' } : {}}>
          <div className="multi-title">Complimentary Add-Ons</div>
          <div className="addon-message">Quantity based on order size</div>

          <div className="addon-outer-container">
            {showAddons.showBread &&
              addOnOption({
                label: 'PRETZEL BREAD',
                icon: hasPretzelBread
                  ? isEsquire
                    ? breadGoldEsquire
                    : breadGold
                  : isEsquire
                  ? breadGrayEsquire
                  : breadGray,
                onClick: togglePretzelBread,
                isSelected: hasPretzelBread,
                loading,
              })}

            {showAddons.showGFBread &&
              addOnOption({
                label: 'GLUTEN FREE BUN',
                icon: hasGfPretzelBread
                  ? isEsquire
                    ? breadGoldEsquire
                    : breadGold
                  : isEsquire
                  ? breadGrayEsquire
                  : breadGray,
                onClick: toggleGfPretzelBread,
                isSelected: hasGfPretzelBread,
                loading,
              })}

            {showAddons.showUtensils &&
              addOnOption({
                label: 'FLATWARE',
                icon: hasFlatware
                  ? isEsquire
                    ? flatwareGoldEsquire
                    : flatwareGold
                  : flatwareGray,
                onClick: toggleFlatware,
                isSelected: hasFlatware,
                loading,
              })}

            {!showAddons.showBread &&
              !showAddons.showGFBread &&
              !showAddons.showUtensils && (
                <div className="error-message">
                  No Complementary Add-Ons available for this restaurant
                </div>
              )}
          </div>

          <motion.img
            src={closeIcon}
            className="close-btn"
            alt="close icon button"
            onClick={onDismiss}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}
          />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              className="addon-save-btn"
              onClick={onCheckout}
              disabled={loading}>
              CONTINUE TO CHECKOUT
            </button>
            <button className="addon-cancel-btn" onClick={onDismiss}>
              Cancel
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddonModal;

AddonModal.propTypes = {
  onDismiss: PropTypes.func,
  toggleFlatware: PropTypes.func,
  togglePretzelBread: PropTypes.func,
  hasFlatware: PropTypes.bool,
  hasPretzelBread: PropTypes.bool,
};

AddonModal.defaultProps = {
  onDismiss: () => {},
  toggleFlatware: () => {},
  togglePretzelBread: () => {},
  hasFlatware: true,
  hasPretzelBread: true,
};
