import React, { useEffect, useState, useRef } from "react";
import {
  MenuItemDetails,
  Header,
  Cart,
  MemberBanner,
  ChoLoader,
  BottleConversionModal,
  ScrollableMenu,
  HandoffModal,
} from "../../components";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { getQueryParam } from "../../_helpers/parse-query-params";
import { AnimatePresence } from "framer-motion";
import {
  alertActions,
  restaurantsActions,
  productsActions,
  basketsActions,
  memberActions,
} from "../../_actions";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import "./styles.scoped.css";
import { appConstants } from "../../_constants";
import { pushGTM, applyThemeForSlug } from "../../_helpers";
import { useRenameDocument } from "../../context/renameDocument";

const moment = require("moment");

const Menu = ({
  loadedRestaurantId,
  basketData = {},
  options,
  restaurantLoading,
  restaurantData,
  memberData,
  menuData,
  menuLoading,
  productAmount,
  openBottlesData,
  history,
  location,
  loggedIn,
  dispatch,
}) => {
  let { locationSlug } = useParams();

  useRenameDocument("Menu");

  const setTheme = (theme) => {
    const root = document.documentElement;

    for (const [key, value] of Object.entries(theme)) {
      root.style.setProperty(key, value);
    }
  }

  applyThemeForSlug(locationSlug, document);

  const bagOpen = getQueryParam(location.search, "bagOpen");
  const bagOpenTimer = useRef(null);
  const [handoffModalOpen, setHandoffModalOpen] = useState(false);
  const [detailVisible, setDetailVisible] = useAsyncState(false);
  const [cartVisible, setCartVisible] = useAsyncState(false);
  const [currentDetail, setCurrentDetail] = useAsyncState({});
  const [detailMode, setDetailMode] = useAsyncState("add");
  const [quickAdd, setQuickAdd] = useAsyncState(false);
  const [covertModalVisible, setCovertModalVisible] = useState(false);
  const [convertItem, setConvertItem] = useAsyncState(null);


  const getRestaurantFromSlug = async () => {
    if (locationSlug) {
      await dispatch(restaurantsActions.getRestaurantBySlug(locationSlug));
    }
  };

  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue);
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x);
        resolve(x);
      });
    return [value, setter];
  }


  useEffect(() => {
    if (!restaurantData || locationSlug !== restaurantData.slug) {
      getRestaurantFromSlug();
      if(basketData.deliverymode === 'pickup'){
      dispatch(alertActions.warning(appConstants.menuPopup));
      }
    }
  }, [locationSlug]);


  useEffect(() => {
    pushGTM('checkout', 'Checkout', undefined, {step:3});
    dispatch(basketsActions.resetBasket());
    getRestaurantFromSlug();
    if (bagOpen) {
      setCartVisible(true);
    }
    return () => {
      clearTimeout(bagOpenTimer.current);
    };
  }, []);

  const onBagClick = () => {
    setCartVisible(true);
  };

  const onCartDismiss = () => {
    clearTimeout(bagOpenTimer.current);
    setCartVisible(false);
  };

  useEffect(() => {
    if (productAmount > 0) {
      getUpsells();
      //basket updated, show cart
      dispatch(basketsActions.resetBasket());
      // setCartVisible(true);
      // bagOpenTimer.current = setTimeout(() => {
      //   setCartVisible(false);
      // }, 1);
    }
  }, [productAmount]);

  const basketIsMissing = () => {
    return isEmpty(basketData.id);
  };

  const basketIsExpired = () => {
    return (
      moment() >
      moment(get(basketData, "earliestreadytime"), "YYYYMMDD HH:mm").add(
        15,
        "minutes"
      )
    );
  };

  useEffect(() => {
    // on restaurant loaded
    if (restaurantData) {
      const restaurantId = restaurantData.id;
      if (basketIsMissing()) {
        dispatch(basketsActions.createBasket(restaurantId, false));
      } else if (basketIsExpired()) {
        dispatch(basketsActions.getBasket(get(basketData, "id")));
      }
    }
  }, [restaurantData]);

  useEffect(() => {
    // on restaurant loaded
    if (restaurantData && restaurantData.slug === locationSlug) {
      dispatch(restaurantsActions.getMenu(restaurantData.id));
      //tip should always be 0 if user not at checkout
      dispatch(basketsActions.addTip(get(basketData, "id"), 0));
    }
  }, [get(restaurantData, "id")]);

  useEffect(() => {
    const metadata = get(currentDetail, "metadata", []);
    let metaObject;
    if (metadata) {
      metaObject = metadata.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }),
        {}
      );
    }

    const isMemberExclusive = get(metaObject, "memberExclusive", "0");
    const isWine = get(metaObject, "isWine", "0");
    const isSweet = get(metaObject, "isSweet", "0");
    if (currentDetail.id) {
      if (isMemberExclusive === "1" && !quickAdd) {
        setDetailVisible(true);
        setQuickAdd(false);
        return
      }
      if (options.length === 0 && quickAdd) {
        dispatch(
          basketsActions.addProducts(
            get(basketData, "id"),
            currentDetail.id,
            currentDetail.name,
            1,
            "",
            "",
            "",
            isWine === "1"
              ? `isWine:${currentDetail.chainproductid}${
                  isSweet === "1" ? ",isSweet" : ""
                }`
              : ""
          )
        );
        setQuickAdd(false);
      } else if(options.length > 0){
        setDetailVisible(true);
        setQuickAdd(false);
      }
      setQuickAdd(false);
    }
  }, [options]);

  const quickAddItem = (product) => {
    pushGTM("click", "productClick", [product]);
    const isWineClubMembership = !!(
      product.metadata &&
      product.metadata.find(field =>
        field.key === 'isWineClubMembership' &&
        field.value === '1'
      )); 
    if(!!isWineClubMembership) {document.location.href = "#/wine-club"; return;}
    if (product.unavailablehandoffmodes.includes(basketData.deliverymode)) {
      dispatch(
        alertActions.olosuccess(
          "This item is not available for delivery.",
          "Sorry..."
        )
      );
    } else {
      setQuickAdd(true);
      setCurrentDetail(product);
      dispatch(productsActions.getModifiers(product.id));
    }
  };

  const showMenuDetail = async (product) => {
    pushGTM("click", "productClick", [product]);   
    const isWineClubMembership = !!(
      product.metadata &&
      product.metadata.find(field =>
        field.key === 'isWineClubMembership' &&
        field.value === '1'
      )); 
    if(!!isWineClubMembership) {document.location.href = "#/wine-club"; return;}

    if (product.unavailablehandoffmodes.includes(basketData.deliverymode)) {
      dispatch(
        alertActions.olosuccess(
          "This item is not available for delivery.",
          "Sorry..."
        )
      );
    } else {
      pushGTM("detail", "productDetailView", [product]);
      setQuickAdd(false);
      setCurrentDetail(product);
      setDetailMode("add");
      await dispatch(productsActions.getModifiers(product.id));
      setDetailVisible(true);
    }
  };

  const showEditModal = async (product) => {
    setDetailMode("edit");
    setQuickAdd(false);
    setCurrentDetail(product);
    await dispatch(productsActions.getModifiers(product.productId));
    setDetailVisible(true);
  };

  const checkoutBasket = () => {
    const checkoutURL = `/checkout/${get(basketData, "id")}`;
    const basketProducts = get(basketData, "products", []);
    pushGTM("checkout", "Checkout", basketProducts, {step: 4});
    if (loggedIn) {
      history.push(checkoutURL);
    } else {
      dispatch(memberActions.setLoginDestination(checkoutURL));
      history.push("/login-olo");
    }
  };

  const loginAsMember = () => {
    const menuURL = `/menu/${locationSlug}`;
    dispatch(memberActions.setLoginDestination(menuURL));
    history.push("/login-olo?continueAsType=member");
  };

  const verifyLogout = () => {
    //are you sure?
    dispatch(alertActions.oloLogout(() => logout()));
  };

  const logout = () => {
    // clear basket of conversions
    const basketId = get(basketData, "id", -1);
    const products = get(basketData, "products", []);
    products.forEach(async (product) => {
      if (product.customdata?.startsWith("conversion:") ?? false) {
        await dispatch(basketsActions.deleteProduct(basketId, product.id));
      }
    });

    dispatch(memberActions.logout());
  };

  const convertBottle = async (item) => {
    //alert if no open bottles
    if (openBottlesData.length === 0) {
      dispatch(
        alertActions.olosuccess(
          "You currently have no bottles in your inventory.",
          "Sorry..."
        )
      );
      return;
    }
    //alert is sweet member converting non-sweet wine
    if (
      memberData.clubType === "Sweet" &&
      item.customdata.split(",")[1] !== "isSweet"
    ) {
      dispatch(
        alertActions.olosuccess(
          `This wine is not swappable for Sweet members. Upgrade your membership <a href=${appConstants.memberUrl}/main#profile>here</a>.`,
          "Sorry..."
        )
      );
      return;
    }
    await setConvertItem(item);
    setCovertModalVisible(true);
  };

  const getUpsells = async () => {
    await dispatch(basketsActions.getUpsells(get(basketData, "id")));
  };

  return (
    <>
      <div className="big-wrap">
        <div className="no-flex">
          <Header
            onBagClick={onBagClick}
            isLoggedIn={loggedIn}
            onLogin={loginAsMember}
          />
          <MemberBanner />
        </div>
        <p className="screen-reader-text"><a href="#menu-container-list" className="screen-reader-text"><span>Skip to Content</span></a></p>
        <div className="menu-wrap">
          {menuData && (
            <ScrollableMenu
              menu={menuData}
              onSelectItem={showMenuDetail}
              onQuickItem={quickAddItem}
            />
          )}

          {detailVisible && (
            <MenuItemDetails
              onDismiss={() => setDetailVisible(false)}
              product={currentDetail}
              detailMode={detailMode}
            />
          )}
        </div>

        <AnimatePresence>
          {cartVisible && (
            <Cart
              openHandoff={() => setHandoffModalOpen(true)}
              onDismiss={onCartDismiss}
              onEdit={(item) => showEditModal(item)}
              onCheckout={checkoutBasket}
              onTouchEvent={() => clearTimeout(bagOpenTimer.current)}
              onLogin={loginAsMember}
              onConvertBottle={convertBottle}
            />
          )}
        </AnimatePresence>

        {covertModalVisible && (
          <BottleConversionModal
            onDismiss={() => setCovertModalVisible(false)}
            item={convertItem}
          />
        )}
        {handoffModalOpen && (
          <HandoffModal onDismiss={(_) => setHandoffModalOpen(false)} />
        )}
        {menuLoading && <ChoLoader />}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const {
    openBottles,
    restaurant,
    menu,
    basket,
    member,
    settings,
    modifiers,
    authentication,
  } = state;

  const loggedIn = get(authentication, "loggedIn", false);
  const basketData = get(basket, "data", {});
  const memberData = get(member, "data", {});
  const basketLoading = get(basket, "loading", false);
  const productAmount = filter(get(basket, "data.products", []), function(product) {
    if (product.customdata !== "complimentary") {
      return product;
    }
  })?.length;
  const options = get(modifiers, "data.optiongroups", []);
  const openBottlesData = get(openBottles, "data", []);
  const { loading: restaurantLoading, data: restaurantData } = restaurant;
  const {
    loading: menuLoading,
    data: menuData,
    restaurantId: loadedRestaurantId,
  } = menu;

  return {
    basket,
    basketData,
    basketLoading,
    productAmount,
    restaurant,
    restaurantData,
    restaurantLoading,
    loadedRestaurantId,
    menu,
    menuData,
    memberData,
    menuLoading: false,
    settings,
    openBottlesData,
    options,
    loggedIn,
  };
}

const connectedApp = withRouter(connect(mapStateToProps)(Menu));
export { connectedApp as Menu };
