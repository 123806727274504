import { appConstants } from "../_constants";
import { memberActions } from "../_actions"
import secureStorage from "../_helpers/secureStorage";
import get from "lodash/get";

export const barrelService = {
  calculateCost,
  getBarrelDetails,
  postOrder,
  chargeMember,
  logout
};

async function getBarrelDetails() {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const id = get(user, "id", "");
  const requestOptions = {
    method: "GET",
    headers: { "authorization": token }
  };

  return await fetch(
    `${appConstants.localApiUrl}members/member/barrel?contactId=${id}`,
    requestOptions
  ).then(handleResponse);
}

async function calculateCost(taxLocationID, regularBottles, magnumBottles) {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const contactID = get(user, "id", "");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": token
    },
    body: JSON.stringify({ 
      taxLocationID,
      regularBottles,
      magnumBottles, 
      contactID 
    })
  };

  return await fetch(
    `${appConstants.localApiUrl}members/member/barrel-cost`,
    requestOptions
  ).then(handleResponse);
}

async function postOrder(taxLocationID, regularBottles, magnumBottles) {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const contactID = get(user, "id", "");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": token
    },
    body: JSON.stringify({ 
      taxLocationID,
      regularBottles,
      pickupLocationID: taxLocationID,
      magnumBottles,
      contactID
    })
  };

  return await fetch(
    `${appConstants.localApiUrl}members/member/barrel-order`,
    requestOptions
  ).then(handleResponse);
}

async function chargeMember(barrelID) {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": token
    },
    body: JSON.stringify({barrelID})
  };

  return await fetch(
    `${appConstants.localApiUrl}members/member/barrel-payment`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  return dispatch => {
    dispatch(memberActions.logout());
  }
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok && response.status === 401) {
      // auto logout if 401 response returned from api
      logout();
      // eslint-disable-next-line no-restricted-globals
      location.reload(true);
    }
    return data;
  });
}
