import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import { SummaryBlockMonthly } from "../SummaryBlock";
import UIButton from "../UIButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { deliveryStateAbbreviations } from "../../_helpers";
import { newMemberActions } from "../../_actions";
import FadeIn from "react-fade-in";
import get from "lodash/get";
import "./styles.css";

import infoIcon from "../../assets/images/info-16.png";

const WineClubInfoMonthly = ({
  active,
  costChange,
  clubType,
  bottleLevel,
  deliveryType,
  signup,
  pricingStructure,
  dispatch,
  stepForward,
  showMonthly,
  handleCollapse,
  stepBack,
  isPromotion,
  promotionDescription
}) => {
  const [pricingString, setPricingString] = useState(
    `Monthly:${deliveryType}:${clubType}:${bottleLevel}:`
  );
  const [isDelivery, setIsDelivery] = useState(deliveryType == "Delivery");
  
  if(!bottleLevel)
    isPromotion? dispatch(newMemberActions.setBottleLevel("2")) : dispatch(newMemberActions.setBottleLevel("1"));
  if(!clubType)
    dispatch(newMemberActions.setClubType("Variety"));
  if(!deliveryType)
    dispatch(newMemberActions.setDeliveryType("Pickup"))

  useEffect(() => {
    setPricingString(`Monthly:${deliveryType}:${clubType}:${bottleLevel}:`);
    if (pricingStructure[pricingString]) {
      costChange(pricingStructure[pricingString]);
    }
  }, [
    deliveryType,
    clubType,
    bottleLevel,
    costChange,
    pricingString,
    pricingStructure,
    signup,
  ]);

  const getPricingString = ( delivery, level) => {
    return `Monthly:${delivery}:${clubType}:${level}:`
  }

  const renderSummaryText = (type) => {
    switch (type) {
      case "Variety": case "Red": case "White":
        return `A ${type} Club Member may choose to receive either the Wine of the Month, or swap for other wines. See (i) for more.`;
      case "Sweet":
        return "A Sweet Club Member may choose to receive any fruit, sparkling, or romance wine of their choice..";
    }
  };

  const clubTypeAdditionalInfo = (type) => {
    return (
      <Popover id="popover-additional">
        <Popover.Content
          style={{
            backgroundColor: "black",
            border: " 1px solid white",
            color: "white",
            fontFamily: "Oswald",
            letterSpacing: "1px",
          }}
        >
          {/* {renderAdditionalInfo(type)} */}
          <p>Swap options are different based on club type.
          Exclusions and additional charges may apply</p>
        </Popover.Content>
      </Popover>
    );
  };

  const renderAdditionalInfo = (type) => {
    let additionalInfo;
      switch (type) {
        case "Variety": case "Red": case "White":
          additionalInfo = "Choose from our Cooper's Hawk brand retail wine list.";
          break;
        case "Sweet":
          additionalInfo = "Some Wines of the Month qualify as well.";
          break;
      }

      additionalInfo += ` Wine ${bottleLevel == 1 ? "bottle" : "bottles"} ${bottleLevel == 1 ? "illustrates" : "illustrate"} bottle quantity, not the actual ${bottleLevel == 1 ? "bottle" : "bottles"} you will pick up. Some exclusions and additional charges may apply.`
      return additionalInfo;
  };

  const selectStates = (
    <Popover id="popover-basic">
      <Popover.Content
        style={{
          backgroundColor: "black",
          border: " 1px solid white",
          color: "white",
          fontFamily: "Oswald",
          letterSpacing: "1px",
        }}
      >
        Delivery option is only available in {deliveryStateAbbreviations()}
      </Popover.Content>
    </Popover>
  );

  const canAdvance = () => {
    return (
      deliveryType !== "" &&
      clubType !== "" &&
      bottleLevel !== "" 
    );
  };

  return (
    <div className="inactive">
        <h4>1. Select Membership</h4>
      <Collapse in={active && showMonthly} onExited={handleCollapse}>
        <div id="collapse-div">
          <FadeIn delay={300}>
            <div className="container expandable" style={{paddingTop: "5px"}}>
              <div className="text" style={{color:'#d9d9d9'}}>
              Membership includes a newly released wine each month, plus benefits such as birthday rewards, discounts, points, and invitation to Member events! Pick up in Tasting Room or enjoy in-restaurant. Cancel any time.
              </div>
              <div className="hruleSummary" />
              <div style={{display:"flex"}}>
              <div className="text">SELECT WINE TYPE</div>
              <div id="info-icon-img" className="mobile-only">
              <div> 
                  <OverlayTrigger
                  trigger="focus"
                  placement="bottom"
                  overlay={clubTypeAdditionalInfo(clubType)}
                  >
                    <Button className="btn-link mobile-only" style={{ outline: "0",
                      boxShadow: "0 0 0 0.2rem transparent",height:"1.5em"}}>
                      <img src={infoIcon} alt="info icon" />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column"}} className="text mobile-only">
                <span style={{color:'#d9d9d9'}}>{renderSummaryText(clubType)}</span>
                <OverlayTrigger
                  trigger="focus"
                  placement="bottom"
                  overlay={clubTypeAdditionalInfo(clubType)}
                >
                  <Button className="btn-link desktop-only" style={{ outline: "0",
                     boxShadow: "0 0 0 0.2rem transparent",height:"1.5em"}}>
                    <img src={infoIcon} alt="info icon" />
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="vert-spacer mobile-only" style={{height:'0.45em'}}></div>
              <Row className="py-2">
                <Col xs={6} md={3}>
                  <UIButton
                    text="VARIETY"
                    onSelect={(e) =>
                      dispatch(newMemberActions.setClubType("Variety"))
                    }
                    disabled={clubType !== "Variety"}
                  />
                </Col>
                <Col md={3} className="desktop-only">
                  <UIButton
                    text="WHITE"
                    onSelect={(e) =>
                      dispatch(newMemberActions.setClubType("White"))
                    }
                    disabled={clubType !== "White"}
                  />
                </Col>
                <Col md={3} className="desktop-only">
                  <UIButton
                    text="RED"
                    onSelect={(e) =>
                      dispatch(newMemberActions.setClubType("Red"))
                    }
                    disabled={clubType !== "Red"}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <UIButton
                    text="SWEET"
                    onSelect={(e) =>
                      dispatch(newMemberActions.setClubType("Sweet"))
                    }
                    disabled={clubType !== "Sweet"}
                  />
                </Col>
              </Row>
              <div className="text" style={{ display: "flex", flexWrap: "wrap",paddingTop:"0.88em"}}>
                {!isPromotion && "SELECT"} BOTTLES PER {isDelivery ? "QUARTER" : "MONTH"} 
                {(!isPromotion && bottleLevel < 3) && 
                <>
                  <div className="mobile-only" style={{height:"0.75em",width:"100%"}}></div>
                  <span style={{ fontSize: "0.9em"}} className="desktop-only">
                      <input
                        type="checkbox"
                        value={isDelivery}
                        checked={isDelivery}
                        id="delivery-checkbox"
                        aria-label="delivery checkbox"
                        onChange={() => {
                          setIsDelivery(!isDelivery);
                          (isDelivery) ? 
                          dispatch(newMemberActions.setDeliveryType("Pickup")) : 
                          dispatch(newMemberActions.setDeliveryType("Delivery"));
                        }}
                        name="sameAsBilling"
                        style={{margin: "0em 0.25em", opacity:"0.75"}}
                      />
                      <span className="text">
                        <span className="delivery-option-text">Select for Quarterly Delivery (shipping included). Only available in</span>
                        <OverlayTrigger
                          trigger="focus"
                          placement="bottom"
                          overlay={selectStates}
                        >
                          <Button className="btn-link" style={{ padding: "0px 0px 5px 5px"}}>select states</Button>
                        </OverlayTrigger>
                        .
                      </span>
                  </span>
                </>
                }
              </div>
              <Row className="py-2">
                <Form.Label as="bottles" />
                {!isPromotion && 
                  <Col md={isDelivery ? 6 : 4}>
                    <UIButton
                      text={isDelivery ? `3 Bottles ($${pricingStructure[getPricingString("Delivery","1")]}/qtr)` : `1 Bottle ($${pricingStructure[getPricingString("Pickup","1")]}/mo)`}
                      onSelect={(e) =>
                        dispatch(newMemberActions.setBottleLevel("1"))
                      }
                      disabled={bottleLevel !== "1"}
                    />
                  </Col>
                }
                <Col md={isPromotion ? 12 : (isDelivery ? 6 : 4)}>
                  <UIButton
                   text={isDelivery ? `6 Bottles ($${pricingStructure[getPricingString("Delivery","2")]}/qtr)` : `2 Bottles ($${pricingStructure[getPricingString("Pickup","2")]}/mo)`}
                    onSelect={(e) =>
                      dispatch(newMemberActions.setBottleLevel("2"))
                    }
                    disabled={bottleLevel !== "2"}
                  />
                </Col>
                {(!isPromotion && !isDelivery) &&
                  <Col md={4}>
                    <UIButton
                      text={isDelivery ? `3 Bottles ($${pricingStructure[getPricingString("Delivery","3")]}/qtr)` : `3 Bottles ($${pricingStructure[getPricingString("Pickup","3")]}/mo)`}
                      onSelect={(e) => {
                        dispatch(newMemberActions.setBottleLevel("3"));
                        dispatch(newMemberActions.setDeliveryType("Pickup"));
                      }
                      }
                      disabled={bottleLevel !== "3"}
                    />
                  </Col>
                }
              </Row>
              {(bottleLevel > 1 && deliveryType!== "Delivery") &&
                <div className="text" style={{ textAlign: "center" }}>
                  <span>Save ${bottleLevel-1} per Bottle =&nbsp;
                  <span style={{color: "var(--member-site-gold)"}}>${(bottleLevel-1) * bottleLevel * 12} Annual Savings</span>&nbsp;
                  </span>
                  <span className="desktop-only" style={{ fontSize: "0.8em"}}>(vs 1-Bottle Option)</span> 
                  <div className="mobile-only" style={{ fontSize: "0.8em"}}>(vs 1-Bottle Option)</div> 
                </div>
              }
              
              <div className="vert-spacer" />
              <SummaryBlockMonthly
                price={pricingStructure[pricingString]}
                disabled={!canAdvance()}
                renderSummaryText={renderSummaryText}
                clubTypeAdditionalInfo={clubTypeAdditionalInfo}
              />

              {/* Mobile-view Delivery CheckBox : className="mobile-only"  
               #_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_*/}
               {(!isPromotion && bottleLevel < 3) && 
                <>
                  <span style={{ fontSize: "0.9em"}} className="mobile-only">
                    <input
                      type="checkbox"
                      value={isDelivery}
                      id="mobile-view-delivery-checkbox"
                      checked={isDelivery}
                      aria-label="mobile view delivery checkbox"
                      onChange={() => {
                      setIsDelivery(!isDelivery);
                        (isDelivery) ? 
                        dispatch(newMemberActions.setDeliveryType("Pickup")) : 
                        dispatch(newMemberActions.setDeliveryType("Delivery"));
                      }}
                      name="sameAsBilling"
                      style={{margin: "0em 0.25em", opacity:"0.75"}}
                    />
                  <span className="text">
                    <span className="delivery-option-text">Check the box for&nbsp;Quarterly Delivery (shipping included). Only available in
                    </span>
                   
                      <OverlayTrigger
                        trigger="focus"
                        placement="bottom"
                        overlay={selectStates}
                        >
                        <Button className="btn-link" style={{ padding: "0px 0px 5px 5px"}}>select states</Button>
                      </OverlayTrigger>
                        
                  </span>
                  </span>
                </>
                }
              {/* #_#_#_#_#_#  end mobile-view delivery checkbox  #_#_#_#_#_#*/}
              <div className="hrule" />
              <Form.Group as={Row} className="py-2" controlId="clubType">
                <Col md={10}></Col>
                <Col md={2}>
                  <UIButton
                    text="NEXT STEP"
                    onSelect={stepForward}
                    disabled={!canAdvance()}
                    validation={true}
                    aria-controls="collapse-div"
                  />
                </Col>
              </Form.Group>
            </div>
          </FadeIn>
        </div>
      </Collapse>

      {!showMonthly && (
        <FadeIn>
          <div className="step-icon-wrap">
            <div className="little-icon-wrap">
              <div className="summary-bullet-container">
               <div className="summary-bullet"></div>
              </div>
              <div className="summary-text">{deliveryType}</div>
            </div>
            <div className="little-icon-wrap">
              <div className="summary-bullet-container">
               <div className="summary-bullet"></div>
              </div>
              <div className="summary-text">
                {deliveryType === "Pickup" ?
                  `${bottleLevel} bottle${bottleLevel > 1 ? "s" : " " } per month` 
                  :
                  `${bottleLevel * 3} bottles shipped quarterly`
                }
              </div>
            </div>
            <div className="little-icon-wrap">
              <div className="summary-bullet-container">
               <div className="summary-bullet"></div>
              </div>
              <div className="summary-text">{clubType} Club</div>
            </div>

            <div className="little-icon-wrap">
            <div className="summary-bullet-container">
                <div className="summary-bullet"></div>
              </div>
              <h5 className="summary-text summary-pricing" style={{ display: "inline" }}>${pricingStructure[pricingString]}</h5>
              <span className="summary-text">
                {deliveryType === "Pickup" ? "/month" : "/quarter"}
              </span>
            </div>
          </div>
        </FadeIn>
      )}
    </div>
    
  );
};

function mapStateToProps(state, ownProps) {
  const {
    member,
    newMember,
    staticContent,
  } = state;
  const membershipType = get(member, "data.membershipType", "");
  const price = get(member, "data.costOfMembership", "");
  const clubType = get(newMember, "data.clubType", "");
  const bottleLevel = get(newMember, "data.bottleLevel", "");
  const deliveryType = get(newMember, "data.deliveryType", "");

  const pricingStructure = get(staticContent, "data.pricingStructure", {});

  return {
    clubType,
    membershipType,
    bottleLevel,
    deliveryType,
    pricingStructure,
    price,
  };
}

const connectedWineClubInfoMonthly = withRouter(
  connect(mapStateToProps)(WineClubInfoMonthly)
);
export { connectedWineClubInfoMonthly as WineClubInfoMonthly };
