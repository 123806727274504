import { appConstants } from "../_constants";
export const Products = {
  getModifiers,
};

const productsUrl = "http://localhost:3001/api/olo/products";

async function getModifiers(productId) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`${appConstants.oloApiUrl}/products/${productId}/modifiers`, requestOptions).then(
    async (response) => {
      if (!response.ok) {
        let result;
        try {
          result = await response.json();
        } catch (e) {
          // Couldn't parse the JSON
          //throw new Error(response.status);
          console.log("error", response.status);
          return e;
        }
        const { message } = result;
        // Got valid JSON with error response, use it
        //throw new Error(message || response.status);
        alert(message);
        return message;
      }
      // Successful response, parse the JSON and return the data
      return response.json();
    }
  );
}
