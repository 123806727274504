import React from 'react';
import { motion } from "framer-motion";
import "./styles.css";
export default function ButtonAnimator({ children }) {
    return(
      <motion.div
        whileHover={{
          scale: 1.01,
          transition: { duration: 0.1 },
        }}
        whileTap={{ scale: 0.99 }}
      >
          {children}
       </motion.div>
    )
  }