import React, { useState, useEffect, createRef } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";
import get from "lodash/get";
import "./styles.scoped.scss";
import MenuItem from "../MenuItem";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

const MenuItemCollection = ({
  data,
  onSelectItem,
  onQuickItem,
  imagePath,
  categories,
  innerRefs,
  onSelectCat,
  isCurrentMember
}) => {
  let products = (data && data.products) || [];
  const [subs, setSubs] = useState(null);
  const [parent, setParent] = useState();
  const [parentFirstCategoryId, setParentFirstSubcategoryId] = useState();
  const [hasParent, setHasParent] = useState(false);
  const [subMenuSelection, setSubMenuSelection] = useState(null);
  let allCatIds = [];
  let subCats = [];

  useEffect(() => {
    if (categories && data && data.id) {
      // does this category have a parent?
      let allCats = categories.filter((category) => category.isParent);
      allCats.forEach(
        (cat) => (allCatIds = [...allCatIds, ...cat.subCategories.split(",")])
      );

      const hasParentCategory = allCatIds.includes(data.id.toString());
      if (hasParentCategory) {
        let allParentCats = allCats.map((cat) => {
          return {
            parent: cat,
            subcategoryIds: cat.subCategories.split(","),
          };
        });
        let parentCategory = allParentCats.find((cat) => {
          return cat.subcategoryIds.includes(data.id.toString());
        });
        let parentFirstSubcategoryId = parentCategory.subcategoryIds[0];
        setParentFirstSubcategoryId(parentFirstSubcategoryId);
        setParent(parentCategory.parent);
      }

      setHasParent(hasParentCategory);
    }
  }, [categories, data]);

  const subcatSelected = (subcat) => {
    const catRef = innerRefs[subcat.id];
    catRef.scrollIntoView();
  };

  return (
    <div>
      {!data.isHidden && (
        <div className={`subcategory-view ${hasParent ? "has-parent" : ""}`}>
          <div
            className={`d-none d-md-block category-name${
              hasParent ? " left" : ""
            }`}
          >
            {data.name.includes("-")
              ? data.name.substr(data.name.indexOf("-") +1).toLowerCase()
              : data.name.toLowerCase()}
          </div>
          {parent != null && data.id != parentFirstCategoryId && (
            <div
              style={{ cursor: "pointer" }}
              className={`d-none d-md-block category-name${
                hasParent ? " right" : ""
              }`}
              onClick={() => {
                onSelectCat(parent);
              }}
            >
              Back to {parent.name}
            </div>
          )}
        </div>
      )}
      {!data.isParent && !data.isHidden && (
        <>
          {data.description && (
            <div
              className={`d-none d-md-block category-description${
                hasParent ? " desc-left" : ""
              }`}
            >
              {data.description}
            </div>
          )}

          <div className="d-md-none category-name mobile">
            {data.name.includes("-")
              ? data.name.substr(data.name.indexOf("-") +1).toLowerCase()
              : data.name.toLowerCase()}
          </div>
        </>
      )}
      {!data.isParent && !data.isHidden && (
        <div className="d-md-none category-description mobile">
          {data.description}
        </div>
      )}
      {data.isParent && (
        <div className="d-none d-md-block category-description">
          <span className="category-wrap">
            {data.subcategories &&
              data.subcategories.map((sub) => {
                return sub ? (
                  <span
                    key={sub.id}
                    className="submenu-item"
                    onClick={() => subcatSelected(sub)}
                  >
                    { sub && sub.name && sub.name.includes("-")
                      ? sub.name.substr(sub.name.indexOf("-") + 1)
                      : sub ? sub.name : ""
                    }
                  </span>
                ) : null;
              })}
          </span>
        </div>
      )}
      {!data.isParent && !data.isHidden && (
        <Container fluid nogutters="true" className="collection-container">
          <Row nogutters="true">
            {products.map((product) => {
              const isWineClubMembership = !!(
                product.metadata &&
                product.metadata.find(field =>
                  field.key === 'isWineClubMembership' &&
                  field.value === '1'
                )); 
              return isWineClubMembership && isCurrentMember ? (null) : (
              <MenuItem
                key={product.id}
                product={product}
                imagePath={imagePath}
                onSelect={onSelectItem}
                onQuick={onQuickItem}
              />
            )})}
          </Row>
        </Container>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { menu, member, authentication } = state;
  const menuData = get(menu, "data", {});
  const memberData = get(member, "data", {});
  let categories = get(menuData, "categories", []);
  const isLoggedIn = get(authentication, "loggedIn", false);
  const isCurrentMember =
    memberData.membershipType !== "Potential"
    && isLoggedIn && memberData.status !== "To be Activated";
  return {
    categories,
    isCurrentMember
  };
}

const connectedMenuItemCollection = connect(mapStateToProps)(
  MenuItemCollection
);
export { connectedMenuItemCollection as MenuItemCollection };

MenuItemCollection.propTypes = {
  data: PropTypes.object,
  imagePath: PropTypes.string,
  onSelectItem: PropTypes.func,
  onSelectCat: PropTypes.func,
};

MenuItemCollection.defaultProps = {
  data: { products: [] },
  imagePath: "",
  onSelectItem: () => {},
  onSelectCat: () => {},
};
