import React, { useState } from "react";
import PropTypes from "prop-types";

import "./styles.css";

const MenuCategories = ({ data, onSelect, value }) => {
  return (
    <div className="category-container">
      <div className="category-inner">
        {data &&
          data.map((category) => {
            return category.name.includes("-") || category.isHidden ? null : (
              <a
                href="#"
                className={`category ${
                  value === category ? "category-selected" : ""
                }`}
                key={category.id}
                onClick={() => onSelect(category)}
              >
                {category.name.toLowerCase()}
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default MenuCategories;

MenuCategories.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
};

MenuCategories.defaultProps = {
  data: [],
  onSelect: () => {},
};
