import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingSpinners from "../../components/LoadingSpinners";
import { PickupHistory } from "./pickup-history";
import { OpenBottles } from "./open-bottles";
import { BarrelOrders } from "./barrel-orders";
import { ReactComponent as BottleImage } from "../../assets/images/winebottle.svg";
import "./styles.css";
import get from "lodash/get";
import { appConstants } from "../../_constants";

const Bottles = ({ pickupBottles, promoBottles, pickupHistory, isLoading, dispatch }) => {
  return isLoading ? (
    <LoadingSpinners />
  ) : (
    <div>
      <Row className="text-center py-4">
        <Col md={12}>
        <Row style={{justifyContent: 'center', alignItems: 'center',}}>
          <Col xs={0.5} style={{alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>
          <BottleImage className="dashicon align-middle d-inline pr-2"/>
          </Col>
          <div style={{whiteSpace: 'pre-line'}}>
          <h5 className="bottle-status" style={{whiteSpace: 'pre-line'}}>
            <strong>
              {" "}
              {pickupBottles} {pickupBottles === 1 ? "Bottle" : "Bottles"}
            </strong>{" "}
            available.
          </h5>
          {promoBottles > 0 &&
                <span className="dine-in-bottles-text" >
                  {'\n'}+{promoBottles} Dine-in only bottle{promoBottles === 1 ? "" : "s"}{" "}
                  </span>
                  }
          </div>
          </Row>

          <h6 className="list-item-text">
          Enjoy your {pickupBottles === 1 ? "bottle" : "bottles"} when you{" "}
            <a href="https://chwinery.com/reservations" target="_blank">
              dine-in
            </a>{" "}
            with us or order with your{" "}
            <a href={appConstants.choloUrl} target="_blank">
              carryout
            </a>
            .
          </h6>
        </Col>
      </Row>
      <OpenBottles />
      <PickupHistory pickupHistory={pickupHistory} />
      <BarrelOrders />
    </div>
  );
};

function mapStateToProps(state) {
  const { openBottles } = state;
  const { data } = state.member;
  const pickupBottles = get(data, "pickupBottles", 0);
  const promoBottles = get(data, "promoBottles", 0);

  return {
    pickupBottles,
    promoBottles,
    isLoading: openBottles.loading,
  };
}

const connectedBottles = connect(mapStateToProps)(Bottles);
export { connectedBottles as Bottles };
