import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import get from "lodash/get";
import ReCAPTCHA from "react-google-recaptcha";
import { WineClubInfo } from "../../components/Forms/wineclub-info";
import { MemberInfo } from "../../components/Forms/member-info";
import { ContactInfo } from "../../components/Forms/contact-info";
import { HomeAddressInfo } from "../../components/Forms/home-address-info";
import { ShippingAddressInfo } from "../../components/Forms/shipping-address-info";
import LoadingSpinners from "../../components/LoadingSpinners";
import { newMemberActions, staticContentActions, alertActions } from "../../_actions";
import { rewardsService} from "../../_services";
import { deliveryStateAbbreviations, logEcommerceEvent, logGTMEvent, pushGTM } from "../../_helpers"
import { appConstants } from "../../_constants";
import queryString from 'query-string';

import "./styles.css";
import { WineClubInfoNewUser } from "../../components/Forms/wineclub-info-new-user";
import { MemberInfoNewUser } from "../../components/Forms/member-info-new-user";
import { ContactInfoNewUser } from "../../components/Forms/contact-info-new-user";
import { HomeAddressInfoNewUser } from "../../components/Forms/home-address-info-new-user";
import { ShippingAddressInfoNewUser } from "../../components/Forms/shipping-address-info-new-user";
import { useRenameDocument } from "../../context/renameDocument";

const NewMonthlyMemberSignUp = ({
  status,
  dispatch,
  loading,
  memberObj,
  paymentUrl,
  deliveryType,
  history,
  loggedIn,
}) => {
  const [validated, setValidated] = useState(false);
  const [price, setPrice] = useState("");
  const [gtmEvent, setGtmEvent] = useState({});
  let { pid } = useParams();
  const isPromotion = pid && pid !== '';
  const [promotion, setPromotion] = useState({});
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {setInnerWidth(window.innerWidth)});

  useRenameDocument('Monthly Member Sign Up')

  const referralCode = queryString.parse(history.location.search).REFERRALCODE;
  const reCaptchaRef = useRef();
  const [reCaptchaError, setReCaptchaError] = useState("");


  const userExistsInSF = async (email) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({"email":email}),
    };
    const response = await fetch(
      `${appConstants.localApiUrl}members/search`,
      requestOptions
    );
    const json = await response.json();
    const invalidPromoStatuses = ['Active','Cancel','Expired','Inactive','UTP','Vacation Hold'];
    return json.with?.some(member => invalidPromoStatuses.includes(member.CHW_Status__c) && member.CHW_Membership_Type__c != 'Potential');
  };

  const userExistsInCognito = async (email) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${appConstants.localApiUrl}users/user/${email}`,
      requestOptions
    );
    const json = await response.json();
    if(json.with){
      dispatch(newMemberActions.setCustomerId(json.with));
    }
    return json.with;
  };

    useEffect(() => {
    dispatch(staticContentActions.getStaticContent());
    logGTMEvent(
      'wine_club_step_view',
      {
        page_location: 'https://member.chwinery.com/NewMonthlyMemberSignUp',
        page_title: 'New Monthly Member Sign Up',
        page_path: '/NewMonthlyMemberSignUp',
        step_name: 'Wine Club Details',
      },
      { membership_type: 'Monthly' },
    );
  }, []);
  
  useEffect(() => {
    dispatch(newMemberActions.setPromotionId(pid));
    rewardsService.getPromotion(pid).then(promo => {
      setPromotion(promo?.with);
    });
  }, [pid]);

  useEffect(() => {
    if(paymentUrl && !paymentUrl?.includes("FAILURE")){
      history.push({
        pathname: "/Payment", 
        state: {
          newStyle: true,
          referralCode,
          gtmEvent,
          signupType: "monthly",
          pid
        }
      });
    }
  }, [paymentUrl]);

  const handleSubmit = async (event) => {
    setReCaptchaError("");
    const reCaptchaToken = reCaptchaRef.current.getValue();
    // If reCaptcha has been completed
    if(reCaptchaToken){
      const form = document.forms[0];
      setValidated(true);
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        event.preventDefault();
        event.stopPropagation();
        const alreadyExists = await userExistsInSF(memberObj.member.Email);
        const customerId = await userExistsInCognito(memberObj.member.Email);
        if (!isPromotion || (isPromotion && !alreadyExists)) {
          memberObj.member.CHW_Cost_of_Membership__c = price;
          memberObj.member.CHW_Promotion__c = pid;
          memberObj.reCaptchaToken = reCaptchaToken;
          if(customerId) memberObj.member.CHW_UUID__c = customerId;
          dispatch(newMemberActions.signup(memberObj, referralCode));
        } else if(isPromotion && alreadyExists){
          dispatch(
            alertActions.error(
              "Oops. Looks like you have an active or past Wine Club Membership. The special offer is only available for NEW Members joining for the first time."
            )
          );
        }
      }
    } else {
      setReCaptchaError("Complete the ReCAPTCHA checkbox above");
    }
  };

  if (loading) {
    return <LoadingSpinners />;
  }

  return ( 
    <div style={{backgroundColor: 'white'}}>
      <img className="image-to-be-gradiented" alt="" src={innerWidth > 576 ? require('../../assets/images/bbbg-v2.png') : require('../../assets/images/bbbg-v3.png')}/>
      <div className="image-gradient" />
    <div className="container form-container">
      <h1 className="join-header" style={{right: innerWidth > 576 ? '3vw' : undefined}}>Join the Wine Club </h1>
    <div className="container form-items-container" style={!!referralCode && innerWidth <= 576 ? {backgroundColor: 'white', paddingTop: 50} : {}}>
      {!!referralCode && (
      <div className="referral-header" style={{flexDirection: 'column', whiteSpace: 'pre-line'}}>
        <div className="referral-header-text">{`Welcome, and congrats! You've been referred by a Member to join the Cooper's Hawk Wine Club and receive a Welcome Bottle of wine to enjoy in our restaurant or at home! ${'\n\n'} Membership includes 2 or 3 bottles of exclusive wine each month, plus Wine Tastings for two monthly, and more.  Your bottles will be available after you join, and your one-time Welcome Bottle will be available after your second payment. We can’t wait to see you!`}</div>
      </div>
        )}
      <Form noValidate validated={validated} className="signup-active">
        <Row className="pl-4 pr-4">
          <Col lg={6} className="pt-4">
              <WineClubInfoNewUser 
              costChange={(price) => setPrice(price)}
              status={status}
              signup="monthly"
              referralCode={referralCode}
              isPromotion={isPromotion}
              setGTMEvent={(event) => setGtmEvent(event)}
              promotionDescription={promotion?.description}
              />
              <MemberInfoNewUser signup="monthly" required={true} />
          </Col>
          <Col lg={6} className="pt-4">
            <ContactInfoNewUser signup="monthly" required={true} />
            <HomeAddressInfoNewUser signup="monthly" required={true} />
          </Col>

          {deliveryType === "Delivery" && (
            <Col lg={{ span: 6, offset: 6 }}>
                <ShippingAddressInfoNewUser signup="monthly" required={true} />
            </Col>
          )}
        </Row>
        <Row style={{marginTop: innerWidth > 576 ? 100 : 0}}>
          <Col md={{span:8, offset: 2}} className="text-center">
            <p style={{color: 'black'}}>
              Please Note: If you choose to swap your wines based on available options, an upcharge may apply.  
              By clicking Proceed to Payment, you are accepting the Cooper’s Hawk Wine Club {" "}
              <a
                href="https://chwinery.com/wine-club/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a> and <a
                href="https://chwinery.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy.
              </a>
            </p>
            <div>
              <div className={`reCaptchaContainer ${reCaptchaError && "reCaptchaContainerError"}`}>
                <ReCAPTCHA
                id="reCAPTCHA"
                  sitekey={appConstants.reCaptchaSiteKeyMUI}
                  ref={reCaptchaRef}
                  theme="light"
                  onChange={(value) => {
                    if(value) {
                      setReCaptchaError("");
                    }
                  }}
                />
              </div>
              {reCaptchaError && (
                <div className="reCaptchaErrorMessage">
                  {reCaptchaError}
                </div>
              )}
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className="proceed-to-payment-button" onPointerDown={(e) => handleSubmit(e)} style={{margin: "25px 0px"}} tabIndex={0}>
                      <div className="proceed-to-payment-text">
                        PROCEED TO PAYMENT
                      </div>
                    </div>
            </div>
            {!isPromotion ? (
              <p style={{color: 'black'}}>
                Please call Member & Guest Services at <a href="tel:7082155674">(708) 215-5674</a>
                &nbsp;if you have questions.
              </p>
            ) : (
              <p>
                {promotion?.tcMessage}{" "}
                <a 
                  href={promotion?.tcURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>.
              </p>
            )}
            <div style={{ height: "5em" }} />
          </Col>
        </Row>
      </Form>
    </div>
    </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { newMember, member, authentication, staticContent } = state;
  const shipping = get(newMember, "data.shippingAddress", "");
  const billing = get(newMember, "data.billingAddress", "");
  const paymentUrl = get(newMember, "paymentUrl", "");
  const loading = get(newMember, "loading", false);
  const homeAddress = get(newMember, "data.homeAddress", {});
  const firstName = get(billing, "firstName", "");
  const lastName = get(billing, "lastName", "");
  const birthDate = get(newMember, "data.birthday", "");
  const clubType = get(newMember, "data.clubType", "Variety");
  const bottleLevel = get(newMember, "data.bottleLevel", "1");
  const deliveryType = get(newMember, "data.deliveryType", "Pickup");
  const phone = get(newMember, "data.phone", "");
  const email = get(billing, "email", "");
  const street = get(homeAddress, "street", "");
  const city = get(homeAddress, "city", "");
  const st = get(homeAddress, "state", "");
  const zip = get(homeAddress, "zipCode", "");
  const shippingStreet = get(shipping, "street", "");
  const shippingCity = get(shipping, "city", "");
  const shippingSt = get(shipping, "state", "");
  const shippingZip = get(shipping, "zipCode", "");
  const loggedIn = get(authentication, "loggedIn", false);

  const giftLength = get(newMember, "data.giftLength", "");
  const pricingStructure = get(staticContent, "data.pricingStructure", {});


  const isPotential = get(member, "data.membershipType", "") === "Potential";

  const memberObj = {
    member: {
      FirstName: firstName,
      LastName: lastName,
      Birthdate: birthDate,
      CHW_Membership_Type__c: "Monthly",
      CHW_Charge__c: deliveryType === "Delivery" ? "Next Billing Cycle" : "Immediately",
      CHW_Club_Type__c: clubType,
      CHW_Level__c: bottleLevel,
      CHW_Delivery__c: deliveryType,
      Phone: phone,
      Email: email,
      MailingStreet: street,
      MailingCity: city,
      MailingState: st,
      MailingPostalCode: zip,
      OtherStreet: shippingStreet,
      OtherCity: shippingCity,
      OtherState: shippingSt,
      OtherPostalCode: shippingZip,
    },
    returnUrl: `${appConstants.paymentReturnUrl}payment-success.htm`,
  };

  return {
    loading,
    newMember,
    deliveryType,
    memberObj,
    paymentUrl,
    isPotential,
    loggedIn,
  };
}

const connectedNewMonthlyMemberSignUp = withRouter(connect(mapStateToProps)(
  NewMonthlyMemberSignUp
));

export { connectedNewMonthlyMemberSignUp as NewMonthlyMemberSignUp };
