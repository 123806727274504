import React, { useEffect, useState } from "react";
import "./styles.scoped.scss";
import { CholoInput } from "../../index";
import { Col, Row } from "react-bootstrap";
import {
  memberActions,
  newMemberActions,
  paymentActions,
  alertActions
} from "../../../_actions";
import moment from "moment";
import { passwordRegex } from "../../../_constants";
import { connect } from "react-redux";
import LoadingSpinners from "../../LoadingSpinners";
import { get } from "lodash";
import exclusiveIcon from "../../../assets/images/wc-icon-exclusive.png";
import tastingsIcon from "../../../assets/images/wc-icon-tastings.png";
import infoIcon from "../../../assets/images/icon-info.png";
import ButtonAnimator from "../../ButtonAnimator";

const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);


const WineClubAccount = ({ member, newMember, isLoading, memberLoading, pricingStructure, ...props }) => {
  const { dispatch, history, match } = props;
  const membershipType = get(member, "data.membershipType", "");
  const clubSelection = get(match, "params.clubSelection", "1");
  const memberData = get(member, "data", {});
  let memberBirthday = get(memberData, "birthday", "");
  const memberId = get(memberData, "memberId", "");
  memberBirthday = memberBirthday ? moment(memberBirthday, "YYYY-MM-DD").format("MM/DD/YYYY") : "";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState(memberBirthday || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [receiveOffers, setReceiveOffers] = useState(true);
  const [edit, setEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [pricingString, setPricingString] = useState(`Monthly:Pickup:Variety:${clubSelection}:`);

  const formattedBirthday = moment(birthday, "MM/DD/YYYY").format("YYYY-MM-DD");
  const userCreated =
    newMember &&
    newMember.potentialMessage &&
    newMember.potentialMessage.startsWith("memberId");

    const bdayValid = () => {
      const year = moment().diff(moment(birthday).add(1, 'days'), "years", true);
      return moment(birthday, "MM/DD/YYYY", true).isValid() && year >=21;
    }
    

  useEffect(() => {
    if (submitted && memberId && memberData.birthday) {
      history.push(`/payment/${clubSelection}`);
    } else if (submitted && memberId) {
      // dispatch(memberActions.updateBirthday(birthday));
    }
  }, [submitted, memberData.birthday, memberId]);

  useEffect(() => {
    dispatch(paymentActions.clearOLOPaymentForm());
    dispatch(newMemberActions.clearNewMember());
    dispatch(memberActions.updateSupplierInfo({}))
  }, []);

  useEffect(() => {
    setPricingString(`Monthly:Pickup:Variety:${clubSelection}:`);
  }, [clubSelection]);

  let createError;
  if (!userCreated) {
    createError =
      submitted &&
      !isLoading &&
      membershipType !== "Potential" &&
      newMember.potentialMessage;
    createError =
      createError && createError.charAt(0) + createError.slice(1).toLowerCase();
  }

  if (typeof createError === "string" && createError.includes("exists")) {
    history.push(`/user-exists/${clubSelection}/${firstName}/${email}`);
    return null;
  }

  const handleEdit = () => {
    setEdit(true);
    setLastName(memberData.lastName);
    setFirstName(memberData.firstName);
    setPhone(memberData.phone);
    setEmail(memberData.email);
  }
  
  const saveProfileChanges = () => {
    dispatch(memberActions.postProfile({
      contact: {
        Id: memberData.sfId,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        Birthdate: formattedBirthday,
      },
    }));
    setEdit(false);
  };

  const handleSubmit = () => {
    setSubmitted(true);

    if (memberId) {
      if (bdayValid()) {
        dispatch(memberActions.updateBirthday(formattedBirthday));
      }
      return;
    } else if (
      isLoading ||
      !firstName ||
      !lastName ||
      !emailRegex.test(email) ||
      !phone.replace(/\D/g, "").length === 10 ||
      !bdayValid() ||
      !password ||
      confirmPassword !== password ||
      !passwordRegex.test(password) ||
      !agreed
    ) {
      !!emailRegex.test(email) &&
      phone.replace(/\D/g, "").length === 10 &&
      bdayValid() &&
      password &&
      confirmPassword === password &&
        !agreed &&  dispatch(
            alertActions.errorWithX(
              "Please read and agree to the Privacy Policy and Terms of Use before submitting."
            )
          );
      return;
    }
    if (
      !isLoading &&
      firstName &&
      lastName &&
      emailRegex.test(email) &
      phone.replace(/\D/g, "").length === 10 &&
      bdayValid() &&
      password &&
      confirmPassword === password &&
      agreed
    )
    dispatch(
      newMemberActions.signupPotentialFull(
        {
          member: {
            FirstName: firstName,
            LastName: lastName,
            CHW_Membership_Type__c: "Potential",
            Email: email,
            Phone: phone,
            HasOptedOutOfEmail: !receiveOffers,
            CHW_Guest__c: false,
            Birthdate: formattedBirthday,
          },
        },
        password
      )
    );
  };

  const renderSelectionCard = () => {
    return (
      <div
        className="border-box d-xs-block d-md-block"
        onClick={() => history.push(`/club-selection/${clubSelection}`)}
      >
        <div className="step-title">
          <div style={{ padding: 15, position: "relative" }}>
            WINE CLUB SELECTION
            <div className="benefits-container">
              <div className="selection-benefit">
                <img
                  src={exclusiveIcon}
                  alt="exclusive icon"
                  style={{ width: 16, marginRight: 6, marginBottom: 2 }}
                />
                <span>
                  {clubSelection === "2"
                    ? "2 Bottles Per Month Variety Wine Club"
                    : "1 Bottle Per Month Variety Wine Club"}
                </span>
              </div>
              {clubSelection === "2" && (
                <div className="selection-benefit">
                  <img
                    src={tastingsIcon}
                    alt="tastings icon"
                    style={{ width: 17, marginRight: 6, marginBottom: 3 }}
                  />
                  <span>2 Monthly Tastings</span>
                </div>
              )}
            </div>
            <div className="price-value">
              ${pricingStructure[pricingString]}/MO
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (memberId && !edit) {
    return (
      <div>
        {renderSelectionCard()}
        {memberLoading ? (
          <div className="border-box" style={{ padding: 15 }}>
            <LoadingSpinners color="var(--member-site-gold)" spinnerStyle={{ marginTop: 10, marginBottom:10, marginLeft: 'auto', marginRight: 'auto' }}/>
          </div>
        ) : (
          <>
          <div className="border-box" style={{ padding: 15 }}>
            <div className="step-title">YOUR ACCOUNT (<a style={{"color":"var(--member-site-gold)"}} onClick={handleEdit}>EDIT</a>)</div>
            <div className="first-last">{`${memberData.firstName} ${memberData.lastName}`}</div>
            <div className="info">{memberData.email}</div>
            <div className="info" style={{ marginBottom: "2em" }}>
              {memberData.phone}
            </div>
            <div className="italic-description">
              Please add your birthdate to verify your age, and so we can send you
              a complimentary dining reward during your birthday month.
            </div>

            <form
              noValidate
              onSubmit={(e) => {
                handleSubmit();
                e.preventDefault();
              }}
            >
              <Row>
                <Col xs={12} md={6}>
                  <div style={{ marginTop: "2em", paddingBottom: "5em" }}>
                    <CholoInput
                      isSmaller
                      id="birthday"
                      name="birthday"
                      isValid={bdayValid()}
                      isSubmitted={submitted}
                      errorMessage={`Please enter a valid date (before ${moment()
                        .subtract(21, "years", true)
                        .format("MM/DD/YYYY")})`}
                      placeholder="Birthday (MM/DD/YYYY)"
                      value={birthday}
                      onTextChanged={(str) => setBirthday(str.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,''))}
                      autoComplete="bday"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {!!createError && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginBottom: 20,
                      }}
                    >
                      {createError}
                    </div>
                  )}
                  <ButtonAnimator>
                  <div className="submit" onClick={handleSubmit}>
                    Continue
                    {isLoading && (
                      <LoadingSpinners
                        spinnerStyle={{ margin: 0, marginLeft: 10 }}
                      />
                    )}
                  </div>
                  </ButtonAnimator>
                  <div
                    className="selection-benefit"
                    style={{ width: "100%", textAlign: "center", marginTop: 10 }}
                  >
                    Next Step: Payment
                  </div>
                </Col>
              </Row>
            </form>
          </div>
          </>
        )}
        </div>
    );
  }

  return (
    <div>
      {renderSelectionCard()}
      <div className="border-box" style={{ padding: 15 }}>
        <div className="step-title">YOUR ACCOUNT</div>
        {!edit && (
        <div className="italic-description">
          Already have a Cooper's Hawk carryout account?{" "}
          <a
            href={`#/wine-club/signin/${clubSelection}`}
            style={{ textDecoration: "underline" }}
          >
            Log in here
          </a>
        </div>
        )}
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col xs={12} md={6}>
              <CholoInput
                isSmaller
                id="firstName"
                name="firstName"
                isValid={!!firstName}
                isSubmitted={submitted}
                errorMessage="First name is required"
                placeholder="First name"
                value={firstName}
                onTextChanged={setFirstName}
                autoComplete="given-name"
              />
            </Col>
            <Col xs={12} md={6}>
              <CholoInput
                isSmaller
                id="lastName"
                name="lastName"
                isValid={!!lastName}
                isSubmitted={submitted}
                errorMessage="Last name is required"
                placeholder="Last name"
                value={lastName}
                onTextChanged={setLastName}
                autoComplete="family-name"
              />
            </Col>
          </Row>
          {!edit && (
          <div>
            <CholoInput
              isSmaller
              isValid={emailRegex.test(email)}
              isSubmitted={submitted}
              errorMessage="Please enter a valid email address"
              placeholder="Email address"
              value={email}
              onTextChanged={setEmail}
            />
          </div>
          )}
          <Row className="d-xs-none d-md-flex">
            <Col xs={12} md={6}>
              <CholoInput
                isSmaller
                id="phone"
                name="phone"
                isValid={phone.replace(/\D/g, "").length === 10}
                errorMessage="Please enter a valid ten digit phone number"
                isSubmitted={submitted}
                placeholder="Phone number"
                value={phone}
                onTextChanged={(val) => setPhone(maskPhone(val))}
                autoComplete="tel"
              />
            </Col>
            <Col xs={12} md={6}>
              <CholoInput
                isSmaller
                id="modalBirthday"
                name="birthday"
                isValid={bdayValid()}
                isSubmitted={submitted}
                errorMessage={`Please enter a valid date (before ${moment()
                  .subtract(21, "years", true)
                  .format("MM/DD/YYYY")})`}
                placeholder="Birthday (MM/DD/YYYY)"
                value={birthday}
                onTextChanged={(str) => setBirthday(str.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,''))}
                autoComplete="bday"
              />
            </Col>
          </Row>
          {edit ? (
            <Row className='mt-2'>
              <Col xs={12}>
                {!!createError && <div className="error-text">{createError}</div>}
                <ButtonAnimator>
                <div className="submit" onClick={saveProfileChanges}>
                  Save Changes
                  {isLoading && (
                    <LoadingSpinners spinnerStyle={{ 
                      margin: 0, marginLeft: 10 
                    }}/>
                  )}
                </div>
                </ButtonAnimator>
              </Col>
            </Row>
          ) : (
            <>
            <Row className="d-xs-none d-md-flex">
              <Col xs={12} md={6}>
                <CholoInput
                  isSmaller
                  name="pInput"
                  isValid={passwordRegex.test(password)}
                  isSubmitted={submitted}
                  errorMessage="Please enter a valid password"
                  eyeball
                  placeholder="Password"
                  value={password}
                  onTextChanged={setPassword}
                  autoComplete="new-password"
                />
              </Col>
              <Col xs={12} md={6}>
                <CholoInput
                  isSmaller
                  isValid={password === confirmPassword}
                  isSubmitted={submitted}
                  errorMessage={
                    password !== confirmPassword ? "Passwords must match" : ""
                  }
                  eyeball
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onTextChanged={setConfirmPassword}
                  autoComplete="new-password"
                />
              </Col>
            </Row>
            <div className="password-info-container">
              <img src={infoIcon} className="info-icon" alt="info icon" />
              <div style={{ fontFamily: "Oswald", paddingLeft: 6 }}>
                Passwords must have at least 8 characters and contain at least one
                number digit.
              </div>
            </div>
            <div style={{ marginBottom: 15 }}>
              <div className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="checkout custom-control-input"
                  checked={agreed}
                  onChange={(e) => setAgreed(!agreed)}
                  id="agreedCheckbox"
                />
                <label className="custom-control-label" for="agreedCheckbox">
                  I have read and agreed to the{" "}
                  <a
                    href="https://chwinery.com/privacy-policy"
                    target="_blank"
                    className="footer-item footerCheckout"
                  >
                    <span>Privacy Policy</span>
                  </a>{" "}
                  and 
                  <a
                    href="https://chwinery.com/user-agreement"
                    target="_blank"
                    className="footer-item footerCheckout"
                  >
                    <span>Terms of Use</span>
                  </a>
                  .
                </label>
              </div>
            </div>
            <Row>
              <Col xs={12}>
                {!!createError && <div className="error-text">{createError}</div>}
                <ButtonAnimator>
                <div className="submit" onClick={handleSubmit}>
                  Create My Account
                  {isLoading && (
                    <LoadingSpinners
                      spinnerStyle={{ margin: 0, marginLeft: 10 }}
                    />
                  )}
                </div>
                </ButtonAnimator>
                <div
                  className="selection-benefit"
                  style={{ width: "100%", textAlign: "center", marginTop: 10 }}
                  >
                  Next Step: Payment
                </div>
              </Col>
            </Row>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

const maskPhone = (value) => {
  let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  value = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  return value;
};

const mapStateToProps = (state) => {
  const {
    newMember,
    member,
    news,
    events,
    pickupHistory,
    openBottles,
    rewards,
    points,
    barrelOrders,
    staticContent
  } = state;
  const isLoading =
    newMember.loading ||
    member.loading ||
    news.loading ||
    events.loading ||
    pickupHistory.loading ||
    openBottles.loading ||
    rewards.loading ||
    points.loading ||
    barrelOrders.loading;
  
  const memberLoading = member.loading;
  const pricingStructure = get(staticContent, "data.pricingStructure", {});

  return {
    newMember,
    member,
    isLoading,
    memberLoading,
    pricingStructure
  };
};

const connectedWineClubAccount = connect(mapStateToProps)(WineClubAccount);

export { connectedWineClubAccount as WineClubAccount };
