import { barrelConstants } from "../_constants";
import { barrelService } from "../_services";
import { alertActions } from "../_actions";
import head from "lodash/head";
import isArray from "lodash/isArray";
import get from "lodash/get";

export const barrelActions = {
  calculateCost,
  getBarrelOrders,
  postOrder
};

function getBarrelOrders() {
  return dispatch => {
    dispatch(request());
    barrelService.getBarrelDetails().then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: barrelConstants.BARREL_LIST_REQUEST };
  }
  function success(response) {
    return { type: barrelConstants.BARREL_LIST_SUCCESS, response };
  }
  function failure(error) {
    return { type: barrelConstants.BARREL_LIST_FAILURE, error };
  }
}

function calculateCost(taxLocationID, regularBottles, magnumBottles) {
  return dispatch => {
    dispatch(request());
    barrelService
      .calculateCost(taxLocationID, regularBottles, magnumBottles)
      .then(
        response => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return { type: barrelConstants.BARREL_COST_REQUEST };
  }
  function success(response) {
    return { type: barrelConstants.BARREL_COST_SUCCESS, response };
  }
  function failure(error) {
    return { type: barrelConstants.BARREL_COST_FAILURE, error };
  }
}

function postOrder(taxLocationID, regularBottles, magnumBottles) {
  return dispatch => {
    dispatch(request());
    barrelService.postOrder(taxLocationID, regularBottles, magnumBottles).then(
      response => {
        if (!response.with.success) {
          dispatch(failure());
          alertActions.error(head(response.messages).toString());
        }
        if (isArray(response.with) && head(response.with).errorCode === "APEX_ERROR") {
          dispatch(failure());
          dispatch(
            alertActions.error(
              "Unknown issue posting your order... Please call Member & Guest Services at (708) 215-5674"
            )
          );
        } else {
          const barrelID = get(response.with, "barrelReserveOrder.sfID", null);
          if (barrelID) {
            barrelService.chargeMember(barrelID).then(response => {
              if (response.with === "APPROVED") {
                dispatch(success(response));
              } else {
                dispatch(failure());
                let message =
                  "Unknown processing issue... Please call Member & Guest Services at (708) 215-5674";
                switch (response.with) {
                  case "ERROR":
                    message =
                      "Thank you for your interest in our Barrel Reserve.  There was an issue when processing your payment, please contact your credit card company for more information.  If you have any questions, please contact our Member and Guest Services at (708)215-5674.";
                    break;
                  case "DECLINED":
                    message =
                      "Thank you for your interest in our Barrel Reserve.  There was an issue when processing your payment, please contact your credit card company for more information.  If you have any questions, please contact our Member and Guest Services at (708)215-5674.";
                    break;
                  default:
                    break;
                }
                dispatch(alertActions.success(message, "Error"));
              }
            });
          }
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: barrelConstants.BARREL_ORDER_REQUEST };
  }
  function success(response) {
    return { type: barrelConstants.BARREL_ORDER_SUCCESS, response };
  }
  function failure(error) {
    return { type: barrelConstants.BARREL_ORDER_FAILURE, error };
  }
}
