import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { memberActions } from "../../_actions";
import { minDate } from "../../_constants";
import get from "lodash/get";

import "./styles.css";

const SecondaryMemberInfo = ({
  dispatch,
  secondaryMemberFirstName,
  secondaryMemberLastName,
  secondaryMemberBirthday,
  invalidAge,
  secondaryDataChange,
  setSecondaryHasContent
}) => {
  var today = new Date();
  var dd = (today.getDate() < 10 ? "0" : "") + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? "0" : "") + (today.getMonth() + 1);
  var yyyy = today.getFullYear() - 21;
  const validBirthdate = yyyy + "-" + mm + "-" + dd;

  const validateAge = () => {
    if (secondaryMemberBirthday > validBirthdate) {
      invalidAge = true;
    }
  };
  const secondaryHasInput = () => {
    const hasInput = (secondaryMemberFirstName || secondaryMemberLastName || secondaryMemberBirthday );
    secondaryDataChange(hasInput);
    return hasInput;
  };


  return (
    <div style={{ marginTop: "2em" }}>
      <h2 className="log-title">Secondary Member Information</h2>

      <Form.Group as={Row}>
        <Form.Label column sm={4} for="secondary-member-first-name">
          First Name
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            value={secondaryMemberFirstName}
            onChange={e =>
              dispatch(memberActions.updateSecondaryFirstName(e.target.value))
            }
            type="text"
            id="secondary-member-first-name"
            name="firstName"
            required={secondaryHasInput()}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid first name.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4} for="secondary-member-last-name">
          Last Name
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            value={secondaryMemberLastName}
            onChange={e =>
              dispatch(memberActions.updateSecondaryLastName(e.target.value))
            }
            type="text"
            id="secondary-member-last-name"
            name="lastName"
            required={secondaryHasInput()}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid last name.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} onSubmit={validateAge()}>
        <Form.Label column sm={4} for="secondary-member-birthday">
          Birthdate
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            value={secondaryMemberBirthday}
            onChange={e =>{
             dispatch(memberActions.updateSecondaryBirthday(e.target.value));
            }
            }
            type="date"
            id="secondary-member-birthday"
            name="birthday"
            placeholder="YYYY-MM-DD"
            max={validBirthdate}
            min={minDate}
            isInvalid={invalidAge}
            pattern="\d{4}-\d{2}-\d{2}"
            required={secondaryHasInput()}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid birthdate.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </div>
  );
};

function mapStateToProps(state) {
  const { member } = state;
  const secondaryMemberFirstName = get(
    member,
    "data.secondaryMemberFirstName",
    ""
  );
  const secondaryMemberLastName = get(
    member,
    "data.secondaryMemberLastName",
    ""
  );
  const secondaryMemberBirthday = get(
    member,
    "data.secondaryMemberBirthday",
    ""
  );
  const invalidAge = false;

  return {
    secondaryMemberFirstName,
    secondaryMemberLastName,
    secondaryMemberBirthday,
    invalidAge
  };
}

const connectedSecondaryMemberInfo = connect(mapStateToProps)(
  SecondaryMemberInfo
);
export { connectedSecondaryMemberInfo as SecondaryMemberInfo };
