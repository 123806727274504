import React, { useState } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const SubscribeInput = ({
  errorMessage,
  isValid,
  value,
  onTextChanged,
  isSubmitted,
  isRequired,
  isSmaller,
  label,
  feedback,
  name,
  type,
  pattern,
  ...props
}) => {
  const [hasChanged, setHasChanged] = useState(false);

  return (
    <Form.Group as={Row}>
        <Form.Label 
            column 
            style={{
                color: "black",
            }}
        >
            {label}
        </Form.Label>
        <Col xl={12}>
            <Form.Control
                {...props}
                value={value}
                onChange={e => {
                    let value = e.target.value;
                    if(type == 'tel'){
                        let x = value
                            .replace(/\D/g, "")
                            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        value = !x[2]
                            ? x[1]
                            : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                    }
                    onTextChanged(value);
                    if (!hasChanged) {
                        setHasChanged(true);
                    }
                }}
                type={type}
                name={name}
                required={isRequired}
                style={{
                    backgroundColor: "white",
                    border: "1px solid #868480",
                    color: "black",
                }}
                pattern={pattern}
            />
            <Form.Control.Feedback type="invalid">
                {feedback}
            </Form.Control.Feedback>
        </Col>
    </Form.Group>
  );
};

export default SubscribeInput;

SubscribeInput.propTypes = {
  value: PropTypes.string,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  isSubmitted: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSmaller: PropTypes.bool,

  label: PropTypes.string,
  feedback: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
};

SubscribeInput.defaultProps = {
  errorMessage: "Please correct your input and try again",
  isValid: true,
  value: "",
  onTextChanged: () => {},
  isSubmitted: false,
  isRequired: true,
  isSmaller:false,
  label: "",
  feedback: "",
  name:"",
  type:"text",
  pattern:"^[a-zA-Z ,.'-]+$",
};