import { paymentConstants } from "../_constants";
import { paymentService } from "../_services";
import { alertActions, memberActions } from "./";
import secureStorage from "../_helpers/secureStorage";
import get from "lodash/get";

export const paymentActions = {
  clearPaymentForm,
  getPaymentForm,
  getOloPaymentForm,
  postPayment,
  getPayments,
  clearPayments,
  clearOLOPaymentForm,
  removePayment,
  chargeMembership
};

function clearPaymentForm() {
  return {
    type: paymentConstants.PAYMENT_FORM_CLEAR
  };
}

function postPayment(params, member) {
  return dispatch => {
    dispatch(request());
    paymentService.postPayment(params).then(
      async result => {
        dispatch(success(result));
        const ls = new secureStorage();
        const token = JSON.parse(ls.get("user")).cognito_token;
        await memberActions.getOrCreate(member, token)(() => {});
        dispatch(memberActions.getMember(true));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: paymentConstants.POST_PAYMENT_REQUEST };
  }
  function success(result) {
    return {
      type: paymentConstants.POST_PAYMENT_SUCCESS,
      data: result
    };
  }
  function failure(error) {
    return { type: paymentConstants.POST_PAYMENT_FAILURE, error };
  }
}

function getPaymentForm(amount, origin, storeCard, transactionType) {
  return dispatch => {
    dispatch(request());
    paymentService
      .getPaymentForm(amount, origin, storeCard, transactionType)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return { type: paymentConstants.PAYMENT_FORM_REQUEST };
  }
  function success(result) {
    return {
      type: paymentConstants.PAYMENT_FORM_SUCCESS,
      data: result
    };
  }
  function failure(error) {
    return { type: paymentConstants.PAYMENT_FORM_FAILURE, error };
  }
}

function getOloPaymentForm(memberId, firstName, lastName) {
  return dispatch => {
    dispatch(request());
    paymentService
      .getOloPaymentForm(memberId, firstName, lastName)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return { type: paymentConstants.OLO_PAYMENT_FORM_REQUEST };
  }
  function success(result) {
    return {
      type: paymentConstants.OLO_PAYMENT_FORM_SUCCESS,
      data: result
    };
  }
  function failure(error) {
    return { type: paymentConstants.OLO_PAYMENT_FORM_FAILURE, error };
  }
}

/*
* Get saved payment methods
*/
function getPayments() {
  return async dispatch => {
    try {
      const response = await paymentService.getPaymentMethods();
      const json = await response.json();
      dispatch(success(json.billingaccounts));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return { type: paymentConstants.BILLING_ACCOUNT_REQUEST };
  }
  function success(data) {
    return { type: paymentConstants.BILLING_ACCOUNT_SUCCESS, data };
  }
  function failure(error) {
    return { type: paymentConstants.BILLING_ACCOUNT_FAILURE, error };
  }
}


function clearPayments() {
  return async dispatch => {
    dispatch({type: paymentConstants.CLEAR_PAYMENTS})
  };
}


function clearOLOPaymentForm() {
  return async dispatch => {
    dispatch({type: paymentConstants.CLEAR_OLO_PAYMENT_FORM})
  };
}

/**
 * Remove payment token from Salesforce and refresh stored payments
 */
function removePayment(contactId) {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await paymentService.removePayment(contactId);
      const json = await response.json();

      if (!get(json, "with.success")) {
        dispatch(failure(json.message));
        return;
      }

      dispatch(memberActions.getMember(true));
      dispatch(success());
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return { type: paymentConstants.REMOVE_PAYMENT_REQUEST };
  }
  function success() {
    return { type: paymentConstants.REMOVE_PAYMENT_SUCCESS };
  }
  function failure(error) {
    return { type: paymentConstants.REMOVE_PAYMENT_FAILURE, error };
  }
}


/**
 * Charge the user's card on file for a wine club membership
 */
function chargeMembership(contactId, amount, restaurantSlug) {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await paymentService.chargeMembership(contactId, amount, restaurantSlug);
      const json = await response.json();

      if (get(json, "status", "") !== "succeeded") {
        dispatch(failure(json.message));
        return;
      }

      dispatch(memberActions.getMember());
      dispatch(success());
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return { type: paymentConstants.CHARGE_MEMBERSHIP_REQUEST };
  }
  function success() {
    return { type: paymentConstants.CHARGE_MEMBERSHIP_SUCCESS };
  }
  function failure(error) {
    return { type: paymentConstants.CHARGE_MEMBERSHIP_FAILURE, error };
  }
}