import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import "./styles.css";
import decrement from "../../assets/images/icon-decrement.svg";
import increment from "../../assets/images/icon-increment.svg";
import incrementEsquire from "../../assets/images/gold-plus.svg";
import decrementEsquire from "../../assets/images/gold-minus.svg";
import { isThemeEsquire } from "../../_helpers";

const Qty = ({ qty, onIncrement, onDecrement }) => {

  const isEsquire = isThemeEsquire();
  
  return (
  <div className="span">
    {qty === -1 ?
      <div className="qty-not-available">Quantity N/A</div>
    :
      <>
      <motion.img
        src={isEsquire? decrementEsquire : decrement}
        alt="minus sign"
        className="plus-minus"
        onClick={onDecrement}
        whileHover={{
          scale: 1.05,
          transition: { duration: 0.1 },
        }}
        whileTap={{ scale: 0.95 }}
      />
      <div className="qty-num">{qty}</div>
      <motion.img
        src={isEsquire ? incrementEsquire : increment}
        alt="plus sign"
        className="plus-minus"
        onClick={onIncrement}
        whileHover={{
          scale: 1.05,
          transition: { duration: 0.1 },
        }}
        whileTap={{ scale: 0.95 }}
      />
      </>
    }
  </div>
)};

export default Qty;

Qty.propTypes = {
  qty: PropTypes.number,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func,
};

Qty.defaultProps = {
  qty: 1,
  onIncrement: () => {},
  onDecrement: () => {},
};
