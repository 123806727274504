import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import size from "lodash/size";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { appConstants } from "../../_constants";
import formatDate from "../../_helpers/format-date";
import "./styles.css";

const CurrentRewards = ({ rewards }) => {
  const currentRewards = get(rewards, "activeMemberRewards", []);

  return size(currentRewards, 0) === 0 ? (
    <div>
      <Row>
        <Col md={12}>
          <h2 className="log-title">Current Rewards</h2>
        </Col>

        <Col md={12}>
          <Table>
            <thead>
              <tr>
                <td className="like-th">There are no current rewards to display.</td>
              </tr>
            </thead>
          </Table>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="log-title">Current Rewards</h2>
        </Col>

        <Col md={10}>
          <Table borderless size="sm" className="my-md-4">
            <thead>
              <tr>
                <td className="like-th">Reward</td>
                <td className="like-th">Amount</td>
                <td className="like-th">Expiration</td>
              </tr>
            </thead>
            <tbody>
              {currentRewards.map((reward, index) => (
                <tr key={index}>
                  <td>{reward.name}</td>
                  <td>{!!reward.value && reward?.value !== .01 ? '$' + reward.value : ''}</td>
                  {formatDate(reward.endDate)}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <br />
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { rewards } = state;
  return {
    rewards: rewards.data,
  };
}

const connectedCurrentRewards = connect(mapStateToProps)(CurrentRewards);
export { connectedCurrentRewards as CurrentRewards };
