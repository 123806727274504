import { ordersConstants } from "../_constants";

const initState = {
  loading: false,
  data: {},
  contactInfo: null,
  validated: false,
  placed: false,
  refreshed: false,
  deliveries: [],
  billingSchemes: [],
  error: null,
};
export function order(
  state = initState,
  action
) {
  switch (action.type) {
    case ordersConstants.VALIDATE_ORDER_REQUEST:
    case ordersConstants.SUBMIT_ORDER_REQUEST:
    case ordersConstants.SUBMIT_PROXY_ORDER_REQUEST:
    case ordersConstants.SIMULATE_PAYMENT_REQUEST:
    case ordersConstants.REFRESH_ORDER_REQUEST:
    case ordersConstants.GET_BILLING_SCHEMES_REQUEST:
    case ordersConstants.REFRESH_DELIVERY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ordersConstants.VALIDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        validated: true,
        data: action.data,
        error: null,
      }
    case ordersConstants.GET_BILLING_SCHEMES_SUCCESS:
      return {
        ...state,
        loading: false,
        billingSchemes: action.data,
        error: null,
      }
    case ordersConstants.SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        placed: true,
        data: action.data,
        error: null,
      }
    case ordersConstants.SIMULATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        placed: true,
        contactInfo: action.data
      }
    case ordersConstants.SUBMIT_PROXY_ORDER_SUCCESS:
      console.log(action.data);
      const { transaction } = action.data;
      const paymentRequest = JSON.parse(transaction.response.body);
      console.log('Spreedly-generated payment request: ');
      console.log(paymentRequest)
      return {
        ...state,
        loading: false,
        placed: true,
        data: paymentRequest,
        error: null,
      }
    case ordersConstants.GET_BILLING_SCHEMES_FAILURE:
    case ordersConstants.VALIDATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        validated: false,
        error: action.error,
      }
    case ordersConstants.SUBMIT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        validated: false,
        error: action.error,
      }
    case ordersConstants.SUBMIT_PROXY_ORDER_FAILURE:
      console.log('Spreedly responded with an error...');
      console.log(action.error);
      return {
        ...state,
        loading: false,
        validated: false,
        error: action.error,
      }
    case ordersConstants.REFRESH_ORDER_FAILURE:
    case ordersConstants.REFRESH_DELIVERY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case ordersConstants.SET_ORDER_CONTACT_INFO:
      return {
        ...state,
        contactInfo: action.data
      }
    case ordersConstants.REFRESH_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshed: true,
        data: action.data
      }
    case ordersConstants.REFRESH_DELIVERY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshed: true,
        deliveries: action.data
      }
    case ordersConstants.RESET_ORDER:
      return {
        ...state,
        loading: false,
        validated: false,
        placed: false,
        refreshed: false,
        error: null,
      }

    default:
      return state;
  }
}
