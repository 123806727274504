import { alertActions } from "./";
import { settingsActions } from "./";
import { basketsConstants } from "../_constants";
import { Baskets } from "../_services/baskets.service";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import moment from "moment";
import { store, pushGTM } from "../_helpers";

export const basketsActions = {
  addProducts,
  coupon,
  getBasket,
  createBasket,
  destroyBasket,
  setCustomField,
  removeCustomField,
  resetLastUpdate,
  updateBasket,
  resetBasket,
  updateProduct,
  deleteProduct,
  addTip,
  getUpsells,
};

function coupon(guid, apply, customCode=null) {
  return async (dispatch) => {
    const {basket} = store.getState();
    guid = guid || get(basket, 'data.id');
    const hasAppliedCoupon = get(basket, "data.coupon.couponcode");

    if (
      !guid ||
      (apply && hasAppliedCoupon) ||
      (!apply && !hasAppliedCoupon)
    ) {
      return;
    }

    dispatch(request());
    // if apply == false, remove coupon
    const data = apply
      ? await Baskets.addCoupon(guid, customCode)
      : await Baskets.removeCoupon(guid, customCode);
    if (typeof data === "string") {
      dispatch(failure(data));
    } else {
      if (typeof data !== "string") dispatch(success(data));
    }
  };

  function request() {
    return apply
      ? { type: basketsConstants.APPLY_COUPON_REQUEST }
      : { type: basketsConstants.REMOVE_COUPON_REQUEST };
  }

  function success(result) {
    return apply
      ? {
          type: basketsConstants.APPLY_COUPON_SUCCESS,
          basket: result,
        }
      : {
          type: basketsConstants.REMOVE_COUPON_SUCCESS,
          basket: result,
        };
  }

  function failure(error) {
    return apply
      ? { type: basketsConstants.APPLY_COUPON_FAILURE, error }
      : { type: basketsConstants.REMOVE_COUPON_FAILURE, error };
  }
}

function getBasket(guid) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Baskets.getBasket(guid);
    if (
      data.error ||
      data.hasOwnProperty("message") ||
      typeof data === "string"
    ) {
      dispatch(
        typeof data === "string"
          ? alertActions.oloerror(data)
          : alertActions.oloerror(data.code === 200 ? data.message : data.error)
      );
      dispatch(failure(data.code === 200 ? data.message : data.error));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: basketsConstants.GET_BASKET_REQUEST };
  }

  function success(result) {
    return {
      type: basketsConstants.GET_BASKET_SUCCESS,
      basket: result,
    };
  }

  function failure(error) {
    return { type: basketsConstants.GET_BASKET_FAILURE, error };
  }
}

function createBasket(vendorid, transfer, guid) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Baskets.createBasket(vendorid, transfer, guid);
    if (
      data.error ||
      data.hasOwnProperty("message") ||
      typeof data === "string"
    ) {
      dispatch(
        typeof data === "string"
          ? failure(data)
          : failure(data.code === 200 ? data.message : data.error)
      );
      dispatch(
        typeof data === "string"
          ? alertActions.oloerror(data)
          : alertActions.oloerror(data.code === 200 ? data.message : data.error)
      );
    } else {
      if (transfer) {
        const products = await store.getState().basket.data.products;
        const newBasket = data.basket;
        const newProducts = newBasket.products;
        if(newProducts.length) {
        await products.forEach((element) => {
          let index = newProducts.findIndex(
            (item) => item.name === element.name
          );

          const optionsArray = [];
          if (newProducts[index]?.choices?.length > 0)
            newProducts[index].choices.forEach((el) => {
              optionsArray.push(el.optionid);
            });
          dispatch(
            basketsActions.updateProduct(
              newBasket.id,
              newProducts[index].id,
              newProducts[index].productId,
              element.quantity,
              optionsArray.toString(),
              element.specialinstructions,
              element.recipient,
              element.customdata
            )
          );
          newProducts[index].quantity = element.quantity;
          newProducts[index].choices = element.choices;
          newProducts[index].specialinstructions = element.specialinstructions;
          newProducts[index].customdata = element.customdata;
          newProducts[index].recipient = element.recipient;
        });
      }
        dispatch(
          success({
            ...data,
            products: {
              ...newProducts,
            },
          })
        );
      } else {
        dispatch(success(data));
      }
    }
  };

  function request() {
    return {
      type: transfer
        ? basketsConstants.TRANSFER_BASKET_REQUEST
        : basketsConstants.CREATE_BASKET_REQUEST,
    };
  }

  function success(result) {
    if (!isEmpty(result.itemsnottransferred)) {
      alertActions.olosuccess(
        `The following items have been removed from the cart due to unavailability at the selected store: ${result.itemsnottransferred.toString()}`,
        "Sorry!"
      );
    }
    return {
      type: transfer
        ? basketsConstants.TRANSFER_BASKET_SUCCESS
        : basketsConstants.CREATE_BASKET_SUCCESS,
      basket: transfer ? result.basket : result,
    };
  }

  function failure(error) {
    return {
      type: transfer
        ? basketsConstants.TRANSFER_BASKET_FAILURE
        : basketsConstants.CREATE_BASKET_FAILURE,
      error,
    };
  }
}

function addProducts(
  guid,
  productid,
  productName,
  quantity,
  options,
  specialinstructions = "",
  recipient = "",
  customdata = "",
  choicecustomfields = ""
) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Baskets.addProducts(
      guid,
      productid,
      quantity,
      options,
      specialinstructions,
      recipient,
      customdata,
      choicecustomfields
    );

    if (
      data.error ||
      data.hasOwnProperty("message") ||
      typeof data === "string"
    ) {
      dispatch(
        typeof data === "string"
          ? alertActions.oloerror(data)
          : alertActions.oloerror(data.message)
      );
      dispatch(failure(data.code === 200 ? data.message : data.error));
    } else {
      const product = { quantity, productId: productid };
      pushGTM('add', 'productAddToCart', [product]);
      // zero out tip on basket anytime items change - tip set at checkout
      dispatch(basketsActions.addTip(guid,0));
      // 02-17-21 GD
      dispatch(success(data, customdata));
      if (customdata !== "complimentary") {
        dispatch(alertActions.bagNotification(productName));
      }
    }
  };

  function request() {
    return { type: basketsConstants.ADD_PRODUCTS_REQUEST };
  }

  function success(data, customdata) {
    return {
      type: basketsConstants.ADD_PRODUCTS_SUCCESS,
      basket: data,
      customdata,
    };
  }

  function failure(error) {
    return { type: basketsConstants.ADD_PRODUCTS_FAILURE, error };
  }
}

function updateProduct(
  basketId,
  basketProductId,
  productId,
  quantity,
  optionsArr,
  specialinstructions,
  recipient,
  customdata,
  choicecustomfields,
  oldQuantity,
) {
  return async (dispatch) => {
    if (specialinstructions && specialinstructions.length > 20) {
      dispatch(
        alertActions.oloerror(
          "Please limit your special instructions to 20 characters"
        )
      );
      return;
    }
    dispatch(request());
    const data = await Baskets.updateProduct(
      basketId,
      basketProductId,
      productId,
      quantity,
      optionsArr,
      specialinstructions,
      recipient,
      customdata,
      choicecustomfields
    );
    if (
      data.error ||
      data.hasOwnProperty("message") ||
      typeof data === "string"
    ) {
      dispatch(
        typeof data === "string"
          ? alertActions.oloerror(data)
          : alertActions.oloerror(data.code === 200 ? data.message : data.error)
      );
      dispatch(failure(data.code === 200 ? data.message : data.error));
    } else {
      let qtyChange = 0;

      if (oldQuantity) {
        qtyChange = quantity - oldQuantity;
        quantity = Math.abs(qtyChange);
      }
      const action = qtyChange >= 0 ? 'add' : 'remove';
      const event = qtyChange >= 0 ? 'productAddToCart' : 'productRemoveFromCart';

      const product = { quantity, productId: productId };
      pushGTM(action, 'productAddToCart', [product]);
      // zero out tip on basket anytime items change - tip set at checkout
      dispatch(basketsActions.addTip(basketId,0));
      // 02-17-21 GD
      dispatch(success(data));
    }
  };

  function request() {
    return { type: basketsConstants.UPDATE_PRODUCT_REQUEST };
  }

  function success(data) {
    return {
      type: basketsConstants.UPDATE_PRODUCT_SUCCESS,
      basket: data,
    };
  }

  function failure(error) {
    return { type: basketsConstants.UPDATE_PRODUCT_FAILURE, error };
  }
}

function deleteProduct(basketId, basketProductId) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Baskets.deleteProduct(basketId, basketProductId);
    if (
      data.error ||
      data.hasOwnProperty("message") ||
      typeof data === "string"
    ) {
      dispatch(
        typeof data === "string"
          ? alertActions.oloerror(data)
          : alertActions.oloerror(data.code === 200 ? data.message : data.error)
      );
      dispatch(failure(data.code === 200 ? data.message : data.error));
    } else {
      pushGTM('remove', 'productRemoveFromCart', [{ basketProductId }]);
      // zero out tip on basket anytime items change - tip set at checkout
      dispatch(basketsActions.addTip(basketId,0));
      // 02-17-21 GD
      dispatch(success(data));
    }
  };

  function request() {
    return { type: basketsConstants.DELETE_PRODUCT_REQUEST };
  }

  function success(data) {
    return {
      type: basketsConstants.DELETE_PRODUCT_SUCCESS,
      basket: data,
    };
  }

  function failure(error) {
    return { type: basketsConstants.DELETE_PRODUCT_FAILURE, error };
  }
}

function addTip(guid, amount) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const data = await Baskets.addTip(guid, amount);
      dispatch(basketsActions.getBasket(guid));
      dispatch(success(data));  
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return {
      type: basketsConstants.ADD_TIP_REQUEST,
    };
  }

  function success(result) {
    return {
      type: basketsConstants.ADD_TIP_SUCCESS,
      data: result,
    };
  }

  function failure(error) {
    return {
      type: basketsConstants.ADD_TIP_FAILURE,
      error,
    };
  }
}

function setCustomField(basketGuid, data) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await Baskets.setCustomField(basketGuid, data);
      dispatch(success(response));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return {
      type: basketsConstants.SET_CUSTOM_FIELD_REQUEST,
    };
  }

  function success(result) {
    return {
      type: basketsConstants.SET_CUSTOM_FIELD_SUCCESS,
      basket: result,
    };
  }

  function failure(error) {
    return {
      type: basketsConstants.SET_CUSTOM_FIELD_FAILURE,
      error,
    };
  }
}

function removeCustomField(basketGuid, customFieldId) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await Baskets.removeCustomField(basketGuid, customFieldId);
      dispatch(success(response));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return {
      type: basketsConstants.REMOVE_CUSTOM_FIELD_REQUEST,
    };
  }

  function success(result) {
    return {
      type: basketsConstants.REMOVE_CUSTOM_FIELD_SUCCESS,
      basket: result,
    };
  }

  function failure(error) {
    return {
      type: basketsConstants.REMOVE_CUSTOM_FIELD_FAILURE,
      error,
    };
  }
}

function resetLastUpdate() {
  return async (dispatch) => {
    dispatch({
      type: basketsConstants.RESET_LAST_UPDATE,
    });
  };
}

function updateBasket(guid, payload, earliestReadyTime, hours) {
  return async (dispatch) => {
    dispatch(request());
    let result;
    let { selectedTimeMode, selectedTimeWanted } = payload;
    const {
      selectedAddress,
      dispatchAptNumber,
      dispatchPhoneNumber,
      handoffMode,
      changeset,
      specialinstructions,
    } = payload;

    try {
      if (
        changeset.handoffMode &&
        ["pickup", "curbside"].includes(handoffMode)
      ) {
        result = await Baskets.updateHandoffType(guid, handoffMode);
        dispatch(settingsActions.setHandoffType(handoffMode));
      }

      // Don't use ASAP if business is currently closed
      if (earliestReadyTime && selectedTimeMode === "asap" && hours) {
        const earliestReadyTimeMom = moment(earliestReadyTime, "YYYYMMDD HH:mm");
        const businessHours = hours.find(entry => entry.type === "business");

        if (businessHours && businessHours.ranges) {
          const range = businessHours.ranges.find(entry => entry.weekday === earliestReadyTimeMom.format("ddd"));
          const startHour = range && businessHours.ranges[0].start;
          const startMom = startHour && moment(startHour, "YYYYMMDD HH:mm");

          if (startMom && startMom.isSameOrAfter(earliestReadyTimeMom, "minute")) {
            selectedTimeMode = "advance";
            selectedTimeWanted = earliestReadyTime;
            changeset.timeWanted = true;
          }
        }
      }

      if (changeset.timeMode && selectedTimeMode === "asap") {
        result = await Baskets.deleteTimeWanted(guid);
      } else if (changeset.timeWanted) {
        try {
          result = await Baskets.updateTimeWanted(guid, selectedTimeWanted);
        } catch (error) {
          dispatch(
            alertActions.error(
              error,
            )
          );
          throw error;
        }
      }
      
      if (changeset.deliveryAddress || changeset.specialinstructions) {
        const { streetaddress, city, zipcode } = selectedAddress;

        result = await Baskets.updateDeliveryAddress(
          guid,
          streetaddress,
          city,
          zipcode,
          dispatchPhoneNumber,
          dispatchAptNumber,
          specialinstructions
        );
      }
      if (changeset.handoffMode && handoffMode === "dispatch") {
        result = await Baskets.updateHandoffType(guid, handoffMode);
        dispatch(settingsActions.setHandoffType(handoffMode));
      }
      dispatch(success(result, dispatchPhoneNumber));
    } catch (e) {
      // string error from Baskets service
      dispatch(failure(e.message));
    }
  };
  function request() {
    return { type: basketsConstants.UPDATE_BASKET_REQUEST };
  }

  function success(result, dispatchPhoneNumber) {
    return {
      type: basketsConstants.UPDATE_BASKET_SUCCESS,
      basket: result,
      dispatchPhoneNumber
    };
  }

  function failure(error) {
    return { type: basketsConstants.UPDATE_BASKET_FAILURE, error };
  }
}

function resetBasket() {
  return {
    type: basketsConstants.RESET_BASKET,
  };
}

function destroyBasket() {
  return {
    type: basketsConstants.DESTROY_BASKET,
  };
}

function getUpsells(guid) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Baskets.getUpsells(guid);
    if (
      data.error ||
      data.hasOwnProperty("message") ||
      typeof data === "string"
    ) {
      dispatch(
        typeof data === "string"
          ? alertActions.oloerror(data)
          : alertActions.oloerror(data.code === 200 ? data.message : data.error)
      );
      dispatch(failure(data.code === 200 ? data.message : data.error));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: basketsConstants.GET_UPSELLS_REQUEST };
  }

  function success(result) {
    return {
      type: basketsConstants.GET_UPSELLS_SUCCESS,
      upsells: result?.groups,
    };
  }

  function failure(error) {
    return { type: basketsConstants.GET_UPSELLS_FAILURE, error };
  }
}
