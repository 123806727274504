import React, { useState } from "react";
import PropTypes from "prop-types";
import eyeIconCH from "../../assets/images/icon-eyeball.png";
import eyeIconEsquire from "../../assets/images/icon-eyeball-esquire.svg";
import eyeIconSlashedCH from "../../assets/images/icon-eyeball-slash.png";
import eyeIconSlashedEsquire from "../../assets/images/icon-eyeball-slash-esquire.svg";
import "./styles.scoped.css";
import { isThemeEsquire } from "../../_helpers";

const CholoInput = ({
  errorMessage,
  isValid,
  value,
  eyeball,
  onTextChanged,
  isSubmitted,
  isRequired,
  isSmaller,
  ...props
}) => {
  const [hidden, setHidden] = useState(eyeball ? true : false);
  const [hasChanged, setHasChanged] = useState(false);
  const isFieldValid = isSubmitted ? isValid : !hasChanged || isValid;
  const isEsquire = isThemeEsquire();
  const eyeIcon = isEsquire ? eyeIconEsquire : eyeIconCH;
  const eyeIconSlashed = isEsquire ? eyeIconSlashedEsquire : eyeIconSlashedCH;

  return (
    <div className="component-wrap">
      <div className={`olo-input ${!isFieldValid && 'error '}${isSmaller && ' smaller-container'}`}>
        <input
          className={`input ${isSmaller && ' smaller-input'}`}
          {...props}
          aria-label={props.placeholder}
          type={hidden ? "password" : "text"}
          value={value}
          onChange={e => {
            onTextChanged(e.target.value);
            if (!hasChanged) {
              setHasChanged(true);
            }
          }}
          required={isRequired}
        />
        {eyeball && (
          <div className="eye-wrap" onClick={() => setHidden(!hidden)}>
            <img 
            src={hidden ? eyeIcon : eyeIconSlashed} 
            className={`eye ${isSmaller && ' smaller-eye'}`}
            alt="eye icon"
            />
          </div>
        )}
      </div>
      {!isFieldValid && errorMessage && (
        <div className={`validation-message ${isSmaller && ' smaller-validation'}`}>{errorMessage}</div>
      )}
    </div>
  );
};

export default CholoInput;

CholoInput.propTypes = {
  eyeball: PropTypes.bool,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  isSubmitted: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSmaller: PropTypes.bool,
};

CholoInput.defaultProps = {
  errorMessage: "Please correct your input and try again",
  isValid: true,
  value: "",
  eyeball: false,
  onTextChanged: () => {},
  isSubmitted: false,
  isRequired: true,
  isSmaller:false,
};
