import { openBottlesConstants } from "../_constants";
import { openBottlesService } from "../_services";
import { alertActions } from "./";

export const openBottleActions = {
  getOpenBottles
};

function getOpenBottles() {
  return dispatch => {
    dispatch(request());
    openBottlesService.getOpenBottles().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: openBottlesConstants.OPEN_BOTTLES_REQUEST };
  }
  function success(result) {
    return {
      type: openBottlesConstants.OPEN_BOTTLES_SUCCESS,
      openBottles: result.with.openBottles
    };
  }
  function failure(error) {
    return { type: openBottlesConstants.OPEN_BOTTLES_FAILURE, error };
  }
}
