import React, { useState, useEffect, useRef} from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import get from "lodash/get";
import LoadingSpinners from "../../components/LoadingSpinners";
import { WineClubInfoMonthly } from "../../components/Forms/wineclub-info-monthly";
import { DeliveryOptionsMonthly } from "../../components/Forms/delivery-options-monthly";
import { BillingInfoMonthly } from "../../components/Forms/billing-info-monthly";
import { alertActions, newMemberActions, staticContentActions } from "../../_actions";
import { appConstants } from "../../_constants";
import { rewardsService} from "../../_services";
import FadeIn from "react-fade-in";
import "./styles.css";

const NewMonthlyMemberSignUp = ({
  dispatch,
  monthlyStep,
  memberObj,
  loading,
  paymentUrl,
  clubType,
  billingPhone,
  deliveryType,
  shippingStreet,
  history
}) => {
  const [price, setPrice] = useState("");
  const [showMonthly, setShowMonthly] = useState(monthlyStep === "1");
  const [showBilling, setShowBilling] = useState(monthlyStep === "2");
  const [showDelivery, setShowDelivery] = useState(monthlyStep === "3");
  let { pid } = useParams();
  const isPromotion = pid && pid !== '';
  const [promotion, setPromotion] = useState({});

  const reCaptchaRefMonthly = useRef();
  const [reCaptchaError, setReCaptchaError] = useState("");

  const handleSubmit = async () => {
    setReCaptchaError("");
    if (deliveryType === "Delivery" && monthlyStep === "2") {
      dispatch(newMemberActions.setMonthlyStep("3"));
    } else {
      const reCaptchaTokenMonthly = reCaptchaRefMonthly.current.getValue();
      if(reCaptchaTokenMonthly){
        memberObj.member.CHW_Cost_of_Membership__c = price;
        const alreadyExists = await userExistsInSF(memberObj.member.Email);
        if (!isPromotion || (isPromotion && !alreadyExists)) {
          memberObj.member.CHW_Cost_of_Membership__c = price;
          memberObj.member.CHW_Promotion__c = pid;
          memberObj.reCaptchaToken = reCaptchaTokenMonthly;
          dispatch(newMemberActions.signup(memberObj));
        } else if(isPromotion && alreadyExists){
          dispatch(
            alertActions.error(
              "Oops. Looks like you have an active or past Wine Club Membership. The special offer is only available for NEW Members joining for the first time."
            )
          );
        }
      }
      else{
        setReCaptchaError("Complete the ReCAPTCHA checkbox above");
      }
    }
  };

  const userExistsInSF = async (email) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({"email":email}),
    };
    const response = await fetch(
      `${appConstants.localApiUrl}members/search`,
      requestOptions
    );
    const json = await response.json();
    const invalidPromoStatuses = ['Active','Cancel','Expired','Inactive','UTP','Vacation Hold'];
    return json.with?.some(member => invalidPromoStatuses.includes(member.CHW_Status__c) && member.CHW_Membership_Type__c != 'Potential');
  };

  useEffect(() => {
    if (paymentUrl === "ADDRESS_VERIFICATION_FAILURE") {
      dispatch(
        alertActions.error(
          "Shipping address invalid. Please update your delivery options."
        )
      );
      dispatch(newMemberActions.setMonthlyStep("3"));
    }
  }, [loading]);

  useEffect(() => {
    dispatch(newMemberActions.setPromotionId(pid));
    rewardsService.getPromotion(pid).then(promo => {
      setPromotion(promo?.with);
    });
  }, [pid]);

  useEffect(() => {
    if(paymentUrl && !paymentUrl?.includes("FAILURE")){
      history.push({
        pathname: "/Payment", 
        state: { 
          newStyle: true,
          signupType: "monthly",
          pid
        }
      });
    }
  }, [paymentUrl]);

  useEffect(() => {
    dispatch(staticContentActions.getStaticContent());
  }, []);

  const handleCollapse = () => {
    if (monthlyStep === "1") {
      setTimeout(function() {
        setShowMonthly(true);
        setShowBilling(false);
        setShowDelivery(false);
      }, 200);
    }
    if (monthlyStep === "2") {
      setTimeout(function() {
        setShowMonthly(false);
        setShowBilling(true);
        setShowDelivery(false);
      }, 200);
    }
    if (monthlyStep === "3") {
      setTimeout(function() {
        setShowMonthly(false);
        setShowBilling(false);
        setShowDelivery(true);
      }, 200);
    }
  };

  const canStep = (step) => {    
    switch(step) {
      case "1":
        return (monthlyStep !== "1" && clubType) ? true : false;
      case "2":
        return (monthlyStep !== "2" && billingPhone) ? true : false;
      case "3":
        return (monthlyStep !== "3" && shippingStreet) ? true : false;
    }
  };

  return loading ? <LoadingSpinners /> : (
    <div className="container">
      <FadeIn>
        <div 
          className={`${canStep("1") ? "toggleable" : ""}`}
          onClick={() => canStep("1") && dispatch(newMemberActions.setMonthlyStep("1"))}
        >
          <WineClubInfoMonthly
            costChange={(price) => setPrice(price)}
            active={monthlyStep === "1"}
            stepBack={() => dispatch(newMemberActions.setMonthlyStep("1"))}
            stepForward={() => {
              dispatch(newMemberActions.setMonthlyStep("2"));
            }}
            handleCollapse={() => handleCollapse()}
            showMonthly={showMonthly}
            isPromotion={isPromotion}
            promotionDescription={promotion?.description}
          />
        </div>
      </FadeIn>
      <br />

      <FadeIn>
        <div
            className={`${canStep("2") ? "toggleable" : ""}`}
            onClick={() => canStep("2") && dispatch(newMemberActions.setMonthlyStep("2"))}
          >
            <BillingInfoMonthly
              costChange={(str) => {}}
              active={monthlyStep === "2"}
              stepBack={() => dispatch(newMemberActions.setMonthlyStep("1"))}
              stepForward={() => {
                handleSubmit();
              }}
              handleCollapse={() => handleCollapse()}
              showBilling={showBilling}
              isPromotion={isPromotion}
              promotionTCMessage={promotion?.tcMessage}
              promotionTCUrl={promotion?.tcURL}
              reCaptchaRefMonthly={reCaptchaRefMonthly}
              reCaptchaError={reCaptchaError}
              setReCaptchaError={setReCaptchaError}
              />
        </div>
      </FadeIn>
      <br />

      {deliveryType === "Delivery" ?
        <FadeIn>
          <div
              className={`${canStep("3") ? "toggleable" : "user-select"}`}
              onClick={() => canStep("3") && dispatch(newMemberActions.setMonthlyStep("3"))}
              >
              <DeliveryOptionsMonthly
                costChange={(str) => {}}
                active={monthlyStep === "3"}
                stepBack={() => dispatch(newMemberActions.setMonthlyStep("2"))}
                stepForward={() => {
                  handleSubmit();
                }}
                handleCollapse={() => handleCollapse()}
                showDelivery={showDelivery}
                reCaptchaRefMonthly={reCaptchaRefMonthly}
                reCaptchaError={reCaptchaError}
                setReCaptchaError={setReCaptchaError}
                />
          </div>
        </FadeIn>
        :
        <div style={{ height: "100px" }}/>
      }
    </div>
  );
};

function mapStateToProps(state) {
  const { newMember } = state;
  const monthlyStep = get(newMember, "data.monthlyStep", "");
  const billing = get(newMember, "data.billingAddress", "");
  const shipping = get(newMember, "data.shippingAddress", "");
  const billingPhone = get(billing, "phone", "");
  const paymentUrl = get(newMember, "paymentUrl", "");
  const loading = get(newMember, "loading", false);
  const firstName = get(billing, "firstName", "");
  const lastName = get(billing, "lastName", "");
  const birthDate = get(newMember, "data.birthday", "");
  const clubType = get(newMember, "data.clubType", "Variety");
  const bottleLevel = get(newMember, "data.bottleLevel", "1");
  const deliveryType = get(newMember, "data.deliveryType", "Pickup");
  const email = get(billing, "email", "");
  const street = get(billing, "street", "");
  const city = get(billing, "city", "");
  const st = get(billing, "state", "");
  const zip = get(billing, "zipCode", "");
  const shippingStreet = get(shipping, "street", "");
  const shippingCity = get(shipping, "city", "");
  const shippingSt = get(shipping, "state", "");
  const shippingZip = get(shipping, "zipCode", "");

  const memberObj = {
    member: {
      FirstName: firstName,
      LastName: lastName,
      Birthdate: birthDate,
      CHW_Membership_Type__c: "Monthly",
      CHW_Charge__c: "Immediately",
      CHW_Club_Type__c: clubType,
      CHW_Level__c: bottleLevel,
      CHW_Delivery__c: deliveryType,
      Phone: billingPhone,
      Email: email,
      MailingStreet: street,
      MailingCity: city,
      MailingState: st,
      MailingPostalCode: zip,
      OtherStreet: shippingStreet,
      OtherCity: shippingCity,
      OtherState: shippingSt,
      OtherPostalCode: shippingZip,
    },
    returnUrl: `${appConstants.paymentReturnUrl}payment-success.htm`,
  };

  return {
    memberObj,
    loading,
    paymentUrl,
    monthlyStep,
    clubType,
    deliveryType,
    billingPhone,
    shippingStreet
  };
}

const connectedNewMonthlyMemberSignUp = connect(mapStateToProps)(
  NewMonthlyMemberSignUp
);
export { connectedNewMonthlyMemberSignUp as NewMonthlyMemberSignUp };
