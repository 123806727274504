import { subscribeConstants } from "../_constants";
import { memberService } from "../_services";
import { alertActions } from ".";

export const marketingActions = {
  clearMarketingSubscribe,
  subscribe,
  setPromotionId,
  setEmail,
  setBirthday,
  setFirstName,
  setLastName,
  setPhone,
  setZip,
};

function clearMarketingSubscribe() {
  return { type: subscribeConstants.CLEAR_MARKETING_SUBSCRIBE };
}

function subscribe(memberObj) {
  return (dispatch) => {
    dispatch(request());
    return memberService.signupPotential(memberObj).then(
      (response) => {
        console.log(response);
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: subscribeConstants.SUBSCRIBE_REQUEST };
  }
  function success(response) {
    return { type: subscribeConstants.SUBSCRIBE_SUCCESS, response };
  }
  function failure(error) {
    return { type: subscribeConstants.SUBSCRIBE_FAILURE, error };
  }
}

function setPromotionId(pid) {
  return { type: subscribeConstants.SET_PROMOTION_ID, pid };
}
function setEmail(email) {
  return { type: subscribeConstants.SET_EMAIL, email };
}
function setBirthday(birthday) {
  return { type: subscribeConstants.SET_BIRTHDAY, birthday };
}
function setFirstName(firstName) {
  return { type: subscribeConstants.SET_FIRSTNAME, firstName };
}
function setLastName(lastName) {
  return { type: subscribeConstants.SET_LASTNAME, lastName };
}
function setPhone(phone) {
  return { type: subscribeConstants.SET_PHONE, phone };
}
function setZip(zip) {
  return { type: subscribeConstants.SET_ZIP, zip };
}
