import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Row, Form, Button, Modal, CloseButton } from "react-bootstrap";
import { memberActions, newMemberActions } from "../../_actions";
import LoadingSpinners from "../../components/LoadingSpinners";
import get from "lodash/get";

import "./styles.css";
import { appConstants } from "../../_constants";
import { useRenameDocument } from "../../context/renameDocument";

const CreateNewSignin = ({
  dispatch,
  loading,
  newMember,
  memberExists,
  membershipType,
  memberNumber,
  email,
  cognitoStatus,
  loggedIn,
  loggingIn,
  signinError,
  history,
  contactId,
}) => {
  const [accountNum, setAccountNum] = useState("");
  const [lastName, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showMembershipIdInfo, setShowMembershipIdInfo] = useState(false);

  useRenameDocument("New Sign In");

  useEffect(() =>{
    // Check if user in SFDC
    if (memberExists) { 
      // Check if user in cognito
      dispatch(memberActions.login(email, "bogus0000password", "", {}, true));
    }
  }, [memberExists, dispatch, email]);

  /*eslint-disable */
  useEffect(() => {
    if (signinError === "inactive") {
      history.replace("/main");
    }
  }, [signinError]);
  /*eslint-enable */

  const validatePassword = () => {
    if (password !== password2) {
      return true;
    }
  };

  const memberDoesNotExist = get(newMember, "error", "");

  const backToLogin = () => {
    setTimeout(() => {
      dispatch(memberActions.logout());
    }, 200);
  };

  if (loggingIn) {
    return <LoadingSpinners />;
  }

  return loggedIn ? (
    <Redirect to="/main" />
  ) : (
    <div className="container">
      <Row className="justify-content-center">
        <Col xs={12} sm={9} lg={5} className="p-2 p-sm-5 rounded bg-light">
          <input type="hidden" />
          <h2 className="log-title">New Sign In</h2>
          <p className="pl-2 mb-4" style={{fontWeight: 500}}>Activate your online account access</p>

          {!memberExists && (
            <Form className="mx-3">
              <Form.Group>
                <Form.Label for="LoggedOut_memberNum">Member ID
                <h2
                  style={{ cursor: "pointer", fontSize: "0.75em" }}
                  onClick={() => setShowMembershipIdInfo(true)}
                  className="badge badge-secondary d-inline-block m-1"
                  data-target="#wineclub_info_modal"
                  data-toggle="modal"
                  >
                  i
                </h2>
                </Form.Label>
                <Form.Control
                  value={accountNum}
                  onInput={(e) => setAccountNum(e.target.value)}
                  autoFocus="autofocus"
                  id="LoggedOut_memberNum"
                  placeholder="eg 1234567"
                  required="required"
                  type="text"
                  isInvalid={memberDoesNotExist === "Member Not Found"}
                />
                <div className="invalid-feedback" id="loggedOut_cardnum_error">
                  Please provide a valid Member ID
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label for="lastName">Member Last Name</Form.Label>
                <Form.Control
                  value={lastName}
                  onInput={(e) => setLastname(e.target.value)}
                  id="lastName"
                  placeholder="Last Name"
                  required="required"
                  type="text"
                  isInvalid={memberDoesNotExist === "Member Not Found"}
                />
                <div className="invalid-feedback" id="lastname_error">
                  Please provide a valid Member Last Name
                </div>
              </Form.Group>

              <Button
                disabled={loading}
                className="btn btn-primary"
                onClick={() =>
                  !loading &&
                  dispatch(
                    newMemberActions.getMemberFromLastCard(
                      lastName,
                      appConstants.formatMemberNumber(accountNum)
                    )
                  )
                }
              >
                {loading ? "Working…" : "Continue"}
              </Button>
            </Form>
          )}

          {memberExists && cognitoStatus === "" && <LoadingSpinners />}
          
          {memberExists && (cognitoStatus === "InvalidLogin" || cognitoStatus === "PasswordResetRequired")  && (
            <Row align="center">
              <Col xs={12}>
                <p>
                  The member is already registered. <br />
                  Please return to login.{""}
                </p>
              </Col>

              <Col xs={12}>
                <Link to="/">
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => backToLogin(e)}
                  >
                    Back to Login
                  </Button>
                </Link>
              </Col>

              <Col xs={12} className="pt-4">
                <Link to="/ForgotPassword">Forgot Password?</Link>
              </Col>
            </Row>
          )}

          {memberExists && cognitoStatus === "UserDoesNotExist" && (
            <>
              <Form.Group>
                <Form.Label>Member ID</Form.Label>
                <Form.Control
                  value={memberNumber}
                  id="UserDoesNotExist_memberNum"
                  placeholder="eg 1234567"
                  type="text"
                  readOnly
                />
                <div className="invalid-feedback" id="cardnum_error">
                  Please provide a valid Member ID
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Membership Type</Form.Label>
                <Form.Control
                  value={membershipType}
                  id="membershipType"
                  type="text"
                  readOnly
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control value={email} id="email" type="text" readOnly />
              </Form.Group>

              <Form.Group>
                <Form.Label for="password">New Password</Form.Label>
                <Form.Control
                  id="password"
                  placeholder="Password"
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  pattern="^(?=.*[A-Za-z])(?=.*\d).{8,}$" // no idea why this works but importing the pattern does not
                                                          // should be using passwordRegexPattern from constants
                />
                <small className="form-text text-muted" id="password_hint">
                  At least eight characters, including letters and numbers.
                </small>
                <Form.Control.Feedback type="invalid">
                  Please provide a password with at least eight characters
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label for="password2">Confirm New Password</Form.Label>
                <Form.Control
                  id="password2"
                  placeholder="Confirm New Password"
                  required
                  onChange={(e) => {
                    setPassword2(e.target.value);
                  }}
                  isInvalid={validatePassword()}
                  type="password"
                  pattern={password}
                />
                <Form.Control.Feedback type="invalid">
                  Passwords must match
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                disabled={loading}
                className="btn btn-primary"
                onClick={(e) =>
                  dispatch(newMemberActions.createCognitoUser(email, password, contactId))
                }
              >
                Continue
              </Button>
            </>
          )}

          <div className="text-center my-4">
            Questions? <a href="tel:7082155674">(708) 215-5674</a>
          </div>
        </Col>
      </Row>
      <Modal show={showMembershipIdInfo} onHide={() => setShowMembershipIdInfo(false)} centered>
        <Modal.Header style={{color: 'black', backgroundColor: 'white'}}>
          <Modal.Title style={{color: 'black'}}>Where to Find Member ID</Modal.Title>
          <CloseButton style={{color: 'black', alignSelf: 'flex-end'}} onClick={() => setShowMembershipIdInfo(false)}/>
        </Modal.Header>
        <Modal.Body style={{color: "black", backgroundColor: "white"}}>
          <img
            className="member-id"
            src={
              // eslint-disable-next-line
              require('../../assets/images/member-id.png')
            }
            style={{width: '100%', height: '100%'}}
            alt="member id diagram"
          />
        </Modal.Body>
          </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  const { newMember, authentication } = state;
  let { loggedIn, loggingIn } = authentication;

  const loading = get(newMember, "loading", false);
  const memberAttributes = get(newMember, "data.attributes", false);
  const newMemberError = get(newMember, "error", "");

  const memberExists = memberAttributes && newMemberError !== "Member Not Found"

  const membershipType = get(newMember, "data.CHW_Membership_Type__c", "");
  const memberNumber = get(newMember, "data.CHW_Member_Number__c", "");
  const email = get(newMember, "data.Email", "");
  const contactId = get(newMember, "data.Id", "");

  const cognitoStatus = get(authentication, "error", "");
  const signinError = get(authentication, "signinError", "");

  return {
    loading,
    newMember,
    membershipType,
    email,
    memberExists,
    memberNumber,
    cognitoStatus,
    loggedIn,
    loggingIn,
    signinError,
    contactId,
  };
}

const connectedCreateNewSignin = connect(mapStateToProps)(CreateNewSignin);
export { connectedCreateNewSignin as CreateNewSignin };
