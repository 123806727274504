export const basketsConstants = {
  CREATE_BASKET_REQUEST: "CREATE_BASKET_REQUEST",
  CREATE_BASKET_SUCCESS: "CREATE_BASKET_SUCCESS",
  CREATE_BASKET_FAILURE: "CREATE_BASKET_FAILURE",

  GET_BASKET_REQUEST: "GET_BASKET_REQUEST",
  GET_BASKET_SUCCESS: "GET_BASKET_SUCCESS",
  GET_BASKET_FAILURE: "GET_BASKET_FAILURE",

  TRANSFER_BASKET_REQUEST: "TRANSFER_BASKET_REQUEST",
  TRANSFER_BASKET_SUCCESS: "TRANSFER_BASKET_SUCCESS",
  TRANSFER_BASKET_FAILURE: "TRANSFER_BASKET_FAILURE",

  ADD_PRODUCTS_REQUEST: "ADD_PRODUCTS_REQUEST",
  ADD_PRODUCTS_SUCCESS: "ADD_PRODUCTS_SUCCESS",
  ADD_PRODUCTS_FAILURE: "ADD_PRODUCTS_FAILURE",

  UPDATE_BASKET_REQUEST: "UPDATE_BASKET_REQUEST",
  UPDATE_BASKET_SUCCESS: "UPDATE_BASKET_SUCCESS",
  UPDATE_BASKET_FAILURE: "UPDATE_BASKET_FAILURE",

  UPDATE_PRODUCT_REQUEST: "UPDATE_PRODUCT_REQUEST",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",

  DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",

  ADD_TIP_REQUEST: "ADD_TIP_REQUEST",
  ADD_TIP_SUCCESS: "ADD_TIP_SUCCESS",
  ADD_TIP_FAILURE: "ADD_TIP_FAILURE",

  APPLY_COUPON_REQUEST: "APPLY_COUPON_REQUEST",
  APPLY_COUPON_SUCCESS: "APPLY_COUPON_SUCCESS",
  APPLY_COUPON_FAILURE: "APPLY_COUPON_FAILURE",

  REMOVE_COUPON_REQUEST: "REMOVE_COUPON_REQUEST",
  REMOVE_COUPON_SUCCESS: "REMOVE_COUPON_SUCCESS",
  REMOVE_COUPON_FAILURE: "REMOVE_COUPON_FAILURE",

  RESET_BASKET: "RESET_BASKET",
  DESTROY_BASKET: "DESTROY_BASKET",

  GET_UPSELLS_REQUEST: "GET_UPSELLS_REQUEST",
  GET_UPSELLS_SUCCESS: "GET_UPSELLS_SUCCESS",
  GET_UPSELLS_FAILURE: "GET_UPSELLS_FAILURE",

  SET_CUSTOM_FIELD_REQUEST: "SET_CUSTOM_FIELD_REQUEST",
  SET_CUSTOM_FIELD_SUCCESS: "SET_CUSTOM_FIELD_SUCCESS",
  SET_CUSTOM_FIELD_FAILURE: "SET_CUSTOM_FIELD_FAILURE",
  
  REMOVE_CUSTOM_FIELD_REQUEST: "REMOVE_CUSTOM_FIELD_REQUEST",
  REMOVE_CUSTOM_FIELD_SUCCESS: "REMOVE_CUSTOM_FIELD_SUCCESS",
  REMOVE_CUSTOM_FIELD_FAILURE: "REMOVE_CUSTOM_FIELD_FAILURE",

  RESET_LAST_UPDATE: "RESET_LAST_UPDATE"
};
