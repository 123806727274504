import { newsConstants } from "../_constants";
import { newsService } from "../_services";
import { alertActions } from "./";

export const newsActions = {
  getNews
};

function getNews() {
  return dispatch => {
    dispatch(request());
    newsService.getNews().then(
      news => {
        dispatch(success(news));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: newsConstants.NEWS_REQUEST };
  }
  function success(news) {
    return { type: newsConstants.NEWS_SUCCESS, news };
  }
  function failure(error) {
    return { type: newsConstants.NEWS_FAILURE, error };
  }
}
