import { get, find, includes, some } from "lodash";

export function parseAddressComponents(place) {
  console.log("place", place);

  const { address_components } = place;

  const streetNumber = find(address_components, (ac) => {
    return includes(ac.types, "street_number");
  });
  const address = find(address_components, (ac) => {
    return includes(ac.types, "route");
  });
  //Address the address not specific enough issue with county addresses
  const city = find(address_components, (ac) => {
    return (
      includes(ac.types, "locality") ||
      includes(ac.types, "administrative_area_level_3") || 
      includes(ac.types, "sublocality_level_1" )
    );
  });
  const zip = find(address_components, (ac) => {
    return includes(ac.types, "postal_code");
  });
  const required = [streetNumber, address, city, zip];
  if (some(required, (item) => item === undefined)) {
    throw new Error(
      "Selected address is not specific enough. Please try another."
    );
  }
  return {
    streetaddress: `${get(streetNumber, "short_name")} ${get(
      address,
      "short_name"
    )}`,
    city: get(city, "short_name"),
    zipcode: get(zip, "short_name"),
  };
}
