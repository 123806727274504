import { eventsConstants } from "../_constants";
import { eventsService } from "../_services";
import { alertActions } from "./";

export const eventActions = {
  getEvents
};

function getEvents() {
  return dispatch => {
    dispatch(request());
    eventsService.getEvents().then(
      events => {
        dispatch(success(events));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: eventsConstants.EVENTS_REQUEST };
  }
  function success(events) {
    return { type: eventsConstants.EVENTS_SUCCESS, events };
  }
  function failure(error) {
    return { type: eventsConstants.EVENTS_FAILURE, error };
  }
}
