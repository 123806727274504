import React, { useState, useEffect, useRef } from "react";
import { Header, CholoInput, ChoLoader, Footer } from "../../components";
import { Button, Col, Row, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import infoIcon from "../../assets/images/icon-info.png";
import get from "lodash/get";
import { Redirect } from "react-router-dom";
import ThemedBackArrow from "../../components/ThemedBackArrow";


import { newMemberActions, memberActions, alertActions } from "../../_actions";

import "./styles.scoped.css";
import { passwordRegex } from "../../_constants";
import { useRenameDocument } from "../../context/renameDocument";

const NewPasswordOlo = ({
  dispatch,
  history,
  email,
  loggingIn,
  loggedIn,
  loginDestination,
  passwordUpdated,
  potentialEmail,
}) => {
  const onBackToBag = () => {
    history.push(loginDestination);
  };

  useRenameDocument("New Password");

  const onBackToForgot = () => {
    history.goBack();
  };

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passValid, setPassValid] = useState(false);
  const [pass2Valid, setPass2Valid] = useState(false);

  const [verificationCode, setVerificationCode] = useState("");

  const passwordObj = {
    username: loggedIn ? email : potentialEmail,
    password: password,
    verification_code: verificationCode,
  };

  const handleSubmit = (event) => {
    setShowInputError(true);
    if (passValid) {
      dispatch(memberActions.resetPassword(passwordObj));
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const [showInputError, setShowInputError] = useState(false);

  return loggedIn && passwordUpdated ? (
    <Redirect to={loginDestination} />
  ) : (
    <>
      <AnimatePresence>
        <div className="big-wrap">
          <Header onBagClick={onBackToBag} hideLogin />
          <Col className="little-wrap">
            <div className="back-to-my-bag" onClick={onBackToForgot}>
              <ThemedBackArrow className="icon-back" width="20px"/>
              <p className="back-to-my-bag-text">Back to request code</p>
            </div>
            <div className="col-xs-12 col-sm-10 col-lg-6">
              <div className="checkout">New Password</div>
              <div className="inner-wrap">
                <p
                  style={{
                    fontFamily: "Oswald",
                    color: "black",
                    letterSpacing: 1,
                    fontSize: 16,
                    marginBottom: 0,
                  }}
                >
                  A verification code has been sent to{" "}
                  {loggedIn ? email : potentialEmail}.<br />
                  <br />
                  <div
                    onClick={() =>
                      dispatch(
                        memberActions.forgotPassword({
                          username: loggedIn ? email : potentialEmail,
                        })
                      )
                    }
                  >
                    <p className="resend-code-text">Send new code</p>
                  </div>
                </p>
                <div className="divider" />
                <Form>
                  <CholoInput
                    name="verificationCode"
                    isValid={!showInputError || verificationCode !== ""}
                    errorMessage="Please provide the verification code sent to your email."
                    placeholder="Verification Code"
                    value={verificationCode}
                    onTextChanged={(value) => {
                      let str = value;
                      setVerificationCode(str.replace(/\s/g, ""));
                    }}
                    autoComplete="off"
                  />

                  <CholoInput
                    isValid={!showInputError || passValid}
                    errorMessage="Please provide a password with at least eight characters"
                    className="password"
                    eyeball
                    placeholder="Password"
                    value={password}
                    onTextChanged={(value) => {
                      setPassValid(passwordRegex.test(value));
                      setPassword(value);
                    }}
                    autoComplete="off"
                  />

                  <CholoInput
                    isValid={!showInputError || pass2Valid}
                    errorMessage="Passwords must match"
                    className="password"
                    eyeball
                    placeholder="Password"
                    value={password2}
                    onTextChanged={(value) => {
                      setPass2Valid(passwordRegex.test(value));
                      setPassword2(value);
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    autoComplete="off"
                  />
                </Form>

                <div className="log-line clearfix">
                  <Button
                    className="button pad"
                    id="new_password"
                    onClick={(e) => handleSubmit(e)}
                  >
                    SET NEW PASSWORD
                  </Button>
                </div>
                {!loggingIn && passwordUpdated && (
                  <span className="input-title">
                    <i>Password Updated</i>
                  </span>
                )}
                <div className="divider" />
                <Row className="horizontal-edges">
                  <Col>
                    <Row>
                      <div className="lefty dont-have">Questions?</div>
                      <div className="checkout-as-guest">
                        <a href="tel:7082155674">(708) 215-5674</a>
                      </div>
                    </Row>
                  </Col>
                  <Col />
                </Row>
              </div>
            </div>

            {loggingIn && <ChoLoader />}
          </Col>
          <Footer />
        </div>
      </AnimatePresence>
    </>
  );
};

function mapStateToProps(state) {
  const {
    loggingIn,
    loggedIn,
    error,
    signinError,
    passwordUpdated,
  } = state.authentication;
  const { member, newMember } = state;
  const newLoading = get(newMember, "loading", false);
  const potentialEmail = get(newMember, "data.potentialEmail", "");
  const email = get(member, "data.email", "");
  const potentialResponse = get(newMember, "paymentUrl", "");
  const loginDestination = get(member, "loginDestination");

  return {
    email,
    newLoading,
    potentialEmail,
    loggingIn,
    loggedIn,
    error,
    signinError,
    loginDestination,
    potentialResponse,
    passwordUpdated,
  };
}

const connectedNewPasswordOlo = withRouter(
  connect(mapStateToProps)(NewPasswordOlo)
);
export { connectedNewPasswordOlo as NewPasswordOlo };
