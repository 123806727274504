import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import size from "lodash/size";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import formatSfDateTime from "../../_helpers/format-sf-datetime";
import "./styles.css";

const RewardsHistory = ({ rewards }) => {
  const rewardsHistory = get(rewards, "memberRewardHistory", []);
  return size(rewardsHistory, 0) === 0 ? (
    <div>
      <Row>
        <Col md={12}>
          <h2 className="log-title">Rewards History</h2>
        </Col>

        <Col md={12}>
          <Table>
            <thead>
              <tr>
                <td className="like-th">There is no reward history to display.</td>
              </tr>
            </thead>
          </Table>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="log-title">Rewards History</h2>
        </Col>

        <Col md={10}>
          <Table borderless size="sm" className="my-md-4">
            <thead>
              <tr>
                <td className="like-th">Reward</td>
                <td className="like-th">Date</td>
                <td className="like-th">Location</td>
              </tr>
            </thead>
            <tbody>
              {rewardsHistory.map((reward, index) => (
                <tr key={index}>
                  <td>{reward.name}</td>
                  {reward.status === "Redeemed" ? (
                    <td>
                      {formatSfDateTime(reward.redemptionDate)} ({reward.status})
                    </td>
                  ) : (
                    <td>
                      {formatSfDateTime(reward.endDate)} ({reward.status})
                    </td>
                  )}
                  {reward.redemptionLocationName === null ? (
                    <td> N/A</td>
                  ) : (
                    <td>{reward.redemptionLocationName}</td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <br />
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { rewards } = state;
  return {
    rewards: rewards.data,
  };
}

const connectedRewardsHistory = connect(mapStateToProps)(RewardsHistory);
export { connectedRewardsHistory as RewardsHistory };
