import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { memberActions } from "../../_actions";
import { Button } from "react-bootstrap";
import secureStorage from "../../_helpers/secureStorage";
import { get, size } from "lodash";
import "./styles.scoped.css";

const MembershipSelector = ({
  dispatch,
  value,
  contacts,
  containerClass = "",
  memberNumber,
  bottlesAvailable,
  nextRewardPoints,
  memberStatus,
  contactId,
  onContinue,
}) => {
  const [selectedItem, setSelectedItem] = useState(contacts[0]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (contacts && contactId) {
      setSelectedItem(contacts.find((contact) => contact.Id === contactId));
    }
  }, [contacts, contactId]);

  return (
    <>
      {(memberStatus === "Active" || memberStatus === "New") && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div className="member-detail">MEMBER</div>
              <div className="member-detail">NUMBER</div>
              <div className="member-detail-heavy" style={{ lineHeight: 3 }}>
                {memberNumber}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div className="member-detail">AVAILABLE</div>
              <div className="member-detail">
                BOTTLE{bottlesAvailable !== 1 ? "S" : ""}
              </div>
              <span className="member-loyalty-heavy">{bottlesAvailable}</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div className="member-detail">POINTS TO</div>
              <div className="member-detail">NEXT REWARD</div>

              <div className="member-loyalty-heavy">{nextRewardPoints}</div>
            </div>
          </div>
        </>
      )}

      <div className={`input-field ${containerClass}`}>
        <input
          aria-lanel="Select Membership"
          value={selectedItem && selectedItem.CHW_Member_Number__c}
          onClick={() => setIsOpen(!isOpen)}
          readOnly
        />
        <span className={`caret ${isOpen ? "caret-up" : "caret-down"}`} />
        <div className={`select-content${isOpen ? " open" : ""}`}>
          {contacts.slice(1).map((item) => (
            <div
              key={item.CHW_Member_Number__c}
              className={`item${
                item.CHW_Member_Number__c === value ? " selected" : ""
              }`}
              onClick={() => {
                dispatch(memberActions.switchMember(item.Id));
                setIsOpen(false);
              }}
            >
              {`${item.CHW_Member_Number__c}`}
            </div>
          ))}
        </div>
      </div>
      <p
        style={{
          color: "#242423",
          fontFamily: "Oswald",
          fontSize: 18,
          fontWeight: 300,
          letterSpacing: 1.25,
          textAlign: "left",
          margin: 5,
        }}
      >
        Please select which membership to use
      </p>
      <Button className="button" onClick={onContinue}>
        CONTINUE
      </Button>
    </>
  );
};

function mapStateToProps(state) {
  const { member, rewards } = state;
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const contacts = get(member, "contacts", []);
  const contactId = get(user, "id", "");
  const memberData = get(member, "data", {});
  const rewardData = get(rewards, "data", {});
  const firstName = get(memberData, "firstName", "Member");
  const memberNumber = get(memberData, "memberNumber", "");
  const pointsTotal = get(memberData, "activePoints", 0);
  const bottlesAvailable = get(memberData, "openOnlineBottles", 0);
  const nextRewardPoints = 350 - pointsTotal;
  const currentRewards = get(rewardData, "activeMemberRewards", []);
  const rewardsAvailable = size(currentRewards, 0);
  const membershipType = get(memberData, "membershipType", "Marketing");
  const memberStatus = get(memberData, "status", "");

  return {
    contactId,
    contacts: [{ CHW_Member_Number__c: "Select" }, ...contacts],
    memberData,
    firstName,
    memberNumber,
    bottlesAvailable,
    nextRewardPoints,
    rewardsAvailable,
    membershipType,
    memberStatus,
  };
}

const connectedMembershipSelector = connect(mapStateToProps)(
  MembershipSelector
);
export { connectedMembershipSelector as MembershipSelector };
