import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import LoadingSpinners from "../../components/LoadingSpinners";
import { adminActions, memberActions } from "../../_actions";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const Admin = ({ isAdmin, searchResults, searching, dispatch, history }) => {
  const [email, setEmail] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [phone, setPhone] = useState("");
  const [card, setCard] = useState("");
  const [searched, setSearched] = useState(false);

  const searchForContact = async () => {
    setSearched(false);
    dispatch(adminActions.clearResults());
    await dispatch(
      adminActions.searchForContact(email, first, last, phone, card)
    );

    setSearched(true);
  };
  const clear = async () => {
    dispatch(adminActions.clearResults());
    setSearched(false);
    setEmail("");
    setFirst("");
    setLast("");
    setPhone("");
    setCard("");
  }

  return isAdmin ? (
    <div className="container main">
      <div className="tinted-container">
        <Tabs className="ch-tabs visibledesktop">
          <Tab eventKey={0} title="Search" id="search">
            <Form noValidate validated={false}>
              <Col lg={12}>
                <h4>Search for Member</h4>

                <Form.Group as={Row} className="input-group">
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      value={first}
                      onInput={e => setFirst(e.target.value)}
                      id="first"
                      placeholder="First Name"
                      required={false}
                      type="text"
                      isInvalid={false}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          searchForContact();
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      value={last}
                      onInput={e => setLast(e.target.value)}
                      id="last"
                      placeholder="Last Name"
                      required={false}
                      type="text"
                      isInvalid={false}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          searchForContact();
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      value={email}
                      onInput={e => setEmail(e.target.value)}
                      id="email"
                      placeholder="Email"
                      required={false}
                      type="email"
                      isInvalid={false}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          searchForContact();
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      value={phone}
                      onInput={e => setPhone(e.target.value)}
                      id="phone"
                      placeholder="Phone"
                      required={false}
                      type="text"
                      isInvalid={false}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          searchForContact();
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Member Number</Form.Label>
                    <Form.Control
                      value={card}
                      onInput={e => setCard(e.target.value)}
                      id="memNum"
                      placeholder="Card Number"
                      required={false}
                      type="text"
                      isInvalid={false}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          searchForContact();
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </Form.Group>
                {!isEmpty(searchResults) && (
                  <>
                    <div
                      className="visible-desktop"
                      style={{ paddingRight: "2em" }}
                    >
                      <Row
                        style={{ width: "100%", padding: "0.5em" }}
                      >
                        <Col style={{ color: "var(--member-site-gold)" }} className="col-3">
                          Name
                        </Col>
                        <Col style={{ color: "var(--member-site-gold)" }} className="col-2">
                          Email
                        </Col>
                        <Col style={{ color: "var(--member-site-gold)" }} className="col-2">
                          Phone
                        </Col>
                        <Col style={{ color: "var(--member-site-gold)" }} className="col-2">
                          Member #
                        </Col>
                        <Col style={{ color: "var(--member-site-gold)" }} className="col-2">
                          Status
                        </Col>
                      </Row>
                      {searchResults.map(result => (
                        <Row
                          key={result.Id}
                          style={{ width: "100%", padding: "0.5em" }}
                        >
                          <Col style={{ color: "white" }} className="col-3">
                            {result.FirstName} {result.LastName}
                          </Col>
                          <Col style={{ color: "white" }} className="col-2">
                            {result.Email}
                          </Col>
                          <Col style={{ color: "white" }} className="col-2">
                            {result.Phone}
                          </Col>
                          <Col style={{ color: "white" }} className="col-2">
                            {result.CHW_Member_Number__c}
                          </Col>
                          <Col style={{ color: "white" }} className="col-2">
                            {result.CHW_Status__c}
                          </Col>
                          <Col className="col-1">
                            <Button
                              variant="secondary"
                              onClick={() => {
                                dispatch(memberActions.switchMember(result));
                                history.push("/main");
                              }}
                            >
                              Impersonate
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </div>
                    <div className="visible-device">
                      <h5 style={{ color: "white" }}>Results:</h5>
                      {searchResults.map(result => (
                        <Card.Body
                          px={0}
                          key={result.Id}
                          className="preorder-card"
                        >
                          <Row style={{ width: "100%", padding: "0.5em" }}>
                            <Col>
                              <Card.Text
                                align="left"
                                className="preorder-details"
                              >
                                {result.FirstName + " " + result.LastName}
                              </Card.Text>
                              <Card.Text>{result.Email}</Card.Text>
                              <Card.Text>{result.Phone}</Card.Text>
                              <Card.Text>
                                {result.CHW_Member_Number__c}
                              </Card.Text>
                            </Col>
                            <Col className="col-3">
                              <Button
                                variant="secondary"
                                onClick={() => alert(result.Id)}
                              >
                                Impersonate
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      ))}
                    </div>
                  </>
                )}
                {searched && isEmpty(searchResults) && !searching && (
                  <h5 style={{ color: "white" }}>No Results...</h5>
                )}
                {searching && <LoadingSpinners />}
                <br />
                <br />
                <div className="float-right">
                  <Button
                    disabled={searching}
                    style={{ marginRight: "3em" }}
                    className="btn btn-primary"
                    onClick={() => clear()}
                    type="button"
                  >
                    {searching ? "Searching" : "Clear"}
                  </Button>
                  <Button
                    disabled={searching}
                    style={{ marginRight: "3em" }}
                    className="btn btn-primary"
                    onClick={() => searchForContact()}
                    type="button"
                  >
                    {searching ? "Searching" : "Search"}
                  </Button>
                </div>
              </Col>
            </Form>
          </Tab>
        </Tabs>
      </div>
    </div>
  ) : (
    <Redirect to="/main" />
  );
};

function mapStateToProps(state) {
  const { adminSearch, member } = state;
  const isAdmin = get(member, "isAdmin", false);
  const searchResults = get(adminSearch, "data", []);
  const searching = get(adminSearch, "loading", []);
  return {
    isAdmin,
    searching,
    searchResults
  };
}

const connectedAdmin = connect(mapStateToProps)(Admin);
export { connectedAdmin as Admin };
