export const barrelConstants = {
  BARREL_COST_REQUEST: "BARREL_COST_REQUEST",
  BARREL_COST_SUCCESS: "BARREL_COST_SUCCESS",
  BARREL_COST_FAILURE: "BARREL_COST_FAILURE",

  BARREL_LIST_REQUEST: "BARREL_LIST_REQUEST",
  BARREL_LIST_SUCCESS: "BARREL_LIST_SUCCESS",
  BARREL_LIST_FAILURE: "BARREL_LIST_FAILURE",

  BARREL_ORDER_REQUEST: "BARREL_ORDER_REQUEST",
  BARREL_ORDER_SUCCESS: "BARREL_ORDER_SUCCESS",
  BARREL_ORDER_FAILURE: "BARREL_ORDER_FAILURE"
};
