import React, { useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { basketsActions } from "../../_actions";
import get from "lodash/get";
import littleBottle from "../../assets/images/icon-little-bottle.png";
import closeIcon from "../../assets/images/close@2x.png";

const ConvertedWineItem = ({ item, dispatch, basketData }) => {
  const [removingItemIdex, setRemovingItemIdex] = useState(-1);

  const onRemoving = (index) => {
    setRemovingItemIdex(index);
  };

  const removeItem = async (item) => {
    await dispatch(basketsActions.deleteProduct(basketData.id, item.id));
    setRemovingItemIdex(-1);
  };

  return (
    <>
      <div style={{ backgroundColor: "#dedbd6", height: 2 }} />
      <div className="conversion-item">
        <div className="use-bottles">
          <img src={littleBottle} className="little-bottle" alt="bottle icon" />
          WINE CLUB BOTTLE APPLIED
          <img
            src={closeIcon}
            alt="close icon"
            className="little-close-icon"
            onClick={() =>
              dispatch(basketsActions.deleteProduct(basketData.id, item.id))
            }
          />
        </div>

        <span className="converted-name">
          {item.quantity} {item.name}
          <div className="included-txt">
            {item.totalcost > 0
              ? `$${item.totalcost}`
              : "Included in membership"}
          </div>
        </span>
      </div>
      <div style={{ backgroundColor: "#dedbd6", height: 2 }} />
    </>
  );
};

function mapStateToProps(state) {
  const { basket, member } = state;
  const basketData = get(basket, "data", {});
  const memberData = get(member, "data", {});
  return { basketData, memberData };
}

const connectedConvertedWineItem = connect(mapStateToProps)(ConvertedWineItem);
export { connectedConvertedWineItem as ConvertedWineItem };
