import { appConstants } from "../_constants";
import { memberActions } from "../_actions";
import secureStorage from "../_helpers/secureStorage";
import get from "lodash/get";

export const paymentService = {
  getPaymentForm,
  postPayment,
  getOloPaymentForm,
  getPaymentMethods,
  removePayment,
  logout,
  chargeMembership,
};

async function getPaymentForm(amount, origin, storeCard, transactionType) {
  var ls = new secureStorage();
  const contact = JSON.parse(ls.get("contact"));
  const location = get(contact, "CHW_Location__c", "");
  const user = JSON.parse(ls.get("user"));
  const id = get(user, "id", "");
  const token = get(user, "cognito_token", "");

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": token
    },
    body: JSON.stringify({
      "Transaction-Type": transactionType,
      "Amount": amount,
      "Contact-Id": id,
      "Return-URL": `${appConstants.paymentReturnUrl}blank.htm`,
      "Payment-Origin": origin,
      "Payment-Location": location,
      "Store-Card": storeCard
    })
  };

  return await fetch(
    `${appConstants.localApiUrl}members/member/payment-form`,
    requestOptions
  ).then(handleResponse);
}

async function getOloPaymentForm(id, firstName, lastName) {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");

  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authorization": token
    },
    body: JSON.stringify({
      "Contact-Id": id,
      "First-Name": firstName,
      "Last-Name": lastName,
      "Return-URL": `${appConstants.paymentReturnUrl}blank.htm`
    })
  };

  return await fetch(
    `${appConstants.localApiUrl}members/member/olo-payment-form`,
    requestOptions
  ).then(handleResponse);
}

async function postPayment(paymentObj) {
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json"
    },
    body: JSON.stringify(paymentObj)
  };

  const queryString = Object.keys(paymentObj)
    .map(key => key + "=" + paymentObj[key])
    .join("&");

  return await fetch(
    `${appConstants.localApiUrl}members/storePayment?${queryString}`,
    requestOptions
  ).then(handleResponse);
}

async function getPaymentMethods() {
  try {
    const ls = new secureStorage();
    const authToken = ls.get("oat");

    if (!authToken) {
      return Promise.reject(new Error("No OLO auth token"));
    }
    return fetch(`${appConstants.oloApiUrl}/users/${authToken}/billingaccounts`);
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Remove payment token from Salesforce
 */
async function removePayment(contactId) {
  try {
    var ls = new secureStorage();
    const user = JSON.parse(ls.get("user"));
    const token = get(user, "cognito_token", "");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization": token
      },
      body: JSON.stringify({ contactId }),
    };
    return fetch(
      `${appConstants.localApiUrl}members/member/remove-card`,
      requestOptions
    );
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Charge the user's card on file
 */
async function chargeMembership(contactId, amount, location) {
  try {
    var ls = new secureStorage();
    const user = JSON.parse(ls.get("user"));
    const token = get(user, "cognito_token", "");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization": token
      },
      body: JSON.stringify({ contactId, amount: amount, location }),
    };
    return fetch(
      `${appConstants.localApiUrl}members/tokenPayment`,
      requestOptions
    );
  } catch (e) {
    return Promise.reject(e);
  }
}

function logout() {
  return dispatch => {
    dispatch(memberActions.logout());
  }
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // eslint-disable-next-line no-restricted-globals
        location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
