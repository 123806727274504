export const stateOptions = [
  { name: "Select State", value: "", id: "", disabled: "disabled", abbreviation: "default"},
  { name: "Alabama", value: "Alabama", id: "1", abbreviation: "AL"},
  { name: "Alaska", value: "Alaska", id: "2", abbreviation: "AK"},
  { name: "Arizona", value: "Arizona", id: "3", abbreviation: "AZ"},
  { name: "Arkansas", value: "Arkansas", id: "4", abbreviation: "AR"},
  { name: "California", value: "California", id: "5", abbreviation: "CA"},
  { name: "Colorado", value: "Colorado", id: "6", abbreviation: "CO"},
  { name: "Connecticut", value: "Connecticut", id: "7", abbreviation: "CT"},
  { name: "Delaware", value: "Delaware", id: "8", abbreviation: "DE"},
  { name: "District of Columbia", value: "District of Columbia", id: "51", abbreviation: "DC"},
  { name: "Florida", value: "Florida", id: "9", abbreviation: "FL"},
  { name: "Georgia", value: "Georgia", id: "10", abbreviation: "GA"},
  { name: "Hawaii", value: "Hawaii", id: "11", abbreviation: "HI"},
  { name: "Idaho", value: "Idaho", id: "12", abbreviation: "ID"},
  { name: "Illinois", value: "Illinois", id: "13", abbreviation: "IL"},
  { name: "Indiana", value: "Indiana", id: "14", abbreviation: "IN"},
  { name: "Iowa", value: "Iowa", id: "15", abbreviation: "IA"},
  { name: "Kansas", value: "Kansas", id: "16", abbreviation: "KS"},
  { name: "Kentucky", value: "Kentucky", id: "17", abbreviation: "KY"},
  { name: "Louisiana", value: "Louisiana", id: "18", abbreviation: "LA"},
  { name: "Maine", value: "Maine", id: "19", abbreviation: "ME"},
  { name: "Maryland", value: "Maryland", id: "20", abbreviation: "MD"},
  { name: "Massachusetts", value: "Massachusetts", id: "21", abbreviation: "MA"},
  { name: "Michigan", value: "Michigan", id: "22", abbreviation: "MI"},
  { name: "Minnesota", value: "Minnesota", id: "23", abbreviation: "MN"},
  { name: "Mississippi", value: "Mississippi", id: "24", abbreviation: "MS"},
  { name: "Missouri", value: "Missouri", id: "25", abbreviation: "MO"},
  { name: "Montana", value: "Montana", id: "26", abbreviation: "MT"},
  { name: "Nebraska", value: "Nebraska", id: "27", abbreviation: "NE"},
  { name: "Nevada", value: "Nevada", id: "28", abbreviation: "NV"},
  { name: "New Hampshire", value: "New Hampshire", id: "29", abbreviation: "NH"},
  { name: "New Jersey", value: "New Jersey", id: "30", abbreviation: "NJ"},
  { name: "New Mexico", value: "New Mexico", id: "31", abbreviation: "NM"},
  { name: "New York", value: "New York", id: "32", abbreviation: "NY"},
  { name: "North Carolina", value: "North Carolina", id: "33", abbreviation: "NC"},
  { name: "North Dakota", value: "North Dakota", id: "34", abbreviation: "ND"},
  { name: "Ohio", value: "Ohio", id: "35", abbreviation: "OH"},
  { name: "Oklahoma", value: "Oklahoma", id: "36", abbreviation: "OK"},
  { name: "Oregon", value: "Oregon", id: "37", abbreviation: "OR"},
  { name: "Pennsylvania", value: "Pennsylvania", id: "38", abbreviation: "PA"},
  { name: "Rhode Island", value: "Rhode Island", id: "39", abbreviation: "RI"},
  { name: "South Carolina", value: "South Carolina", id: "40", abbreviation: "SC"},
  { name: "South Dakota", value: "South Dakota", id: "41", abbreviation: "SD"},
  { name: "Tennessee", value: "Tennessee", id: "42", abbreviation: "TN"},
  { name: "Texas", value: "Texas", id: "43", abbreviation: "TX"},
  { name: "Utah", value: "Utah", id: "44", abbreviation: "UT"},
  { name: "Vermont", value: "Vermont", id: "45", abbreviation: "VT"},
  { name: "Virginia", value: "Virginia", id: "46", abbreviation: "VA"},
  { name: "Washington", value: "Washington", id: "47", abbreviation: "WA"},
  { name: "West Virginia", value: "West Virginia", id: "48", abbreviation: "WV"},
  { name: "Wisconsin", value: "Wisconsin", id: "49", abbreviation: "WI"},
  { name: "Wyoming", value: "Wyoming", id: "50", abbreviation: "WY" }
];
