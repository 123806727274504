import { productsConstants } from "../_constants";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

export function modifiers(state = { loading: false }, action) {
  switch (action.type) {
    case productsConstants.MODIFERS_REQUEST:
    case productsConstants.SELECT_MODIFER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productsConstants.MODIFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.modifiers,
      };
    case productsConstants.MODIFERS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case productsConstants.SELECT_MODIFER_SUCCESS:
      const { data } = state;
      const newData = cloneDeep(data);
      const optiongroups = get(data, "optiongroups", []);
      const groupIndex = optiongroups.findIndex(
        (group) => group.id === action.ids.groupId
      );
      if(groupIndex>-1) {
      if(newData.optiongroups[groupIndex].mandatory || (!newData.optiongroups[groupIndex].mandatory && newData.optiongroups[groupIndex].maxselects ==="1")){
        newData.optiongroups[groupIndex].options.forEach(option => {
          option.selected = option.id === action.ids.optionId;
          if(option.children) {
            option.modifiers.forEach(modifier => {
              if(modifier.mandatory || (!modifier.mandatory && modifier.maxselects ==="1")) {
                modifier.selected = modifier.id === action.ids.optionId
              }
            })
          }
        });
      } else {
        newData.optiongroups[groupIndex].options.forEach(option => {
          if(option.id === action.ids.optionId) {
            option.selected = !option.selected;
            if(option.children) {
              option.modifiers.forEach(modifier => {
                modifier.selected = modifier.id === action.ids.optionId
              })
            }
          }
        });
      };
    }
    else {
      newData.optiongroups.forEach(group => {
       group.options.filter(option => option.children).forEach(child => {
        child.modifiers.filter(mod => mod.id === action.ids.groupId).forEach(xtra => {
          xtra.options.forEach(op => {
            if(op.mandatory || (!op.mandatory && op.maxselects ==="1")) {
              op.selected = op.id === action.ids.optionId
            } else {
              op.selected = !op.selected;
            }
          })
        })
      })
    })
  }
      
      return {
        ...state,
        loading: false,
        data: {...newData},
      };

    default:
      return state;
  }
}
