import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingSpinners from "../LoadingSpinners";
import UIButton from "../UIButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { newMemberActions } from "../../_actions";
import { stateOptions } from "../../_helpers/state-options";
import { appConstants } from "../../_constants";
import isEmpty from "lodash/isEmpty";
import FadeIn from "react-fade-in";

import get from "lodash/get";

import "./styles.css";

const BillingInfo = ({
  active,
  loading,
  dispatch,
  billingStreet,
  billingCity,
  billingZipCode,
  billingFirstName,
  billingLastName,
  billingEmail,
  billingPhone,
  billingState,
  pricingStructure,
  shippingStreet,
  shippingCity,
  shippingZipCode,
  shippingFirstName,
  shippingLastName,
  shippingEmail,
  shippingState,
  deliveryType,
  clubType,
  bottleLevel,
  giftLength,
  shippingAsBilling,
  stepForward,
  stepBack,
  formValid,
  sendTo,
  showBilling,
  handleCollapse,
  reCaptchaRef,
  reCaptchaError,
  setReCaptchaError,
  innerWidth,
}) => {
  const pricingString = `Gift:${deliveryType}:${clubType}:${bottleLevel}:${giftLength}`;

  const [validated, setValidated] = useState(false);
  const [billingValid, setBillingValid] = useState(false);
  const isDelivery = (deliveryType === 'Delivery');
  let formElement;

  useEffect(() => {
    const billingFilled = (shippingAsBilling) ?
      !isEmpty(shippingFirstName) &&
      !isEmpty(shippingLastName) &&
      !isEmpty(shippingStreet) &&
      !isEmpty(shippingCity) &&
      !isEmpty(shippingState) &&
      !isEmpty(shippingZipCode)
    :
      !isEmpty(billingFirstName) &&
      !isEmpty(billingLastName) &&
      !isEmpty(billingStreet) &&
      !isEmpty(billingCity) &&
      !isEmpty(billingState) &&
      !isEmpty(billingZipCode);

    setValidated(billingFilled);
    setBillingValid(formElement.checkValidity());
  }, [
    shippingAsBilling,
    shippingFirstName,
    shippingLastName,
    shippingStreet,
    shippingCity,
    shippingState,
    shippingZipCode,
    billingStreet,
    billingCity,
    billingZipCode,
    billingFirstName,
    billingLastName,
    billingEmail,
    billingPhone,
    billingState,
  ]);

  const canAdvance = () => {
    return billingValid;
  };

  if (loading) {
    return <LoadingSpinners />;
  }

  const formControlStyle = {
  backgroundColor: 'white',
  color: 'black',
  borderColor: '#000',
  borderRadius: 10,
}

  return (
    <div className={`${active ? 'signup-active' : 'inactive'}`}>
      <h4 className="section-header">3. Billing Information</h4>
      <Collapse in={active && showBilling} onExited={handleCollapse}>
        <div id="collapse-div">
          <FadeIn>
            <div
              className="container"
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <Form
                noValidate
                validated={validated}
                ref={(element) => (formElement = element)}
                style={{ fontFamily: "Oswald", fontSize: 18 }}
              >
                <Form.Group as={Row} className="py-2 mb-0">
                  <div style={{ marginLeft: ".85em" }} />
                  <Form.Label className="text pb-1" style={{ fontSize: 16, color: 'black' }} for="same-as-shipping">
                    <h3 className='text' style={{color: 'var(--wineclub-signup-gold'}}>

                    Home Address
                    </h3>
                  </Form.Label>
                  {sendTo === "me" && ( 
                    <>
                      <Form.Check
                        id="same-as-shipping"
                        style={{ margin: ".25em 0 0 1.5em" }}
                        type="checkbox"
                        checked={shippingAsBilling}
                        onChange={() => {
                          dispatch(
                            newMemberActions.setShippingAsBilling(
                              !shippingAsBilling
                              )
                              );
                            }}
                        name="shippingAsBilling"
                        tabIndex={0}
                      />
                      <div className="summary-text" style={{
                        padding: ".5em 0 0 .25em",
                          color: 'black'
                        }}
                      >
                        Same as shipping
                      </div>
                    </>
                  )}
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={6}>
                    <Form.Label className="input-label" for="first-name-gift">First Name*</Form.Label>
                    <Form.Control
                    style={formControlStyle}
                      value={
                        shippingAsBilling ? shippingFirstName : billingFirstName
                      }
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingFirstName(e.target.value)
                        )
                      }
                      type="text"
                      id="first-name-gift"
                      name="recipientFirstName"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid first name.
                    </Form.Control.Feedback>
                  </Col>

                  <Col sm={6}>
                    <Form.Label className="input-label" for="last-name-gift">Last Name*</Form.Label>
                    <Form.Control
                    style={formControlStyle}
                      value={
                        shippingAsBilling ? shippingLastName : billingLastName
                      }
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingLastName(e.target.value)
                        )
                      }
                      id="last-name-gift"
                      type="text"
                      name="recipientLastName"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid last name.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={12}>
                    <Form.Label className="input-label" for="street-gift">Street*</Form.Label>
                    <Form.Control
                    style={formControlStyle}
                      type="input"
                      value={
                        shippingAsBilling && isDelivery ? shippingStreet : billingStreet || ""
                      }
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingStreet(e.target.value)
                        )
                      }
                      id="street-gift"
                      name="street"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid street.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={6}>
                    <Form.Label className="input-label" for="city-gift">City*</Form.Label>
                    <Form.Control
                    style={formControlStyle}
                      value={
                        shippingAsBilling && isDelivery ? shippingCity : billingCity || ""
                      }
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingCity(e.target.value)
                        )
                      }
                      id="city-gift"
                      type="text"
                      name="city"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid city.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={3}>
                    <Form.Label className="input-label" for="state-gift">State*</Form.Label>
                    <div className="form-control-select-custom-border"/>
                    <Form.Control
                    style={{...formControlStyle, position: 'relative', bottom: 38, borderColor: 'transparent', borderRightWidth: 10}}
                      as="select"
                      id="state-gift"
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingState(e.target.value)
                        )
                      }
                      value={
                        shippingAsBilling && isDelivery ? shippingState : billingState || ""
                      }
                      required={true}
                    >
                      {stateOptions.map((state) => (
                        <option key={state.id} value={state.value}>
                          {state.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" className="shift-up">
                      Please provide a valid state.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={3} style={innerWidth <= 576 ? {position: 'relative', bottom: 32} : {}}>
                    <Form.Label className="input-label" for="zipcode-gift">Zip Code*</Form.Label>
                    <Form.Control
                    style={formControlStyle}
                      pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
                      type="input"
                      id="zipcode-gift"
                      onChange={(e) =>
                        dispatch(newMemberActions.setBillingZip(e.target.value))
                      }
                      value={
                        shippingAsBilling && isDelivery ? shippingZipCode : billingZipCode || ""
                      }
                      name="zipCode"
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid zip code.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} style={{position: 'relative', bottom: 32}}>
                  <Col sm={6}>
                    <Form.Label className="input-label" for="email-gift">Email*</Form.Label>
                    <Form.Control
                    style={formControlStyle}
                      value={shippingAsBilling ? shippingEmail : billingEmail}
                      onChange={(e) =>
                        dispatch(
                          newMemberActions.setBillingEmail(e.target.value)
                        )
                      }
                      id="email-gift"
                      type="text"
                      name="email"
                      required={true}
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={6}>
                    <Form.Label className="input-label" for="phone-number-gift">Phone Number*</Form.Label>
                    <Form.Control
                    style={formControlStyle}
                      value={billingPhone}
                      onChange={(e) => {
                        let value = e.target.value;
                        let x = value
                          .replace(/\D/g, "")
                          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        value = !x[2]
                          ? x[1]
                          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                        dispatch(
                          newMemberActions.setBillingPhone(value)
                        );
                      }}
                      type="tel"
                      name="billingPhone"
                      id="phone-number-gift"
                      required={true}
                      pattern="^\D*(\d\D*){10}$"
                      // pattern="^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                      />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid phone number.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div style={{position: 'relative', marginTop: -38}}>
                <div className="inactive">
                  <div className="membership-cost">
                    <h6 >COST OF MEMBERSHIP </h6>
                    <h5>${pricingStructure[pricingString]}</h5>
                  </div>
                </div>
                <div className="mt-1" style={{color: "black"}}>
                  By clicking Proceed to Payment, you are accepting the&nbsp;
                  <a href="https://chwinery.com/wine-club/terms-conditions">Terms and Conditions</a> of the Cooper's Hawk Wine Club and our&nbsp; 
                  <a href="https://chwinery.com/privacy-policy">Privacy Policy</a>.
                </div>
                
                <div id="giftReCaptchaContainer">
                  {/* Don't agree with removing this. Bad UX experience
                  <Col md={2}>
                    <UIButton text="BACK" onSelect={stepBack} />
                    </Col>*/}
                  <div id="giftReCaptchaInnerContainer" style={{marginBottom: "1rem"}}>
                    <div className={`reCaptchaContainer ${reCaptchaError && "reCaptchaContainerError"}`}>
                      <ReCAPTCHA
                        sitekey={appConstants.reCaptchaSiteKeyMUI}
                        ref={reCaptchaRef}
                        theme="light"
                        onChange={(value) => {
                          if(value) {
                            setReCaptchaError("");
                          }
                        }}
                      />
                    </div>
                    {reCaptchaError && (
                      <div className="reCaptchaErrorMessage" id="giftReCaptchaErrorMessage">
                        {reCaptchaError}
                      </div>
                    )}
                  </div>
                  <Col sm={4}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="proceed-to-payment-button" onPointerDown={() => canAdvance() && stepForward()} style={canAdvance() ? {} : {backgroundColor: 'transparent'}}>
                      <div className="proceed-to-payment-text" style={canAdvance() ? {} : {color: 'gray'}}>
                        PROCEED TO PAYMENT
                      </div>
                    </div>
                    </div>
                  </Col>
                  </div>
                </div>
              </Form>
            </div>
          </FadeIn>
        </div>
      </Collapse>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { member, newMember, staticContent, paymentForm } = state;
  const shippingAsBilling = get(newMember, "data.shippingAsBilling", false);
  const url = get(paymentForm, "url", "");
  const loading = get(paymentForm, "loading", false);

  const memberSignatureOptOut = get(
    member,
    "data.memberSignatureOptOut",
    false
  );
  const deliveryType = get(newMember, "data.deliveryType", "");
  const giftLength = get(newMember, "data.giftLength", "");
  const sendTo = get(newMember, "data.sendTo", "me");
  const shipDate = get(
    newMember,
    "data.shipDate",
    new Date().toLocaleDateString()
  );

  const clubType = get(newMember, "data.clubType", "Red");
  const bottleLevel = get(newMember, "data.bottleLevel", "");

  const billingAddress = get(newMember, "data.billingAddress", "");
  const billingFirstName = get(billingAddress, "firstName", "");
  const billingLastName = get(billingAddress, "lastName", "");
  const billingEmail = get(billingAddress, "email", "");
  const billingPhone = get(billingAddress, "phone", "");
  const billingStreet = get(billingAddress, "street", "");
  const billingCity = get(billingAddress, "city", "");
  const billingState = get(billingAddress, "state", "");
  const billingZipCode = get(billingAddress, "zipCode", "");

  const shippingAddress = get(newMember, "data.shippingAddress", "");
  const shippingFirstName = get(shippingAddress, "firstName", "");
  const shippingLastName = get(shippingAddress, "lastName", "");
  const shippingEmail = get(shippingAddress, "email", "");
  const shippingPhone = get(shippingAddress, "phone", "");
  const shippingStreet = get(shippingAddress, "street", "");
  const shippingCity = get(shippingAddress, "city", "");
  const shippingState = get(shippingAddress, "state", "");
  const shippingZipCode = get(shippingAddress, "zipCode", "");

  const optOutText = get(staticContent, "data.messages.memberSignature", "");
  const pricingStructure = get(staticContent, "data.pricingStructure", {});

  return {
    memberSignatureOptOut,
    optOutText,
    pricingStructure,
    sendTo,
    shipDate,
    url,
    loading,
    billingEmail,
    billingPhone,
    billingFirstName,
    billingLastName,
    billingStreet,
    billingCity,
    billingZipCode,
    billingState,
    shippingAsBilling,
    shippingEmail,
    shippingPhone,
    shippingFirstName,
    shippingLastName,
    shippingStreet,
    shippingCity,
    shippingZipCode,
    shippingState,
    deliveryType,
    clubType,
    bottleLevel,
    giftLength,
  };
}

const connectedBillingInfo = withRouter(connect(mapStateToProps)(BillingInfo));
export { connectedBillingInfo as BillingInfo };
