import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import "./styles.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import secureStorage from "../../_helpers/secureStorage";

const PotentialMemberLanding = ({ firstName }) => {
  return (
    <Row className="justify-content-center p-4">
      <Col xs={12} sm={10} lg={7} className="rounded bg-light p-4">
        <h2>Welcome back, {firstName}</h2>
        <h6 className="desc mt-4 mb-4">
          Sign up today for a Wine Club unlike any other! Discover a different
          handcrafted wine each month, and enjoy discounts, dining rewards,
          exclusive events and more.
        </h6>
        <br />

        <Row className="justify-content-center">
          <Col md={6}>
            <a
              href="https://chwinery.com/wine-club/overview"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="secondary-btn">LEARN MORE</Button>
            </a>
          </Col>
          <Col md={6}>
            <Link to="/NewMonthlyMemberSignUp">
              {/* <Link to="/MemberUpgrade"> */}
              <Button className="btn primary-btn">JOIN NOW</Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  if (state.barrelOrders.data) {
    state.barrelOrders.data.paymentStatus = null;
  }
  const { loggedIn } = state.authentication;
  const { data, isAdmin } = state.member;
  const isPotential = get(data, "membershipType", "") === "Potential";
  const memberNumber = get(data, "memberNumber", "");

  const { member } = state;
  const shippingAddress = get(member, "data.shippingAddress", {});
  const homeAddress = get(member, "data.homeAddress", {});
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const contactId = get(user, "id", "");

  const firstName = get(member, "data.firstName", "");
  const lastName = get(member, "data.lastName", "");
  const birthDate = get(member, "data.birthday", "");
  const membershipType = get(member, "data.membershipType", "");
  const clubType = get(member, "data.clubType", "");
  const bottleLevel = get(member, "data.bottleLevel", "");
  const deliveryType = get(member, "data.deliveryType", "");
  const phone = get(member, "data.phone", "");
  const email = get(member, "data.email", "");
  const street = get(homeAddress, "street", "");
  const city = get(homeAddress, "city", "");
  const st = get(homeAddress, "state", "");
  const zip = get(homeAddress, "zipCode", "");
  const shippingStreet = get(shippingAddress, "street", "");
  const shippingCity = get(shippingAddress, "city", "");
  const shippingSt = get(shippingAddress, "state", "");
  const shippingZip = get(shippingAddress, "zipCode", "");
  const status = get(member, "data.status", "");
  const memberSignatureOptOut = get(
    member,
    "data.memberSignatureOptOut",
    false
  );
  const secondaryMemberFn = get(member, "data.secondaryMemberFirstName", "");
  const secondaryMemberLn = get(member, "data.secondaryMemberLastName", "");
  const secondaryMemberBday = get(member, "data.secondaryMemberBirthday", "");

  const profileObject = {
    contact: {
      Id: contactId,
      FirstName: firstName,
      LastName: lastName,
      Birthdate: birthDate,
      CHW_Membership_Type__c: membershipType,
      Phone: phone,
      Email: email,
      MailingStreet: street,
      MailingCity: city,
      MailingState: st,
      MailingPostalCode: zip,
      OtherStreet: shippingStreet,
      OtherCity: shippingCity,
      OtherState: shippingSt,
      OtherPostalCode: shippingZip,
      CHW_Card_On_File_Optout__c: memberSignatureOptOut,
      CHW_Secondary_Contact_First_Name__c: secondaryMemberFn,
      CHW_Secondary_Contact_Last_Name__c: secondaryMemberLn,
      CHW_Secondary_Contact_Birthday__c: secondaryMemberBday,
    },
    displayClubType: clubType,
    displayLevel: bottleLevel,
    displayDelivery: deliveryType,
  };

  const isLoading = member.loading;

  return {
    isAdmin,
    loggedIn,
    memberNumber,
    isPotential,
    status,
    profileObject,
    isLoading,
    membershipType,
    firstName,
  };
}

const connectedPotentialMemberLanding = connect(mapStateToProps)(
  PotentialMemberLanding
);
export { connectedPotentialMemberLanding as PotentialMemberLanding };
