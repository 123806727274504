import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./styles.scoped.css";
import SelectArrowDown from "../../assets/images/select-arrow-down.png";
import get from "lodash/get";

const MenuCategoriesSelect = ({
  data,
  categories,
  value,
  onSelect,
  innerRefs,
  subcat
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [hasParent, setHasParent] = useState(false);
  const [selectedSubcat, setSelectedSubcat] = useState(null);
  let allCatIds = [];
  let horizScrollRefs = {};

  useEffect(() => {
    if (value.isParent) {
      setSelectedSubcat(null);
    } else {
      setSelectedSubcat(value.id);
      const subRef = horizScrollRefs[value.id];
      setTimeout(() => {
        if (subRef) {
          subRef.scrollIntoView();
        }
      }, 1000);
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      // does this category have a parent?
      let allCats = categories.filter((category) => category.isParent);
      allCats.forEach(
        (cat) => (allCatIds = [...allCatIds, ...cat.subCategories.split(",")])
      );
      setHasParent(allCatIds.includes(value.id.toString()));
    }
  }, [value]);

  const subcatSelected = (subcat) => {
    onSelect(subcat);
    setSelectedSubcat(subcat.id);
  };

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  const handleSelect = (entry) => {
    onSelect(entry);
  };

  if (!isExpanded) {
    const selectedValue = value;

    return (
      <div>
        <div className="select-container" onClick={handleClick}>
          <div className="selected-label">
            {value && value.name.includes("-")
              ? value.name.split("-")[0].toLowerCase()
              : value && value.name
              ? value.name.toLowerCase()
              : ""}
          </div>
          <img className="select-arrow-down" src={SelectArrowDown} alt="select down arrow" />
        </div>
        {value.subcategories && value.subcategories.length !== 0 && (
          <div className="category-description">
            <span className="mobile-horiz-scroll-wrapper">
              {value &&
                value.subcategories &&
                value.subcategories.map((sub) => (
                  <span
                    key={sub && sub.id}
                    ref={(ref) => (horizScrollRefs[sub && sub.id] = ref)}
                    className={`submenu-item ${
                      sub && subcat && sub.id === subcat.id ? "sub-selected" : ""
                    }`}
                    onClick={() => subcatSelected(sub)}
                  >
                    { sub && sub.name && sub.name.includes("-")
                      ? sub.name.substr(sub.name.indexOf("-") + 1)
                      : sub ? sub.name : ""
                    }
                  </span>
                ))}
            </span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="select-container-expanded" onClick={handleClick}>
      <div className="select-header-container">
        <div className="expanded-label">Select a category</div>
        <img className="select-arrow-up" src={SelectArrowDown} alt="select up arrow" />
      </div>
      <div className="divider" />
      <div className="cat-list-container">
        {categories &&
          categories.map((entry) => {
            return entry.name.includes("-") || entry.isHidden ? null : (
              <div
                className={`cat-list-item ${entry === value ? "selected" : ""}`}
                key={entry.id}
                onClick={() => handleSelect(entry)}
              >
                <div className="cat-list-text">{entry.name.toLowerCase()}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { menu } = state;
  const menuData = get(menu, "data", {});
  const categories = get(menuData, "categories", []);
  return {
    categories,
  };
}

const connectedMenuCategoriesSelect = connect(mapStateToProps)(
  MenuCategoriesSelect
);
export { connectedMenuCategoriesSelect as MenuCategoriesSelect };

MenuCategoriesSelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
};

MenuCategoriesSelect.defaultProps = {
  data: [],
  onSelect: () => {},
};
