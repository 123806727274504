import { appConstants } from "../_constants";
import { memberActions } from "../_actions";
import secureStorage from "../_helpers/secureStorage"
import get from "lodash/get";

export const newsService = {
  getNews,
  logout
};

function getNews() {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const requestOptions = {
    method: "GET",
    headers: { "authorization": token }
  };

  return fetch(
    `${appConstants.localApiUrl}members/news`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  return dispatch => {
    dispatch(memberActions.logout());
  }
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok && response.status === 401) {
      // auto logout if 401 response returned from api
      logout();
      // eslint-disable-next-line no-restricted-globals
      location.reload(true);
    }
    return data;
  });
}
