import React from "react";
import GoldPlusImg from "../../assets/images/gold-plus-carryout.svg";
import EsqGoldPlus from "../../assets/images/gold-plus-esquire.svg";
import { connect } from "react-redux";
import { isThemeEsquire } from "../../_helpers";
import { createUseStyles } from "react-jss";

const useThemedButtonStyles = createUseStyles({
    'button': {
        width: props => `${props.width}px`,
    },
    '.button::hover' : {
        width: props =>  `${props.width+2}px`
    }
});


const GoldPlus = (props) => {
    const classes = useThemedButtonStyles({width: props.width});
    return (
            <img
            src={props.isEsquire ? EsqGoldPlus : GoldPlusImg}
            onClick={props.onClick}
            className={classes.button}
            alt="plus icon"
            ></img>
    )

}

const mapStateToProps = (state, ownProps) => {
    let isEsquire = isThemeEsquire();
    return {
        isEsquire,
        ...ownProps
    }
}


export default connect(mapStateToProps)(GoldPlus);