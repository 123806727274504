import React, { useState, useEffect, useMemo, createRef } from 'react';
import {
  SegmentedSelector,
  LocationSearch,
  GoogleMap,
  Header,
  LocationList,
  ListMapMobileToggle,
  LocationCard,
  HandoffModal,
  Footer,
} from '../../components';
import initialImage from '../../assets/images/main-locator-img.jpg';
import esquireImage from '../../assets/images/esquire-locator-image.jpg';
import oloHomepageImage from '../../assets/images/olo-homepage-image.jpg';
import closeIcon from '../../assets/images/close@2x.png';
import { appConstants } from '../../_constants';
import { motion } from 'framer-motion';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  alertActions,
  restaurantsActions,
  basketsActions,
  settingsActions,
  memberActions,
  ordersActions,
} from '../../_actions';
import {
  applyThemeForSlug,
  isThemeEsquire,
  pushGTM,
  useWindowDimensions,
} from '../../_helpers';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import './styles.css';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import invert from 'lodash/invert';
import Loader from 'react-loader-spinner';

const HANDOFF_TYPE_MAP = {
  0: 'pickup',
  1: 'curbside',
  2: 'dispatch',
};

const Locator = ({
  basketOk,
  basketSaved,
  basketData = {},
  itemsRemoved = [],
  basketLoading = false,
  restaurants = {},
  restaurant,
  restaurantsNearby = {},
  handoffPreference,
  selected,
  homeLocation,
  dispatch,
  history,
  loggedIn,
  location,
}) => {
  const { height, width } = useWindowDimensions();
  const { loading, data } = restaurants;
  const locations = get(data, 'restaurants', []);

  const isEsquire = isThemeEsquire();

  const nearData = isEsquire ? data : get(restaurantsNearby, 'data', []);
  let locationsFiltered = get(nearData, 'restaurants', []);
  const searched = isEsquire ? true : get(nearData, 'firstFilter', false);

  const [mapLoaded, setMapLoaded] = useState(false);
  const [segment, setSegment] = useState(
    parseInt(get(invert(HANDOFF_TYPE_MAP), handoffPreference, 0), 10),
  );
  const [searchValue, setSearchValue] = useState('');
  const [searchGeo, setSearchGeo] = useState(null);
  const [addressComponents, setAddressComponents] = useState(null);
  const [mobileViewToggle, setMobileViewToggle] = useState(0);
  const [slug, setSlug] = useState('');
  const [handoffModalOpen, setHandoffModalOpen] = useState(false);
  const [selectLocationModalOpen, setSelectLocationModalOpen] = useState(false);
  const [acquiringLocation, setAcquiringLocation] = useState(false);
  const [showMap, setShowMap] = useState(true);


  const refs = useMemo(
    () => Array.from({ length: locations.length }).map(() => createRef()),
    [],
  );

  function debounce(fn, ms) {
    let timer;
    return _ => {
      clearTimeout(timer);
      timer = setTimeout(_ => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    const refreshToken = urlParams.get('refreshToken');
    const selectedLocation = urlParams.get('location');
    const email = urlParams.get('email');
    if (email && token && refreshToken && locations) {
      fromApp(email, token, refreshToken, selectedLocation);
    }
  }, [location, locations]);

  const fromApp = async (email, token, refreshToken, selectedLocation) => {
    await dispatch(memberActions.loginWithToken(email, token, refreshToken));
    if (selectedLocation) {
      const location = await locations.find(
        loc => loc.slug === selectedLocation,
      );
      if (location) locationSelected(location, true);
    }
  };

  //fetch all restaurants on first load
  useEffect(() => {
    pushGTM('checkout', 'Checkout', undefined, { step: 1 });

    dispatch(ordersActions.reset());
    dispatch(restaurantsActions.getRestaurants(isThemeEsquire()));
    if (width < 460) {
      setMapLoaded(true);
    }

    const debouncedHandleResize = debounce(function _handleResize() {
      console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
    }, 500);

    window.addEventListener('resize', debouncedHandleResize);
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  //Set the ch theme when loading the page for the first time
  useEffect(() => {
    if (window.location.hostname.includes('esquire')) {
      applyThemeForSlug('esquire', document);
    } else {
      applyThemeForSlug('', document);
    }
  }, []);

  // if the search location changes or the segment filter changes, requery locations
  useEffect(() => {
    if (searchGeo) {
      searchNearby();
    }
  }, [searchGeo, segment]);

  useEffect(() => {
    // George added 12-10-2020 anytime locations are searched, highlight first item by default
    if (locationsFiltered[0]) {
      dispatch(restaurantsActions.restaurantSelected(locationsFiltered[0]));
    }
  }, [locationsFiltered]);

  useEffect(() => {
    dispatch(settingsActions.setHandoffType(HANDOFF_TYPE_MAP[segment]));
    if (segment === 2)
      dispatch(
        alertActions.warning(appConstants.locatorPopup, 'locator-warning'),
      );
    // if (segment === 2) searchCleared();
    searchCleared();
  }, [segment]);

  //query locations based on geo and segment selected
  const searchNearby = () => {
    setSearchValue(searchGeo.address);
    dispatch(
      restaurantsActions.getRestaurantsNearby(
        searchGeo.location.lat,
        searchGeo.location.lng,
        segment,
      ),
    );
  };

  // when an item is selected form the list or map, find it's match store selection in state and scroll list
  const locationSelected = async (location, quickAdd = false) => {
    let i = locationsFiltered.findIndex(obj => isEqual(location, obj));
    dispatch(restaurantsActions.restaurantSelected(location));
    setTimeout(() => {
      if (refs && refs[i] && refs[i].current && width > 480) {
        refs[i].current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 300);
    // George added 12-10-2020 - if mobile (width<=480) kick off order immediately on locationSelected
    if (isEqual(location, selected) || quickAdd || width <= 480) {
      dispatch(
        basketsActions.createBasket(
          location.id,
          !isEmpty(basketData.products),
          !isEmpty(basketData.products) ? basketData.id : '',
        ),
      ).then(() => {
        showDetails(location.slug);
      });
    }
  };

  const viewAll = () => {
    if (searchValue === '' && searchGeo === '') return;
    dispatch(restaurantsActions.getRestaurantsNearby(1, 1, segment));
    setSearchValue('');
    setSearchGeo('');
  };

  // reset, clear out, re-query, select first item (nearest)
  const searchCleared = () => {
    dispatch(restaurantsActions.getRestaurantsNearby(0, 0, segment));
    setSearchValue('');
    setSearchGeo('');
  };

  // get geolocation from browser
  const getLocation = () => {
    setAcquiringLocation(true);
    navigator.geolocation.getCurrentPosition(position => {
      setSearchGeo({
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        address: 'Current Location',
      });
      setAcquiringLocation(false);
    });
  };

  const showDetails = async slug => {
    setSlug(slug);
    setHandoffModalOpen(true);
  };

  useEffect(() => {
    if ((slug && basketOk) || (slug && basketSaved)) {
      history.push(`/menu/${slug}`);
    }
  }, [basketOk, basketSaved]);

  const gotoMenu = () => {
    history.push(`/menu/${slug}`);
  };

  const onBagClick = () => {
    if (basketData.products && basketData.products.length > 0) {
      history.push(`/menu/${get(restaurant.data, 'slug')}?bagOpen=1`);
    } else {
      setSelectLocationModalOpen(true);
    }
  };

  const onLogin = () => {
    const locator = `/`;
    dispatch(memberActions.setLoginDestination(locator));
    history.push('/login-olo?continueAsType=member');
  };

  const logout = () => {
    //clear basket of conversions
    const basketId = get(basketData, 'id', -1);
    const products = get(basketData, 'products', []);
    products.forEach(async product => {
      if (product.customdata?.startsWith('conversion:') ?? false) {
        await dispatch(basketsActions.deleteProduct(basketId, product.id));
      }
    });

    dispatch(memberActions.logout());
  };

  const verifyLogout = () => {
    //are you sure?
    dispatch(alertActions.oloLogout(() => logout()));
  };

  useEffect(() => {
    if (isEsquire) {
      setShowMap(false);
    }
  }, [isEsquire]);

  return (
    <Container fluid>
      <Header onBagClick={onBagClick} isLoggedIn={loggedIn} onLogin={onLogin} />
      <Row
        style={{
          backgroundColor: '#f5f3f0',
          width: '100vw',

          position: 'relative',
        }}>
        <div
          className="left-col scroll"
          style={{
            height:
              height -
              80 -
              (width < 460 && searched && !isEmpty(locationsFiltered) ? 50 : 0),
            width: 460,
          }}>
          <div className="a">
            <h1 className="page-title">Start Your Order</h1>
            <p className='instruction'>Delivery only available at select locations</p>
            <>
              <p className="sub-title">
                {isEsquire ? '' : 'Step 1. Select your order type'}
              </p>

              {!isEsquire && (
                <>
                  <SegmentedSelector
                    onSelect={index => {
                      setSegment(index);
                    }}
                    selectedIndex={segment}
                  />
                  <p className="sub-title">
                    {segment === 2
                      ? 'Step 2. Enter your address to find a delivery location'
                      : 'Step 2. Enter a zip code to find nearby locations'}
                  </p>
                </>
              )}
              {isEsquire && (
                <p className="sub-title">Choose your Esquire Location</p>
              )}

              {!isEsquire && (
                <>
                  <LocationSearch
                    segment={segment}
                    value={searchValue}
                    onChange={value => setSearchValue(value)}
                    key={segment}
                    onSearch={result => {
                      setSearchGeo(result.geo);
                      setAddressComponents(result.addressComponents);
                      pushGTM('checkout', 'Checkout', undefined, { step: 2 });
                    }}
                    onCleared={searchCleared}
                    placeholderText={
                      segment === 2 ? 'Your address' : 'Zip code or City, State'
                    }
                  />
                  {segment !== 2 && (
                    <p
                      className="sub-title view-btn location-txt"
                      onClick={() => getLocation()}>
                      {acquiringLocation ? (
                        <Row
                          style={{
                            justifyContent: 'flex-end',
                            marginRight: 10,
                          }}>
                          <Loader
                            type="ThreeDots"
                            color="#a47f51aa"
                            height={5}
                            width={5}
                            radius={5}
                          />
                          Fetching Location...
                        </Row>
                      ) : (
                        'Use Current Location'
                      )}
                    </p>
                  )}
                </>
              )}
            </>
            {!isEsquire && !isEmpty(locationsFiltered) && (
              <div className="location-txt-row">
                {searched && (
                  <p className="sub-title">
                    {segment === 2
                      ? 'Step 3. Select a location'
                      : 'Step 3. Select a location'}
                  </p>
                )}
                {segment !== 2 && (
                  <p className="sub-title view-btn" onClick={viewAll}>
                    {searchValue === '' && searchGeo === ''
                      ? 'Viewing All'
                      : 'View All'}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="b">
            {searched && (
              <LocationList
                segment={segment}
                innerRefs={refs}
                locations={locationsFiltered}
                onCardClick={item => locationSelected(item, true)}
                onButtonClick={item => locationSelected(item, true)}
                handoffMode={HANDOFF_TYPE_MAP[segment]}
                showMap={showMap}
                setShowMap={setShowMap}
                isEsquire={isEsquire}
              />
            )}

            {(isEsquire || !isEmpty(searchGeo)) &&
            searched &&
            isEmpty(locationsFiltered) ? (
              <div className="no-results">
                <div className="no-results-text">
                  We were unable to find any participating locations in your
                  area for{' '}
                  {segment === 2
                    ? 'delivery'
                    : segment === 0
                    ? 'pickup.'
                    : 'curbside.'}
                  {segment === 2 ? ' to your address.' : ''} Please check your
                  search and try again.
                </div>
              </div>
            ) : (
              isEmpty(locationsFiltered) &&
              !isEmpty(homeLocation) && (
               (segment!== 2 || homeLocation?.supportsdispatch) ?
                <LocationCard
                  isHome={true}
                  highlight={true}
                  data={homeLocation}
                  onCardClick={() => {
                    dispatch(
                      basketsActions.createBasket(
                        homeLocation.id,
                        !isEmpty(basketData.products),
                        !isEmpty(basketData.products) ? basketData.id : '',
                      ),
                    ).then(() => {
                      showDetails(homeLocation.slug);
                    });
                  }}
                  handoffMode={HANDOFF_TYPE_MAP[segment]}
                /> : null
              )
            )}
          </div>

          {width < 460 && (
            <Footer
              style={{
                marginBottom: 0,
                position:
                  searched && !isEmpty(locationsFiltered)
                    ? 'inherit'
                    : 'absolute',
              }}
            />
          )}
        </div>
        <div
          className="no-pad"
          style={
            width < 460
              ? {
                  left: 460 * !mobileViewToggle,
                  position: 'absolute',
                  top: 0,
                  width: '100vw',
                  height: 'calc(100vh - 50px)',
                }
              : {
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: 'calc(100vw - 460px)',
                  bottom: 0,
                }
          }>
          {searched && !isEmpty(locationsFiltered) && showMap ? (
            <GoogleMap
              searched
              mobileViewToggle
              mobileMap={width < 460}
              key={locationsFiltered.toString()}
              mapLoaded={() => setMapLoaded(true)}
              onSelected={item => locationSelected(item)}
              locations={locationsFiltered}
              selected={selected}
              segment={segment}
            />
          ) : (
            <img
              src={isEsquire ? esquireImage : oloHomepageImage}
              style={{ objectFit: 'cover', height: '100%', width: '100%' }}
              alt="group cheersing wine at dinner"
            />
          )}

          {width < 460 &&
            mobileViewToggle === 1 &&
            (isEsquire || !isEmpty(searchGeo)) &&
            searched &&
            !isEmpty(locationsFiltered) &&
            !isEmpty(selected) && (
              <LocationCard
                highlight={true}
                mobileMap={width < 460}
                data={selected}
                onCardClick={() => locationSelected(selected)}
                handoffMode={HANDOFF_TYPE_MAP[segment]}
              />
            )}
        </div>
      </Row>
      {width < 460 && searched && !isEmpty(locationsFiltered) && (
        <ListMapMobileToggle
          onToggle={i => setMobileViewToggle(i)}
          toggleValue={mobileViewToggle}
        />
      )}
      {handoffModalOpen && (
        <HandoffModal
          onSaved={gotoMenu}
          onDismiss={_ => setHandoffModalOpen(false)}
          fromSearch={true}
          addressComponents={addressComponents}
        />
      )}

      {selectLocationModalOpen && (
        <div className={'select-location-modal'}>
          <div className="modal-title-dark">Select a location</div>
          <div className="modal-message">
            Please select a location to begin your order.
          </div>
          <motion.img
            src={closeIcon}
            className="close-btn"
            alt="close button icon"
            onClick={_ => setSelectLocationModalOpen(false)}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}
          />
          <Button
            className="button"
            onClick={() => setSelectLocationModalOpen(false)}>
            OK
          </Button>
        </div>
      )}
      {width >= 460 && <Footer style={{ position: 'absolute' }} />}
    </Container>
  );
};

function mapStateToProps(state) {
  const {
    restaurants,
    restaurant,
    restaurantSelected,
    restaurantsNearby,
    basket,
    settings,
    authentication,
    member,
  } = state;
  const memberData = get(member, 'data', {});
  const lastLocation = get(memberData, 'lastLocation', {});
  const lastSlug = get(lastLocation, 'CHW_OLO_Slug__c', '');
  const homeLocation =
    restaurants &&
    restaurants.data &&
    restaurants.data.restaurants &&
    restaurants.data.restaurants.find(
      restaurant => restaurant.slug === lastSlug,
    );

  return {
    restaurants,
    restaurant,
    restaurantsNearby,
    handoffPreference: get(settings, 'handoff', 'pickup'),
    selected: restaurantSelected.data,
    basketData: basket.data,
    basketOk: basket.created,
    basketSaved: basket.saved,
    basketLoading: basket.loading,
    itemsRemoved: basket.itemsnottransferred,
    loggedIn: get(authentication, 'loggedIn', false),
    homeLocation,
  };
}

const connectedLocator = withRouter(connect(mapStateToProps)(Locator));
export { connectedLocator as Locator };
