import React from "react";
import { Header, Footer } from "../../components";
import { Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { AnimatePresence } from "framer-motion";


import "./styles.scoped.css";
import ThemedBackArrow from "../../components/ThemedBackArrow";
import { useRenameDocument } from "../../context/renameDocument";

const ContactUs = ({ history }) => {
  const onBackToBag = () => {
    history.goBack();
  };

  useRenameDocument("Contact Us");

  return (
    <>
      <AnimatePresence>
        <div className="big-wrap">
          <Header onBagClick={onBackToBag} hideLogin />

          <Col className="little-wrap">
            <div className="back-to-my-bag" onClick={onBackToBag}>
              <ThemedBackArrow className="icon-back" width="20px"/>
              <p className="back-to-my-bag-text">Back</p>
            </div>
            <div>
              <p className="title">Contact Us</p>
            </div>
          </Col>
          <Footer />
        </div>
      </AnimatePresence>
    </>
  );
};

const connectedContactUs = withRouter(ContactUs);
export { connectedContactUs as ContactUs };
