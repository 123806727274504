export const restaurantsConstants = {
  MENU_REQUEST: "MENU_REQUEST",
  MENU_SUCCESS: "MENU_SUCCESS",
  MENU_FAILURE: "MENU_FAILURE",

  RESTAURANT_REQUEST: "RESTAURANT_REQUEST",
  RESTAURANT_SUCCESS: "RESTAURANT_SUCCESS",
  RESTAURANT_FAILURE: "RESTAURANT_FAILURE",

  RESTAURANTS_REQUEST: "RESTAURANTS_REQUEST",
  RESTAURANTS_SUCCESS: "RESTAURANTS_SUCCESS",
  RESTAURANTS_FAILURE: "RESTAURANTS_FAILURE",

  RESTAURANTS_NEARBY_REQUEST: "RESTAURANTS_NEARBY_REQUEST",
  RESTAURANTS_NEARBY_SUCCESS: "RESTAURANTS_NEARBY_SUCCESS",
  RESTAURANTS_NEARBY_FAILURE: "RESTAURANTS_NEARBY_FAILURE",

  RESTAURANT_SELECTED: "RESTAURANT_SELECTED",

  HOURS_REQUEST: "HOURS_REQUEST",
  HOURS_CACHE_MISS: "HOURS_CACHE_MISS",
  HOURS_CACHE_HIT: "HOURS_CACHE_HIT",
  HOURS_SUCCESS: "HOURS_SUCCESS",
  HOURS_FAILURE: "HOURS_FAILURE",

  CHECK_DELIVERY_ADDRESS_REQUEST: "CHECK_DELIVERY_ADDRESS_REQUEST",
  CHECK_DELIVERY_ADDRESS_SUCCESS: "CHECK_DELIVERY_ADDRESS_SUCCESS",
  CHECK_DELIVERY_ADDRESS_FAILURE: "CHECK_DELIVERY_ADDRESS_FAILURE",

  RESET_DELIVERY_CHECK: "RESET_DELIVERY_CHECK",
};
