/* eslint-disable no-restricted-globals */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, OverlayView, Marker } from '@react-google-maps/api';
import mapStyles from './mapStyles';
import mapIcon from '../../assets/images/map-icon.png';
import selectedMapIcon from '../../assets/images/map-icon-selected.png';
import esquireMapIcon from '../../assets/images/map-icon-esquire.svg';
import esquireSelectedMapIcon from '../../assets/images/map-icon-esquire-selected.svg';
import { useWindowDimensions, isEsquireLocation } from '../../_helpers';
import './styles.css';
import isEqual from 'lodash/isEqual';

const Map = React.memo(
  ({
    mapLoaded,
    locations,
    selected,
    onSelected,
    center,
    segment,
    mobileMap,
  }) => {
    const { width } = useWindowDimensions();

    const mapContainerStyle = {
      width: mobileMap ? '100vw' : 'calc(100vw - 460px)',
      height: mobileMap ? 'calc(100vh - 135px)' : 'calc(100vh - 130px)',
    };

    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
      if (map && selected && selected.latitude && selected.longitude) {
        map.panTo({ lat: selected.latitude, lng: selected.longitude });
      }
    }, [selected]);

    const onMapLoad = useCallback(function callback(map) {
      let markers = [];
      const icon = {
        url: mapIcon, // url
        scaledSize: new window.google.maps.Size(50, 50), // scaled size
      };
      const bounds = new window.google.maps.LatLngBounds();
      // Create markers.

      locations.forEach(location => {
        var marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            location.latitude,
            location.longitude,
          ),
          icon: './',
          map: map,
          location: location,
        });
        marker.addListener('click', () => {
          onSelected(location);
        });
        markers.push(marker);
      });

      setMarkers(markers);
      for (let i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].getPosition());
      }
      window.google.maps.event.addListenerOnce(map, 'bounds_changed', function(
        event,
      ) {
        map.fitBounds(bounds);
        if (map.getZoom() > 10) {
          map.setZoom(10);
        }
      });

      setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
      setMap(null);
    }, []);

    const getIcon = (markerLocation, selectedLocation) => {
      const isSelected = isEqual(markerLocation, selectedLocation);
      let iconUrl;
      if (isSelected) {
        iconUrl = isEsquireLocation(markerLocation)
          ? esquireSelectedMapIcon
          : selectedMapIcon;
      } else {
        iconUrl = isEsquireLocation(markerLocation) ? esquireMapIcon : mapIcon;
      }

      return {
        url: iconUrl,
        scaledSize: new window.google.maps.Size(50, 50),
      };
    };

    return (
      <GoogleMap
        onUnmount={onUnmount}
        onLoad={onMapLoad}
        mapContainerStyle={mapContainerStyle}
        onTilesLoaded={mapLoaded}
        options={{ styles: mapStyles, zoomControl: true }}>
        {markers.map(marker => (
          <Marker
            zIndex={isEqual(marker.location, selected) ? 1 : -1}
            onClick={() => onSelected(marker.location)}
            icon={getIcon(marker.location, selected)}
            position={{
              lat: marker.location.latitude,
              lng: marker.location.longitude,
            }}
            key={marker.location.id}
          />
        ))}
      </GoogleMap>
    );
  },
);

export default Map;

Map.propTypes = {
  locations: PropTypes.array,
  scriptLoaded: PropTypes.func,
  selected: PropTypes.object,
  center: PropTypes.object,
  mobileMap: PropTypes.bool,
  innerStyle: PropTypes.object,
};

Map.defaultProps = {
  locations: [
    {
      lat: 41.8862729,
      lng: -87.7194441,
    },
  ],
  center: { lat: 41.8862729, lng: -87.7194441 },
  selected: {},
  scriptLoaded: () => {},
  innerStyle: {},
};
