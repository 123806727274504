import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { newMemberActions } from "../../_actions";
import get from "lodash/get";
import "./styles.css";
import { useRenameDocument } from "../../context/renameDocument";

const GiftApproved = ({ location, dispatch }) => {
  useRenameDocument("Gift Approved");
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {setInnerWidth(window.innerWidth)});
  const deliveryTypeMessage =
    location.state.deliveryType === "Delivery"
      ? "shipped quarterly"
      : "picked up at any of our locations";
  const deliveryActivationMessage =
    location.state.deliveryType === "Delivery"
      ? `next quarterly collection (${location.state.nextChargeOutMonth}) in ${
          location.state.nextChargeOutMonth.split(", ")[2]
        }`
      : `Wine of the Month for ${location.state.nextChargeOutMonth}`;
  const bottle = location.state.bottleLevel == 1 ? "bottle" : "bottles";

  return location.state.newStyle ? (
    <div>
    <img
        className="image-to-be-gradiented"
        src={
          // eslint-disable-next-line
          innerWidth < 576 && require('../../assets/images/bbbg-v3.png')
        }
        alt=""
        style={{ zIndex: -1 }}
      />
      <div className="image-gradient" style={{height: innerWidth <= 576 ? undefined : '91.3vh'}} />
      <div style={{ position: 'relative', top: innerWidth > 576 ? 200 : 160 }}>
        <div className="container">
          <div
            className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded newGiftPayment"
            style={{ backgroundColor: 'white' }}>
            <h2 className="new-approved-header" id="gift-approved">
              Payment Approved
            </h2>
            <p
              className="log-title mb-sm-4 pt-1"
              id="thanks-for-purchase"
              style={{ color: 'black' }}>
              {location.state.firstName}, thank you for purchasing a{' '}
              {location.state.duration} Month {location.state.clubType} Club
              Gift Membership with {location.state.bottleLevel} {bottle} per
              month to be {deliveryTypeMessage}. <br />
              <br />
              Head to any Cooper's Hawk location to activate your Membership, or
              call Member and Guest Services at{' '}
              <a href="tel:7082155674">(708) 215-5674</a>. To receive the{' '}
              {deliveryActivationMessage}, activate your account before{' '}
              {location.state.nextChargeOutDate}.
            </p>
            <p
              className="log-title mb-sm-4"
              id="temporary-account-number"
              style={{ color: 'black' }}>
              You will receive an email confirmation shortly with your Temporary
              Wine Club Account Number: <strong>{location.state.cardNum}</strong>
            </p>
          </div>
        </div>
      </div>
      </div>
  ) : (
    <div className="container">
      <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
        <h2 className="log-title" id="payment-success">
          Payment Approved
        </h2>
        <p className="log-title mb-sm-4 pt-1" id="purchase-information">
          {location.state.firstName}, thank you for purchasing a{" "}
          {location.state.duration} Month {location.state.clubType} Club Gift
          Membership with {location.state.bottleLevel} {bottle} per month to be{" "}
          {deliveryTypeMessage}. <br />
          <br />
          Head to any Cooper's Hawk location to activate your Membership, or
          call Member and Guest Services at{" "}
          <a href="tel:7082155674">(708) 215-5674</a>. To receive the{" "}
          {deliveryActivationMessage}, activate your account before{" "}
          {location.state.nextChargeOutDate}.
        </p>
        <p className="log-title mb-sm-4" id="temp-account-number">
          You will receive an email confirmation shortly with your Temporary
          Wine Club Account Number: <strong>{location.state.cardNum}</strong>
        </p>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { newMember } = state;
  const { data } = newMember;
  const email = get(data, "email", "");
  return {
    email,
  };
}
const connectedGiftApproved = connect(mapStateToProps)(GiftApproved);
export { connectedGiftApproved as GiftApproved };
