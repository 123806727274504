import React, { useState, useEffect, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import { UpsellCheckout, CartItem } from "../../components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { get } from "lodash";
import closeIcon from "../../assets/images/close@2x.png";
import editIcon from "../../assets/images/icon-edit-location.png";
import infoIcon from "../../assets/images/info-16.png";
import UpIcon from "../../assets/images/icon-circle-arrow-forward.svg";
import UpIconEsquire from "../../assets/images/icon-circle-arrow-esquire.svg";
import { basketsActions, alertActions } from "../../_actions";
import { Restaurants } from "../../_services/restaurants.service";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import "./styles.css";
import AddonModal from "../AddonModal";
import {useComplimentaryProducts, isThemeEsquire} from "../../_helpers";

const moment = require("moment");

const Cart = ({
  coupon,
  isLoggedIn,
  memberData,
  onDismiss,
  basketData,
  restaurantData,
  settings,
  onLogin,
  onCheckout,
  onEdit,
  onConvertBottle,
  onTouchEvent,
  openHandoff,
  dispatch,
  history,
  menuData,
  loading,
}) => {
  const [hours, setHours] = useState(null);
  const handoffPreference = get(settings, "handoff");
  const timeWanted = get(basketData, "timewanted", null);
  const deliveryAddress = get(basketData, "deliveryaddress", null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [displayLocationInfo, setDisplayLocationInfo] = useState(false);
  const [addonModalOpen, setAddonModalOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const target = useRef(null);
  const {
    flatware,
    pretzelBread,
    gfPretzelBread,
    cartItems,
    toggleFlatware,
    togglePretzelBread,
    toggleGfPretzelBread,
  } = useComplimentaryProducts({menuData, basketData, loading});

  const getTime = (days, open = false) => {
    const OLO_FORMAT = "YYYYMMDD HH:mm";
    const TIME_LABEL_FORMAT = "h:mm a";
    const todayM = moment();
    const today = days.find(
      (day) => moment(day.start, OLO_FORMAT).date() === todayM.date()
    );
    if (today) {
      return {
        open: moment(today.start, OLO_FORMAT).format(TIME_LABEL_FORMAT),
        close: moment(today.end, OLO_FORMAT).format(TIME_LABEL_FORMAT),
      };
    }
    return null;
  };
  
  const handleCheckout = () => {
    if (!basketData.subtotal) {
      alertZero();
      return;
    }
    
    if (pretzelBread && gfPretzelBread && flatware) {
      // User has already chosen both complimentary items, so skip to checkout
      onCheckout();
    }

    setAddonModalOpen(true);
  };

  useEffect(() => {
    // refresh basket data anytime we mount the cart UI to keep data fully synced - 02-18-21 GD
    !!basketData && dispatch(basketsActions.getBasket(basketData.id));
    // --------------------------------------------------------
    setTimeout(() => {
      setIsLoaded(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (restaurantData) {
      Restaurants.getHours(restaurantData.id).then((res) => {
        const businessHours = res.calendar.find(
          (hours) => hours.type === "business"
        );
        const ranges = get(businessHours, "ranges", []);
        setHours(getTime(ranges));
      });
    }
  }, [restaurantData]);

  useEffect(() => {
    const isCurrentMember =
      memberData.membershipType !== "Potential" &&
      memberData.status === "Active";
    const isDelivery = handoffPreference === "dispatch";
    let applyOrRemove = "";
    if (coupon === null) {
      applyOrRemove = isCurrentMember && !isDelivery ? "apply" : "";
    } else {
      applyOrRemove = isCurrentMember && !isDelivery? "" : "remove";
    }
    if (applyOrRemove !== "") {
      dispatch(basketsActions.coupon(basketData.id, applyOrRemove === "apply"));
    }
  }, [basketData]);

  const getHandoffLabel = (_) => {
    const handoffModeLabel = (_) => {
      switch (handoffPreference) {
        case "pickup":
          return "Pickup";
        case "curbside":
          return "Curbside";
        case "dispatch":
          if (deliveryAddress) {
            const building = deliveryAddress.building
              ? `#${deliveryAddress.building}`
              : "";
            return `Delivery to ${deliveryAddress.streetaddress} ${building}`;
          } else {
            return "Set delivery address";
          }
        default:
          return "Set handoff details";
      }
    };
    const handoffTimeLabel = (_) => {
      let isASAP = timeWanted === null;
      let timeWantedM = timeWanted && moment(timeWanted, "YYYYMMDD HH:mm");

      try {
        // Show as ASAP if timeWanted is business open time
        const businessHours = restaurantData.hours.find(entry => entry.type === "business");
        const range = businessHours.ranges.find(
          entry => entry.weekday === (timeWantedM || moment()).format("ddd"),
        );
        const startHour = range && businessHours.ranges[0].start;
        const startMom = startHour && moment(startHour, "YYYYMMDD HH:mm");

        if (!timeWantedM) {
          if (startMom.isAfter(moment())) {
            isASAP = false;
            const earliestReadyTime = moment(basketData.earliestreadytime, "YYYYMMDD HH:mm");
            const time = earliestReadyTime.minute(Math.ceil(earliestReadyTime.minute() / 15) * 15);
            timeWantedM = moment(time);
          }
        }
      } catch (e) {
        console.log("Caught", e);
      }
      if (!isASAP) {
        const isToday = timeWantedM.date() === moment().date();
        return `${
          isToday ? "Today" : timeWantedM.format("MMM Do")
        }, ${timeWantedM.format("hh:mm a")}`;
      } else {
        try {
          const earliestReadyTime = moment(basketData.earliestreadytime, "YYYYMMDD HH:mm");
          return `ASAP, ${earliestReadyTime.format("hh:mm a")}`;
        } catch (e) {
          console.log("Caught", e);
          return "ASAP";
        }
      }
    };
    return `${handoffModeLabel()} / ${handoffTimeLabel()}`;
  };

  const clearCart = () => {
    dispatch(
      alertActions.olosuccess(
        "Are you sure you want to empty your bag and start a new order?",
        "Clear Bag",
        () => {
          dispatch(basketsActions.destroyBasket());
          history.replace("/");
        },
        "CLEAR"
      )
    );
  };

  const alertZero = () => {
    dispatch(
      alertActions.olosuccess(
        "Please add an item to your cart in order to check out.",
        "Bag total is $0.00"
      )
    );
  };

  const pointsToolTip = (
    <Popover id="popover-points">
      <Popover.Content
        style={{
          backgroundColor: "black",
          border: " 1px solid white",
          color: "white",
          fontFamily: "Oswald",
          letterSpacing: "1px",
        }}
      >
        Wine Club Members earn points on the order subtotal amount minus the discount, and excluding the tax.
      </Popover.Content>
    </Popover>
  );

  const isEsquire = isThemeEsquire();

  return (
    <>
      <div className="cart-overlay">
        <motion.div
          transition={{ duration: 0.15 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onDismiss}
          id="touch-layer"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(5px)",
          }}
        />

        <motion.div
          className="cart-overlay-inner"
          transition={{ duration: 0.15 }}
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
        >
          <div
            className={`cart-scroll-container ${loading && !addonModalOpen && "basket-loading-opacity"}`}
            onScroll={onTouchEvent}
            onClick={onTouchEvent}
            onMouseEnter={() => {
              if (isLoaded) {
                onTouchEvent();
              }
            }}
            onMouseMove={() => {
              if (isLoaded) {
                onTouchEvent();
              }
            }}
          >
            <h2 className="cart-title">Your Order</h2>
            <div className="cart-location-title">
              Cooper's Hawk - {restaurantData.name}
              <span>
                <motion.img
                  src={isEsquire ? UpIconEsquire : UpIcon}
                  id="location-detail-icon"
                  alt="location detail icon"
                  onClick={() => setDisplayLocationInfo(!displayLocationInfo)}
                  className={`location-detail-icon`}
                  transition={{ duration: 0.2 }}
                  animate={{
                    rotate: displayLocationInfo ? [0, -90] : [0, 90],
                  }}
                />
              </span>
            </div>

            {displayLocationInfo &&
              <div>
                <div className="cart-address-line">{`${
                  restaurantData.streetaddress
                } ${restaurantData.city}, ${restaurantData.state}`}
                </div>
                <div className="cart-address-line">
                  {restaurantData.iscurrentlyopen && hours
                    ? `Open today ${hours.open} - ${hours.close}`
                    : "Closed"}
                  <span>
                    <button className="location-edit" onClick={openHandoff}>
                      <img src={editIcon} className="location-edit-icon" alt="edit icon" />
                    </button>
                  </span>
                </div>
                <div className="cart-address-line">
                  <a href={`tel:${restaurantData.telephone}`}>
                    {restaurantData.telephone}
                  </a>
                </div>
              </div>
            }

            <div className="cart-dispatch-method" onClick={openHandoff}>
              {getHandoffLabel()}
            </div>

            {!isEmpty(cartItems) ? (
              <div>
                <div className="cart-divider" />
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div className="cart-location-title">selected items</div>
                  <div className="clear-cart" onClick={clearCart}>
                    CLEAR BAG
                  </div>
                </div>
                {cartItems.map((item, index) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    index={index}
                    onEdit={onEdit}
                    onConvertBottle={onConvertBottle}
                  />
                ))}
                <div
                  style={{
                    backgroundColor: "#dedbd6",
                    height: 2,
                    width: "100%",
                  }}
                />
                <UpsellCheckout />
              </div>
            ) : (
              <>
                <br />
                <br />
                <br />
                <div className="complete-meal-wrap">
                  <div className="complete-meal-title">
                    Your cart is currently empty... <br />
                    <br />
                    Add some yummy stuff!
                  </div>
                </div>
                <br />
                <br />
                <br />
              </>
            )}
            {memberData.membershipType !== "Potential" && isLoggedIn && (
              <div className="bottle-club">
                <div>
                  {isLoggedIn && (
                    <>
                      {memberData.status !== "To be Activated" && (
                        <>
                          <div className={"bottle-club-join bold"}>
                            WINE CLUB MEMBER BENEFITS
                          </div>

                          <div className="member-number">
                            Member Number: {memberData.memberNumber}
                          </div>
                        </>
                      )}

                      {memberData.status === "UTP" && (
                        <div className="member-text-message">
                          A payment update is required on your Wine Club account, but you can still
                          place an order. To enjoy Member Benefits, update your payment on the profile
                          tab on your account page or by calling&nbsp;
                          <a href="tel:7082155674" style={{ color: "white" }}>
                            (708) 215-5674
                          </a>.
                        </div>
                      )}

                      {memberData.status === "Vacation Hold" && (
                        <div className="member-text-message">
                          Your Wine Club account is on Vacation Hold per your request, but you can
                          still place an order.To enjoy Member Benefits, call&nbsp;
                          <a href="tel:7082155674" style={{ color: "white" }}>
                            (708) 215-5674
                          </a>
                          &nbsp;to reactivate.
                        </div>
                      )}

                      {(memberData.status === "Cancel" ||
                        memberData.status === "Inactive") && (
                        <div className="member-text-message">
                          Your Wine Club account is currently not active, but you can still place an order.
                          To enjoy Member Benefits, rejoin on the profile tab on your account page or by calling&nbsp;
                          <a href="tel:7082155674" style={{ color: "white" }}>
                            (708) 215-5674
                          </a>.
                        </div>
                      )}

                      {memberData.status === "Expired" && (
                        <div className="member-text-message">
                          Your Wine Club account is currently not active, but you can still place an order.
                          To enjoy Member Benefits, call&nbsp;
                          <a href="tel:7082155674" style={{ color: "white" }}>
                            (708) 215-5674
                          </a>
                          &nbsp;to rejoin.
                        </div>
                      )}

                    </>
                  )}
                </div>
                <div>
                  {isLoggedIn &&
                    memberData.membershipType !== "Potential" &&
                    (memberData.status === "Active" ||
                      memberData.status === "New") && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="bottle-club-join bold">
                            Points earned on this order:
                            <OverlayTrigger
                            trigger="focus"
                              placement="bottom"
                              overlay={pointsToolTip}
                            >
                              <Button className="btn-link">
                                <img src={infoIcon} alt="info icon"/>
                              </Button>
                            </OverlayTrigger>
                          </div>
                          <div className="bottle-club-join bold">
                            {basketData.coupondiscount
                              ? parseInt(
                                  basketData.subtotal -
                                    basketData.coupondiscount
                                )
                              : parseInt(basketData.subtotal)}
                          </div>
                        </div>
                        {handoffPreference !== "dispatch" && <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="bottle-club-join bold">
                            Total amount saved:
                          </div>
                          <div className="bottle-club-join bold">
                            $
                            {basketData.coupondiscount &&
                              basketData.coupondiscount.toFixed(2)}
                          </div>
                        </div> }
                      </>
                    )}
                </div>
              </div>
            )}

            {(!isLoggedIn || memberData.membershipType === "Potential") && (
              <div className="wine-club-box">
                <div className="wine-club-info">
                  Join our Wine Club today to save 10% on carryout orders and to start earning points!
                  <br /><br />
                  <a onClick={() => {document.location.href = "#/wine-club"}} style={{ cursor: 'pointer', textDecoration: 'underline'}}>
                    Learn More
                  </a>
                </div>

                <div className="already-a-member" onClick={onLogin}>
                  Already a member?{" "}
                  <span
                    className="learn-more"
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.1 },
                    }}
                    whileTap={{ scale: 0.95 }}
                  >
                        Log in
                      </span>{" "}
                  to access your bottles.
                </div>
              </div>
            )}
            <div className="sub-totals">
              <div className="info-row">
                <div className="info-item">Subtotal</div>
                <div className="info-item">
                  ${basketData.subtotal && basketData.subtotal.toFixed(2)}
                </div>
              </div>
              {basketData.customerhandoffcharge > 0 && (
                <div className="info-row">
                  <div className="info-item">Delivery Charge</div>
                  <div className="info-item">
                    ${basketData.customerhandoffcharge.toFixed(2)}
                  </div>
                </div>
              )}
              {basketData.coupondiscount > 0 && (
                <div className="info-row">
                  <div className="info-item">Member Discount</div>
                  <div className="info-item">
                    -$
                    {basketData.coupondiscount &&
                      basketData.coupondiscount.toFixed(2)}
                  </div>
                </div>
              )}
              {basketData.totalfees > 0 && (
                <div className="info-row">
                  <div 
                    className="info-item" 
                    ref={target} 
                    onMouseEnter={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}
                  >
                    Service Fee {" "}
                    <img src={infoIcon} className="club-info-icon" alt="club info icon" />
                    <Overlay target={target.current} show={showInfo} placement="bottom">
                      {({ placement, arrowProps, show: _show, popper, ...otherProps }) => (
                        <div
                          {...otherProps}
                          style={{
                            backgroundColor: "#6f6c67",
                            border: "1px solid white",
                            color: "white",
                            fontFamily: "Oswald",
                            letterSpacing: "1px",
                            opacity: .9,
                            padding: '4px 12px',
                            borderRadius: 3,
                            marginLeft: 7,
                            zIndex: 100,
                            fontWeight: 200,
                            ...otherProps.style,
                          }}
                        >
                          This ${basketData.totalfees.toFixed(2)} service fee helps us operate our online ordering service.
                        </div>
                      )}
                    </Overlay>
                  </div>
                  <div className="info-item">
                    ${basketData.totalfees.toFixed(2)}
                  </div>
                </div>
              )}
               <div className="info-row">
                <div className="info-item">Tax <span style={{fontWeight: 400}}>(estimated)</span></div>
                <div className="info-item">
                  ${basketData.salestax && basketData.salestax.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div className={`button-floater ${loading && !addonModalOpen && "basket-loading-opacity"}`}>
            <motion.div
              className={
                isEmpty(cartItems) ? "checkout-btn cart-empty" : "checkout-btn"
              }
              onClick={() => setAddonModalOpen(true)}
              whileHover={{
                scale: 1.01,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.99 }}
            >
              CHECKOUT: $
              {basketData.total && basketData.total.toFixed(2)}
            </motion.div>
          </div>
        </motion.div>
      </div>
      <motion.img
        src={closeIcon}
        alt="close icon"
        className="close-btn"
        onClick={onDismiss}
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        whileHover={{
          scale: 1.05,
          transition: { duration: 0.1 },
        }}
        whileTap={{ scale: 0.95 }}
      />
      
      {addonModalOpen && (
        <AddonModal 
          onDismiss={() => setAddonModalOpen(false)} 
          onCheckout={onCheckout}
          hasFlatware={!!flatware}
          hasPretzelBread={!!pretzelBread}
          hasGfPretzelBread={!!gfPretzelBread}
          toggleFlatware={toggleFlatware}
          togglePretzelBread={togglePretzelBread}
          toggleGfPretzelBread={toggleGfPretzelBread}
          loading={loading}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { basket, restaurant, settings, authentication, member, menu } = state;
  const basketData = get(basket, "data", {});
  const upsells = get(basket, "upsells", []);
  const loading = get(basket, "loading", false);
  const restaurantData = get(restaurant, "data", {});
  const memberData = get(member, "data", {});
  const menuData = get(menu, "data", {});
  const isLoggedIn = get(authentication, "loggedIn", false);
  const coupon = get(basketData, "coupon", null);

  return {
    basketData,
    coupon,
    menuData,
    restaurantData,
    memberData,
    settings,
    isLoggedIn,
    upsells,
    loading,
  };
}

const connectedCart = withRouter(connect(mapStateToProps)(Cart));
export { connectedCart as Cart };

Cart.propTypes = {
  isLoggedIn: PropTypes.bool,
  onEdit: PropTypes.func,
  onConvertBottle: PropTypes.func,
  onRemove: PropTypes.func,
  onCheckout: PropTypes.func,
  onLogin: PropTypes.func,
  onDismiss: PropTypes.func,
  basketData: PropTypes.object,
  restaurantData: PropTypes.object,
  memberData: PropTypes.object,
  loading: PropTypes.bool,
};

Cart.defaultProps = {
  onEdit: () => {},
  onRemove: () => {},
  onCheckout: () => {},
  onLogin: () => {},
  onDismiss: () => {},
  onConvertBottle: () => {},
  isLoggedIn: false,
  basketData: {},
  memberData: {},
  restaurantData: {},
  loading: false,
};
