import React, { useState, useEffect } from "react";
import { newMemberActions, memberActions, alertActions } from "../../_actions";
import { rewardsService} from "../../_services";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import get from "lodash/get";
import "./styles.css";
import { useRenameDocument } from "../../context/renameDocument";

const Approved = ({ location, dispatch, email, isLoggedIn, history, pid, contactId, customerId}) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [validated, setValidated] = useState(false);
  const [promotion, setPromotion] = useState({});

  useRenameDocument("Approved");

  const formControlStyle = {
  backgroundColor: 'white',
  color: 'black',
  borderColor: '#000',
  borderRadius: 10,
}

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {setInnerWidth(window.innerWidth)});

  const handleSubmit = (event) => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      if (password === password2) {
        dispatch(newMemberActions.createCognitoUser(email, password, contactId));
        history.replace("/main");
      } else {
        dispatch(alertActions.error("Passwords do not match"));
      }
    }
  };

  const handleContinue = async () => {
    if (isLoggedIn) {
      dispatch(memberActions.getMember()).then(history.replace("/main"));
    } else {
      history.replace("/");
    }
  };

  useEffect(() => {
    dispatch(newMemberActions.setPromotionId(pid));
    rewardsService.getPromotion(pid).then(promo => {
      setPromotion(promo?.with);
    });
  }, [pid]);

  return location.state.newStyle ? (
     <div>
    <img
        className="image-to-be-gradiented"
        src={
          // eslint-disable-next-line
          innerWidth <= 576 && require('../../assets/images/bbbg-v3.png')
        }
        style={{ zIndex: -1 }}
        alt=""
      />
      <div className="image-gradient" style={{height: innerWidth <= 576 ? undefined : location.state.referralCode ? '92.8vh' : '91.3vh'}} />

      <div style={{ position: 'relative', top: innerWidth > 576 ? 50 : 160 }}>
      <div className="container">
        <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded newGiftPayment" style={{backgroundColor: 'white', maxHeight: 700}}>
          <h2 class="new-approved-header" id="payment-approved">
            Payment Approved
          </h2>
          <h6 class="log-title mb-sm-4" id="sub-text-one" style={{color: 'black'}}>
            Thank you for joining!
          </h6>
          <h6 class="log-title mb-sm-4" id="sub-text-two" style={{color: 'black'}}>
            Your Wine of the Month is now available on your account and ready
            for pick up at your local Cooper's Hawk.
            {pid && ' ' + promotion?.paymentApprovedMessage + '.'}
          </h6>
          <h6 class="log-title mb-sm-4" id="sub-text-three" style={{color: 'black'}}>
            {`Your${location.state.referralCode ? ' temporary ' : ' '}Wine Club Card Number is ${location.state.cardNum}`}
          </h6>
          
          {location.state.referralCode && 
            <h6 class="log-title mb-sm-4" id="sub-text-four" style={{color: 'black'}}>
              Your Welcome Bottle will be loaded onto your account after you've completed your 2nd Wine Club monthly payment.
            </h6>
          }

          {customerId ? (
            <Button onClick={() => handleContinue()}>Continue</Button>
            ) : (
              <Form noValidate validated={validated}>
              <Form.Group>
                <Form.Label for="approved-password" style={{color: 'black'}}>
                  Please take this opportunity to complete your online access
                  by setting up a password:
                </Form.Label>
                <Form.Control
                style={formControlStyle}
                  id="approved-password"
                  placeholder="Password"
                  required
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  pattern="^(?=.*[A-Za-z])(?=.*\d).{8,}$" // no idea why this works but importing the pattern does not
                  // should be using passwordRegexPattern from constants
                  />
                <small className="form-text text-muted" id="approved-password-hint">
                  At least eight characters, including letters and numbers.
                </small>
                <Form.Control.Feedback type="invalid">
                  Passwords must be at least 8 characters and include letters
                  and numbers.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label for="approved-confirm-passowrd" style={{color: 'black'}}  >Confirm New Password</Form.Label>
                <Form.Control
                style={formControlStyle}
                  id="approved-confirm-password"
                  placeholder="Confirm New Password"
                  required
                  onChange={e => {
                    setPassword2(e.target.value);
                  }}
                  type="password"
                  pattern={password}
                  />
                <Form.Control.Feedback type="invalid">
                  Passwords must match
                </Form.Control.Feedback>
              </Form.Group>
              <div style={{ height: '1em' }} />
              {/* <Button onClick={e => handleSubmit(e)}>Create Password</Button> */}
                    <div className="create-password-button" onPointerDown={(e) => handleSubmit(e)}>
                      <div className="create-password-text">
                        Create Password
                      </div>
                    </div>
              <div style={{ height: '10em' }} />
            </Form>
          )}
        </div>
          </div>
      </div>
    </div>
  ) : (
    <div className="container">
      <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
        <h2 class="log-title" id="approved-payment">
          Payment Approved
        </h2>
        <h6 class="log-title mb-sm-4" id="thanks-for-joining">
          Thank you for joining!
        </h6>
        <h6 class="log-title mb-sm-4" id="wine-of-month">
          Your Wine of the Month is now available on your account and ready
          for pick up at your local Cooper's Hawk.
          {pid && ' ' + promotion?.paymentApprovedMessage + '.'}
        </h6>
        <h6 class="log-title mb-sm-4" id="card-number">
          Your Wine Club Card Number is {location.state.cardNum}
        </h6>

        {customerId ? (
          <Button onClick={() => handleContinue()}>Continue</Button>
        ) : (
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label for="password">
                Please take this opportunity to complete your online access by
                setting up a password:
              </Form.Label>
              <Form.Control
                id="password"
                placeholder="Password"
                required
                onChange={e => {
                  setPassword(e.target.value);
                }}
                type="password"
                pattern="^(?=.*[A-Za-z])(?=.*\d).{8,}$" // no idea why this works but importing the pattern does not
                // should be using passwordRegexPattern from constants
              />
              <small className="form-text text-muted" id="password_hint">
                At least eight characters, including letters and numbers.
              </small>
              <Form.Control.Feedback type="invalid">
                Passwords must be at least 8 characters and include letters
                and numbers.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label for="password2">Confirm New Password</Form.Label>
              <Form.Control
                id="password2"
                placeholder="Confirm New Password"
                required
                onChange={e => {
                  setPassword2(e.target.value);
                }}
                type="password"
                pattern={password}
              />
              <Form.Control.Feedback type="invalid">
                Passwords must match
              </Form.Control.Feedback>
            </Form.Group>
            <div style={{ height: '1em' }} />
            <Button onClick={e => handleSubmit(e)}>Create Password</Button>
            <div style={{ height: '10em' }} />
          </Form>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { newMember, authentication } = state;
  const { data } = newMember;
  const isLoggedIn = get(authentication, "loggedIn", false);
  const billing = get(data, "billingAddress", "");
  const pid = get(data, "pid", "");
  const contactId = get(data, "Id", "");
  const customerId = get(data, "customerId", "");
  const email = get(billing, "email", "");
  return {
    email,
    isLoggedIn,
    pid,
    contactId,
    customerId,
  };
}

const connectedApproved = connect(mapStateToProps)(Approved);
export { connectedApproved as Approved };
