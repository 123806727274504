import { addAuthorization } from "../_helpers";
import { store } from "../_helpers/store";
import { appConstants, memberConstants } from "../_constants";
import { memberActions, basketsActions, paymentActions } from "../_actions";
import { createBrowserHistory } from "history";
import get from "lodash/get";
import set from "lodash/set";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import secureStorage from "../_helpers/secureStorage";
import moment from "moment";
import { updateCookie } from "../_helpers/withCookie";

const LAST_LOCATION = "data.lastLocation.CHW_OLO_Slug__c";

export const memberService = {
  activateGift,
  changeEmail,
  checkSupplierCode,
  createCognitoUser,
  getMember,
  switchMember,
  getMembersFromEmail,
  getMembersFromCustomerId,
  getMemberFromLastCard,
  getPoints,
  bottlePickup,
  login,
  loginSimple,
  loginWithToken,
  logout,
  postProfile,
  postReplacementCard,
  postSupplierMember,
  signup,
  forgotPassword,
  resetPassword,
  signupPotential,
  getOrCreate,
  refreshToken,
  marketingSubscribe,
  getMembersFromEmail,
  convertPotentialToMonthly,
  postReferral,
};


async function checkSupplierCode(code) {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    }

    return await fetch(
      `${appConstants.localApiUrl}members/supplier?code=${code}`,
      requestOptions,
    ).then(handleResponse)
      .then((data) => {
        const response = get(data, "with");
        return response;
      });
  } catch (e) {

  }
}


async function postSupplierMember(body, UUID, token) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization": token,
      },
      body: JSON.stringify(
        {
          member: {
            firstName: body.firstName,
            lastName: body.lastName,
            birthdate: body.dob,
            postalCode: body.zipCode,
            phone: body.phone,
            email: body.email,
            inviteCode: body.code
          },
          UUID,
        }),
    }

    return await fetch(
      `${appConstants.localApiUrl}members/supplier`,
      requestOptions,
    ).then(handleResponse)
      .then((data) => {
        const response = get(data, "with");
        return response;
      });
  } catch (e) {

  }
}


async function activateGift(memberObject) {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const contactId = get(user, "id", "");

  const MailingStreet = get(memberObject, "MailingStreet", "");
  const MailingCity = get(memberObject, "MailingCity", "");
  const MailingState = get(memberObject, "MailingState", "");
  const MailingPostalCode = get(memberObject, "MailingPostalCode", "");
  const OtherStreet = get(memberObject, "OtherStreet", "");
  const OtherCity = get(memberObject, "OtherCity", "");
  const OtherState = get(memberObject, "OtherState", "");
  const OtherPostalCode = get(memberObject, "OtherPostalCode", "");
  
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": token
    },
    body: JSON.stringify({ 
      contactId,
      MailingStreet,
      MailingCity,
      MailingState,
      MailingPostalCode,
      OtherStreet,
      OtherCity,
      OtherState,
      OtherPostalCode 
    })
  };

  return fetch(
    `${appConstants.localApiUrl}members/member/activate-gift`,
    requestOptions
  ).then(handleResponse);
}

async function changeEmail(email, oldEmail, contactId) {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": token
    },
    body: JSON.stringify({ email, contactId }),
  };

  return fetch(
    `${appConstants.localApiUrl}users/user/${oldEmail}/changeEmail`,
    requestOptions
  ).then(handleResponse);
}

async function createCognitoUser(username, password, contactId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password, contactId }),
  };

  return fetch(
    `${appConstants.localApiUrl}users/me/signup`,
    requestOptions
  ).then(handleResponse);
}

async function forgotPassword(usernameObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(usernameObj),
  };

  return fetch(
    `${appConstants.localApiUrl}users/me/forgotPassword`,
    requestOptions
  ).then(handleResponse);
}

async function resetPassword(passwordObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(passwordObj),
  };

  return fetch(
    `${appConstants.localApiUrl}users/me/resetPassword`,
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      return userDidAuthenticate(data);
    });
}

async function signup(memberObject) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(memberObject),
  };

  return fetch(`${appConstants.localApiUrl}members`, requestOptions).then(
    handleResponse
  );
}

async function signupPotential(memberObject) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(memberObject),
  };

  return fetch(
    `${appConstants.localApiUrl}members/potential`,
    requestOptions
  ).then(handleResponse);
}

async function convertPotentialToMonthly(memberObject) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(memberObject),
  };

  return fetch(
    `${appConstants.localApiUrl}members/convert-potential`,
    requestOptions
  ).then(handleResponse);
}

async function marketingSubscribe(memberObject) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(memberObject),
  };

  return fetch(
    `${appConstants.localApiUrl}members`,
    requestOptions
  ).then(handleResponse);
}

async function login(username, password, password2) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body:
      password2 === ""
        ? JSON.stringify({ username, password })
        : JSON.stringify({ username, password, newPassword: password2 }),
  };
  return await fetch(
    `${appConstants.localApiUrl}users/me/login`,
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      const userResponse = get(data, "with");
      const isAdmin = get(userResponse, "is_admin", false);
      return userDidAuthenticate(data);
    });
}

async function loginSimple(username, password, password2) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body:
      password2 === ""
        ? JSON.stringify({ username, password })
        : JSON.stringify({ username, password, newPassword: password2 }),
  };
  return await fetch(
    `${appConstants.localApiUrl}users/me/login`,
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {

      const userResponse = get(data, "with");
      const isAdmin = get(userResponse, "is_admin", false);
      return userResponse;
    });
}

async function loginWithToken(token, refreshToken, email) {
  return userDidAuthenticate({ "with": { "cognito_token": token, "cognito_expires_in": 1800, "cognito_refresh_token": refreshToken }}, email);
}



async function refreshToken(token) {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refresh_token: token })
    };

    addAuthorization(requestOptions.headers);

    return fetch(
      `${appConstants.localApiUrl}users/me/refreshToken`,
      requestOptions
    );
  } catch (e) {
  }
}

async function userDidAuthenticate(data, email) {
  const userResponse = get(data, "with");
  userResponse.exp = moment()
    .add(userResponse.cognito_expires_in, 'seconds')
    .toISOString();
  const theStore = store.getState();
  const username = get(theStore.authentication, "username", "a");
  const cognitoToken = get(userResponse, "cognito_token");
  await getMembersFromCustomerId(cognitoToken).then((res) => {
    let contacts = res.with;
    if (isEmpty(contacts)) {
      var ls = new secureStorage();
      ls.set("user", JSON.stringify(userResponse));
      updateCookie();
      const isAdmin = get(userResponse, "is_admin", false);
      store.dispatch(
        isAdmin
          ? { type: memberConstants.ADMIN }
          : {
              type: memberConstants.NO_CONTACTS,
            }
      );
      if (isAdmin) {
        return userResponse;
      }
    }
    let contact = head(contacts);
    let status = get(contact, "CHW_Status__c", "");
    if (status === "To be Activated") {
      store.dispatch({
        type: memberConstants.INACTIVE,
      });
    }
    let id = get(contact, "Id", "");
    userResponse.id = id;
    var ls = new secureStorage();
    ls.set("user", JSON.stringify(userResponse));
    ls.set("contact", JSON.stringify(contact));

    updateCookie();
    store.dispatch({
      type: memberConstants.GETMEMBERS_FROM_EMAIL_SUCCESS,
      response: res,
    });
    store.dispatch(memberActions.getMember(true));
  });
  return userResponse;
}

async function logout() {
  const history = createBrowserHistory({ forceRefresh: true });

  try {
    const oldStore = localStorage.getItem("persist:root");
    localStorage.clear();

    // Restore last location
    if (oldStore) {
      const json = JSON.parse(oldStore);
      const lastLoc = get(JSON.parse(json.member), LAST_LOCATION, "");
      const newBranch = JSON.stringify(set({}, LAST_LOCATION, lastLoc));
      localStorage.setItem("persist:root", JSON.stringify({ member: newBranch }));
    }

    updateCookie();
    history.replace("/");
  } catch (e) {
    history.replace("/");
  }
}

async function getMemberFromLastCard(lastName, cardNumber) {
  const requestOptions = {
    method: "GET",
  };
  return await fetch(
    `${
      appConstants.localApiUrl
    }members/member?lastName=${lastName}&cardNumber=${cardNumber}`,
    requestOptions
  ).then(handleResponse);
}

async function getMembersFromEmail(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };
  return await fetch(
    `${appConstants.localApiUrl}members/search`,
    requestOptions
  ).then(handleResponse);
}

async function getMembersFromCustomerId(customerId) {
  const requestOptions = {
    method: "GET",
  };
  return await fetch(
    `${appConstants.localApiUrl}members/searchByCustomerId?customerId=${customerId}`,
    requestOptions
  ).then(handleResponse);
}

async function getMember() {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const id = get(user, "id", "");
  const token = get(user, "cognito_token", "");
  const requestOptions = {
    method: "GET",
    headers: { "authorization": token }
  };
  return await fetch(
    `${appConstants.localApiUrl}members/member/info?contactId=${id}`,
    requestOptions
  ).then(handleResponse);
}

async function switchMember(contact) {
  var ls = new secureStorage();
  const existingUser = JSON.parse(ls.get("user"));
  const id = typeof contact === "string" ? contact : get(contact, "Id", "");
  existingUser["id"] = id;
  ls.set("user", JSON.stringify(existingUser));
  updateCookie();
}

async function getPoints() {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const id = get(user, "id", "");
    const requestOptions = {
    method: "GET",
    headers: { "authorization": token }
  };
  return fetch(
    `${appConstants.localApiUrl}members/member/points-history?contactId=${id}`,
    requestOptions
  ).then(handleResponse);
}

async function bottlePickup(pickupRequest) {
  try {
    const { restaurant, basket, authentication } = store.getState();
    const loggedIn = get(authentication, "loggedIn", false);

    if (!loggedIn) {
      return Promise.resolve();
    }

    var ls = new secureStorage();
    const user = JSON.parse(ls.get("user"));
    const id = get(user, "id", "");
    const token = get(user, "cognito_token", "");

    const checkNumber = get(pickupRequest, "checkNumber", 0);

    const basketData = get(basket, "data", {});
    const basketProducts = get(basketData, "products", []);
    const timeMode = get(basketData, "timemode", "asap");
    const timeWanted = get(basketData, "timewanted", "");
    const readyTime = get(basketData, "earliestreadytime", "");
    let year  = "3000";
    let month = "01";
    let day   = "01";
    let time  = "00:00";

    if(timeMode !== "asap" && timeWanted?.length > 9) {
      year  = timeWanted.slice(0,4);
      month = timeWanted.slice(4,6);
      day   = timeWanted.slice(6,8);
      time  = timeWanted.slice(9);
    }else if(timeMode === "asap" && readyTime?.length > 9) {
      year  = readyTime.slice(0,4);
      month = readyTime.slice(4,6);
      day   = readyTime.slice(6,8);
      time  = readyTime.slice(9);
    }

    const restaurantData = get(restaurant, "data", {});
    const slug = get(restaurantData, "slug", "");
    const utcOffset = get(restaurantData, "utcoffset", 0);

    const bottleConversions = [];

    basketProducts.forEach((product) => {
      const customdata = get(product, "customdata", "");
      let bottlePickup = {};
      if (customdata?.startsWith("conversion:") ?? false) {
        const customAlt = customdata.split("conversion:");
        bottlePickup.sfId = customAlt.length > 1 ? customAlt[1] : "";
        bottlePickup.quantity = get(product, "quantity", 0);
        bottlePickup.additionalCost = get(product, "basecost", 0);
        bottleConversions.push(bottlePickup);
      }
    });

    const pickup = {
      request: {
        contactId: id,
        restaurauntOloSlug: slug,
        checkNumber: checkNumber,
        oloBottles: bottleConversions,
        pickupDate: `${year}-${month}-${day} ${time}:00`,
        utcOffset: utcOffset
      },
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization": token
      },
      body: JSON.stringify(pickup),
    };

    return fetch(
      `${appConstants.localApiUrl}members/member/bottle-pickup?contactId=${id}`,
      requestOptions
    ).then(handleResponse);
  } catch (e) {
    console.error(e.message);
    return Promise.resolve();
  }
}

async function postProfile(profileObject) {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": token,
    },
    body: JSON.stringify(profileObject),
  };

  const response = await fetch(
    `${appConstants.localApiUrl}members/member/post-profile`,
    requestOptions
  );
  return handleResponse(response);
}

function postReplacementCard() {
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const token = get(user, "cognito_token", "");
  const id = get(user, "id", "");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": token
    },
  };
  return fetch(
    `${appConstants.localApiUrl}members/member/replace-card?contactId=${id}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 400) {
        if (data.because === "EmailAlreadyInUse") {
          return Promise.reject(
            "This email is already in use as a Wine Club login."
          );
        }
        return Promise.reject(data.because);
      }
      if (response.status === 404) {
        if (data.because === "UserDoesNotExist") {
          return Promise.reject(data.because);
        }
      }
      if (response.status === 401) {
        if (
          data.because === "PasswordResetRequired" ||
          data.because === "InvalidLogin"
        ) {
          return Promise.reject(data.because);
        } else {
          // auto logout if 401 response returned from api
          logout();
          // eslint-disable-next-line no-restricted-globals
          location.reload(true);
        }
      }
      if (response.status === 422) {
        // Possible Values from REST_CREATE_MEMBER
        // CANNOT FIND MEMBER. NO EMAIL IN REQUEST
        // NO MEMBER EXISTS WITH EMAIL: {EMAIL}
        // NOT A POTENTIAL MEMBER: {EMAIL}
        // MULTIPLE EMAILS EXISTS
        // CANNOT UPGRADE MULTIPLE MEMBERS
        if (data.because?.startsWith('NO MEMBER EXISTS WITH EMAIL:')) {
          return Promise.reject('Unable to upgrade carryout account to membership. Please login or signup');
        }
        else if (data.because === 'CANNOT FIND MEMBER. NO EMAIL IN REQUEST') {
          return Promise.reject('Whoops! It looks like you forgot to enter an email. Please try again');
        }
        else if (
          data.because === 'MULTIPLE EMAILS EXISTS' ||
          data.because === 'CANNOT UPGRADE MULTIPLE MEMBERS' ||
          data.because?.startsWith('NOT A POTENTIAL MEMBER:')
        ) {
          return Promise.reject('It looks like you already have a membership with us');
        }
        else {
          return Promise.reject(data.because);
        }
      }
      if (
        data.because === "ErrorUpdatingEmail" ||
        data.because === "EmailAlreadyInUse"
      ) {
        return Promise.reject(
          "Unable to update email. Please ensure it is not already in use as a Wine Club login."
        );
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

async function getOrCreate(user) {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    };

    return fetch(`${appConstants.oloApiUrl}/users/getOrCreate`, requestOptions);
  } catch (e) {
  }
}

async function postReferral(referralCode, memberId) {
  const bodyObject = {
    referralCode: referralCode,
    memberNumber: memberId,
  };
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyObject),
    };

    return fetch(
      `${appConstants.webApiUrl}referral`,
      requestOptions,
    );
  } catch (e) {}
}

async function deleteOloUser(token) {
  try {
    const requestOptions = {
      method: "DELETE",
    };

    return fetch(`${appConstants.oloApiUrl}/users/${token}`, requestOptions);
  } catch (e) {
  }
}
