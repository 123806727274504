import React from "react";
import { connect } from "react-redux";
import LoadingSpinners from "../../components/LoadingSpinners";
import { UpcomingEvents } from "./upcoming-events";

import "./styles.css";

const Events = ({ isLoading }) => {
  return isLoading ? (
    <LoadingSpinners />
  ) : (
    <div>
      <UpcomingEvents />
    </div>
  );
};

function mapStateToProps(state) {
  const { events } = state;
  return {
    isLoading: events.loading
  };
}

const connectedEvents = connect(mapStateToProps)(Events);
export { connectedEvents as Events };
