import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles.scoped.scss";
import { withRouter } from "react-router-dom";
import { useWindowDimensions } from "../../_helpers";
import forEach from "lodash/forEach";
import MenuCategories from "../../components/MenuCategories";
import { MenuCategoriesSelect } from "../../components/MenuCategoriesSelect";
import { MenuItemCollection } from "../../components/MenuItemCollection";
import Footer from "../../components/Footer";

const ScrollableMenu = (props) => {
  const { menu, onSelectItem, onQuickItem, location } = props;
  let categories = menu && menu.categories && menu.categories;
  const defaultFirstCategory = categories ? categories.find(category => !category.name.includes("-") && !category.isHidden)  : null;

  const [category, setCategory] = useState();
  const [parentCategory, setParentCategory] = useState(defaultFirstCategory);
  const [selectedSubcat, setSelectedSubcat] = useState(null);
  const scrollRef = useRef(null);
  const categoryRefs = useRef({})
  const { width } = useWindowDimensions();

  React.useEffect(() => {
    if(width < 768) {
      scrollRef.current.scrollTo({
        top: 40,
        behavior: "smooth",
      });
    }

  }, []);
  
  React.useEffect(() => {
    let hash = location.hash;
    if(hash) {
      hash = hash.replace("#", '');
      const category = categories ? categories.find(x => x.name && x.name.toLowerCase() === hash) : null;
      if(category) {
        handleSetCategory(category);
      }
    }
    
  }, [location.hash])

  // Watch scroll position and update category
  React.useEffect(() => {
    const curScrollRef = scrollRef.current;
    let lastPos = curScrollRef.scrollTop;
    let isInit = false;

    const handleScroll = () => {
      if (Math.abs(curScrollRef.scrollTop - lastPos) > 0 || !isInit) {
        isInit = true;
        let lastCat = categories[0];
        let lastParentCat = defaultFirstCategory;

        forEach(categories, (cat) => {
          if (!categoryRefs.current[cat.id]) {
            return
          }

          const catPos =
            categoryRefs.current[cat.id].offsetTop -
            scrollRef.current.offsetTop -
            categoryRefs.current[cat.id].scrollTop -
            120;
          
          const isParentCategory = !cat.name.includes("-") && !cat.isHidden;
          if(isParentCategory && catPos <= curScrollRef.scrollTop ) {
            lastParentCat = cat;
          }
          
          if (catPos > curScrollRef.scrollTop) {
            return false;
          }
          lastCat = cat;
        });

        setCategory(lastCat);
        
        setParentCategory(lastParentCat);

        lastPos = curScrollRef.scrollTop;
      }
    };
    curScrollRef.addEventListener("scroll", handleScroll, false);

    return () => {
      curScrollRef.removeEventListener("scroll", handleScroll, false);
    };
  }, [scrollRef.current, menu, categoryRefs.current]);

  const handleSetCategory = (cat) => {
    const catRef = categoryRefs.current[cat.id];

    if (catRef) {
      let topScroll = catRef.offsetTop - scrollRef.current.offsetTop;
      if (width < 768) {
        if (cat.subcategories.length !== 0) {
          topScroll += 40;
        } else {
          topScroll += 20;
        }
      }
      scrollRef.current.scrollTo({
        top: topScroll,
      });
    }
  };


  return (
    <div className="menu-container">
      <div className="no-flex d-none d-md-block">
        <MenuCategories
          data={categories}
          value={parentCategory || category || defaultFirstCategory}
          onSelect={handleSetCategory}
        />
      </div>

      <div className="no-flex d-md-none select-container">
        <MenuCategoriesSelect
          innerRefs={categoryRefs.current}
          data={categories}
          value={parentCategory}
          onSelect={handleSetCategory}
          subcatSelected={(cat) => setSelectedSubcat(cat)}
          subcat={category}
        />
      </div>

      <div
        className="scroll-container"
        ref={scrollRef}
        style={{ height: "calc(100vh - 275px)" }}
      >
        {!!categories &&
          categories.map((cat) => (
            <div key={cat.id}>
              {cat.isHidden ? null : (
                <div ref={(ref) => (categoryRefs.current[cat.id] = ref)} id="menu-container-list">
                  <MenuItemCollection
                    innerRefs={categoryRefs.current}
                    data={cat}
                    imagePath={menu.imagepath}
                    onSelectItem={onSelectItem}
                    onQuickItem={onQuickItem}
                    onSelectCat={handleSetCategory}
                    selectedSubcat={selectedSubcat}
                  />
                </div>
              )}
            </div>
          ))}
          <div className="footer-container">
            <Footer />
          </div>
      </div>
    </div>
  );
};

export default withRouter(ScrollableMenu);

ScrollableMenu.propTypes = {
  product: PropTypes.object,
  imagePath: PropTypes.string,
  onSelectItem: PropTypes.func,
};

ScrollableMenu.defaultProps = {
  product: {},
  onSelectItem: () => {},
};
