import React from "react";
import { memberConstants } from "../_constants";
import { Baskets, memberService } from "../_services";

import {
  alertActions,
  newsActions,
  eventActions,
  pickupHistoryActions,
  openBottleActions,
  staticContentActions,
  rewardsActions,
  paymentActions,
  basketsActions,
} from "./";
import { barrelActions } from "./barrel.actions";
import get from "lodash/get";
import secureStorage  from "../_helpers/secureStorage";
import {updateCookie} from "../_helpers/withCookie";
import {store} from "../_helpers";

export const memberActions = {
  activateGift,
  barrelLogin,
  changeEmail,
  clearAuthErrors,
  setEmail,
  confirmReplacementCard,
  getMember,
  switchMember,
  getMembersFromEmail,
  getPoints,
  bottlePickup,
  login,
  loginWithToken,
  logout,
  forgotPassword,
  resetPassword,
  postProfile,
  postReplacementCard,
  updateBottleLevel,
  updateBirthday,
  updateHomeCity,
  updateShippingCity,
  updateClubType,
  updateEmail,
  updateFirstName,
  updateLastName,
  updateOptOut,
  updatePhone,
  updateSecondaryFirstName,
  updateSecondaryLastName,
  updateSecondaryBirthday,
  updateHomeState,
  updateHomeStreet,
  updateHomeZip,
  updateShippingState,
  updateShippingStreet,
  updateShippingZip,
  updateGiftLength,
  updatePickupType,
  toggleMemberBanner,
  setLoginDestination,
  clearLoginDestination,
  autoLogin,
  getOrCreate,
  sendReferral,
  updateSupplierInfo
};

function login(username, password, password2 = "", basketData, suppressError = false) {
  const { settings } = store.getState();
  return dispatch => {
    dispatch(request({ username }));
    memberService.login(username, password, password2).then(
      response => {
        basketData && settings && !(get(settings, "handoff") === 'dispatch') && dispatch(basketsActions.coupon(basketData.id, true))
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));

        if (error === "PasswordResetRequired") {
          dispatch(newPass());
        } else {
          if(!suppressError){
            dispatch(alertActions.error("Invalid email address or password."));
          }
        }
      }
    );
  };

  function newPass() {
    return { type: memberConstants.NEW_PASSWORD_REQUIRED };
  }

  function admin() {
    return { type: memberConstants.ADMIN };
  }

  function request(user) {
    return { type: memberConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: memberConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: memberConstants.LOGIN_FAILURE, error };
  }
}


function loginWithToken(email, token, refreshToken, basketData, suppressError = false) {
  const { settings } = store.getState();
  return dispatch => {
    dispatch(request({ username: email }));
    memberService.loginWithToken(token, refreshToken, email).then(
      response => {
        basketData && settings && !(get(settings, "handoff") === 'dispatch') && dispatch(basketsActions.coupon(basketData.id, true))
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));

        if (error === "PasswordResetRequired") {
          dispatch(newPass());
        } else {
          if(!suppressError){
            dispatch(alertActions.error("Invalid email address or password."));
          }
        }
      }
    );
  };

  function newPass() {
    return { type: memberConstants.NEW_PASSWORD_REQUIRED };
  }

  function admin() {
    return { type: memberConstants.ADMIN };
  }

  function request(user) {
    return { type: memberConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: memberConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: memberConstants.LOGIN_FAILURE, error };
  }
}


function activateGift(memberObj){
  return dispatch => {
    dispatch(request());
    memberService.activateGift(memberObj).then(
      response => {
        dispatch(success(response));
        if(response.with.status === "ERROR"){
          if(response.with.message?.includes("Shipping Address")){
            dispatch(alertActions.errorWithX("The shipping address could not be validated. Please confirm your details and try again"));
          } else{
            dispatch(alertActions.errorWithX(response.with.message));
          }
        } else{
          dispatch(memberActions.getMember());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  
  function request() {
    return { type: memberConstants.ACTIVATE_GIFT_REQUEST };
  }
  function success(response) {
    return { type: memberConstants.ACTIVATE_GIFT_SUCCESS, response };
  }
  function failure(error) {
    return { type: memberConstants.ACTIVATE_GIFT_FAILURE, error };
  }
}

function setEmail(email) {
  return { type: memberConstants.SET_EMAIL, email };
}

function sendReferral(referralCode, memberNumber) {
    return dispatch => {
      dispatch(request());
      memberService.postReferral(referralCode, memberNumber).then((response) => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
      );
    };

    function request() {
    return { type: memberConstants.POSTMEMBER_REFERRALCODE_REQUEST };
  }
  function success(response) {
    return { type: memberConstants.POSTMEMBER_REFERRALCODE_SUCCESS, response };
  }
  function failure(error) {
    return { type: memberConstants.POSTMEMBER_REFERRALCODE_FAILURE, error };
  }
  };

function changeEmail(email, oldEmail, contactId) {
  return dispatch => {
    dispatch(request());
    memberService.changeEmail(email, oldEmail, contactId).then(
      response => {
        dispatch(success(response));
        dispatch(memberActions.getMember(true));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: memberConstants.CHANGE_EMAIL_REQUEST };
  }
  function success(response) {
    return { type: memberConstants.CHANGE_EMAIL_SUCCESS, response };
  }
  function failure(error) {
    return { type: memberConstants.CHANGE_EMAIL_FAILURE, error };
  }
}

function barrelLogin(username, password) {
  return dispatch => {
    dispatch(request({ username }));
    memberService.login(username, password).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: memberConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: memberConstants.BARREL_LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: memberConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return dispatch => {
    dispatch({ type: "LOGOUT" })
    memberService.logout();
  };
}

function forgotPassword(usernameObj) {
  return dispatch => {
    dispatch(request());
    memberService.forgotPassword(usernameObj).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: memberConstants.FORGOT_PASSWORD_REQUEST };
  }
  function success(response) {
    return { type: memberConstants.FORGOT_PASSWORD_SUCCESS, response };
  }
  function failure(error) {
    return { type: memberConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function resetPassword(passwordObj) {
  const username = passwordObj.username;
  return dispatch => {
    dispatch(request({ username }));
    memberService.resetPassword(passwordObj).then(
      response => {
        dispatch(success(response));
      },
      error => {
        if ((error = "Internal Server Error")) {
          dispatch(
            alertActions.error(
              "Error verifying new password. Please ensure all fields are entered correctly, with no spaces."
            )
          );
        }
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: memberConstants.RESET_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: memberConstants.RESET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: memberConstants.RESET_PASSWORD_FAILURE, error };
  }
}

function getPoints() {
  return dispatch => {
    dispatch(request());

    memberService.getPoints().then(
      points => dispatch(success(points)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: memberConstants.GETPOINTS_REQUEST };
  }
  function success(points) {
    return { type: memberConstants.GETPOINTS_SUCCESS, points };
  }
  function failure(error) {
    return { type: memberConstants.GETPOINTS_FAILURE, error };
  }
}

function bottlePickup(pickupRequest) {
  return dispatch => {
    dispatch(request());

    memberService.bottlePickup(pickupRequest).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: memberConstants.BOTTLE_PICKUP_REQUEST };
  }
  function success(response) {
    return { type: memberConstants.BOTTLE_PICKUP_SUCCESS, response };
  }
  function failure(error) {
    return { type: memberConstants.BOTTLE_PICKUP_FAILURE, error };
  }
}

function getMember(doGetOrCreate) {
  return dispatch => {
    const { settings, member } = store.getState();
    let email = get(member, "email", "");
    let isEmptyContact = false;
    try {
      var ls = new secureStorage();
      const user = JSON.parse(ls.get("user"));
      const id = get(user, "id", "");
      isEmptyContact = !id;
    } catch (error) {
      console.error(error)
    }
    if(isEmptyContact) {
      setTimeout(() => {
        dispatch(failure("memberError"));
      })
      return showAccountError(true, email);
    }

    dispatch(request());
    return memberService
      .getMember()
      .then(
        member => {
          dispatch(success(member));
          dispatch(newsActions.getNews());

          if (doGetOrCreate) {
            try {
              const ls = new secureStorage();
              const cognitoToken = JSON.parse(ls.get("user")).cognito_token;
              dispatch(memberActions.getOrCreate(member.with, cognitoToken))
            } catch (e) {
              console.log("Caught", e);
            }
          }

          if (member.with.status === "Active" 
          && member.with.membershipType !== "Potential"
          && settings
          && !(get(settings, "handoff") === 'dispatch')
          ) {
            dispatch(basketsActions.coupon('', true));
          } else {
            dispatch(basketsActions.coupon('', false));
          }
          dispatch(eventActions.getEvents());
          dispatch(pickupHistoryActions.getPickupHistory());
          dispatch(openBottleActions.getOpenBottles());
          dispatch(staticContentActions.getStaticContent());
          dispatch(rewardsActions.getRewards());
          dispatch(barrelActions.getBarrelOrders());
          return member;
        },
        error => {
          dispatch(failure(error));
          if (error === "Forbidden") {
            showAccountError(isEmptyContact, email);
            return;
          }
          dispatch(
            alertActions.error("An error has occurred. Please try again.")
          );
        }
      )
      .then((member) => {
        dispatch(memberActions.getPoints());
        return member
      });
  };

  function showAccountError(isEmptyContact, email) {
    alertActions.error(
      null,
      <div>
        <span>
          There is a problem with your account, please contact member and
          guest services at{" "}
          <a href="tel:(708) 215-5674">(708) 215-5674</a>.
        </span>
        <br/>
        {isEmptyContact && (
          <span>
            ErrorCode A100 - {email}
          </span>
        )}
      </div>
    );
  }

  function request() {
    return { type: memberConstants.GETMEMBER_REQUEST };
  }
  function success(member) {
    return { type: memberConstants.GETMEMBER_SUCCESS, member };
  }
  function failure(error) {
    return { type: memberConstants.GETMEMBER_FAILURE, error };
  }
}

function switchMember(id) {
  return dispatch => {
    dispatch(request());
    memberService
      .switchMember(id)
      .then(() => {
        dispatch(memberActions.getMember(true));
      })
      .then(() => {
        dispatch(success(id));
      });
  };

  function request() {
    return { type: memberConstants.SWITCH_MEMBER_REQUEST };
  }
  function success(id) {
    return { type: memberConstants.SWITCH_MEMBER_SUCCESS, id };
  }
  function failure(error) {
    return { type: memberConstants.SWITCH_MEMBER_FAILURE, error };
  }
}

function getMembersFromEmail(email) {
  return dispatch => {
    dispatch(request());
    memberService.getMembersFromEmail(email).then(
      response => {
        console.log('response', response);
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: memberConstants.GETMEMBERS_FROM_EMAIL_REQUEST };
  }
  function success(response) {
    return { type: memberConstants.GETMEMBERS_FROM_EMAIL_SUCCESS, response };
  }
  function failure(error) {
    return { type: memberConstants.GETMEMBERS_FROM_EMAIL_FAILURE, error };
  }
}

function postReplacementCard() {
  return dispatch => {
    dispatch(request());

    memberService.postReplacementCard().then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: memberConstants.REPLACEMENT_CARD_REQUEST };
  }
  function success(response) {
    return { type: memberConstants.REPLACEMENT_CARD_SUCCESS, response };
  }
  function failure(error) {
    return { type: memberConstants.REPLACEMENT_CARD_FAILURE, error };
  }
}

function confirmReplacementCard() {
  return dispatch => {
    dispatch(confirm());

    function confirm() {
      return { type: memberConstants.REPLACEMENT_CARD_CONFIRM };
    }
  };
}

function postProfile(profileObject) {
  return dispatch => {
    dispatch(request());
    memberService.postProfile(profileObject).then(
      response => {
        dispatch(success(response));
        dispatch(memberActions.getMember(true));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: memberConstants.POSTMEMBER_REQUEST };
  }
  function success(response) {
    return { type: memberConstants.POSTMEMBER_SUCCESS, response };
  }
  function failure(error) {
    return { type: memberConstants.POSTMEMBER_FAILURE, error };
  }
}

/**
 * Get or create a new SSO user with OLO
 * @param profileObject
 */
function getOrCreate(member, cognitoToken) {
  return async dispatch => {
    try {
      const { basket } = store.getState();
      const basketId = get(basket, 'data.id');

      const requestBody = {
        provider: 'coopershawk',
        provideruserid: member.sfId,
        providertoken: cognitoToken,
        contactnumber: member.phone || '',
        firstname: member.firstName || '',
        lastname: member.lastName || '',
        emailaddress: member.email || '',
      };

      if (basketId) {
        requestBody.basketid = basketId;
      }

      dispatch(request());
      const response = await memberService.getOrCreate(requestBody, cognitoToken);
      const json = await response.json();
      if (json.authtoken) {
        const ls = new secureStorage();
        ls.set("oat", json.authtoken);
        updateCookie();
        dispatch(paymentActions.getPayments());
        dispatch(success(json));
        return json;
      }
    } catch (e) {
      console.log('Caught', e);
      dispatch(failure(e.message));
    }
  };

  function request() {
    return { type: memberConstants.GET_OR_CREATE_REQUEST };
  }
  function success(result) {
    return { type: memberConstants.GET_OR_CREATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: memberConstants.GET_OR_CREATE_FAILURE, error };
  }
}


function updateOptOut() {
  return { type: memberConstants.UPDATE_OPTOUT };
}

function updateClubType(clubType) {
  return { type: memberConstants.UPDATE_CLUBTYPE, clubType };
}

function updateBottleLevel(bottleLevel) {
  return { type: memberConstants.UPDATE_BOTTLELEVEL, bottleLevel };
}

function updateFirstName(firstName) {
  return { type: memberConstants.UPDATE_FIRSTNAME, firstName };
}

function updateLastName(lastName) {
  return { type: memberConstants.UPDATE_LASTNAME, lastName };
}

function updateBirthday(birthday) {
  return { type: memberConstants.UPDATE_BIRTHDAY, birthday };
}

function updateSecondaryFirstName(firstName) {
  return { type: memberConstants.UPDATE_SECONDARY_FIRSTNAME, firstName };
}

function updateSecondaryLastName(lastName) {
  return { type: memberConstants.UPDATE_SECONDARY_LASTNAME, lastName };
}

function updateSecondaryBirthday(birthday) {
  return { type: memberConstants.UPDATE_SECONDARY_BIRTHDAY, birthday };
}

function updatePhone(phone) {
  return { type: memberConstants.UPDATE_PHONE, phone };
}

function updateEmail(email) {
  return { type: memberConstants.UPDATE_EMAIL, email };
}

function updateHomeStreet(street) {
  return { type: memberConstants.UPDATE_HOME_STREET, street };
}

function updateHomeCity(city) {
  return { type: memberConstants.UPDATE_HOME_CITY, city };
}

function updateHomeState(state) {
  return { type: memberConstants.UPDATE_HOME_STATE, state };
}

function updateHomeZip(zip) {
  return { type: memberConstants.UPDATE_HOME_ZIP, zip };
}

function updateShippingStreet(street) {
  return { type: memberConstants.UPDATE_SHIPPING_STREET, street };
}

function updateShippingCity(city) {
  return { type: memberConstants.UPDATE_SHIPPING_CITY, city };
}

function updateShippingState(state) {
  return { type: memberConstants.UPDATE_SHIPPING_STATE, state };
}

function updateShippingZip(zip) {
  return { type: memberConstants.UPDATE_SHIPPING_ZIP, zip };
}

function updateGiftLength(giftLength) {
  return { type: memberConstants.UPDATE_GIFT_LENGTH, giftLength };
}

function updatePickupType(pickupType) {
  return { type: memberConstants.UPDATE_PICKUP_TYPE, pickupType };
}

function toggleMemberBanner() {
  return { type: memberConstants.TOGGLE_MEMBER_HEADER };
}

function setLoginDestination(destination) {
  return { type: memberConstants.SET_LOGIN_DESTINATION, destination };
}

function clearLoginDestination() {
  return { type: memberConstants.CLEAR_LOGIN_DESTINATION };
}

function clearAuthErrors() {
  return { type: memberConstants.CLEAR_AUTH_ERRORS };
}

function autoLogin() {
  return { type: "AUTO_LOGIN" };
}

function updateSupplierInfo(data) {
  return { type: memberConstants.UPDATE_SUPPLIER, data };
}