import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import size from "lodash/size";
import "./styles.css";

const PickupHistory = ({ pickupHistory }) => {
  if (size(pickupHistory, 0) === 0) {
    return (
      <div>
        <Row>
          <Col md={12}>
            <h2 className="log-title">Bottle History</h2>
          </Col>

          <Col md={12}>
            <Table>
              <thead>
                <tr>
                  <td className="like-th">There are no bottles to display.</td>
                </tr>
              </thead>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div>
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="log-title">Bottle History</h2>
        </Col>

        <Col md={10}>
          <Table borderless size="sm" className="my-md-4">
            <thead>
              <tr>
                <td className="like-th">Date</td>
                <td className="like-th">Wine</td>
              </tr>
            </thead>
            <tbody>
              {pickupHistory.map((bottle, index) => (
                <tr key={index}>
                  <td>{new Date(bottle.pickupDate).toLocaleDateString()}</td>
                  <td>{bottle.womName}{" "}{bottle.hasLuxUpgrade ? "(Lux Upgrade Redeemed)" : ""}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { pickupHistory } = state;
  return {
    pickupHistory: pickupHistory.data
  };
}

const connectedPickupHistory = connect(mapStateToProps)(PickupHistory);
export { connectedPickupHistory as PickupHistory };
