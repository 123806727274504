// Utilities to assist with conditional themeing
import { themeActions } from "../_actions";
import { Restaurants } from "../_services";
import { themes } from "./themes/style-overrides";

export const isEsquireLocation = (location) => {
  return isEsquireSlug(location.slug);
};

export const isEsquireSlug = (slug) => {
  if(!slug){
    return false;
  }
  const esquireLocationSlugs = [ 'esquire-by-coopers-hawk-demo-vendor', 'esquire'];
  return esquireLocationSlugs.includes(slug);
}

export const setTheme = (theme, document) => {
  const root = document.documentElement;

  for (const [key, value] of Object.entries(theme)) {
    root.style.setProperty(key, value);
  }
}

export const isThemeEsquire = () => {
  const theme = window.sessionStorage.getItem('ch-theme');
  return theme?.includes('esquire');
}

export const applyThemeForSlug = (slug, document) => {
  if(isEsquireSlug(slug)){
    setTheme(themes.esquire.cssVars, document);
    window.sessionStorage.setItem('ch-theme', 'esquire');
  }
  else {
    setTheme(themes.default.cssVars, document);
    window.sessionStorage.setItem('ch-theme', 'chwinery')
  }
}
