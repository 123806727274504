import { memberConstants } from "../_constants";
import secureStorage  from "../_helpers/secureStorage";
import { isEmpty } from "lodash";

let ls = new secureStorage();
let user = ls.get("user");

if (user === "") {
  user = "{}";
}

user = JSON.parse(user);

const initialState = !isEmpty(user) && !user.privateMode
  ? { loggedIn: true, username: user.username }
  : { loggingIn: false, loggedIn: false, shouldResetPassword: false };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case memberConstants.NO_CONTACTS:
      return {
        ...state,
        signinError: "no-contacts",
      };
    case memberConstants.INACTIVE:
      return {
        ...state,
        signinError: "inactive",
      };
    case memberConstants.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        shouldResetPassword: true,
        hasResetPassword: false,
      };

    case memberConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: false,
        loggingIn: true,
        username: action.user.username,
      };
    case memberConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
      };
    case memberConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        error: action.error,
      };
    case memberConstants.LOGOUT:
      return { loggedIn: false };
    default:
      return state;
    case memberConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loggingIn: true,
        username: action.user.username,
      };
    case memberConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        user: action.user,
        passwordUpdated: true,
      };
    case memberConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        error: action.error,
      };
    case memberConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        shouldResetPassword: false,
        hasResetPassword: true,
        loggingIn: true,
        passwordUpdated: false,
      };
    case memberConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loggingIn: false,
      };

    case memberConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loggingIn: false,
      };
    case memberConstants.GETMEMBER_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
      };
    case memberConstants.GETMEMBER_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
      };
    case "AUTO_LOGIN":
      return {
        ...state,
        loggedIn: true,
        username: "Dev",
      };
  }
}
