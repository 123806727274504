import React, { useEffect, useState } from "react";
import LoadingSpinners from "../../components/LoadingSpinners";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import { remove } from "js-cookie";

const PaymentFrame = ({ loading, url, frameAction }) => {
  const [isMessageTimeout, setisMessageTimeout] = useState(false);
  useEffect(() => {
    window.addEventListener("message", handleIframeTask);
    isMessageTimeout && window.location.replace("./Timeout")
  });

  const handleIframeTask = e => {
    // find if the payload has a timeout error
    const task = e.data.toString().trim();
    const removeText = task.replace("error|","");
    const regex = /MESSAGE":"TRANSACTION/g;
    const found = removeText.match(regex);
    const stringFound = found?.toString();
    const stringFoundEdit = stringFound?.replace(`":"`,"");
    stringFoundEdit?.includes("MESSAGETRANSACTION") && setisMessageTimeout(true) ;
    isMessageTimeout && console.log('did ittt')
// 

    frameAction(e.data.toString());
  };

  return (
    <div className="container">
      {loading && <LoadingSpinners />}
      <div className="frameWrapper">
        <iframe
          title="payment-frame"
          height="100%"
          width="100%"
          src={`${url}`}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {}

const connectedPaymentFrame = withRouter(
  connect(mapStateToProps)(PaymentFrame)
);
export { connectedPaymentFrame as PaymentFrame };
