import { adminConstants } from "../_constants";

export function adminSearch(state = { loading: false, data: [] }, action) {
  switch (action.type) {
    case adminConstants.ADMIN_CLEAR_RESULTS:
      return {
        ...state,
        data: []
      };
    case adminConstants.ADMIN_SEARCH_REQUEST:
      return {
        loading: true
      };
    case adminConstants.ADMIN_SEARCH_SUCCESS:
      return {
        loading: false,
        data: action.results.with
      };
    case adminConstants.ADMIN_SEARCH_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
