import { restaurantsConstants } from "../_constants";
import { get, findIndex } from "lodash";

const applyCalendarUpdates = (restaurants, calendar, restaurantId) => {
  const rIndex = findIndex(restaurants, { id: restaurantId }),
    restaurant = restaurants[rIndex],
    updatedRestaurant = {
      ...restaurant,
      hours: calendar,
    };
  if (rIndex !== -1) {
    restaurants.splice(rIndex, 1, updatedRestaurant);
  }
  return restaurants;
};

export function restaurants(state = { loading: false }, action) {
  switch (action.type) {
    case restaurantsConstants.RESTAURANTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case restaurantsConstants.RESTAURANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.restaurants,
      };
    case restaurantsConstants.RESTAURANTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case restaurantsConstants.HOURS_SUCCESS:
      if (!state.data) {
        return state;
      }
      return {
        ...state,
        data: {
          restaurants: applyCalendarUpdates(
            state.data.restaurants,
            action.calendar,
            action.restaurantId
          ),
        },
      };

    default:
      return state;
  }
}

export function restaurantsNearby(
  state = { loading: false, firstFilter: false },
  action
) {
  switch (action.type) {
    case restaurantsConstants.RESTAURANTS_NEARBY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.restaurants,
      };
    case restaurantsConstants.HOURS_SUCCESS:
      if (!state.data) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          restaurants: applyCalendarUpdates(
            state.data.restaurants,
            action.calendar,
            action.restaurantId
          ),
        },
      };

    default:
      return state;
  }
}

export function restaurantSelected(state = { loading: false }, action) {
  switch (action.type) {
    case restaurantsConstants.RESTAURANT_SELECTED:
      return {
        ...state,
        data: action.selectedRestaurant,
      };
    case restaurantsConstants.HOURS_SUCCESS:
      if (state.data && state.data.id === action.restaurantId) {
        const stateRestaurant = state.data;
        return {
          ...state,
          data: {
            ...stateRestaurant,
            hours: action.calendar,
          },
        };
      }
    case restaurantsConstants.RESTAURANTS_NEARBY_SUCCESS:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
}

export function restaurant(state = { loading: false }, action) {
  switch (action.type) {
    case restaurantsConstants.RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case restaurantsConstants.RESTAURANT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          hours: state.data && state.data.id === action.restaurant.id ? state.data.hours : undefined,
          ...action.restaurant,
        },
      };
    case restaurantsConstants.RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case restaurantsConstants.HOURS_SUCCESS:
      if (state.data && state.data.id === action.restaurantId) {
        const stateRestaurant = state.data;
        return {
          ...state,
          data: {
            ...stateRestaurant,
            hours: action.calendar,
          },
        };
      }

    default:
      return state;
  }
}

export function menu(
  state = { loading: false, data: null, restaurantId: null },
  action
) {
  switch (action.type) {
    case restaurantsConstants.MENU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case restaurantsConstants.MENU_SUCCESS:
      const data = action.menu;
      const categories = data.categories;
      categories.forEach((category, index) => {
        let subCats = [];
        if (category.metadata) {
          category.metadata.reduce((_, item) => {
            categories[index][item.key] = item.value;
          }, {});
        }
        if (category.isParent === "1") {
          const subCategories = categories.filter((cat) =>
            category.subCategories.split(",").includes(cat.id.toString())
          );
          const subCategoriesIds = subCategories.map(
            (subCategory) => subCategory.id
          );
          category.subCategories = subCategoriesIds.join(",");
          subCats = subCategories;
        }
        category.products = category.products.map(prod => ({ ...prod, category }));
        category["subcategories"] = subCats;
      });
      return {
        ...state,
        loading: false,
        data,
        restaurantId: action.restaurantId,
      };
    case restaurantsConstants.MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export function hours(state = { loading: false }, action) {
  switch (action.type) {
    case restaurantsConstants.HOURS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case restaurantsConstants.HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.calendar,
      };
    case restaurantsConstants.HOURS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export function delivery(state = { loading: false, data: [] }, action) {
  switch (action.type) {
    case restaurantsConstants.CHECK_DELIVERY_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case restaurantsConstants.CHECK_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.delivery,
      };
    case restaurantsConstants.CHECK_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case restaurantsConstants.RESET_DELIVERY_CHECK:
      return {
        ...state,
        loading: false,
        data: {},
      };

    default:
      return state;
  }
}
