import React from "react";
import { HashRouter, Route, Switch, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {ModalContainer} from "./ModalContainer";
import {WineClubSelection} from "./WineClubSelection";
import {WineClubAccount} from "./WineClubAccount";
import {WineClubLogin} from "./WineClubLogin";
import {WineClubPayment} from "./WineClubPayment";
import {WineClubHome} from "./WineClubHome";
import {WineClubConfirmation} from "./WineClubConfirmation";
import {UserExists} from "./UserExists";

const basename = "/wine-club";
const title = "Cooper's Hawk Wine Club";

const steps = [
  {
    title: "CLUB OPTION",
    slug: 'club-selection',
    Component: WineClubSelection,
  },
  {
    title: "ACCOUNT",
    slug: 'account',
    Component: WineClubAccount,
  },
  {
    title: "PAYMENT",
    slug: 'payment',
    Component: WineClubPayment,
  }
];

const WineClubModal = ({ forgotPass }) => {
  const path = useLocation().hash.split('/');

  if (path[1] !== basename.slice(1)) {
    return null;
  }

  return (
    <HashRouter basename={basename}>
      <Switch>
        <Route exact path="/" component={WineClubHome} />
        <Route path="/club-selection/:clubSelection" render={renderStep(0)} />
        <Route path="/club-selection" render={renderStep(0)} />
        <Route path="/account/:clubSelection" render={renderStep(1)} />
        <Route path="/signin/:clubSelection" render={renderStep(1, WineClubLogin)} />
        <Route path="/payment/:clubSelection" render={renderStep(2)} />
        <Route path="/confirmation/:clubSelection" component={WineClubConfirmation} />
        <Route path="/user-exists/:clubSelection/:name/:email" render={(props) => (
               <UserExists {...props} forgotPass={forgotPass} />
          )} />
      </Switch>
    </HashRouter>
  );
};

const renderStep = (id, NewComponent) => props => {
  const Component = NewComponent ? NewComponent : steps[id].Component;
  const newProps = {
    ...props,
    title,
    steps,
    activeStep: id,
  };

  return (
    <ModalContainer {...newProps} progressHeader>
      <Component {...newProps} />
    </ModalContainer>
  );
};

const mapStateToProps = state => {
  const { member } = state;

  return { member };
};

const connectedWineClubModal = connect(mapStateToProps)(WineClubModal);
export { connectedWineClubModal as WineClubModal };
