import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import "./styles.css";
import { useRenameDocument } from "../../context/renameDocument";

const BluepayTimeout = ({ history }) => {
  useRenameDocument("Bluepay Timeout");
  return (
    <div className="container">
      <div className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
        <h2 class="log-title" id="title">
         Timeout Error
        </h2>
        <h6 class="log-title mb-sm-4" id="form-time-out">
        This form has timed out. 
        </h6>
        <br />
        <h6 class="log-title mb-sm-4" id="try-again">
        Please click proceed to payment, or try again later.
        </h6>
        <div style={{ height: "1em" }} />
        <Button onClick={() => history.goBack()}>Proceed to Payment</Button>
        <div style={{ height: "10em" }} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {}

const connectedBluepayTimeout = connect(mapStateToProps)(BluepayTimeout);
export { connectedBluepayTimeout as BluepayTimeout };
