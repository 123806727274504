import React, { useState, useEffect } from "react";
import { Route, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AnimatedSwitch } from "react-router-transition";
import PrivateRoute from "./PrivateRoute";
import get from "lodash/get";
import { Login } from "./pages/Login";
import Signup from "./pages/Signup";
import { SetPasswordFromTemp } from "./pages/SetPasswordFromTemp";
import { CreateNewSignin } from "./pages/CreateNewSignin";
import { ResetPassword } from "./pages/ResetPassword";
import { NewPassword } from "./pages/NewPassword";
import { ResetRequired } from "./pages/ResetRequired";
import { UpdateCard } from "./pages/UpdateCard";
import { ForgotPassword } from "./pages/ForgotPassword";
import { BarrelReserve } from "./pages/BarrelReserve";
import { NewGiftMemberSignUp, NewMonthlyMemberSignUp as NewMonthlyMemberSignUpTF } from "./pages/NewMemberSignup";
import { NewMonthlyMemberSignUp } from "./pages/NewMonthlyMemberSignUp";
import { PotentialMemberLanding } from "./pages/PotentialMemberLanding";
import { ChNavbar } from "./components/ChNavbar";
import { ChNavbarLight } from "./components/ChNavbarLight";
import { ToastContainer } from "react-toastify";
import { Main } from "./pages/Main";
import { Payment } from "./pages/Payment";
import { Approved } from "./pages/Approved";
import { GiftApproved } from "./pages/GiftApproved";
import { BarrelOrderApproved } from "./pages/BarrelOrderApproved";
import { BarrelLogin } from "./pages/BarrelLogin";
import { Admin } from "./pages/Admin";
import { ErrorPage } from "./pages/Error";
import { Private } from "./pages/Private";
import { withTracker } from "./withTracker";
import withCookie from "./_helpers/withCookie";
import { GiftActivation } from "./pages/GiftActivation";
import { BluepayTimeout } from "./pages/BluepayTimeout";
import { NewSupplierMemberSignUp } from './pages/NewSupplierMemberSignUp';
import { logPageView } from "./_helpers";
import { useSearchParams } from 'react-router-dom';

const App = ({ location, paymentUrl, loggedIn, memberNumber }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [previousHash, setPreviousHash] = useState('/');
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {setInnerWidth(window.innerWidth)});
  const history = useHistory();

   const determinePath = (pathname) => {
    switch (pathname) {
      case "/Signup": return true;
      case "/NewGiftMemberSignUp": return true;
      case "/NewMonthlyMemberSignUp": return true;
      case "/NewSupplierMemberSignUp": return true;
      default : return false;
    }
  }

  // This complex looking check is used to set the background images for the Wine Club Signup Flow
  // This will be replaced if and when all backgrounds are updates to match.
  useEffect(() => {
    const { pathname } = location;
    if (determinePath(pathname)) {
      if (pathname === '/Signup' && innerWidth > 576) {
        document.body.className = '';
        document.body.classList.add('signup-bg');
      } else {
        document.body.classList.add('gift-flow-bg');
      }
    } else if (history?.location?.state?.newStyle) {
      if (pathname === '/Payment') {

      } else if (innerWidth > 576) {
        document.body.className = '';
        document.body.classList.add('signup-bg');
      } else {
        document.body.classList.add('gift-flow-bg');
      }
    } else {
      if (pathname === '/BarrelReserve') {
        document.body.classList.add('barrel-reserve-bg');
      } else {
        document.body.className = '';
      }
    }
  }, [location, innerWidth]);

  useEffect(() => {
    const { hash, pathname } = location;

    if (hash === '' && pathname === '/main') {
      document.title = `Dashboard | Coopers Hawk Wine club`;
    } else if (hash === '#reward') { 
      document.title = `Points & Rewards | Coopers Hawk Wine club`;
    } else if (hash === '#bottles') {
      document.title = `Bottles | Coopers Hawk Wine club`;
    } else if (hash === '#events') {
      document.title = `Events | Coopers Hawk Wine club`;
    } else if (hash === '#profile') {
      document.title = `Profile | Coopers Hawk Wine club`;
    }
  }, [location])

  // useEffect(() => {
  //   if (loggedIn && memberNumber) {
  //     logPageView('login', {page_path: 'main#dashboard', page_location: `${document.location.href}`, page_referrer: `${document.referrer}`, page_title: 'login'}, {user_id: memberNumber})
  //   }
  // }, [loggedIn, memberNumber])

  // useEffect(() => {
  //   const {pathname, hash} = location;

  //   if (memberNumber) {
  //     // We only want to set the previous hash once we login. Otherwise it will be set to '' and the first dashboard view will not register.
  //     if (pathname !== '/') {
  //       setPreviousHash(hash);
  //     }
      
  //     const main = pathname === '/main';
  //     // hash check is performed to prevent duplicate page_view calls
  //     if (main && hash !== previousHash) {
  //       if (hash === '') {
  //         logPageView('page_view', {page_path: 'main#dashboard', page_location: `${document.location.href}`, page_referrer: `${document.referrer}`, page_title: 'Member dashboard'}, {user_id: memberNumber})
  //       }
  //       else if (hash === '#reward') {
  //         logPageView('page_view', {page_path: 'main#reward', page_location: `${document.location.href}`, page_referrer: `${document.referrer}`, page_title: 'Member points'}, {user_id: memberNumber})
  //       }
  //       else if (hash === '#bottles') {
  //         logPageView('page_view', {page_path: 'main#bottles', page_location: `${document.location.href}`, page_referrer: `${document.referrer}`, page_title: 'Member bottles'}, {user_id: memberNumber})
  //       }
  //       else if (hash === '#events') {
  //         logPageView('page_view', {page_path: 'main#events', page_location: `${document.location.href}`, page_referrer: `${document.referrer}`, page_title: 'Member events'}, {user_id: memberNumber})
  //       }
  //       else if (hash === '#profile') {
  //         logPageView('page_view', {page_path: 'main#profile', page_location: `${document.location.href}`, page_referrer: `${document.referrer}`, page_title: 'Member profile'}, {user_id: memberNumber})
  //       }
  //     }
  //   }
  // }, [location, memberNumber]);

  return (
    <>
    {determinePath(location.pathname) || history?.location?.state?.newStyle ? <ChNavbarLight setCurrentTab={setCurrentTab} /> : <ChNavbar setCurrentTab={setCurrentTab} />}
    {/* <ChNavbar setCurrentTab={setCurrentTab} /> */}
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        <Route exact path="/" component={Login} />
        <Route exact path="/BarrelLogin" component={BarrelLogin} />
        <Route exact path="/Signup" component={Signup} />
        <Route exact path="/ResetPassword" component={ResetPassword} />
        <Route exact path="/NewPassword" component={NewPassword} />
        <Route exact path="/ForgotPassword" component={ForgotPassword} />
        <Route exact path="/ResetRequired" component={ResetRequired} />
        <Route exact path="/Private" component={Private} />
        <Route exact path="/CreateNewSignin" component={CreateNewSignin} />
        <Route
          exact
          path="/PotentialMember"
          component={PotentialMemberLanding}
        />
        <Route exact path="/NewSupplierMemberSignUp" component={NewSupplierMemberSignUp} />
        <Route exact path="/GiftActivation" component={GiftActivation} />

        <Route
          exact
          path="/NewGiftMemberSignUp"
          component={NewGiftMemberSignUp}
        />
        
        {/* Original Signup-forms */}
        <Route
          exact
          path="/NewMonthlyMemberSignUp"
          component={NewMonthlyMemberSignUp}
          />
        <Route
          path="/NewMonthlyMemberSignUp/:pid"
          component={NewMonthlyMemberSignUp}
        />
        {/* TailFeather Signup-forms */}
        <Route
          exact
          path="/NewMonthlyMemberSignUpTF"
          component={NewMonthlyMemberSignUpTF}
          />
        {/* NEED TO HOOK UP TF PROMOTIONS STILL
        <Route
          path="/NewMonthlyMemberSignUpTF/:pid"
          component={NewMonthlyMemberSignUpTF}
        /> */}

        <Route
          exact
          path="/SetPasswordFromTemp"
          component={SetPasswordFromTemp}
        />

        <Route exact path="/Payment" component={Payment} />
        { paymentUrl !== null ? 
            <Route exact path="/GiftApproved" component={GiftApproved} />
          :
            <Route exact path="/GiftApproved" render={() => (window.location = "https://chwinery.com")} />
        }
        { paymentUrl !== null ? 
          <Route exact path="/Approved" component={Approved} />
          :
          <Route exact path="/Approved" render={() => (window.location = "https://chwinery.com")} />
        }
        <Route exact path="/Error" component={ErrorPage} />
        <Route exact path="/Timeout" component={BluepayTimeout} />
        <PrivateRoute
          exact
          path="/BarrelOrderApproved"
          component={BarrelOrderApproved}
        />
        <PrivateRoute exact path="/Admin" component={Admin} />
        <PrivateRoute exact path="/BarrelReserve" component={BarrelReserve} />
        <PrivateRoute exact path="/UpdateCard" component={UpdateCard} />
        <PrivateRoute
          exact
          path="/main"
          component={withTracker(Main)}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </AnimatedSwitch>
      <ToastContainer
        className="hide-toastify"
        autoClose={false}
        position="top-center"
        closeButton={false}
        containerId="alert"
      />
    </>
  );
};

function mapStateToProps(state) {
  const { alert, newMember, member, authentication } = state;
  const paymentUrl = get(newMember, "paymentUrl", null);
  const {loggedIn} = authentication;
  const memberNumber = member?.data?.memberNumber;

  return {
    alert,
    paymentUrl,
    loggedIn,
    memberNumber,
  };
}

const AuthApp = withCookie(App);
const connectedApp = withRouter(connect(mapStateToProps)(AuthApp));
export { connectedApp as App };
