import { staticContentConstants } from "../_constants";

export function staticContent(
  state = {
    loading: false,
    data: {
      messages: {},
      pricingStructure: {}
    }
  },
  action
) {
  switch (action.type) {
    case staticContentConstants.STATIC_CONTENT_REQUEST:
      return {
        loading: true
      };
    case staticContentConstants.STATIC_CONTENT_SUCCESS:
      return {
        loading: false,
        data: action.staticContent.with
      };
    case staticContentConstants.STATIC_CONTENT_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
