import secureStorage from './secureStorage';
import React, { useEffect } from "react";
import moment from "moment";
import assign from "lodash/assign";
import { memberService } from "../_services";
import {updateCookie} from "./withCookie";

/**
 * HOC refreshes the Cognito token
 */
export default function withRefreshToken(Component) {
  return props => {
    useEffect(scheduleRefresh, []);

    return <Component {...props} />;
  };
}

/**
 * Schedule the Cognito token to be refreshed 5 minutes prior to token expiration
 */
const scheduleRefresh = () => {
  try {
    const ls = new secureStorage();
    const user = ls.get("user");
    const exp = user && moment(JSON.parse(user).exp);
    
    if (!exp) {
      return;
    }

    const timeUntilExp = exp
      .subtract(5, 'minutes')  // Refresh 5 minutes before token expires
      .diff(moment(), 'milliseconds');

    const timeout = setTimeout(performRefresh, timeUntilExp);
    return () => clearTimeout(timeout);
  } catch (e) {
    console.log("Caught", e);
  }
};

/**
 * Refresh the Cognito token
 */
const performRefresh = async () => {
  try {
    const ls = new secureStorage();
    const refreshToken = ls.get("crt");

    if (refreshToken) {
      const res = await memberService.refreshToken(refreshToken);
      const json = await res.json();

      if (json.status === "succeeded") {
        delete json.with.id;  // Returns null on refresh
        delete json.with.access_token;

        // Merge objects to avoid losing existing properties
        const user = assign(JSON.parse(ls.get("user")), json.with);
        user.exp = moment()
          .add(user.cognito_expires_in, 'seconds')
          .toISOString();

        ls.set("user", JSON.stringify(user));
        updateCookie();
        scheduleRefresh();
      }
    }
  } catch (e) {
    console.log("Caught", e);
  }
};
