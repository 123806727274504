export const ordersConstants = {
  VALIDATE_ORDER_REQUEST: "VALIDATE_ORDER_REQUEST",
  VALIDATE_ORDER_SUCCESS: "VALIDATE_ORDER_SUCCESS",
  VALIDATE_ORDER_FAILURE: "VALIDATE_ORDER_FAILURE",

  SUBMIT_ORDER_REQUEST: "SUBMIT_ORDER_REQUEST",
  SUBMIT_ORDER_SUCCESS: "SUBMIT_ORDER_SUCCESS",
  SUBMIT_ORDER_FAILURE: "SUBMIT_ORDER_FAILURE",

  SUBMIT_PROXY_ORDER_REQUEST: "SUBMIT_PROXY_ORDER_REQUEST",
  SUBMIT_PROXY_ORDER_SUCCESS: "SUBMIT_PROXY_ORDER_SUCCESS",
  SUBMIT_PROXY_ORDER_FAILURE: "SUBMIT_PROXY_ORDER_FAILURE",

  SIMULATE_PAYMENT_REQUEST: "SIMULATE_PAYMENT_REQUEST",
  SIMULATE_PAYMENT_SUCCESS: "SIMULATE_PAYMENT_SUCCESS",

  SET_ORDER_CONTACT_INFO: "SET_ORDER_CONTACT_INFO",

  REFRESH_ORDER_REQUEST: "REFRESH_ORDER_REQUEST",
  REFRESH_ORDER_SUCCESS: "REFRESH_ORDER_SUCCESS",
  REFRESH_ORDER_FAILURE: "REFRESH_ORDER_FAILURE",

  REFRESH_DELIVERY_STATUS_REQUEST: "REFRESH_DELIVERY_STATUS_REQUEST",
  REFRESH_DELIVERY_STATUS_SUCCESS: "REFRESH_DELIVERY_STATUS_SUCCESS",
  REFRESH_DELIVERY_STATUS_FAILURE: "REFRESH_DELIVERY_STATUS_FAILURE",

  GET_BILLING_SCHEMES_REQUEST: "GET_BILLING_SCHEMES_REQUEST",
  GET_BILLING_SCHEMES_SUCCESS: "GET_BILLING_SCHEMES_SUCCESS",
  GET_BILLING_SCHEMES_FAILURE: "GET_BILLING_SCHEMES_FAILURE",

  RESET_ORDER: "RESET_ORDER",
};
