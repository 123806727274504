import { newsConstants } from "../_constants";

export function news(state = { loading: false }, action) {
  switch (action.type) {
    case newsConstants.NEWS_REQUEST:
      return {
        loading: true
      };
    case newsConstants.NEWS_SUCCESS:
      return {
        loading: false,
        data: action.news.with
      };
    case newsConstants.NEWS_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
