import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PaymentFrame } from "../../components/PaymentFrame";
import { paymentService, memberService } from "../../_services";
import { Redirect, Link } from "react-router-dom";
import LoadingSpinners from "../../components/LoadingSpinners";
import Button from "react-bootstrap/Button";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import "./styles.css";
import {
  newMemberActions,
  paymentActions,
  memberActions,
} from "../../_actions";
import { logEcommerceEvent, logGTMEvent } from "../../_helpers";
import { useRenameDocument } from "../../context/renameDocument";

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backButtonVisible: true,
      params: {},
      requested: false,
      cardNumber: "",
      pageType: "",
      bluepayResult: "",
      innerWidth: window.innerWidth,
    };
  }

  newUser = this.props.location.state.signupType === 'monthly' || this.props.location.state.signupType === 'gift';
  gtmEvent = this.props.location.state.gtmEvent;
  
  componentDidMount() {

    document.title = `Payment | Coopers Hawk Wine club`;

    if (this.props.location.state.newStyle) {
    document.body.classList.add('gift-flow-bg');
  }
    
  window.addEventListener('resize', () => {this.setState({innerWidth: window.innerWidth})});

  // WE added a null value to be assigned to the transaction ID so the memberID gets populated.
    if (this.props.location.state.signupType === "monthly" && this.props.history.action !== 'POP') {
      logEcommerceEvent("add_to_cart", this.gtmEvent.items, this.gtmEvent.shipping, this.gtmEvent.delivery_type, this.gtmEvent.membership_type, this.gtmEvent.value, null, this.props.id);
      logGTMEvent("wine_club_step_complete", {page_title: "New Monthly Member Sign Up", page_path: "/NewMonthlyMemberSignUp", step_name: "Wine Club Details"}, {membership_type: "Monthly"});
      logGTMEvent('wine_club_step_view', {page_title: 'Payment', page_path: "/Payment", step_name: 'Payment Info'}, {membership_type: `${this.props.location.state.signupType.charAt(0).toUpperCase()}${this.props.location.state.signupType.slice(1)}`})
    } else if (this.props.location.state.signupType === "gift" && this.props.history.action !== 'POP') {
      logEcommerceEvent("add_to_cart", this.gtmEvent.items, this.gtmEvent.shipping, this.gtmEvent.delivery_type, this.gtmEvent.membership_type, this.gtmEvent.value, null, this.props.id);
      logGTMEvent('wine_club_step_view', {page_title: 'Payment', page_path: "/Payment", step_name: 'Payment Info'}, {membership_type: `${this.props.location.state.signupType.charAt(0).toUpperCase()}${this.props.location.state.signupType.slice(1)}`})
    }
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
    match;
    
    while ((match = regex.exec(this.state.paymentUrl))) {
      /*eslint-disable */
      this.state.params[decodeURIComponent(match[1])] = decodeURIComponent(
        match[2]
        );
        /*eslint-enable */
      }
      if (!isEmpty(this.state.params)) {
        memberService.postProfile(this.state.profileObj);
      }
    }
    
    request = async (params) => {
      this.setState({ requested: true });
      await paymentService.postPayment(JSON.parse(params)).then((res) => {
        const member = get(res.with, "member", {});

      const cardNum = get(member, "CHW_Member_Number__c", "unknown");
      const firstName = get(member, "Gift_Giver_First_Name__c", "");
      const bottleLevel = get(member, "CHW_Level__c", "");
      const memberType = get(member, "CHW_Membership_Type__c", "");
      const deliveryType = get(member, "CHW_Delivery__c", "");
      const clubType = get(member, "CHW_Club_Type__c", "");
      const nextChargeOutDate = get(res.with, "nextChargeOutDate", "");
      const nextChargeOutMonth = get(res.with, "chargeOutMonths", "");
      const duration = get(member, "CHW_Months_to_Give__c", "");

      if (this.state.bluepayResult === "approved") {
        var path = "";
        switch (this.props.location.state.signupType) {
          case "update":
            path = "/main";
            break;
          case "gift":
            path = "/GiftApproved";
            break;
          default:
            path = "/Approved";
        }
        if (path === "/main") {
          this.props.dispatch(memberActions.getMember());
        }
        this.props.history.push({
          pathname: path,
          state: {
            cardNum,
            firstName,
            bottleLevel,
            memberType,
            deliveryType,
            clubType,
            duration,
            nextChargeOutDate,
            nextChargeOutMonth,
            signupType: this.props.location.state.signupType,
            pid: this.props.location.state?.pid,
            // eslint-disable-next-line
            innerWidth: innerWidth,
            newStyle: this.props.location.state.newStyle,
            referralCode: this.props.location.state.referralCode,
          },
        });
        if (this.newUser) {
          const transaction_id = `${this.props.id}:${new Date().getTime()}`;
          if (this.props.location.state.signupType === 'monthly') {
            logGTMEvent('wine_club_step_complete', {page_location: 'https://member.chwinery.com/Payment', page_title: 'NewMonthlyMemberSignup', page_path: "/NewMonthlyMemberSignUp", step_name: 'Payment Info'}, {membership_type: `${this.props.location.state.signupType.charAt(0).toUpperCase()}${this.props.location.state.signupType.slice(1)}`});
          } else {
            logGTMEvent('wine_club_step_complete', {page_location: 'https://member.chwinery.com/Payment', page_title: 'NewGiftMemberSignup', page_path: "/NewGiftMemberSignUp", step_name: 'Payment Info'}, {membership_type: `${this.props.location.state.signupType.charAt(0).toUpperCase()}${this.props.location.state.signupType.slice(1)}`});
          }
          logEcommerceEvent('purchase', this.gtmEvent.items, this.gtmEvent.shipping, this.gtmEvent.delivery_type, this.gtmEvent.membership_type, this.gtmEvent.value, transaction_id, this.props.id);
        }
        if (this.props.location.state.signupType === "update") {
          logGTMEvent('update_payment', {page_title: 'Payment', page_path: '/Payment', page_referrer: `${document.referrer}`})
        }
      } else if (this.state.bluepayResult === "error") {
        this.props.history.push({pathname: '/Error', state: {error: true}});
      }
    });
  };

  reactToData = async (data) => {
    let dataArr = data.split("|");
    let message = dataArr[0];
    let paramString = dataArr[1];
    // store payment now
    if (paramString) {
      this.setState({ bluepayResult: message }, () =>
        this.request(paramString)
      );
    }
  };

  shippingRedirect = (signupType) => {
    let targetPage;
    if(signupType == "monthly" || signupType == "potential") {
      targetPage = <Redirect to="/NewMonthlyMemberSignup" />
    }
    else if(signupType == "gift") {
      targetPage = <Redirect to="/NewGiftMemberSignup" />
    }
    else{
      targetPage = <Redirect to="/main" />
    }
    return targetPage;
  }
  render() {
    if (this.state.loggingIn) {
      return <LoadingSpinners />;
    }
    return this.state.loggedIn ? (
      <Redirect to="/main" />
    ) : this.props.location.state.newStyle ? (
      // This is separated for ease of styling. This is only used for the Wine Club Signup Flow.
      <div>
        {/* eslint-disable-next-line */}
        <img className="image-to-be-gradiented" alt="payment header" src={innerWidth > 576 ? require('../../assets/images/bbbg-v2.png') : require('../../assets/images/bbbg-v3.png')} style={{zIndex: -1}}/>
            <div className="image-gradient" style={{zIndex: -1}}/>

      <div className="container main">
            <h1 className="payment-join-header">Join the Wine Club </h1>
        {/* <div className="tinted-container"> */}
        <div className="vertical-shift">
          {this.state.backButtonVisible && (
            <button
            className="float-right"
            onClick={() => {
              this.props.history.goBack();
              this.props.dispatch(
                this.props.location.state.signupType === "update"
                ? paymentActions.clearPaymentForm()
                : newMemberActions.clearPaymentForm()
                );
              }}
              >
                Back
              </button>
          )}
          {this.state.cardNumber === "" ? (
            this.props.url?.includes("FAILURE") || this.props.paymentUrl?.includes("FAILURE") ? this.shippingRedirect(this.props.location.state.signupType) :
            <PaymentFrame
            frameAction={(data) =>
              !this.state.requested ? this.reactToData(data) : null
            }
            update={false}
            url={
              this.props.location.state.signupType === "update"
              ? this.props.url
              : this.props.paymentUrl
            }
            />
            ) : (
              <div class="row p-2 p-sm-5">
              <div class="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
                <h2 class="log-title" id="payment-page-payment-approved">
                  Payment Approved
                </h2>

                <h6 class="log-title mb-sm-4" id="payment-page-text">
                  {this.state.pageType === "gift"
                    ? "Thank you for purchasing a gift membership!"
                    : "Logging you in..."}
                </h6>

                <h6 class="log-title mb-sm-4" id="payment-page-club-number">
                  Your Wine Club Number is {this.state.cardNumber}.
                </h6>
                <br />
                <Link to="/">
                  <Button>Continue</Button>
                </Link>
                <br />
              </div>
            </div>
          )}
        </div>
      </div>
          </div>
    ) : (
      <div className="container main">
        <div className="tinted-container">
          {this.state.backButtonVisible && (
            <button
            className="float-right"
                onClick={() => {
                  this.props.history.goBack();
                  this.props.dispatch(
                    this.props.location.state.signupType === "update"
                      ? paymentActions.clearPaymentForm()
                      : newMemberActions.clearPaymentForm()
                  );
                }}
              >
                Back
              </button>
          )}
          {this.state.cardNumber === "" ? (
            this.props.url?.includes("FAILURE") || this.props.paymentUrl?.includes("FAILURE") ? this.shippingRedirect(this.props.location.state.signupType) :
            <PaymentFrame
              frameAction={(data) =>
                !this.state.requested ? this.reactToData(data) : null
              }
              update={false}
              url={
                this.props.location.state.signupType === "update"
                  ? this.props.url
                  : this.props.paymentUrl
              }
            />
          ) : (
            <div class="row p-2 p-sm-5">
              <div class="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
                <h2 class="log-title" id="payment-page-approved">
                  Payment Approved
                </h2>

                <h6 class="log-title mb-sm-4" id="payment-page-thank-you">
                  {this.state.pageType === "gift"
                    ? "Thank you for purchasing a gift membership!"
                    : "Logging you in..."}
                </h6>

                <h6 class="log-title mb-sm-4" id="payment-page-wine-club-number">
                  Your Wine Club Number is {this.state.cardNumber}.
                </h6>
                <br />
                <Link to="/">
                  <Button>Continue</Button>
                </Link>
                <br />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { member, newMember, paymentForm, authentication } = state;
  const { loggingIn, loggedIn } = authentication;
  const { data, paymentUrl } = newMember;
  const { url } = paymentForm;
  const status = get(member, "data.status", "");
  const email = get(data, "email", "");
  const id = get(data, "Id", "");
  return {
    id,
    email,
    loggingIn,
    loggedIn,
    paymentUrl,
    status,
    url,
  };
}

const connectedPayment = withRouter(connect(mapStateToProps)(Payment));
export { connectedPayment as Payment };
