import secureStorage from "./secureStorage";

/**
 * Mutates headers to include Cognito bearer token
 */
const addAuthorization = (headers = {}) => {
  try {
    const ls = new secureStorage();
    const token = JSON.parse(ls.get("user")).cognito_token;

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    console.log("Caught", e);
  }
};

export { addAuthorization };
