import { newMemberConstants, memberConstants } from "../_constants";
import { memberService } from "../_services";
import { alertActions, memberActions } from "../_actions";
import { store } from "../_helpers/store";
import get from "lodash/get";
import { restaurantSelected } from "../_reducers/restaurants.reducer";

export const newMemberActions = {
  clearNewMember,
  clearPaymentForm,
  createCognitoUser,
  getMemberFromLastCard,
  setGiftStep,
  setMonthlyStep,
  setClubType,
  setGiftLength,
  setBillingEmail,
  setBillingPhone,
  setBottleLevel,
  setDeliveryType,
  setShippingFirstName,
  setShippingLastName,
  setShippingBirthday,
  setBirthday,
  setPhone,
  setShippingEmail,
  setShippingAsBilling,
  setSameAsBilling,
  setHomeCity,
  setHomeState,
  setHomeStreet,
  setHomeZip,
  setBillingFirstName,
  setBillingLastName,
  setBillingCity,
  setBillingState,
  setBillingStreet,
  setBillingZip,
  setPotentialEmail,
  setPromotionId,
  setRecipientFirstName,
  setRecipientLastName,
  setRecipientBirthday,
  setGiftMessage,
  setShipDate,
  setRecipientCity,
  setRecipientState,
  setRecipientStreet,
  setRecipientZip,
  setRecipientEmail,
  setShippingCity,
  setShippingState,
  setShippingStreet,
  setShippingZip,
  setSendTo,
  signup,
  signupPotential,
  signupPotentialFull,
  setCustomerId,
  convertPotentialToMonthly,
};

function clearPaymentForm() {
  return { type: newMemberConstants.CLEAR_PAYMENT_FORM };
}

function clearNewMember() {
  return { type: newMemberConstants.CLEAR_NEW_MEMBER };
}

function signup(memberObj, referralCode) {
  return (dispatch) => {
    dispatch(request());
    return memberService.signup(memberObj).then(
      (response) => {
        if (referralCode) {
          memberService.postReferral(referralCode, response.with.recordId)
        }
        dispatch(success(response));
      },
      (error) => {
        // transform error codes to human readable friendly messages
        if(error === 'PROMO_DOES_NOT_EXIST'){
          error = 'Sorry this is an invalid promotion.';
        }
        else if(error === 'PROMO_NOT_STARTED'){
          error = 'Sorry this promotion has not started yet. Check back later.';
        }
        else if(error === 'PROMO_EXPIRED'){
          error = 'Sorry this promotion has ended.';
        }
        else if(error.includes('Shipping Address')){
          error = 'The shipping address could not be validated. Please confirm your details and try again.';
        }
        else{
          error = 'There was an issue with your information provided. Please confirm and try again.';
        }
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: newMemberConstants.SIGNUP_REQUEST };
  }
  function success(response) {
    return { type: newMemberConstants.SIGNUP_SUCCESS, response };
  }
  function failure(error) {
    return { type: newMemberConstants.SIGNUP_FAILURE, error };
  }
}

function signupPotential(memberObj) {
  return (dispatch) => {
    dispatch(request());
    return memberService.signupPotential(memberObj).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: newMemberConstants.POTENTIAL_SIGNUP_REQUEST };
  }
  function success(response) {
    return { type: newMemberConstants.POTENTIAL_SIGNUP_SUCCESS, response };
  }
  function failure(error) {
    return { type: newMemberConstants.POTENTIAL_SIGNUP_FAILURE, error };
  }
}

function convertPotentialToMonthly(memberObj) {
  return (dispatch) => {
    dispatch(request());
    return memberService.convertPotentialToMonthly(memberObj).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: newMemberConstants.CONVERT_POTENTIAL_MONTHLY_REQUEST };
  }
  function success(response) {
    return { type: newMemberConstants.CONVERT_POTENTIAL_MONTHLY_SUCCESS, response };
  }
  function failure(error) {
    return { type: newMemberConstants.CONVERT_POTENTIAL_MONTHLY_FAILURE, error };
  }
}

function createCognitoUser(email, password, contactId) {
  return (dispatch) => {
    dispatch(request());
    memberService.createCognitoUser(email, password, contactId).then(
      (response) => {
        dispatch(success(response));
        dispatch(memberActions.login(email, password));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: newMemberConstants.COGNITO_REQUEST };
  }
  function success(response) {
    return { type: newMemberConstants.COGNITO_SUCCESS, response };
  }
  function failure(error) {
    return { type: newMemberConstants.COGNITO_FAILURE, error };
  }
}

function signupPotentialFull(memberObj, password) {
  return async (dispatch) => {
    try {
      dispatch(request());

      // Create salesforce user
      const sfResponse = await memberService.signupPotential(memberObj);
      const sfResponseSuccess = get(sfResponse, "with.success", false);
      const sfResponseMessage = get(sfResponse, "with.message");
      const memberId = get(sfResponse, "with.recordId");
      const email = get(memberObj, "member.Email", "");
      
      if(sfResponseSuccess){
        dispatch(successSF(sfResponse));

        // Create cognito user and login
        const cognitoRepsonse = await memberService.createCognitoUser(email, password, memberId);
        dispatch(successCognito(cognitoRepsonse));
        dispatch(memberActions.login(email, password));
      }
      else{
        dispatch(failure(sfResponseMessage));
      }

    } catch (err) {
      dispatch(failure(err));
    }
  };

  function request() {
    return { type: newMemberConstants.POTENTIAL_SIGNUP_REQUEST };
  }
  function successSF(response) {
    return { type: newMemberConstants.POTENTIAL_SIGNUP_SUCCESS, response };
  }
  function successCognito(response) {
    return { type: newMemberConstants.COGNITO_SUCCESS, response };
  }
  function failure(error) {
    return { type: newMemberConstants.POTENTIAL_SIGNUP_FAILURE, error };
  }
}

function getMemberFromLastCard(lastName, cardNumber) {
  return (dispatch) => {
    dispatch(request());
    memberService.getMemberFromLastCard(lastName, cardNumber).then(
      (response) => {
        let contact = response.with;
        let status = get(contact, "CHW_Status__c", "");
        if (status === "To be Activated") {
          store.dispatch({
            type: memberConstants.INACTIVE,
          });
        } else if (!status) {
          dispatch(failure("Member Not Found"));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        dispatch(failure(error));
        if (error !== "Member Not Found") {
          dispatch(alertActions.error(error));
        }
      }
    );
  };

  function request() {
    return { type: newMemberConstants.GETMEMBERS_FROM_LASTCARD_REQUEST };
  }
  function success(response) {
    return {
      type: newMemberConstants.GETMEMBERS_FROM_LASTCARD_SUCCESS,
      response,
    };
  }
  function failure(error) {
    return { type: newMemberConstants.GETMEMBERS_FROM_LASTCARD_FAILURE, error };
  }
}

function setPotentialEmail(email) {
  return { type: newMemberConstants.SET_POTENTIAL_EMAIL, email };
}

function setGiftLength(giftLength) {
  return { type: newMemberConstants.SET_GIFT_LENGTH, giftLength };
}

function setBottleLevel(bottleLevel) {
  return { type: newMemberConstants.SET_BOTTLELEVEL, bottleLevel };
}

function setClubType(clubType) {
  return { type: newMemberConstants.SET_CLUBTYPE, clubType };
}

function setDeliveryType(deliveryType) {
  return { type: newMemberConstants.SET_DELIVERY_TYPE, deliveryType };
}

function setShippingFirstName(firstName) {
  return { type: newMemberConstants.SET_SHIPPING_FIRSTNAME, firstName };
}

function setShippingLastName(lastName) {
  return { type: newMemberConstants.SET_SHIPPING_LASTNAME, lastName };
}

function setShippingBirthday(birthday) {
  return { type: newMemberConstants.SET_SHIPPING_BIRTHDAY, birthday };
}

function setPhone(phone) {
  return { type: newMemberConstants.SET_PHONE, phone };
}

function setShippingEmail(email) {
  return { type: newMemberConstants.SET_SHIPPING_EMAIL, email };
}

function setRecipientFirstName(firstName) {
  return { type: newMemberConstants.SET_RECIPIENT_FIRSTNAME, firstName };
}

function setRecipientLastName(lastName) {
  return { type: newMemberConstants.SET_RECIPIENT_LASTNAME, lastName };
}

function setRecipientBirthday(birthday) {
  return { type: newMemberConstants.SET_RECIPIENT_BIRTHDAY, birthday };
}

function setGiftMessage(giftMessage) {
  return { type: newMemberConstants.SET_GIFT_MESSAGE, giftMessage };
}

function setShipDate(shipDate) {
  return { type: newMemberConstants.SET_SHIP_DATE, shipDate };
}

function setBirthday(birthday) {
  return { type: newMemberConstants.SET_BIRTHDAY, birthday };
}

function setHomeStreet(street) {
  return { type: newMemberConstants.SET_HOME_STREET, street };
}

function setHomeCity(city) {
  return { type: newMemberConstants.SET_HOME_CITY, city };
}

function setHomeState(state) {
  return { type: newMemberConstants.SET_HOME_STATE, state };
}

function setHomeZip(zip) {
  return { type: newMemberConstants.SET_HOME_ZIP, zip };
}

function setBillingFirstName(firstName) {
  return { type: newMemberConstants.SET_BILLING_FIRSTNAME, firstName };
}

function setBillingLastName(lastName) {
  return { type: newMemberConstants.SET_BILLING_LASTNAME, lastName };
}

function setBillingEmail(email) {
  return { type: newMemberConstants.SET_BILLING_EMAIL, email };
}

function setBillingPhone(phone) {
  return { type: newMemberConstants.SET_BILLING_PHONE, phone };
}

function setBillingStreet(street) {
  return { type: newMemberConstants.SET_BILLING_STREET, street };
}

function setBillingCity(city) {
  return { type: newMemberConstants.SET_BILLING_CITY, city };
}

function setBillingState(state) {
  return { type: newMemberConstants.SET_BILLING_STATE, state };
}

function setBillingZip(zip) {
  return { type: newMemberConstants.SET_BILLING_ZIP, zip };
}

function setRecipientStreet(street) {
  return { type: newMemberConstants.SET_RECIPIENT_STREET, street };
}

function setRecipientCity(city) {
  return { type: newMemberConstants.SET_RECIPIENT_CITY, city };
}

function setRecipientState(state) {
  return { type: newMemberConstants.SET_RECIPIENT_STATE, state };
}

function setRecipientZip(zip) {
  return { type: newMemberConstants.SET_RECIPIENT_ZIP, zip };
}

function setRecipientEmail(email) {
  return { type: newMemberConstants.SET_RECIPIENT_EMAIL, email };
}

function setShippingStreet(street) {
  return { type: newMemberConstants.SET_SHIPPING_STREET, street };
}

function setShippingCity(city) {
  return { type: newMemberConstants.SET_SHIPPING_CITY, city };
}

function setShippingState(state) {
  return { type: newMemberConstants.SET_SHIPPING_STATE, state };
}

function setShippingZip(zip) {
  return { type: newMemberConstants.SET_SHIPPING_ZIP, zip };
}

function setGiftStep(giftStep) {
  return { type: newMemberConstants.SET_GIFT_STEP, giftStep };
}

function setMonthlyStep(monthlyStep) {
  return { type: newMemberConstants.SET_MONTHLY_STEP, monthlyStep };
}

function setCustomerId(customerId) {
  return { type: newMemberConstants.SET_CUSTOMER_ID, customerId };
}

function setSendTo(sendTo) {
  return { type: newMemberConstants.SET_SEND_TO, sendTo };
}

function setPromotionId(pid) {
  return { type: newMemberConstants.SET_PROMOTION_ID, pid };
}

function setShippingAsBilling(shippingAsBilling) {
  return {
    type: newMemberConstants.SET_SHIPPING_AS_BILLING,
    shippingAsBilling,
  };
}

function setSameAsBilling(sameAsBilling) {
  return {
    type: newMemberConstants.SET_SAME_AS_BILLING,
    sameAsBilling,
  };
}
