import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { App } from "./App";
import { AppOLO } from "./AppOLO";
import { history } from "./_helpers";
import { store, persistor } from "./_helpers/store";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import { appConstants } from "./_constants";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
ReactGA.initialize("UA-33832817-2");

// WEC: Added tag manager code 09022020
const tagManagerArgs = {
  gtmId: "GTM-TJQCC4N",
};

//CHW DEV - added wcorrie-09252020 - Amended 09302020
const subdomains = {
  [appConstants.memberRFUrl]: App,
  [appConstants.choloRFUrl]: AppOLO,
  [appConstants.memberUrl]: App,
  [appConstants.memberChDevUrl]: App, 
  [appConstants.choloUrl]: AppOLO,
  [appConstants.choloEsquireUrl]: AppOLO,
};
TagManager.initialize(tagManagerArgs);

const script = document.createElement('script');
script.src = `https://try.abtasty.com/${appConstants.ABTASTY_ID}.js`;
document.body.prepend(script);

// Process monitoring
// const rumScript = document.createElement('script');
// rumScript.src = "(function(w,d,s,r,k,h,m){ if(w.performance && w.performance.timing && w.performance.navigation) { w[r] = w[r] || function(){(w[r].q = w[r].q || []).push(arguments)}; 	h=d.createElement(\'script\');h.async=true;h.setAttribute(\'src\',s+k); d.getElementsByTagName(\'head\')[0].appendChild(h); (m = window.onerror),(window.onerror = function (b, c, d, f, g) {m && m(b, c, d, f, g),g || (g = new Error(b)),(w[r].q = w[r].q || []).push(['captureException',g]);}) } })(window,document,\'//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=\',\'s247r\',\'6ecc2ee43775bb8d436d50cc4ae21f30\');";
// document.body.prepend(rumScript);

const hostname = window.location.hostname;
const AppComponent = hostname.includes("redfoundry") ?
  subdomains[hostname] || AppOLO :
  subdomains["https://" + hostname] || AppOLO;


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router basename={process.env.PUBLIC_URL} history={history}>
        {!!AppComponent && <AppComponent />}
        {!AppComponent && <h1>404</h1>}
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
