import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import "./styles.css";
import addBtn from "../../assets/images/icon-menu-itm-add.png";

const ComplimentaryItem = ({ data, onComplimentaryItemClick }) => {
  return (
    <Row
      className="complimentary-item-wrap align-items-center"
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.1 },
      }}
      whileTap={{ scale: 0.95 }}
    >
      <Col>
        <div className="complimentary-item-name">
          {data.name} (Complimentary)
        </div>
        <div className="complimentary-item-note flex-container">
          Quantity based on order size
        </div>
      </Col>
      <Col lg={2} m={2} s={2} xs={2}>
        <motion.img
          src={addBtn}
          alt="add button"
          className="float-right"
          onClick={onComplimentaryItemClick}
          whileHover={{
            scale: 1.05,
            transition: { duration: 0.1 },
          }}
          whileTap={{ scale: 0.95 }}
        />
      </Col>
    </Row>
  );
};

export default ComplimentaryItem;

ComplimentaryItem.propTypes = {
  data: PropTypes.object,
};

ComplimentaryItem.defaultProps = {
  data: {},
};
