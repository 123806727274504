import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { AnimateOnChange, AnimateGroup } from "react-animation";
import "./styles.css";
import { Button, OverlayTrigger } from "react-bootstrap";

import empty from "../../assets/gift-images/CH-empty@2x.png";
import white from "../../assets/gift-images/CH-white1@2x.png";
import red from "../../assets/gift-images/CH-red1@2x.png";
import sweet from "../../assets/gift-images/CH-sweet1@2x.png";
import variety from "../../assets/gift-images/CH-variety1@2x.png";
import whites from "../../assets/gift-images/CH-whites@2x.png";
import reds from "../../assets/gift-images/CH-reds@2x.png";
import sweets from "../../assets/gift-images/CH-sweets@2x.png";
import varieties from "../../assets/gift-images/CH-varieties@2x.png";
import bottleIcon from "../../assets/gift-images/icons/icon-number@2x.png";
import clubIcon from "../../assets/gift-images/icons/icon-glass@2x.png";
import infoIcon from "../../assets/images/info-16.png";
import deliveryIcon from "../../assets/gift-images/icons/icon-delivery@2x.png";
import threeBottle from "../../assets/gift-images/CH_threebottle.png";

const SummaryBlock = ({
  deliveryType,
  bottleLevel,
  clubType,
  price,
  disabled,
  renderSummaryText,
  clubTypeAdditionalInfo,
}) => {
  const renderSwitch = (type) => {
    if (disabled) {
      return empty;
    }
    // TODO: Add 3 bottle images

    if (bottleLevel == 3) {
      return threeBottle;
    }

    switch (type) {
      case "White":
        return bottleLevel == 1 ? white : whites;
      case "Red":
        return bottleLevel == 1 ? red : reds;
      case "Variety":
        return bottleLevel == 1 ? variety : varieties;
      case "Sweet":
        return bottleLevel == 1 ? sweet : sweets;
    }
  };

  return (
    <div className={`wrap ${deliveryType == "Pickup" && "desktop-only"}`}>
      <div className="summary desktop-only">
        <div className="inner">
          <svg id="left">
            <line x1="0" y1="8%" x2="8%" y2="0" />
            <line x1="0" y1="16%" x2="16%" y2="0" />
            <line x1="0" y1="24%" x2="24%" y2="0" />
          </svg>

          <AnimateOnChange durationOut={700} animation="fade">
            <div className="content">
              <img
                src={renderSwitch(clubType)}
                alt="bottle icon"
                className="bottle"
              />
              {disabled ? (
                <div className="empty-summary">
                  Make your selections to see the membership price
                </div>
              ) : (
                <div style={{ padding: 10 }}>
                  <div className="membership-summary">
                    <div className="big-icon-wrap">
                      <div className="summary-little-icon-wrap desktop-only">
                        <img src={deliveryIcon} className="icon" alt="delivery icon" />
                        <div className="summary-block-text">
                          {deliveryType}
                        </div>
                      </div>
                      <div className="summary-little-icon-wrap">
                        <img src={bottleIcon} className="icon" alt="bottle icon" />
                        <div className="summary-block-text desktop-only">
                          {deliveryType === "Delivery" ? 
                            <>
                              {`${bottleLevel * 3} bottles per quarter`}
                            </>
                            : 
                            <>
                              {bottleLevel == 1 ? "1 bottle per month" : `${bottleLevel} bottles per month`}
                            </>
                          }
                        </div>
                        <div style={{ marginRight: "0px"}} className="summary-block-text mobile-only">
                          {deliveryType === "Delivery" ? 
                            <>
                              {`${bottleLevel * 3} ${clubType} bottles per quarter`}
                            </>
                            : 
                            <>
                              {`${bottleLevel} ${clubType} bottle${bottleLevel > 1 ? "s" : ""} per month`}
                            </>
                          }
                        </div>
                      </div>
                      <div className="summary-little-icon-wrap desktop-only">
                        <img src={clubIcon} className="icon" alt="club icon" />
                        <div className="summary-block-text"> {clubType} Club</div>
                      </div>
                    </div>
                    <div style={{ flexGrow: 1 }} />
                    <div id="monthly-membership-cost" className="summary-block-text summary-block-price">
                      <span style={{ fontSize: "1.2em" }}>COST OF MEMBERSHIP </span>
                      <br className="desktop-only" />
                      <span style={{ fontSize: "1.1em" }}>
                        ${price}
                        <span style={{ fontSize: "0.9em" }}>
                          {deliveryType === "Pickup" ? "/month" : "/quarter"}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="bottom-horizontal-wrap">
                    <div id="monthly-summary-text" className="summary-block-text desktop-only">
                      {renderSummaryText(clubType)}
                      <OverlayTrigger
                        trigger="focus"
                        placement="bottom"
                        overlay={clubTypeAdditionalInfo(clubType)}
                      >
                        <Button className="btn-link summary-block-info-icon">
                          <img style={{ verticalAlign: "top"}} src={infoIcon} alt="info icon" />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </AnimateOnChange>
          <svg id="right">
            <line x1="100%" y1="92%" x2="92%" y2="100%" />
            <line x1="100%" y1="84%" x2="84%" y2="100%" />
            <line x1="100%" y1="76%" x2="76%" y2="100%" />
          </svg>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { newMember } = state;
  const data = get(newMember, "data", {});
  const deliveryType = get(data, "deliveryType", "");
  const bottleLevel = get(data, "bottleLevel", "1");
  const clubType = get(data, "clubType", "Variety");

  return { deliveryType, bottleLevel, clubType };
}

const connectedSummaryBlock = connect(mapStateToProps)(SummaryBlock);

export { connectedSummaryBlock as SummaryBlockMonthly };
