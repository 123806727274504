import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import secureStorage from "./secureStorage";
import pick from "lodash/pick";

import { memberConstants } from "../_constants";
import { memberActions } from "../_actions";

const fieldsToPersist = ["user", "contact", "oat", "crt"];
const userFields = ["id", "is_admin", "cognito_token", "exp"];

/**
 * HOC ensures SecureLS data is updated when application mounts
 */
export default function withCookie(Component) {
  return (props) => {
    const { dispatch } = props;
    const [hasRestored, setHasRestored] = useState(false);

    // Uncomment to update the user when the page receives focus
    // useEffect(() => {
    //   const focusHandler = () => updateStorage(dispatch, false, setHasRestored);
    //   window.addEventListener('focus', focusHandler);
    //   return () => window.removeEventListener('focus', focusHandler);
    // }, []);

    updateStorage(dispatch, hasRestored, setHasRestored);

    return <Component {...props} />;
  };
}

/**
 * Update value in cookie. Call this method when localStorage is updated.
 */
function updateCookie() {
  const { hostname } = window.location;
  const domain = hostname.substring(hostname.indexOf(".") + 1);

  for (const field of fieldsToPersist) {
    try {
      const ls = new secureStorage();
      if (field === "user") {
        let lsData = ls.get(field);
        lsData = lsData && JSON.parse(lsData);
        if (lsData) {
          ls.set(field, JSON.stringify(pick(lsData, userFields)));

          if (lsData.cognito_refresh_token) {
            ls.set('crt', lsData.cognito_refresh_token);
          }
        }
      }

      const data = ls.get(field);
      if (data) {
        Cookies.set(field, btoa(data), {
          domain,
          expires: 30,
        });
      } else {
        Cookies.remove(field, { domain });
      }
    } catch (e) {
      console.log('Caught', e);
      Cookies.remove(field, { domain });
    }
  }
}

/**
 * Update value in local storage
 */
function updateStorage(dispatch, hasRestored, setHasRestored) {
  for (const field of fieldsToPersist) {
    try {
      const ls = new secureStorage();
      const rawData = Cookies.get(field);
      const data = rawData ? atob(rawData) : rawData;
      const oldData = ls.get(field) || undefined;
      if (data === oldData && hasRestored) {
        return;
      }
      if (data) {
        ls.set(field, data);
        if (field === "user") {
          setHasRestored(true);
          dispatch({ type: memberConstants.LOGIN_SUCCESS });
          dispatch(memberActions.getMember(true));
        } else if (field === "contact") {
          if(oldData !== data){
            dispatch({
              type: memberConstants.GETMEMBERS_FROM_EMAIL_SUCCESS,
              response: { with: [data] },
            });
          }
        }
      } else if (oldData) {
        localStorage.removeItem(field);
        if (field === "user") {
          dispatch({ type: memberConstants.LOGOUT });
        }
      }
    } catch (e) {
      console.log('Caught', e);
    }
  }
}

export { updateCookie };
