import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import size from "lodash/size";

import "./styles.css";

const OpenBottles = ({ openBottles }) => {
  if (size(openBottles, 0) === 0) {
    return (
      <div>
        <Row>
          <Col md={12}>
            <h2 className="log-title">Bottles Available</h2>
          </Col>

          <Col md={12}>
            <Table>
              <thead>
                <tr>
                  <td className="like-th">There are no bottles available.</td>
                </tr>
              </thead>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div>
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="log-title">Bottles Available</h2>
        </Col>

        <Col md={10}>
          <Table borderless size="sm" className="my-md-4">
            <thead>
              <tr>
                <td className="like-th">Year</td>
                <td className="like-th">Month</td>
                <td className="like-th">Name</td>
              </tr>
            </thead>
            <tbody>
              {openBottles.map((bottle, index) => (
                <tr key={index}>
                  <td>{bottle.womYear}</td>
                  <td>{bottle.womMonthName === "None" ? "" : bottle.womMonthName}</td>
                  <td>{bottle.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { openBottles } = state;
  const openBottleData = openBottles?.data; //openBottles?.data?.filter(x => !!!x.isPromo).size();
  return {
    openBottles: openBottleData
  };
}

const connectedOpenBottles = connect(mapStateToProps)(OpenBottles);
export { connectedOpenBottles as OpenBottles };
