import { alertActions } from "./";
import { productsConstants } from "../_constants";
import { Products } from "../_services/products.service";

export const productsActions = {
  getModifiers,
  selectModifier,
};

function getModifiers(productId) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Products.getModifiers(productId);
    if (data.error) {
      dispatch(failure(data.error));
      dispatch(alertActions.error(data.error));
      return;
    }
    dispatch(success(data));
  };

  function request() {
    return { type: productsConstants.MODIFERS_REQUEST };
  }

  function success(result) {
    return {
      type: productsConstants.MODIFERS_SUCCESS,
      modifiers: result,
    };
  }

  function failure(error) {
    return { type: productsConstants.MODIFERS_FAILURE, error };
  }
}

function selectModifier(groupId, optionId) {
  return async (dispatch) => {
    dispatch(success({ groupId, optionId }));
  };
}

function success(result) {
  return {
    type: productsConstants.SELECT_MODIFER_SUCCESS,
    ids: result,
  };
}

function changeQty(qty, productId) {
  return async (dispatch) => {
    dispatch(success({ qty, productId }));
  };
  function success(result) {
    return {
      type: productsConstants.CHANGE_PRODUCT_QTY,
      changeQtyObj: result,
    };
  }
}
