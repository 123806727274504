import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { shippingStateOptions, stateOptions } from '../../_helpers';
import { connect } from 'react-redux';
import { newMemberActions } from '../../_actions';
import { minDate } from '../../_constants';
import get from 'lodash/get';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';

import './styles.css';

const RecipientDetails = ({
  dispatch,
  formIsValid,
  recipientFirstName,
  recipientLastName,
  birthday,
  invalidAge,
  // giftShipDate,
  giftMessage,
  required,
  recipientStreet,
  recipientCity,
  recipientState,
  recipientZipCode,
  recipientEmail,
  deliveryType,
  todayDate,
  innerWidth,
}) => {
  let formElement;

  useEffect(() => {
    const recipientFilled =
      deliveryType === 'Pickup'
        ? !isEmpty(recipientFirstName) && !isEmpty(recipientLastName)
        : // !isEmpty(giftShipDate) &&
          !isEmpty(recipientFirstName) &&
          !isEmpty(recipientLastName) &&
          !isEmpty(recipientStreet) &&
          !isEmpty(recipientCity) &&
          !isEmpty(recipientState) &&
          !isEmpty(recipientZipCode);
    setValidated(recipientFilled);
    formIsValid(formElement.checkValidity() && !invalidAge);
  }, [
    // giftShipDate,
    deliveryType,
    dispatch,
    birthday,
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    giftMessage,
    required,
    recipientStreet,
    recipientCity,
    recipientState,
    recipientZipCode,
  ]);
  var today = new Date();
  var dd = (today.getDate() < 10 ? '0' : '') + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1);
  var yyyy = today.getFullYear() - 21;
  const validBirthdate = yyyy + '-' + mm + '-' + dd;
  const [validated, setValidated] = useState(false);

  const isPickup = deliveryType === 'Pickup';
  const states = isPickup ? stateOptions : shippingStateOptions;

  const giftMessageLength = size(giftMessage, 0);

  const validateAge = () => {
    if (birthday > validBirthdate) {
      invalidAge = true;
    }
  };

  const formControlStyle = {
  backgroundColor: 'white',
  color: 'black',
  borderColor: '#000',
  borderRadius: 10,
}

  return (
    <div>
      <h3 className="text">Gift Recipient Details</h3>
      <Form
        noValidate
        validated={validated}
        ref={element => (formElement = element)}>
        <Form.Group as={Row} onSubmit={validateAge()}>
          <Col sm={6}>
            <Form.Label className="input-label" for="recipient-first-name">
              First Name*
            </Form.Label>
            <Form.Control
            style={formControlStyle}
              value={recipientFirstName}
              onChange={e =>
                dispatch(newMemberActions.setRecipientFirstName(e.target.value))
              }
              id="recipient-first-name"
              type="text"
              name="recipientFirstName"
              required={required}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid first name.
            </Form.Control.Feedback>
          </Col>

          <Col sm={6}>
            <Form.Label className="input-label" for="recipient-last-name">
              Last Name*
            </Form.Label>
            <Form.Control
            style={formControlStyle}
              value={recipientLastName}
              onChange={e =>
                dispatch(newMemberActions.setRecipientLastName(e.target.value))
              }
              type="text"
              id="recipient-last-name"
              name="recipientLastName"
              required={required}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid last name.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {!isPickup && (
          <>
            <Form.Group as={Row}>
              <Col sm={12}>
                <Form.Label className="input-label" for="recipient-street">
                  Street*
                </Form.Label>
                <Form.Control
                style={formControlStyle}
                  value={recipientStreet}
                  onChange={e =>
                    dispatch(
                      newMemberActions.setRecipientStreet(e.target.value),
                    )
                  }
                  type="text"
                  id="recipient-street"
                  name="street"
                  required={required}
                />
                <Form.Control.Feedback
                
                  type="invalid">
                  Please provide a valid first name.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label className="input-label" for="recipient-city">
                  City*
                </Form.Label>
                <Form.Control
                style={formControlStyle}
                  value={recipientCity}
                  onChange={e =>
                    dispatch(newMemberActions.setRecipientCity(e.target.value))
                  }
                  type="text"
                  name="lastName"
                  id="recipient-city"
                  required={required}
                />
                <Form.Control.Feedback
                
                  type="invalid">
                  Please provide a valid last name.
                </Form.Control.Feedback>
              </Col>
              <div style={{ height: '4em' }} />

              <Col sm={3}>
                <Form.Label className="input-label" for="recipient-state">
                  State*
                </Form.Label>
                <div className="form-control-select-custom-border"/>
                <Form.Control
                  style={{...formControlStyle, position: 'relative', bottom: 38, borderColor: 'transparent', borderRightWidth: 10}}
                  as="select"
                  onChange={e =>
                    dispatch(newMemberActions.setRecipientState(e.target.value))
                  }
                  id="recipient-state"
                  value={recipientState}
                  required={required}>
                  {states.map(state => (
                    <option key={state.id} value={state.value}>
                      {state.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback className="shift-up"
                  type="invalid">
                  Please provide a valid state.
                </Form.Control.Feedback>
              </Col>

              <Col sm={3} style={innerWidth <= 576 ? {position: 'relative', bottom: 32} : {}}>
                <Form.Label className="input-label" for="recipient-zipcode">
                  Zip Code*
                </Form.Label>
                <Form.Control
                style={formControlStyle}
                  pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
                  type="input"
                  id="recipient-zipcode"
                  value={recipientZipCode}
                  onChange={e =>
                    dispatch(newMemberActions.setRecipientZip(e.target.value))
                  }
                  name="zipCode"
                  required={required}
                />
                <Form.Control.Feedback
                
                  type="invalid">
                  Please provide a valid zip code.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </>
        )}
        <Form.Group as={Row} className={`${!isPickup ? "shift-up" : ""}`}>
          <Col sm={6}>
            <Form.Label className="input-label" for="recipient-birthday">
              Birthdate*
            </Form.Label>
            <Form.Control
            style={formControlStyle}
              value={birthday}
              onChange={e =>
                dispatch(newMemberActions.setRecipientBirthday(e.target.value))
              }
              type="date"
              id="recipient-birthday"
              name="birthday"
              placeholder="YYYY-MM-DD"
              required={required}
              max={validBirthdate}
              min={minDate}
              isInvalid={invalidAge}
              pattern="\d{4}-\d{2}-\d{2}"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid birthdate.
            </Form.Control.Feedback>
          </Col>
          <Col sm={6}>
            <Form.Label className="input-label" for="recipient-email">
              Email
            </Form.Label>
            <Form.Control
            style={formControlStyle}
              value={recipientEmail}
              className={`${isEmpty(recipientEmail) ? 'form-optional' : ''}`}
              onChange={e =>
                dispatch(newMemberActions.setRecipientEmail(e.target.value))
              }
              type="text"
              id="recipient-email"
              name="email"
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              required={!isEmpty(recipientEmail)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className={`${!isPickup ? "shift-up" : ""}`}>
          <Col sm={12}>
            <Form.Label className="input-label" for="recipient-gift-message">
              Gift Message
            </Form.Label>
            <Form.Control
            style={formControlStyle}
              value={giftMessage}
              className={`${isEmpty(giftMessage) ? 'form-optional' : ''}`}
              as="textarea"
              id="recipient-gift-message"
              rows="3"
              onChange={e =>
                dispatch(newMemberActions.setGiftMessage(e.target.value))
              }
              name="Message"
              maxLength={255}
            />
            <p>Please limit to 255 Characters.</p>
          </Col>
        </Form.Group>
        {/* <div className="vert-spacer" />
        <Form.Group as={Row} className="justify-content-center">
          <Col sm={"auto"} className="my-auto">
            <Form.Label className="mb-0">Welcome package ship date</Form.Label>
          </Col>
          <Col sm={"auto"}>
            <Form.Control
              value={giftShipDate}
              onChange={(e) =>
                dispatch(newMemberActions.setShipDate(e.target.value))
              }
              type="date"
              name="shipDate"
              required={false}
              min={todayDate}
            />
          </Col>
        </Form.Group> */}
      </Form>
    </div>
  );
};

function mapStateToProps(state) {
  var today = new Date();
  var dd = (today.getDate() < 10 ? '0' : '') + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1);
  var yyyy = today.getFullYear();
  const todayDate = yyyy + '-' + mm + '-' + dd;

  const { newMember } = state;
  // const giftShipDate = get(newMember, "data.shipDate", todayDate);
  const giftMessage = get(newMember, 'data.giftMessage', '');
  const recipientAddress = get(newMember, 'data.recipientAddress', '');
  const deliveryType = get(newMember, 'data.deliveryType', '');
  const recipientFirstName = get(recipientAddress, 'firstName', '');
  const recipientLastName = get(recipientAddress, 'lastName', '');
  const birthday = get(recipientAddress, 'birthday', '');
  const recipientStreet = get(recipientAddress, 'street', '');
  const recipientCity = get(recipientAddress, 'city', '');
  const recipientSt = get(recipientAddress, 'state', '');
  const recipientZipCode = get(recipientAddress, 'zipCode', '');
  const recipientEmail = get(recipientAddress, 'email', '');

  const invalidAge = false;

  return {
    // giftShipDate,
    recipientFirstName,
    recipientLastName,
    birthday,
    giftMessage,
    recipientStreet,
    recipientCity,
    recipientState: recipientSt,
    recipientZipCode,
    deliveryType,
    todayDate,
    recipientEmail,
    invalidAge,
  };
}

const connectedRecipientDetails = connect(mapStateToProps)(RecipientDetails);
export { connectedRecipientDetails as RecipientDetails };
