import React, { useEffect, useState } from "react";
import "./styles.scoped.scss";
import MenuItem from "../../MenuItem";
import { ModalContainer } from "../ModalContainer";
import { connect } from "react-redux";
import { get, some } from "lodash";
import { motion } from "framer-motion";
import { basketsActions } from "../../../_actions";
import { DropDownSelector } from "../../../components";
import { useRenameDocument } from "../../../context/renameDocument";

const WineClubConfirmation = ({ match, menu, basket, dispatch, menuData }) => {
  const clubSelection = get(match, "params.clubSelection", "1");
  const womData = get(menu, "data.categories", [])
    .reduce((acc, { products }) => [...acc, ...products], [])
    .find((product) =>
      some(product.metadata, { key: "isCurrentWOM", value: "1" })
    );
  const isWine = "1";
  const isSweet = some(get(womData, "metadata", {}), {
    key: "isSweet",
    value: "1"
  });

  useRenameDocument("Wine Club Confirmation");

  const [freeObj, setFreeObj] = useState(null);
  const [bottlesToConvert, setBottlesToConvert] = useState(1);
  const [isBottlesToConvertOpen, setIsBottlesToConvertOpen] = useState(false);
  const [updateButtonEnabled, setUdateButtonEnabled] = useState(true);

  useEffect(() => {
    if (!womData) return;

    const freeWineId = findFreeWine()?.id ?? "";
    for (let i = 0; i < menuData.categories.length; i++) {
      for (let j = 0; j < menuData.categories[i].products.length; j++) {
        if (menuData.categories[i].products[j].id === freeWineId) {
          setFreeObj(menuData.categories[i].products[j]);
          break;
        }
      }
    }
  }, [womData]);

  const bottlesToConvertOptions = Array.from(
    Array(parseInt(clubSelection)).keys()
  ).map((number) => {
    return {
      label: `Use ${number + 1} bottle${
        number !== 0 ? "s" : ""
      } from my Wine Club account`,
      value: number + 1
    };
  });

  const addToBasket = async () => {
    await dispatch(
      basketsActions.addProducts(
        get(basket.data, "id"),
        womData.id,
        womData.name,
        1,
        "",
        "",
        "",
        isWine === "1"
          ? `isWine:${womData.chainproductid}${
              isSweet === "1" ? ",isSweet" : ""
            }`
          : ""
      )
    );
    document.location.href = "#/";
  };

  const useMyWineClubBottles = async () => {
    if (!freeObj) {
      alert("Cannot find free wine");
      return;
    }

    const freeProductId = freeObj?.id;
    const freeProductName = freeObj?.name;
    const freeProductSfId = freeObj?.sfId ?? "";

    setUdateButtonEnabled(false);
    await dispatch(
      basketsActions.addProducts(
        get(basket.data, "id"),
        freeProductId,
        freeProductName,
        bottlesToConvert,
        "",
        "",
        "",
        `conversion:${freeProductSfId}`
      )
    );
    setUdateButtonEnabled(false);
    document.location.href = "#/";
  };

  const findFreeWine = () => {
    if (!isWine) {
      return;
    }

    const chainId = womData.chainproductid;
    const swapWines = menuData.categories.find(
      (category) =>
        category.id === 33329 || category.name.includes("Wine-Member-Swap")
    );

    if (chainId && swapWines) {
      let freeProducts = get(swapWines, "products", []);
      for (let i = 0; i < freeProducts.length; i++) {
        if (freeProducts[i].metadata) {
          let metaObject = freeProducts[i].metadata.reduce(
            (obj, item) =>
              Object.assign(obj, { [item.key]: item.value.split(",") }),
            {}
          );
          freeProducts[i].fullPriceId = metaObject.fullPriceId;
          freeProducts[i].sfId = metaObject.sfId;
        }
      }
      let freeProduct = freeProducts.find(
        (product) =>
          product.fullPriceId && product.fullPriceId.includes(String(chainId))
      );
      return freeProduct;
    }
  };

  return (
    <ModalContainer>
      <div style={{ padding: 20 }}>
        <div className="large-text">
          Congratulations on your
          <br />
          Wine Club Membership!
        </div>
        <div className="small-text">
          You have <span className="club-selection">{clubSelection}</span>{" "}
          available bottle{clubSelection === "1" ? "" : "s"} and the 10% member
          discount has been applied to your bag.
        </div>
        <div className="small-text">
          Add our current Wine of the Month to your bag, or{" "}
          <a href="#wine" style={{ textDecoration: "underline" }}>
            browse
          </a>{" "}
          other wines for today's order.
        </div>
      </div>
      <div style={{ paddingBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!!womData && (
            <div
              d-block
              style={{
                justifyContent: "center",
                marginInline: 20,
                alignItems: "center"
              }}
            >
              <MenuItem
                confirmationDisplay
                key={womData.id}
                product={womData}
                imagePath={menu.data.imagepath}
                onSelect={() => {}}
                onQuick={addToBasket}
                style={{ maxWidth: 500 }}
                freeCost={freeObj ? freeObj.cost : null}
                showAdd={false}
              />

              <div className="dropdown-container">
                {bottlesToConvertOptions.length !== 0 && (
                  <DropDownSelector
                    value={bottlesToConvert}
                    items={bottlesToConvertOptions}
                    onClick={(_) =>
                      setIsBottlesToConvertOpen(!isBottlesToConvertOpen)
                    }
                    onChange={(item) => {
                      setBottlesToConvert(item);
                      setIsBottlesToConvertOpen(false);
                    }}
                    isOpen={isBottlesToConvertOpen}
                    containerClass="dropdown-cc"
                  />
                )}
              </div>
              <motion.div
                style={{
                  pointerEvents: !updateButtonEnabled ? "none" : "",
                  opacity: updateButtonEnabled ? 1.0 : 0.5
                }}
                onClick={useMyWineClubBottles}
                className="wci-use-btn"
                whileHover={{
                  scale: 1.02,
                  transition: { duration: 0.1 }
                }}
                whileTap={{ scale: 0.98 }}
              >
                USE MY WINE CLUB BOTTLES
              </motion.div>
            </div>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

const mapStateToProps = (state) => {
  const { menu, basket } = state;
  const menuData = get(menu, "data", {});
  return { menu, basket, menuData };
};

const connectedWineClubConfirmation = connect(mapStateToProps)(
  WineClubConfirmation
);

export { connectedWineClubConfirmation as WineClubConfirmation };
