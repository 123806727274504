import { alertActions } from "./";
import { restaurantsConstants } from "../_constants";
import { Restaurants } from "../_services/restaurants.service";
import { store } from "../_helpers";
import { setCached, getCached } from "../_helpers/hours-cache";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

export const restaurantsActions = {
  getMenu,
  getRestaurants,
  getRestaurantBySlug,
  getRestaurantsNearby,
  restaurantSelected,
  getHours,
  checkRestaurantDelivery,
  resetDeliveryCheck,
};

const haversineDistance = ([lat1, lon1], [lat2, lon2], isMiles = false) => {
  const toRadian = (angle) => (Math.PI / 180) * angle;
  const distance = (a, b) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (isMiles) {
    finalDistance /= 1.60934;
  }

  return finalDistance;
};

function getRestaurantsNearby(lat, long, segment = 0, radius = 50) {
  const clear = lat === 0 && long === 0;
  const all = lat === 1 && long === 1;
  const { restaurants } = store.getState();
  const { data } = restaurants;
  const locations = get(data, "restaurants", []);

  var locationsWithDistance = locations.map((location, i) => ({
    ...location,
    milesFromSearch: haversineDistance(
      [location.latitude, location.longitude],
      [lat, long],
      true
    ),
  }));
  let result;

  switch (segment) {
    case 2:
      // sort by data.supportsdispatch
      result = locationsWithDistance.filter(
        (location) =>
          location.supportsdispatch && location.milesFromSearch < radius
      );
      break;
    case 0:
      // sort by data.canpickup
      result = locationsWithDistance.filter(
        (location) => location.canpickup && location.milesFromSearch < radius
      );
      break;
    case 1:
      // sort by data.supportscurbside
      result = locationsWithDistance.filter(
        (location) =>
          location.supportscurbside && location.milesFromSearch < radius
      );
      break;
    default:
      result = locationsWithDistance.filter(
        (location) => location.canpickup && location.milesFromSearch < radius
      );
  }

  return async (dispatch) => {
    dispatch(
      success({
        restaurants: clear
          ? []
          : all
          ? locations
          : sortBy(result, "milesFromSearch"),
        firstFilter: true,
      })
    );
  };

  function success(result) {
    return {
      type: restaurantsConstants.RESTAURANTS_NEARBY_SUCCESS,
      restaurants: result,
    };
  }
}

function getRestaurants(isEsquire = false) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Restaurants.getRestaurants(isEsquire);
    if (data.error) {
      dispatch(failure(data.error));
      dispatch(alertActions.error(data.error));
      return;
    }
    dispatch(success(data));
  };

  function request() {
    return { type: restaurantsConstants.RESTAURANTS_REQUEST };
  }

  function success(result) {
    return {
      type: restaurantsConstants.RESTAURANTS_SUCCESS,
      restaurants: result,
    };
  }

  function failure(error) {
    return { type: restaurantsConstants.RESTAURANTS_FAILURE, error };
  }
}

function restaurantSelected(location) {
  return async (dispatch) => {
    dispatch(success(location));
  };
  function success(result) {
    return {
      type: restaurantsConstants.RESTAURANT_SELECTED,
      selectedRestaurant: result,
    };
  }
}

function getRestaurantBySlug(locationSlug) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Restaurants.getBySlug(locationSlug);
    if (data.error) {
      dispatch(failure(data.error));
      dispatch(alertActions.error(data.error));
      return;
    }
    dispatch(success(data));
    dispatch(getHours(data.id));
  };

  function request() {
    return { type: restaurantsConstants.RESTAURANT_REQUEST };
  }

  function success(result) {
    return {
      type: restaurantsConstants.RESTAURANT_SUCCESS,
      restaurant: result,
    };
  }

  function failure(error) {
    return { type: restaurantsConstants.RESTAURANT_FAILURE, error };
  }
}

function getMenu(restaurantId) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Restaurants.getMenu(restaurantId);

    if (data.error) {
      dispatch(failure(data.error));
      dispatch(alertActions.error(data.error));
      return;
    }
    dispatch(success(data));
  };

  function request() {
    return { type: restaurantsConstants.MENU_REQUEST };
  }

  function success(result) {
    return {
      type: restaurantsConstants.MENU_SUCCESS,
      menu: result,
      restaurantId,
    };
  }

  function failure(error) {
    return { type: restaurantsConstants.MENU_FAILURE, error };
  }
}

function getHours(restaurantId) {
  return async (dispatch) => {
    dispatch(request());
    const data = await Restaurants.getHours(restaurantId);
    if (data.error) {
      dispatch(failure(data.error));
      dispatch(alertActions.error(data.error));
      return;
    }
    dispatch(success(data));
  };

  function request() {
    return { type: restaurantsConstants.HOURS_REQUEST };
  }

  function success(result) {
    return {
      type: restaurantsConstants.HOURS_SUCCESS,
      restaurantId,
      calendar: result.calendar,
    };
  }

  function failure(error) {
    return { type: restaurantsConstants.HOURS_FAILURE, error };
  }
}

function checkRestaurantDelivery(restaurantId, address) {
  return async (dispatch) => {
    dispatch(request());
    const { streetaddress, city, zipcode } = address;

    const data = await Restaurants.checkRestaurantDelivery(
      restaurantId,
      streetaddress,
      city,
      zipcode
    );
    if (data.error) {
      dispatch(failure(data.error));
      dispatch(alertActions.error(data.error));
      return;
    }
    dispatch(success(data));
  };

  function request() {
    return { type: restaurantsConstants.CHECK_DELIVERY_ADDRESS_REQUEST };
  }

  function success(result) {
    return {
      type: restaurantsConstants.CHECK_DELIVERY_ADDRESS_SUCCESS,
      delivery: result,
    };
  }

  function failure(error) {
    return { type: restaurantsConstants.CHECK_DELIVERY_ADDRESS_FAILURE, error };
  }
}

function resetDeliveryCheck() {
  return {
    type: restaurantsConstants.RESET_DELIVERY_CHECK,
  };
}
