import React from "react";
import { MembershipSelector, MemberBanner } from "../../components";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import closeIcon from "../../assets/images/close@2x.png";
import "./styles.css";

const MultiModal = ({
  title,
  message,
  onDismiss,
  onContinue,
  className = "",
}) => {
  return (
    <AnimatePresence>
      <motion.div
        className="multi-overlay"
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div
          onClick={onDismiss}
          id="touch-layer"
          style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
        />
        <div className={`multi-overlay-inner ${className}`}>
          <div className="multi-title">{title}</div>
          <div className="multi-message">{message}</div>

          <MembershipSelector onContinue={onContinue} />

          <motion.img
            src={closeIcon}
            alt="close button icon"
            className="close-btn"
            onClick={onDismiss}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MultiModal;

MultiModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
};

MultiModal.defaultProps = {
  title:
    "It appears that you have more than one membership associated with this login.",
  message: "",
  onDismiss: () => {},
};
