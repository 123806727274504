import { memberConstants } from "../_constants";

export function replacementCard(state = {}, action) {
  switch (action.type) {
    case memberConstants.REPLACEMENT_CARD_REQUEST:
      return {
        requested: false,
        confirmed: false
      };
    case memberConstants.REPLACEMENT_CARD_SUCCESS:
      return {
        requested: true,
        confirmed: false,
        data: action.response.with
      };
    case memberConstants.REPLACEMENT_CARD_FAILURE:
      return {
        error: action.error
      };

    case memberConstants.REPLACEMENT_CARD_CONFIRM:
      return {
        ...state,
        requested: false,
        confirmed: true
      };

    default:
      return state;
  }
}
