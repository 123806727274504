import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PaymentFrame } from "../../components/PaymentFrame";

import "./styles.css";
import { paymentService } from "../../_services";
import { memberActions } from "../../_actions";

class UpdateCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backButtonVisible: true,
      paymentUrl: "",
      profileObj: {},
      params: {},
      requested: false
    };
  }

  componentDidMount() {
    this.storePassedData();
  }

  request = async params => {
    this.setState({ requested: true });
    await paymentService.postPayment(JSON.parse(params));
    this.props.dispatch(memberActions.getMember());
    this.props.history.replace("/main");
  };

  storePassedData = () => {
    this.setState({
      paymentUrl: this.props.location.state.url,
      profileObj: this.props.location.state.profileObject
    });
  };

  reactToData = async data => {
    if (data === "hide-button") {
      this.setState({ backButtonVisible: false });
    }
    if (data === "error") {
      this.props.history.goBack();
    } else {
      let dataArr = data.split("|");
      var theseParams = dataArr[1];
      if (theseParams) {
        this.request(theseParams);
      }
    }
  };

  render() {
    return (
      <div className="container main">
        <div className="tinted-container">
          {this.state.backButtonVisible && (
            <button
              className="float-right"
              onClick={() => {
                this.props.history.replace("/main");
              }}
            >
              Back
            </button>
          )}
          <PaymentFrame
            frameAction={data =>
              !this.state.requested ? this.reactToData(data) : null
            }
            update={true}
            url={this.state.paymentUrl}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isLoading = state.member.loading;
  return { isLoading };
}

const connectedUpdateCard = withRouter(connect(mapStateToProps)(UpdateCard));
export { connectedUpdateCard as UpdateCard };
