import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Dashboard } from "../../tab-content/dashboard";
import { Events } from "../../tab-content/events";
import { Link } from "react-router-dom";
import { PointsRewards } from "../../tab-content/points-rewards";
import { Bottles } from "../../tab-content/bottles";
import { Profile } from "../../tab-content/profile";
import { DirectPayment } from "../../tab-content/direct-payment";

import "./styles.css";

const ChTabs = ({ currentTab, changeTab, toggleUserSwitch }) => {
  const titleLink = (title, route) => {
    return (
      <Link to={route} className="tab-link">
        {title}
      </Link>
    );
  };
  return (
    <Tabs
      activeKey={currentTab}
      onSelect={k => changeTab(k)}
      className="ch-tabs visibledesktop">
      <Tab eventKey={0} title={titleLink("Dashboard")} id="dashboard">
        <Dashboard
          changeTab={tab => changeTab(tab)}
          toggleUserSwitch={() => toggleUserSwitch()}
        />
      </Tab>
      <Tab eventKey={1} title={titleLink("Points & Rewards", "#reward")}>
        <PointsRewards />
      </Tab>
      <Tab eventKey={2} title={titleLink("Bottles", "#bottles")}>
        <Bottles />
      </Tab>
      <Tab eventKey={3} title={titleLink("Events", "#events")}>
        <Events />
      </Tab>
      <Tab eventKey={4} title={titleLink("Profile", "#profile")}>
        <Profile />
      </Tab>
      <Tab eventKey={5} title={titleLink("UTP", "#utp")} tabClassName="d-none">
        <DirectPayment currentTab={currentTab} changeTab={changeTab} />
      </Tab>
    </Tabs>
  );
};

export default ChTabs;
