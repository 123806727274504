import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import { shippingStateOptions, deliveryStateAbbreviations } from "../../_helpers";
import UIButton from "../UIButton";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { newMemberActions } from "../../_actions";
import { appConstants } from "../../_constants";
import FadeIn from "react-fade-in";
import infoIcon from "../../assets/images/info-16.png";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import "./styles.css";

const DeliveryOptionsMonthly = ({
  costChange,
  sameAsBilling,
  active,
  stepForward,
  handleCollapse,
  showDelivery,
  dispatch,
  shippingFirstName,
  shippingLastName,
  shippingStreet,
  shippingCity,
  shippingState,
  shippingZipCode,
  shippingEmail,
  signup,
  billingEmail,
  billingPhone,
  billingFirstName,
  billingLastName,
  billingStreet,
  billingCity,
  billingZipCode,
  billingState,
  deliveryType,
  clubType,
  bottleLevel,
  pricingStructure,
  reCaptchaRefMonthly,
  reCaptchaError,
  setReCaptchaError
}) => {
  const [pricingString, setPricingString] = useState(
    `Monthly:${deliveryType}:${clubType}:${bottleLevel}:`
  );
  const [shippingValid, setShippingValid] = useState(false);
  const [validated, setValidated] = useState(false);
  let formElement;

  useEffect(() => {
    setPricingString(`Monthly:${deliveryType}:${clubType}:${bottleLevel}:`);
    if (pricingStructure[pricingString]) {
      costChange(pricingStructure[pricingString]);
    }
  }, [
    deliveryType,
    clubType,
    bottleLevel,
    costChange,
    pricingString,
    pricingStructure,
  ]);

  useEffect(() => {
    const shippingFilled = 
      !isEmpty(shippingFirstName) &&
      !isEmpty(shippingLastName) &&
      !isEmpty(shippingEmail) &&
      !isEmpty(shippingStreet) &&
      !isEmpty(shippingCity) &&
      !isEmpty(shippingState) &&
      !isEmpty(shippingZipCode);
    setValidated(shippingFilled);
    setShippingValid(formElement.checkValidity());
    }, [
    sameAsBilling,
    shippingValid,
    dispatch,
    shippingStreet,
    shippingEmail,
    shippingFirstName,
    shippingLastName,
    shippingCity,
    shippingState,
    shippingZipCode,
    signup,
    billingEmail,
    billingPhone,
    billingFirstName,
    billingLastName,
    billingStreet,
    billingCity,
    billingZipCode,
    billingState
  ]);

  const selectStates = (
    <Popover id="popover-basic">
      <Popover.Content
        style={{
          backgroundColor: "black",
          border: " 1px solid white",
          color: "white",
          fontFamily: "Oswald",
          letterSpacing: "1px",
        }}
      >
        Delivery option is only available in {deliveryStateAbbreviations()}
      </Popover.Content>
    </Popover>
  );

  const sameAsCheckHandler = () => {
    sameAsBilling = !sameAsBilling;
    dispatch(newMemberActions.setSameAsBilling(sameAsBilling));
    if(sameAsBilling){
      dispatch(newMemberActions.setShippingFirstName(billingFirstName));
      dispatch(newMemberActions.setShippingLastName(billingLastName));
      dispatch(newMemberActions.setShippingStreet(billingStreet));
      dispatch(newMemberActions.setShippingCity(billingCity));
      dispatch(newMemberActions.setShippingState(billingState));
      dispatch(newMemberActions.setShippingZip(billingZipCode));
      dispatch(newMemberActions.setShippingEmail(billingEmail));  
    } else {
      dispatch(newMemberActions.setShippingFirstName(""));
      dispatch(newMemberActions.setShippingLastName(""));
      dispatch(newMemberActions.setShippingStreet(""));
      dispatch(newMemberActions.setShippingCity(""));
      dispatch(newMemberActions.setShippingState(""));
      dispatch(newMemberActions.setShippingZip(""));
      dispatch(newMemberActions.setShippingEmail(""));
    }
  };

  return (
    <div className="inactive">
      <h4>3. Shipping Information</h4>
      <Collapse in={active && showDelivery} onExited={handleCollapse}>
        <div id="collapse-div">
          <FadeIn>
            <div
              className="container"
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="text">
                Wines may be picked up at any Cooper's Hawk Winery &amp;
                Restaurant location or shipped quarterly directly to a home or business.
                An adult 21 years or older must be present to sign for the delivery.
              </div>
              <FadeIn>
                <div className="hruleSummary" />
                <Form
                  noValidate
                  validated={validated}
                  ref={(element) => (formElement = element)}
                >
                  <div 
                    style={{
                      display: "flex",
                      flexWrap: "wrap", 
                      alignItems: "center", 
                      marginBottom: "5px"
                    }}
                  >
                    <div className="text" style={{marginRight: "1em"}}>
                      YOUR SHIPPING DETAILS
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="monthly-same-as-billing"
                        checked={sameAsBilling}
                        onChange={() => {
                          sameAsCheckHandler();
                        }}
                        name="sameAsBilling"
                        style={{marginRight: "0.25em"}}
                      />
                      <span className="text" for="monthly-same-as-billing">Same as Home Address</span>
                    </div>
                  </div>
                  <Row>
                    <Col sm={4} className="field">
                      <Form.Control
                        value={shippingFirstName}
                        onChange={(e) =>
                          dispatch(newMemberActions.setShippingFirstName(e.target.value))
                        }
                        type="text"
                        name="shippingFirstName"
                        placeholder="First name"
                        required={true}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid first name.
                      </Form.Control.Feedback>
                    </Col>

                    <Col sm={4} className="field">
                      <Form.Control
                        value={shippingLastName}
                        onChange={(e) =>
                          dispatch(newMemberActions.setShippingLastName(e.target.value))
                        }
                        type="text"
                        name="shippingLastName"
                        placeholder="Last name"
                        required={true}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid last name.
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={4} className="field">
                      <Form.Control
                        value={shippingEmail}
                        onChange={(e) =>
                          dispatch(newMemberActions.setShippingEmail(e.target.value))
                        }
                        type="text"
                        name="email"
                        placeholder="Email address"
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        required={true}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <div className="text desktop-only" style={{margin: "0.25em 1em 0 0"}}>
                    SHIPPING ADDRESS
                  </div>
                  <Row>
                    <Col sm={5} className="field">
                      <Form.Control
                        type="input"
                        value={shippingStreet}
                        onChange={(e) =>
                          dispatch(newMemberActions.setShippingStreet(e.target.value))
                        }
                        name="street"
                        placeholder="Street address"
                        required={true}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid street.
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={3} className="field">
                      <Form.Control
                        value={shippingCity}
                        onChange={(e) =>
                          dispatch(newMemberActions.setShippingCity(e.target.value))
                        }
                        type="text"
                        name="city"
                        placeholder="City"
                        required={true}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid city.
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={2} className="field">
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          dispatch(newMemberActions.setShippingState(e.target.value))
                        }
                        value={shippingState}
                        required={true}
                      >
                        {shippingStateOptions.map((state) => (
                          <option key={state.id} value={state.value}>
                            {state.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid state.
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={2} className="field">
                      <Form.Control
                        pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
                        type="input"
                        onChange={(e) =>
                          dispatch(newMemberActions.setShippingZip(e.target.value))
                        }
                        value={shippingZipCode}
                        name="zipCode"
                        placeholder="Zip code"
                        required={true}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid zip code.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form>
              </FadeIn>
              <FadeIn>
                <div className="inactive expandable">
                  <div className="ok">
                    <h3>Total amount due:</h3>
                    <h5>
                      ${pricingStructure[pricingString]}
                      <span className="step-summary-text">
                        /quarter
                      </span>
                      </h5>
                  </div>
                </div>
              </FadeIn>
              <div className="hrule" />
              <div>
                <p>
                  By clicking Proceed to Payment, you are accepting the
                  <a href="https://chwinery.com/wine-club/terms-conditions"> Terms and Conditions </a> 
                  of the Cooper's Hawk Wine Club and our 
                  <a href="https://chwinery.com/privacy-policy"> Privacy Policy</a>.
                </p>

                 <div id="giftReCaptchaContainer">
                {/* Don't agree with removing this. Bad UX experience
                <Col md={2}>
                  <UIButton text="BACK" onSelect={stepBack} />
                  </Col>*/}
                <div style={{marginBottom: "1rem"}}>
                  <div className={`reCaptchaContainer ${reCaptchaError && "reCaptchaContainerError"}`}>
                    <ReCAPTCHA
                      sitekey={appConstants.reCaptchaSiteKeyMUI}
                      ref={reCaptchaRefMonthly}
                      theme="dark"
                      onChange={(value) => {
                        if(value) {
                          setReCaptchaError("");
                        }
                      }}
                    />
                  </div>
                  {reCaptchaError && (
                    <div className="reCaptchaErrorMessage" id="monthlyReCaptchaErrorMessage">
                      {reCaptchaError}
                    </div>
                  )}
                </div>
                </div>

              </div>
              <Form.Group as={Row} className="py-2" controlId="clubType">
                {/* Don't agree with removing this. Bad UX experience
                <Col md={2}>
                  <UIButton text="BACK" onSelect={stepBack} />
                </Col>*/}
                <Col md={10}></Col>
                <Col md={2}>
                  <UIButton
                    onSelect={stepForward}
                    disabled={!shippingValid}
                    validation={true}
                    text="CONTINUE TO PAYMENT"
                    aria-controls="collapse-div"
                  />
                </Col>
              </Form.Group>
            </div>
          </FadeIn>
        </div>
      </Collapse>
      {!showDelivery && (
        <FadeIn>
          <div className="step-icon-wrap">
            {shippingFirstName !== "" && (
              <>
                <div className="little-icon-wrap">
                  <div className="summary-bullet-container">
                    <div className="summary-bullet"></div>
                  </div>
                  <div className="summary-text">
                    {shippingFirstName}&nbsp;
                    {shippingLastName} 
                  </div>
                </div>
                <div className="little-icon-wrap">
                  <div className="summary-bullet-container">
                    <div className="summary-bullet"></div>
                  </div>
                  <div className="summary-text">
                    {shippingCity}, {shippingState} 
                  </div>
                </div>
                <div className="little-icon-wrap">
                  <div className="summary-bullet-container">
                    <div className="summary-bullet"></div>
                  </div>
                  <div className="summary-text">
                    {shippingZipCode}
                  </div>
                </div>
              </>
            )}
          </div>
        </FadeIn>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { newMember, staticContent } = state;
  const sameAsBilling = get(newMember, "data.sameAsBilling", false);
  const shipAddress = get(newMember, "data.shippingAddress", "");
  const shippingFirstName = get(shipAddress, "firstName", "");
  const shippingLastName = get(shipAddress, "lastName", "");
  const shippingStreet = get(shipAddress, "street", "");
  const shippingCity = get(shipAddress, "city", "");
  const shippingState = get(shipAddress, "state", "");
  const shippingZipCode = get(shipAddress, "zipCode", "");
  const shippingEmail = get(shipAddress, "email", "");
  const billingAddress = get(newMember, "data.billingAddress", "");
  const billingFirstName = get(billingAddress, "firstName", "");
  const billingLastName = get(billingAddress, "lastName", "");
  const billingEmail = get(billingAddress, "email", "");
  const billingPhone = get(billingAddress, "phone", "");
  const billingStreet = get(billingAddress, "street", "");
  const billingCity = get(billingAddress, "city", "");
  const billingState = get(billingAddress, "state", "");
  const billingZipCode = get(billingAddress, "zipCode", "");

  const deliveryType = get(newMember, "data.deliveryType", "");
  const clubType = get(newMember, "data.clubType", "");
  const bottleLevel = get(newMember, "data.bottleLevel", "");
  const pricingStructure = get(staticContent, "data.pricingStructure", {});
    
  return {
    sameAsBilling,
    shippingFirstName,
    shippingLastName,
    shippingStreet,
    shippingCity,
    shippingState,
    shippingZipCode,
    shippingEmail,
    billingEmail,
    billingPhone,
    billingFirstName,
    billingLastName,
    billingStreet,
    billingCity,
    billingZipCode,
    billingState,
    deliveryType,
    clubType,
    bottleLevel,
    pricingStructure
  };
}

const connectedDeliveryOptionsMonthly = withRouter(
  connect(mapStateToProps)(DeliveryOptionsMonthly)
);
export { connectedDeliveryOptionsMonthly as DeliveryOptionsMonthly };
