import React from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Gauge from "../../components/Gauge";
import { CurrentRewards } from "./current-rewards";
import { PointsHistory } from "./points-history";
import { RewardsHistory } from "./rewards-history";
import LoadingSpinners from "../../components/LoadingSpinners";

import get from "lodash/get";

import "./styles.css";

const PointsRewards = ({ member, dispatch, isLoading }) => {
  const currentPoints = get(member, "activePoints", 0);
  const ambassadorScore = get(member, "ambassadorScore", 0);
  const availablePointsTotal =
    Math.round(currentPoints) + (350 - (Math.round(currentPoints) % 350));

  return isLoading ? (
    <LoadingSpinners />
  ) : (
    <div>
      <Row className="justify-content-center">
        <Col md={5} sm={5} xs={6}>
          <Gauge
            points={currentPoints}
            availablePoints={availablePointsTotal}
            label="current points"
          />
        </Col>
        <Col md={5} sm={5} xs={6}>
          <Gauge
            points={ambassadorScore}
            availablePoints={7000}
            label="ambassador score"
          />
        </Col>
      </Row>
      <CurrentRewards />
      <PointsHistory />
      <RewardsHistory />
    </div>
  );
};

function mapStateToProps(state) {
  const { member, rewards } = state;

  return {
    member: member.data,
    isLoading: rewards.loading,
    rewards: rewards.data
  };
}

const connectedPointsRewards = connect(mapStateToProps)(PointsRewards);
export { connectedPointsRewards as PointsRewards };
