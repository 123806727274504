import get from "lodash/get";

import { store } from ".";

/**
 * Push an event to GTM dataLayer
 * @param field name within ecommerce property (eg 'click')
 * @param event GTM event name (eg 'productClick')
 * @param products Array of products
 * @param step Optional checkout step
 */
const pushGTM = (field, event, products, actionField, basket, member) => {
  try {
    const dataObj = {
      event:`enhanceEcom ${event}`,
      currencyCode: "USD",
      ecommerce: {
        [field]: {}
      }
    };

    if (products) {
      dataObj.ecommerce[field].products = products.map(mapProduct);
    }

    if (actionField) {
      dataObj.ecommerce[field].actionField = actionField;
    }

    if (member) {
      dataObj.member = member;
    }

    console.log("Pushing to GTM dataLayer", dataObj);
    window.dataLayer.push(dataObj);
  } catch (e) {
    console.log("Unable to push to GTP dataLayer", e);
  }
};


// General purpose GTM event logger. It handles the layout of the event object, all that is needed is to pass
// the string event name, and the page_data and event_data objects.
const logGTMEvent = (event, page_data, event_data) => {
  try {
    const GTMEvent = {
      event,
      page_data,
      event_data
    };
    window.dataLayer.push(GTMEvent);
  } catch (e) {
    console.log("Unable to push to GTM dataLayer", e);
  }
}

// This function is used in the Wine Club Signup Flow. It is a variant of pushGTM
// And was given a new function as such. Below is the data structure.
// event: String
// items: [{}]
// shipping: String
// delivery_type: String
// membership_type: String;
// value: Int;
// transaction_id: string;
// user_data: string;
const logEcommerceEvent = (event, items, shipping, delivery_type, membership_type, value, transaction_id, user_data) => {
  try {
    let GTMEvent = null;
    if (user_data) {
      GTMEvent = {
        event,
        ecommerce: {
          currency: "USD",
          items,
          shipping,
          delivery_type,
          membership_type,
          value,
          transaction_id,
          user_data,
        }
      };
    } else {
      GTMEvent = {
        event,
        ecommerce: {
          currency: "USD",
          items,
          shipping,
          delivery_type,
          membership_type,
          value,
        }
    };
  }
    window.dataLayer.push(GTMEvent);
  } catch (e) {
    console.log("Unable to push to GTM dataLayer", e);
  }
}


const logPageView = (event, page_data, user_data) => {
  try {
    let GTMEvent = null;
      GTMEvent = {
        event,
        page_data,
        user_data,
      };
      window.dataLayer.push(GTMEvent);
  } catch (e) {
    console.log("Unable to push to GTM dataLayer", e);
  }
}

/**
 * Maps CH-OLO product object to GTM product object
 */
const mapProduct = (product, basket) => {
  try {
    const state = store.getState();
    const { menu: { data: { categories } } } = state;
    basket = basket || state.basket;
    let id = product.chainproductid || product.productId;

    if (product.basketProductId) {
      // Get product from basket
      const basketProducts = get(basket, "data.products", []);
      const basketProduct = basketProducts.find(prod => prod.id === product.basketProductId) || {};
      id = basketProduct.productId;

      product = {
        ...product,
        productId: basketProduct.productId,
        quantity: basketProduct.quantity,
      };
    }

    product = {
      ...product,
      ...categories.reduce((acc, cat) =>
        cat.products.find(prod => prod.chainproductid === id || prod.id === id) || acc,
        {}
      )
    };

    const returnObj = {
      name: product.name.trim(),
      id,
      price: product.cost,
      brand: 'CHWinery',
      category: product.category.name && product.category.name.trim(),
    };

    if (product.quantity) {
      returnObj.quantity = product.quantity;
    }

    if (product.choices && product.choices.length > 0) {
      returnObj.variant = product.choices[0].name;
    }

    return returnObj;
  } catch (e) {
    console.log("Unable to map product for GTM", e);
  }

};

export { pushGTM, logGTMEvent, logEcommerceEvent, logPageView };
