import React from "react";
import PropTypes from "prop-types";

const RadialChart = ({ gaugeWidth, points, availablePoints, label }) => {
  const circumference = 3.14 * gaugeWidth;
  const strokeLength = (points / availablePoints) * circumference;

  return (
    <div className="radial-chart">
      <svg
        viewBox={`0 0 ${gaugeWidth + 20} ${gaugeWidth + 20}`}
        width={"100%"}
        height={"100%"}
        className="radial-chart"
      >
        <circle
          className="radial-chart-total"
          stroke="#fff"
          strokeWidth={2}
          fill="none"
          cx="90"
          cy="90"
          r={gaugeWidth / 2}
        />
        <circle
          className="radial-chart-progress"
          stroke="var(--member-site-gold)"
          strokeWidth={7}
          strokeDasharray={`${strokeLength}, ${circumference}`}
          fill="none"
          cx="90"
          cy="90"
          r={gaugeWidth / 2}
        />
        <text
          x="50%"
          y="30%"
          textAnchor="middle"
          fill="#fff"
          fontFamily="Montserrat"
          fontSize={`${gaugeWidth * 0.3}`}
          dy="10%"
        >
          {points}
        </text>
        <text
          x="50%"
          y="60%"
          fontSize="18"
          fontFamily="Montserrat"
          fill="var(--member-site-gold)"
          textAnchor="middle"
        >
          {label.split(" ")[0].toUpperCase()}
        </text>
        <text
          x="50%"
          y="75%"
          fontSize="18"
          fontFamily="Montserrat"
          fill="var(--member-site-gold)"
          textAnchor="middle"
        >
          {label.split(" ")[1].toUpperCase()}
        </text>
      </svg>
    </div>
  );
};

RadialChart.defaultProps = {
  gaugeWidth: 160,
  points: 100,
  availablePoints: 200,
  label: "Current Points"
};
RadialChart.propTypes = {
  gaugeWidth: PropTypes.number,
  points: PropTypes.number,
  availablePoints: PropTypes.number,
  label: PropTypes.string
};
export default RadialChart;
