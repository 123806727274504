import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { memberActions, newMemberActions } from "../../_actions";
import { minDate } from "../../_constants";
import get from "lodash/get";

import "./styles.css";

const MemberInfo = ({
  dispatch,
  firstName,
  lastName,
  birthday,
  invalidAge,
  signup,
}) => {
  var today = new Date();
  var dd = (today.getDate() < 10 ? "0" : "") + today.getDate();
  var mm = (today.getMonth() + 1 < 10 ? "0" : "") + (today.getMonth() + 1);
  var yyyy = today.getFullYear() - 21;
  const validBirthdate = yyyy + "-" + mm + "-" + dd;

  const validateAge = () => {
    if (birthday > validBirthdate) {
      invalidAge = true;
    }
  };

  return (
    <div style={{ marginTop: "2em" }}>
      <h2 className="log-title">Member Information</h2>
      <Form.Group as={Row}>
        <Form.Label column sm={4} for="member-info-first-name">
          First Name*
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            value={firstName}
            onChange={(e) =>
              signup === "profile"
                ? dispatch(memberActions.updateFirstName(e.target.value))
                : dispatch(newMemberActions.setBillingFirstName(e.target.value))
            }
            type="text"
            id="member-info-first-name"
            name="firstName"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid first name.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4} for="member-info-last-name">
          Last Name*
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            value={lastName}
            onChange={(e) =>
              signup === "profile"
                ? dispatch(memberActions.updateLastName(e.target.value))
                : dispatch(newMemberActions.setBillingLastName(e.target.value))
            }
            id="member-info-last-name"
            type="text"
            name="lastName"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid last name.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} onSubmit={validateAge()}>
        <Form.Label column sm={4} for="member-info-birthday">
          Birthdate*
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            value={birthday}
            onChange={(e) =>
              signup === "profile"
                ? dispatch(memberActions.updateBirthday(e.target.value))
                : dispatch(newMemberActions.setBirthday(e.target.value))
            }
            type="date"
            name="birthday"
            id="member-info-birthday"
            placeholder="YYYY-MM-DD"
            required
            max={validBirthdate}
            min={minDate}
            isInvalid={invalidAge}
            pattern="\d{4}-\d{2}-\d{2}"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid birthdate.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { member, newMember } = state;
  const signup = ownProps.signup;
  const billing = get(newMember, "data.billingAddress", "");

  const firstName =
    signup === "profile"
      ? get(member, "data.firstName", "")
      : get(billing, "firstName", "");

  const lastName =
    signup === "profile"
      ? get(member, "data.lastName", "")
      : get(billing, "lastName", "");

  const birthday =
    signup === "profile"
      ? get(member, "data.birthday", "")
      : get(newMember, "data.birthday", "");

  const invalidAge = false;

  return { firstName, lastName, birthday, invalidAge };
}

const connectedMemberInfo = connect(mapStateToProps)(MemberInfo);
export { connectedMemberInfo as MemberInfo };
