const moment = require("moment");

export function getCached(restaurantId) {
	const cacheKey = `${restaurantId}#hours`;
	const cacheEntry = localStorage.getItem(cacheKey);
	if (!cacheEntry) {
		return null;
	}
	const cacheObj = JSON.parse(cacheEntry);
	const cacheExpiryM = moment(cacheObj.expiry, "YYYYMMDD HH:mm");
	if (cacheExpiryM < moment()) {
		localStorage.removeItem(cacheKey);
		return null;
	}
	return cacheObj.data;

}

export function setCached(restaurantId, hours) {
	const cacheKey = `${restaurantId}#hours`;
	const cacheObj = {
		expiry: moment().add(15, "minutes").format("YYYYMMDD HH:mm"),
		data: hours
	};
	localStorage.setItem(cacheKey, JSON.stringify(cacheObj));
}