import React from "react";
import { Route, Redirect } from "react-router-dom";
import secureStorage from "./_helpers/secureStorage";
import get from 'lodash/get';

function PrivateRoute({
  location,
  component: Component,
  currentTab,
  setCurrentTab,
  ...rest
}) {
  var ls = new secureStorage();
  var urlParams = new URLSearchParams(location.search);
  const hasAction = urlParams.has("action");
  const action = urlParams.get("action");
  const user = JSON.parse(ls.get("user"));
  const browserPrivateMode = get(user, 'privateMode', false);

  return (
    <Route
      {...rest}
      render={props =>
        browserPrivateMode ? (<Redirect to="Private" /> )
        : 
        (hasAction && action === "email" ? <Redirect to= "BarrelLogin" /> : 
          <Component
            {...props}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
