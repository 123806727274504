import React from "react";
import BackArrow from "../../assets/images/icon-circle-arrow-back.svg";
import EsquireBackArrow from "../../assets/images/icon-gold-back-esquire.svg";
import { connect } from "react-redux";
import { isThemeEsquire } from "../../_helpers";



const BackArrowIcon = ({isEsquire, className, width, alt = "Back Arrow"}) => {

    return (
        <img
        src={isEsquire ? EsquireBackArrow : BackArrow}
        className={className}
        alt={alt}
        width={width}
        ></img>
    )

}

const mapStateToProps = (state, ownProps) => {
    let isEsquire = isThemeEsquire();
    return {
        isEsquire,
        ...ownProps
    }


}


export default connect(mapStateToProps)(BackArrowIcon);