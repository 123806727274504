import React, { useEffect, useState } from "react";
import "./styles.scoped.scss";
import { connect } from "react-redux";
import { get } from "lodash";
import { UpsellItem } from "../../components";
import { basketsActions } from "../../_actions";
import { AnimatePresence, motion } from "framer-motion";
import { isThemeEsquire } from "../../_helpers";

// using Fisher-Yates algorithm, equal probability
function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const UpsellCheckout = ({
  shuffledUpsells,
  basketId,
  imagepath,
  dispatch,
}) => {
  const [upselling, setUpselling] = useState(false);

  useEffect(() => {
    setUpselling(true);
    setTimeout(() => {
      setUpselling(false);
    }, 500);
  }, [shuffledUpsells]);

  const upSell = (item) => {
    dispatch(
      basketsActions.addProducts(
        basketId,
        item.id,
        item.name,
        1,
        "",
        "",
        "",
        item.isWine === "1"
          ? `isWine:${item.chainproductid}${
              item.isSweet === "1" ? ",isSweet" : ""
            }`
          : ""
      )
    );
  };

  const isEsquire = isThemeEsquire();
  const mustShow = (!isEsquire && shuffledUpsells.length !== 0);


  return  mustShow && (
    <div className="complete-meal-wrap">
      <div className="complete-meal-title">MAY WE SUGGEST</div>
      <motion.div
        animate={{ opacity: upselling ? 0.1 : 1 }}
        transition={{ duration: 0.1 }}
      >
      { shuffledUpsells.length > 0 && 
      <div> 
        {shuffledUpsells.map((item) => (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <UpsellItem
                data={item}
                onUpsellClick={() => upSell(item)}
                imagePath={imagepath}
              />
            </motion.div>
          </AnimatePresence>
        ))}
      </div>
      }
      </motion.div>
    </div>
  );
};

function mapStateToProps(state) {
  const { basket, menu } = state;
  const upsells = get(basket, "upsells", []);
  const basketId = get(get(basket, "data", {}), "id", "");
  const basketProducts = get(get(basket, "data", {}), "products", "");
  let { imagepath, categories } = get(menu, "data", {}) || {};

  const menuReducer = (prev, curr) => {
    return prev.concat(curr.products);
  };
  
  const menuProducts = categories ? categories.reduce(menuReducer, []) : [];

  // find upsell product complement in full menu to get image and metadata
  const upsellsWithProductInfo = upsells 
    ? upsells.map(upsellCategory => upsellCategory.items.map( // 
        (upsell) => {
          const menuProduct = menuProducts.find((product) => product.id === upsell.id);
          const metadata = get(menuProduct, "metadata", []);
          let metaObject;
          // convert metadata list to temp object
          if (metadata) {
            metaObject = metadata.reduce(
              (obj, menuProduct) => Object.assign(obj, { [menuProduct.key]: menuProduct.value }),
              {}
            );
          }
          // assign temp object metadata fields to upsell item
          if (menuProduct) {
            menuProduct.isWine = get(metaObject, "isWine", "0");
            menuProduct.isSweet = get(metaObject, "isSweet", "0");
            menuProduct.fullPriceId = get(metaObject, "fullPriceId", "");
          }
          return menuProduct;
        }).filter((x) => x && !x.fullPriceId) // filter out all wine swaps from each cateogry
      ).filter((x) => x.length > 0)           // filter out any empty upsell categories leftover
    : [];

  // shuffle upsell categories and return top element from each
  const upsellReducer = (prev, curr) => {
    return prev.concat(shuffle(curr)[0]); 
  };
  const shuffledUpsells = upsellsWithProductInfo
    ? shuffle(upsellsWithProductInfo.reduce(upsellReducer, []))
    : [];

  return { shuffledUpsells, basketId, imagepath };
}

const connectedUpsellCheckout = connect(mapStateToProps)(UpsellCheckout);
export { connectedUpsellCheckout as UpsellCheckout };
