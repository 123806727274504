import React, {useState} from "react";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import "./styles.scoped.scss";
import Blank from "../../assets/images/blank.png";
import GoldPlus from "../../assets/images/gold-plus-carryout.svg";
import { connect } from "react-redux";
import get from "lodash/get";
import { basketsActions } from "../../_actions";
import { withRouter } from "react-router-dom";
import { getImageUrl } from "../../_helpers";

const PairingItem = ({
  product,
  imagePath,
  basketData,
  dispatch,
  foodPairName,
}) => {
  const images = product.images;
  const imgSrc = images && `${imagePath}${getImageUrl(images, 'mobile-webapp-menu')}`;
  const cost = product.cost.toFixed(2);
  const bannerTextMetadata =
    product.metadata && product.metadata.find((x) => x.key === "bannerText");
  const hasBanner = bannerTextMetadata;

  const isWineMetaData = product.metadata
    ? product.metadata.find((x) => x.key === "isWine")
    : null;

  const isSweetMetaData = product.metadata
    ? product.metadata.find((x) => x.key === "isSweet")
    : null;

  const isWine = isWineMetaData && String(isWineMetaData.value) === "1";
  const isSweet = isSweetMetaData && String(isSweetMetaData.value) === "1";
  const [pairingSelected, setPairingSelected] = useState(false);

  return (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      key={product.id}
      style={{ padding: ".75%" }}
    >
      <div className="menu-item">
        <img src={imgSrc || Blank} className="menu-item-image" alt="menu item image" />
        {!imgSrc && <div className="menu-item-image-placeholder" />}
        <div className="menu-item-description-container">
          <div className="flex-container">
            {pairingSelected ? (
              <div className="pairing-validated">Bottle Added!</div>
            ):(
              <>
              <div className="item-name">{product.name}</div>
              <div className="item-cost">${cost}</div>
              </>
            )
            }
          </div>
          <div className="flex-container description-container">
            <div className="menu-item-availability-container">
              <div className="item-description">{product.description}</div>
              {!!product.availability.description && (
                <div className="item-availability-description">{`(Available ${
                  product.availability.description
                })`}</div>
              )}
            </div>
          </div>
        </div>
        {!pairingSelected && (
          <div
            className="plus-container"
            style={{ position: "absolute", bottom: 0, right: 0 }}
          >
            <img
              src={GoldPlus}
              alt="plus sign"
              className="gray-plus"
              onClick={() => {
                setPairingSelected(true);
                dispatch(
                  basketsActions.addProducts(
                    get(basketData, "id"),
                    product.id,
                    product.name,
                    1,
                    "",
                    "WINE_PAIRING",
                    "",
                    isWine
                      ? `isWine:${product.chainproductid}${
                          isSweet ? ",isSweet" : ""
                        }`
                      : ""
                  )
                );
              }}
            />
          </div>
        )}
      </div>
    </Col>
  );
};

function mapStateToProps(state) {
  const { member, basket } = state;
  const { loggedIn } = state.authentication;
  const basketData = get(basket, "data", {});
  const memberData = get(member, "data", {});
  const membershipType = get(memberData, "membershipType", "Marketing");
  const isPotential = membershipType === "Potential";
  const isCurrentUserMember = loggedIn && !isPotential;

  return {
    isCurrentUserMember,
    basketData,
  };
}

export default withRouter(connect(mapStateToProps)(PairingItem));

PairingItem.propTypes = {
  product: PropTypes.object,
  imagePath: PropTypes.string,
  onSelect: PropTypes.func,
};

PairingItem.defaultProps = {
  product: {},
  imagePath: "",
  onSelect: () => {},
};
