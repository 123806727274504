import React, {useEffect, useRef, useState} from "react";
import "./styles.scoped.scss";
import {connect} from "react-redux";
import { get } from "lodash";
import moment from "moment";
import {alertActions, memberActions, paymentActions} from "../../../_actions";
import {Col, Row} from "react-bootstrap";
import LoadingSpinners from "../../LoadingSpinners";
import {toast} from "react-toastify";
import secureStorage from "../../../_helpers/secureStorage";
import exclusiveIcon from "../../../assets/images/wc-icon-exclusive.png";
import tastingsIcon from "../../../assets/images/wc-icon-tastings.png";
import {newMemberActions} from "../../../_actions/new-member.actions";
import {appConstants} from "../../../_constants/app.constants";
import ButtonAnimator from "../../ButtonAnimator";

const WineClubPayment = ({
  dispatch,
  history,
  match,
  memberData,
  selectedMember,
  bluepayUrl,
  savedPayments,
  savedPaymentsError,
  bluepayFormLoading,
  loadingNewCard,
  savedPaymentsLoading,
  contactId,
  restaurantSlug,
  pricingStructure,
}) => {
  const clubSelection = get(match, "params.clubSelection", "1");
  const membershipType = get(memberData, "membershipType", "Potential");
  const [iframeHeight, setIframeHeight] = useState(300);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [hasRequestedForm, setHasRequestedForm] = useState(false);
  const [hasLoadedForm, setHasLoadedForm] = useState(false);
  const [hasAddedPayment, setHasAddedPayment] = useState(false);
  const [hasChargedPayment, setHasChargedPayment] = useState(false);
  const [pricingString, setPricingString] = useState(`Monthly:Pickup:Variety:${clubSelection}:`);
  const isLoadingCard = !!(bluepayFormLoading || loadingNewCard || savedPaymentsLoading);
  const bluepayRef = useRef();

  const getPaymentForm = () => {
    setHasRequestedForm(true);
    dispatch(newMemberActions.convertPotentialToMonthly({
      member: {
        FirstName: memberData.firstName,
        LastName: memberData.lastName,
        Birthdate: memberData.birthday,
        CHW_Membership_Type__c: "Monthly",
        CHW_Charge__c: "Immediately",
        CHW_Club_Type__c: "Variety",
        CHW_Level__c: clubSelection,
        CHW_Delivery__c: "Pickup",
        CHW_Cost_of_Membership__c: parseFloat(pricingStructure[pricingString]),
        Email: memberData.email,
        Phone: memberData.phone,
        HasOptedOutOfEmail: memberData.optOutEmail,
        CHW_Guest__c: false,
      },
      returnUrl: `${appConstants.paymentReturnUrl}payment-success.htm`,
      source: "OLO",
      location: restaurantSlug
    }));
  };

  useEffect(() => {
    if (savedPayments.length && membershipType === "Monthly" && memberData.status !== "Will be Charged") {
      dispatch(paymentActions.clearOLOPaymentForm());
      history.push(`/confirmation/${clubSelection}`);
    }
    // added membershipType to dependency array - nav to confirmation was not happening on conversion of potential member - 02-23-21 GD
  }, [savedPayments, bluepayUrl, membershipType]);

  useEffect(() => {
    const messageListener = async e => {
      try {
        let {data, source} = e;
        if (!bluepayRef.current || source !== bluepayRef.current.contentWindow) {
          // Ignore messages that are not from the bluepay iframe
          return;
        }

        if (data.includes('resize_iframe')) {
          data = JSON.parse(data);
          setIframeHeight(data.payload - 100);
          return;
        }

        const responseType = data?.split('|')[0];
        const response = responseType?.length < data?.length ? JSON.parse(data.slice(responseType.length + 1)) : "";
        if (!responseType || data === 'cancel') {
          // dispatch(paymentActions.clearOLOPaymentForm());
          return;
        }else if(responseType !== 'approved'){
          const errorMessage = decodeURIComponent(response.MESSAGE);
          alertActions.oloerror(`${errorMessage}. Please try again or enter credit card only for this order`);
          getPaymentForm();
          return;
        }else{
          setHasAddedPayment(true);
          dispatch(paymentActions.postPayment(response, memberData));
        }

      } catch (e) {
        // TODO: Handle leftover error
      }

    };
    window.addEventListener('message', messageListener);
    getPaymentForm();

    return () => window.removeEventListener('message', messageListener);
  }, []);

  useEffect(() => {
    setPricingString(`Monthly:Pickup:Variety:${clubSelection}:`);
  }, [clubSelection]);

  const removeStoredPayment = () => {
    dispatch(
      alertActions.olosuccess(
        "This will remove the existing payment method",
        "Remove Payment",
        () => {
          toast.dismiss();
          dispatch(paymentActions.removePayment(selectedMember));
        },
        "REMOVE",
      )
    );
  };

  const renderSelectionCard = () => {
    return (
      <div className="border-box d-block" onClick={() => history.push(`/club-selection/${clubSelection}`)}>
        <div className="step-title">
          <div style={{position: 'relative'}}>
            WINE CLUB SELECTION
            <div className="benefits-container">
              <div className="selection-benefit">
                <img src={exclusiveIcon} style={{width: 16, marginRight: 6, marginBottom: 2}} alt="exclusive icon" />
                {clubSelection === '2' ? "2 Bottles Per Month Variety Wine Club" : "1 Bottle Per Month Variety Wine Club"}
              </div>
              {clubSelection === '2' && (
              <div className="selection-benefit">
                <img src={tastingsIcon} style={{width: 17, marginRight: 6, marginBottom: 3}} alt="tastings icon" />
                2 Monthly Tastings
              </div>)}
            </div>
            <div className="price-value">${pricingStructure[pricingString]}/MO</div>
          </div>
        </div>
      </div>
    );
  };

  let birthday = get(memberData, "birthday", "");
  if(birthday) {
    birthday = moment(birthday, "YYYY-MM-DD").format("MM/DD/YYYY");
  }

  const firstName = get(memberData, "firstName", "");
  const lastName = get(memberData, "lastName", "");
  const renderAccountCard = () => {
    return (
      <div className="border-box d-block d-md-none" onClick={() => history.push(`/account/${clubSelection}`)}>
        <div className="step-title">
          <div>
            YOUR ACCOUNT
            <div className="benefits-container">
              <div className="selection-benefit">
                {firstName} {lastName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{!!birthday && `Birthday: ${birthday}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSubmit = () => {
    if (!acceptTerms) {
      dispatch(alertActions.errorWithX("Please read and agree to the Terms and Conditions before submitting."));
      return;
    } if (savedPayments.length) {
      dispatch(paymentActions.chargeMembership(contactId, pricingStructure[pricingString], restaurantSlug));
    } else {
      bluepayRef.current.contentWindow.postMessage('submit_form', '*');
    }
  };

  return (
    <div>
      {renderSelectionCard()}
      {renderAccountCard()}
      <div className="border-box" style={{borderRadius: "4px 4px 0 0", marginBottom: 0}}>
        <div className="step-title" style={{marginBottom: 10}}>PAYMENT DETAILS</div>
        {!!bluepayUrl && !isLoadingCard && !savedPayments.length ? (
          <div style={{height: iframeHeight}}>
          <iframe
            src={`${ bluepayUrl }&enhancedresponse=true&cardnumbernumericonly=true&invalidcreditcardevent=true&invalidcvvevent=true&invalidexpiryevent=true`}
            style={{ height: iframeHeight, width: '100%', border: 0, display: hasLoadedForm ? 'inline' : 'none' }}
            ref={bluepayRef}
            onLoad={() => {
              bluepayRef.current.contentWindow.postMessage('hide_buttons', '*');
              setHasLoadedForm(true);
            }}
            ></iframe>
          </div>
        ) : !isLoadingCard && !savedPayments.length && (<div style={{ height: iframeHeight }}><LoadingSpinners color="var(--member-site-gold)"  spinnerStyle={{marginLeft: 'auto', marginRight: 'auto', marginTop: 30, marginBottom: 40}} /></div>)}

        {(!!isLoadingCard || (!!savedPayments.length && membershipType !== "Potential" && memberData.status !== "Will be Charged")) && (
          <LoadingSpinners color="var(--member-site-gold)"  spinnerStyle={{marginLeft: 'auto', marginRight: 'auto', marginTop: 30, marginBottom: 40}} />
        )}

        {!!savedPayments.length && !isLoadingCard && savedPayments.map((payment, index) => (
          <div className="d-flex flex-row p-1 pb-3" key={payment.accountid}>
            <div className="d-flex flex-column">
              <div className="custom-control custom-radio pl-2">
                <input
                  type="radio"
                  className="custom-control-input"
                  id={`payWithStored${index}`}
                  name="memberPaymentOption"
                  onChange={(e) => {}}
                  checked={true}
                />
                <label
                  className="custom-control-label payment-radio-label ml-3 removeCardContainer"
                  htmlFor={`payWithStored${index}`}
                >
                  {payment.cardtype}, ending in {payment.cardsuffix}
                  <div
                    className="removeText"
                    onClick={() => removeStoredPayment(payment)}>
                    Remove
                  </div>
                </label>
              </div>
            </div>
          </div>
        ))}
        {!!savedPayments.length && !isLoadingCard && (
          <div className="d-flex flex-row p-1">
            <div className="d-flex flex-column">
              <div className="custom-control custom-radio pl-2">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="newCard"
                  name="newCard"
                  onChange={(e) => removeStoredPayment()}
                  checked={false}
                />
                <label
                  className="custom-control-label payment-radio-label ml-3 removeCardContainer"
                  htmlFor="newCard"
                >
                  Use a different card
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="border-box" style={{borderRadius: "0 0 4px 4px", marginTop: 0}}>
        <div className="step-title" style={{marginBottom: 10}}>TERMS & CONDITIONS</div>
        <div className="selection-benefit-cancel" style={{lineHeight: "22px", marginBottom: 15}}>
          Membership can be cancelled at anytime.
        </div>
        <div className="selection-benefit" style={{lineHeight: "22px", marginBottom: 15}}>
          This membership will renew every month on the 4th Tuesday.
        </div>

        <div className="custom-control custom-checkbox mb-2">
          <input
            type="checkbox"
            className="checkout custom-control-input"
            checked={acceptTerms}
            onChange={(e) => setAcceptTerms(!acceptTerms)}
            id="termsCheckbox"
          />
          <label
            className="custom-control-label"
            htmlFor="termsCheckbox"
          >
            I have read and agreed to the <a target="_blank" rel="noopener noreferrer" href="https://chwinery.com/wine-club/terms-conditions">Wine Club Terms & Conditions</a>
          </label>
        </div>

        <Row style={{marginTop: 25}}>
          <Col xs={12}>
            {!!savedPaymentsError && <div className="payment-error">{savedPaymentsError}</div>}
            <ButtonAnimator>
            <div className="submit" onClick={handleSubmit}>
              Complete Purchase
              {/*{isLoading && (*/}
              {/*  <LoadingSpinners spinnerStyle={{margin: 0, marginLeft: 10}} />*/}
              {/*)}*/}
            </div>
            </ButtonAnimator>
          </Col>
        </Row>
      </div>
    </div>
  );
};


const  mapStateToProps = state => {
  const { member, paymentForm, newMember, restaurant, staticContent } = state;
  const memberData = get(member, 'data', {});
  const selectedMember = get(member, "selectedMember", memberData.sfId || null);
  const bluepayUrl = get(newMember, "paymentUrl", "");
  const savedPayments = get(paymentForm, "savedPayments", []);
  const savedPaymentsError = get(paymentForm, "savedPaymentsError");
  const bluepayFormLoading = get(paymentForm, "bluepayFormLoading", false);
  const savedPaymentsLoading = get(paymentForm, "savedPaymentsLoading", false);
  const loadingNewCard = get(paymentForm, "loadingNewCard", false);
  const restaurantSlug = get(restaurant, "data.slug", "");
  const pricingStructure = get(staticContent, "data.pricingStructure", {});


  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const contactId = get(user, "id", "");
  const homeAddress = get(memberData, "homeAddress", {});
  const shippingAddress = get(memberData, "shippingAddress", {});
  const memberSignatureOptOut = get(memberData, "memberSignatureOptOut", false);
  const optOutEmail = get(memberData, "optOutEmail", false);

  const profileObject = {
    contact: {
      Id: contactId,
      FirstName: memberData.firstName,
      LastName: memberData.lastName,
      Birthdate: memberData.birthday,
      CHW_Membership_Type__c: "Monthly",
      CHW_Charge__c: "Immediately",
      CHW_Club_Type__c: "Variety",
      CHW_Level__c: "2",
      CHW_Delivery__c: "Pickup",
      Phone: memberData.phone || null,
      Email: memberData.email || null,
      MailingStreet: homeAddress.street || null,
      MailingCity: homeAddress.city || null,
      MailingState: homeAddress.state || null,
      MailingPostalCode: homeAddress.zipCode || null,
      OtherStreet: shippingAddress.street || null,
      OtherCity: shippingAddress.city || null,
      OtherState: shippingAddress.state || null,
      OtherPostalCode: shippingAddress.zipCode || null,
      CHW_Card_On_File_Optout__c: memberSignatureOptOut,
      CHW_Secondary_Contact_First_Name__c: memberData.secondaryMemberFirstName || null,
      CHW_Secondary_Contact_Last_Name__c:  memberData.secondaryMemberLastName || null,
      CHW_Secondary_Contact_Birthday__c: memberData.secondaryMemberBirthday || null,
      HasOptedOutOfEmail: optOutEmail,
    },
    displayClubType: "Variety",
    displayLevel: "2",
    displayDelivery: "Pickup",
  };

  return {
    memberData,
    selectedMember,
    bluepayUrl,
    savedPayments,
    savedPaymentsError,
    bluepayFormLoading,
    savedPaymentsLoading,
    loadingNewCard,
    profileObject,
    contactId,
    restaurantSlug,
    pricingStructure,
  };
};

const connectedWineClubPayment = connect(mapStateToProps)(WineClubPayment);

export { connectedWineClubPayment as WineClubPayment };
