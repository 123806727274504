import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Table from "react-bootstrap/Table";
import size from "lodash/size";

import "./styles.css";

const UpcomingEvents = ({ upcomingEvents }) => {
  return size(upcomingEvents, 0) === 0 ? (
    <div>
      <Row>
        <Col md={12}>
          <h2 className="log-title">Upcoming Events</h2>
        </Col>

        <Col md={12}>
          <Table>
            <thead>
              <tr>
                <td className="like-th">There are no upcoming events to display.</td>
              </tr>
            </thead>
          </Table>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="log-title">Upcoming Events</h2>
        </Col>

        <Col md={10} className="visibledesktop">
          <Table borderless size="sm" className="my-md-4">
            <thead>
              <tr>
                <td className="like-th">Event</td>
                <td className="like-th">Price</td>
                <td className="like-th">Venue</td>
                <td className="like-th">Date</td>
              </tr>
            </thead>
            <tbody>
              {upcomingEvents.map((event, index) => (
                <tr key={index}>
                  <td>
                    <a href={event.url}>{event.name}</a>
                  </td>
                  <td>${event.price}</td>
                  <td>{event.venue}</td>
                  <td>
                    <span>
                      {new Date(event.startTime).toLocaleDateString()},&nbsp;
                      {new Date(event.startTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit"
                      })}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>

        <Col className="visibledevice">
          {upcomingEvents.map((event, index) => (
            <Card.Body px={0} key={index}>
              <Card.Title>
                <a href={event.url} style={{color: event.url ?? '#fff'}}>
                  {event.name}
                </a>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <span>
                  {new Date(event.startTime).toLocaleDateString()},&nbsp;
                  {new Date(event.startTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit"
                  })}
                </span>
                <strong className="text-light float-right">
                  ${event.price}
                </strong>
              </Card.Subtitle>
              <Card.Text>
                Venue:
                <br />
                {event.venue}
              </Card.Text>
            </Card.Body>
          ))}
        </Col>
        <Col md={12}>
          <Pagination></Pagination>
        </Col>
        <br />
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { events } = state;
  return {
    upcomingEvents: events.data
  };
}

const connectedUpcomingEvents = connect(mapStateToProps)(UpcomingEvents);
export { connectedUpcomingEvents as UpcomingEvents };
