export const newMemberConstants = {
  SIGNUP_REQUEST: "NEW_MEMBER_SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "NEW_MEMBER_SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "NEW_MEMBER_SIGNUP_FAILURE",

  POTENTIAL_SIGNUP_REQUEST: "NEW_POTENTIAL_MEMBER_SIGNUP_REQUEST",
  POTENTIAL_SIGNUP_SUCCESS: "NEW_POTENTIAL_MEMBER_SIGNUP_SUCCESS",
  POTENTIAL_SIGNUP_FAILURE: "NEW_POTENTIAL_MEMBER_SIGNUP_FAILURE",

  CONVERT_POTENTIAL_MONTHLY_REQUEST: "NEW_MEMBER_CONVERT_POTENTIAL_MONTHLY_REQUEST",
  CONVERT_POTENTIAL_MONTHLY_SUCCESS: "NEW_MEMBER_CONVERT_POTENTIAL_MONTHLY_SUCCESS",
  CONVERT_POTENTIAL_MONTHLY_FAILURE: "NEW_MEMBER_CONVERT_POTENTIAL_MONTHLY_FAILURE",

  COGNITO_REQUEST: "NEW_MEMBER_COGNITO_REQUEST",
  COGNITO_SUCCESS: "NEW_MEMBER_COGNITO_SUCCESS",
  COGNITO_FAILURE: "NEW_MEMBER_COGNITO_FAILURE",

  CLEAR_PAYMENT_FORM: "CLEAR_PAYMENT_FORM",
  CLEAR_NEW_MEMBER: "CLEAR_NEW_MEMBER",

  GETMEMBERS_FROM_LASTCARD_REQUEST: "MEMBER_GETMEMBERS_FROM_LASTCARD_REQUEST",
  GETMEMBERS_FROM_LASTCARD_SUCCESS: "MEMBER_GETMEMBERS_FROM_LASTCARD_SUCCESS",
  GETMEMBERS_FROM_LASTCARD_FAILURE: "MEMBER_GETMEMBERS_FROM_LASTCARD_FAILURE",

  SET_POTENTIAL_EMAIL: "SET_POTENTIAL_EMAIL",

  SET_OPTOUT: "NEW_MEMBER_SET_OPTOUT",
  SET_CLUBTYPE: "NEW_MEMBER_SET_CLUBTYPE",
  SET_BOTTLELEVEL: "NEW_MEMBER_SET_BOTTLELEVEL",
  SET_SHIPPING_FIRSTNAME: "NEW_MEMBER_SET_SHIPPING_FIRSTNAME",
  SET_SHIPPING_LASTNAME: "NEW_MEMBER_SET_SHIPPING_LASTNAME",
  SET_SHIPPING_BIRTHDAY: "NEW_MEMBER_SET_SHIPPING_BIRTHDAY",
  SET_BIRTHDAY: "NEW_MEMBER_SET_BIRTHDAY",
  SET_SECONDARY_FIRSTNAME: "NEW_MEMBER_SET_SECONDARY_FIRSTNAME",
  SET_SECONDARY_LASTNAME: "NEW_MEMBER_SET_SECONDARY_LASTNAME",
  SET_SECONDARY_BIRTHDAY: "NEW_MEMBER_SET_SECONDARY_BIRTHDAY",
  SET_SHIPPING_EMAIL: "NEW_MEMBER_SET_SHIPPING_EMAIL",
  SET_PHONE: "NEW_MEMBER_SET_PHONE",
  SET_BILLING_FIRSTNAME: "NEW_MEMBER_SET_BILLING_FIRSTNAME",
  SET_BILLING_LASTNAME: "NEW_MEMBER_SET_BILLING_LASTNAME",
  SET_BILLING_STREET: "NEW_MEMBER_SET_BILLING_STREET",
  SET_BILLING_CITY: "NEW_MEMBER_SET_BILLING_CITY",
  SET_BILLING_STATE: "NEW_MEMBER_SET_BILLING_STATE",
  SET_BILLING_ZIP: "NEW_MEMBER_SET_BILLING_ZIP",

  SET_BILLING_PHONE: "NEW_MEMBER_SET_BILLING_PHONE",
  SET_BILLING_EMAIL: "NEW_MEMBER_SET_BILLING_EMAIL",

  SET_SHIPPING_AS_BILLING: "NEW_MEMBER_SET_SHIPPING_AS_BILLING",
  SET_SAME_AS_BILLING: "NEW_MEMBER_SET_SAME_AS_BILLING",

  SET_SHIPPING_STREET: "NEW_MEMBER_SET_SHIPPING_STREET",
  SET_SHIPPING_CITY: "NEW_MEMBER_SET_SHIPPING_CITY",
  SET_SHIPPING_STATE: "NEW_MEMBER_SET_SHIPPING_STATE",
  SET_SHIPPING_ZIP: "NEW_MEMBER_SET_SHIPPING_ZIP",

  SET_GIFT_LENGTH: "NEW_MEMBER_SET_GIFT_LENGTH",
  SET_SEND_TO: "NEW_MEMBER_SET_SEND_TO",

  SET_GIFT_STEP: "NEW_MEMBER_SET_GIFT_STEP",
  SET_MONTHLY_STEP: "NEW_MEMBER_SET_MONTH_STEP",
  SET_DELIVERY_TYPE: "NEW_MEMBER_SET_DELIVERY_TYPE",
  SET_GIFT_MESSAGE: "NEW_MEMBER_SET_GIFT_MESSAGE",
  SET_RECIPIENT_FIRSTNAME: "NEW_MEMBER_SET_RECIPIENT_FIRSTNAME",
  SET_RECIPIENT_LASTNAME: "NEW_MEMBER_SET_RECIPIENT_LASTNAME",
  SET_RECIPIENT_BIRTHDAY: "NEW_MEMBER_SET_RECIPIENT_BIRTHDAY",
  SET_SHIP_DATE: "NEW_MEMBER_SET_SHIP_DATE",
  SET_RECIPIENT_STREET: "NEW_MEMBER_SET_RECIPIENT_STREET",
  SET_RECIPIENT_CITY: "NEW_MEMBER_SET_RECIPIENT_CITY",
  SET_RECIPIENT_STATE: "NEW_MEMBER_SET_RECIPIENT_STATE",
  SET_RECIPIENT_ZIP: "NEW_MEMBER_SET_RECIPIENT_ZIP",
  SET_RECIPIENT_EMAIL: "NEW_MEMBER_SET_RECIPIENT_EMAIL",
  SET_HOME_STREET: "NEW_MEMBER_SET_HOME_STREET",
  SET_HOME_CITY: "NEW_MEMBER_SET_HOME_CITY",
  SET_HOME_STATE: "NEW_MEMBER_SET_HOME_STATE",
  SET_HOME_ZIP: "NEW_MEMBER_SET_HOME_ZIP",
  SET_PROMOTION_ID: "NEW_MEMBER_SET_PROMOTION_ID",
  SET_CUSTOMER_ID: "NEW_MEMBER_SET_CUSTOMER_ID",
};
