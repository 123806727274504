import { memberConstants, restaurantsConstants } from "../_constants";

export function member(
  state = {
    loading: false,
    isAdmin: false,
    data: {},
    seeLess: false,
    loginDestination: null,
    selectedMember: undefined,
    showWineClubModal: false,
  },
  action
) {
  switch (action.type) {
    case restaurantsConstants.RESTAURANT_SELECTED:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          lastLocation: {
            ...(state.data && state.data.lastLocation ? state.data.lastLocation : {}),
            CHW_OLO_Slug__c: action.selectedRestaurant ? action.selectedRestaurant.slug : null,
          },
        },
      };

    case memberConstants.ACTIVATE_GIFT_REQUEST:
      return{
        ...state,
        loading: true,
      };
    case memberConstants.ACTIVATE_GIFT_SUCCESS:
      return{
        ...state,
        loading: false,
        data: {
          ...state.data
        }
      };
    case memberConstants.ACTIVATE_GIFT_FAILURE:
      return{
        ...state,
        loading: false,
        error: action.error
      };

    case memberConstants.ADMIN:
      return {
        ...state,
        isAdmin: true,
      };

    case memberConstants.SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };

    case memberConstants.CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        changingEmail: true,
      };
    case memberConstants.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        changingEmail: false,
      };
    case memberConstants.CHANGE_EMAIL_FAILURE:
      return {
        ...state,
        changingEmail: false,
        error: action.error,
      };

    case memberConstants.POSTMEMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case memberConstants.POSTMEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case memberConstants.POSTMEMBER_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case memberConstants.GETMEMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case memberConstants.GETMEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.member.with,
          lastLocation: {
            ...(state.data && state.data.lastLocation ? state.data.lastLocation : {}),
          },
        },
      };
    case memberConstants.GETMEMBER_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case memberConstants.GETMEMBERS_FROM_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case memberConstants.GETMEMBERS_FROM_EMAIL_SUCCESS:
      const contacts = action.response.with
        .map(contact => {
          try {
            return typeof contact === "string" ? JSON.parse(contact) : contact;
          } catch (e) {
            console.log('Caught', e);
            return contact || '';
          }
        })
        .filter(
          (contact) => ["Active","UTP","Inactive","Cancel","Expired","Vacation Hold","New"].includes(contact.CHW_Status__c)
        );
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
        },
        contacts,
        selectedMember: contacts && contacts[0] && contacts[0].Id,
      };
    case memberConstants.GETMEMBERS_FROM_EMAIL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case memberConstants.UPDATE_OPTOUT:
      return {
        ...state,
        data: {
          ...state.data,
          memberSignatureOptOut: !state.data.memberSignatureOptOut,
        },
      };
    case memberConstants.UPDATE_CLUBTYPE:
      return {
        ...state,
        data: {
          ...state.data,
          clubType: action.clubType,
        },
      };
    case memberConstants.UPDATE_BOTTLELEVEL:
      return {
        ...state,
        data: {
          ...state.data,
          bottleLevel: action.bottleLevel,
        },
      };
    case memberConstants.UPDATE_FIRSTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          firstName: action.firstName,
        },
      };
    case memberConstants.UPDATE_LASTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          lastName: action.lastName,
        },
      };
    case memberConstants.UPDATE_BIRTHDAY:
      return {
        ...state,
        data: {
          ...state.data,
          birthday: action.birthday,
        },
      };

    case memberConstants.UPDATE_SECONDARY_FIRSTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          secondaryMemberFirstName: action.firstName,
        },
      };
    case memberConstants.UPDATE_SECONDARY_LASTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          secondaryMemberLastName: action.lastName,
        },
      };
    case memberConstants.UPDATE_SECONDARY_BIRTHDAY:
      return {
        ...state,
        data: {
          ...state.data,
          secondaryMemberBirthday: action.birthday,
        },
      };
    case memberConstants.UPDATE_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          email: action.email,
        },
      };
    case memberConstants.UPDATE_PHONE:
      return {
        ...state,
        data: {
          ...state.data,
          phone: action.phone,
        },
      };
    case memberConstants.UPDATE_HOME_STREET:
      return {
        ...state,
        data: {
          ...state.data,
          homeAddress: {
            ...state.data.homeAddress,
            street: action.street,
          },
        },
      };
    case memberConstants.UPDATE_HOME_CITY:
      return {
        ...state,
        data: {
          ...state.data,
          homeAddress: {
            ...state.data.homeAddress,
            city: action.city,
          },
        },
      };
    case memberConstants.UPDATE_HOME_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          homeAddress: {
            ...state.data.homeAddress,
            state: action.state,
          },
        },
      };
    case memberConstants.UPDATE_HOME_ZIP:
      return {
        ...state,
        data: {
          ...state.data,
          homeAddress: {
            ...state.data.homeAddress,
            zipCode: action.zip,
          },
        },
      };
    case memberConstants.UPDATE_SHIPPING_STREET:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            street: action.street,
          },
        },
      };
    case memberConstants.UPDATE_SHIPPING_CITY:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            city: action.city,
          },
        },
      };
    case memberConstants.UPDATE_SHIPPING_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            state: action.state,
          },
        },
      };
    case memberConstants.UPDATE_SHIPPING_ZIP:
      return {
        ...state,
        data: {
          ...state.data,
          shippingAddress: {
            ...state.data.shippingAddress,
            zipCode: action.zip,
          },
        },
      };

    case memberConstants.UPDATE_GIFT_LENGTH:
      return {
        ...state,
        data: {
          ...state.data,
          giftLength: action.giftLength,
        },
      };
    case memberConstants.UPDATE_PICKUP_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          pickupType: action.pickupType,
        },
      };

    case memberConstants.TOGGLE_MEMBER_HEADER:
      return {
        ...state,
        seeLess: !state.seeLess,
      };

    case memberConstants.SET_LOGIN_DESTINATION:
      return {
        ...state,
        loginDestination: action.destination,
      };
    case memberConstants.CLEAR_LOGIN_DESTINATION:
      return {
        ...state,
        loginDestination: null,
      };

    case memberConstants.SWITCH_MEMBER_SUCCESS:
      return {
        ...state,
        selectedMember: action.id,
      };

    case memberConstants.SHOW_WINECLUB_MODAL:
      return {
        ...state,
        showWineClubModal: true,
      };

    case memberConstants.HIDE_WINECLUB_MODAL:
      return {
        ...state,
        showWineClubModal: false,
      };

      case memberConstants.UPDATE_SUPPLIER:
        return {
          ...state,
          supplierInfo: action.data,
        }

    default:
      return state;
  }
}
