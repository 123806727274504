import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { basketsActions, alertActions } from "../_actions";

/**
 * HOC ensures that the Bag is cleared if it was created in the previous day and it has one hour of inactivity
 */
const withBagTimeout = (Component) => {
  const Wrapper = (props) => {
    const { dispatch, basket, restaurantData } = props;

    useEffect(() => {
      const checkBasket = () => {
        const yersterday = moment()
          .subtract(1, "days")
          .startOf("day");
        if (
          basket.createdTime &&
          moment(basket.createdTime).isSameOrBefore(yersterday, "d")
        ) {
          if (
            basket.lastUpdate &&
            moment().diff(moment(basket.lastUpdate), "hour") >= 1
          ) {
            dispatch(basketsActions.destroyBasket());
            dispatch(basketsActions.resetLastUpdate());
            dispatch(
              alertActions.olosuccess("Sorry your basket is expired.", "Oops")
            );
            if (restaurantData && restaurantData.id) {
              dispatch(basketsActions.createBasket(restaurantData.id, false));
            }
          }
        }
      };
      checkBasket();
      const timeout = setTimeout(checkBasket, 60 * 60 * 1000);

      return () => {
        clearTimeout(timeout);
      };
    }, [basket]);

    return <Component {...props} />;
  };
  function mapStateToProps(state) {
    const { restaurant, basket } = state;
    const { loading: restaurantLoading, data: restaurantData } = restaurant;
    return {
      restaurantData,
      basket,
    };
  }
  return connect(mapStateToProps)(Wrapper);
};

export default withBagTimeout;
