import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { AnimateOnChange, AnimateGroup } from "react-animation";
import "./styles.css";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";

import empty from "../../assets/gift-images/CH-empty@2x.png";
import white from "../../assets/gift-images/CH-white1@2x.png";
import red from "../../assets/gift-images/CH-red1@2x.png";
import sweet from "../../assets/gift-images/CH-sweet1@2x.png";
import variety from "../../assets/gift-images/CH-variety1@2x.png";
import whites from "../../assets/gift-images/CH-whites@2x.png";
import reds from "../../assets/gift-images/CH-reds@2x.png";
import sweets from "../../assets/gift-images/CH-sweets@2x.png";
import varieties from "../../assets/gift-images/CH-varieties@2x.png";
import calendarIcon from "../../assets/gift-images/icons/icon-calendar@2x.png";
import bottleIcon from "../../assets/gift-images/icons/icon-number@2x.png";
import clubIcon from "../../assets/gift-images/icons/icon-glass@2x.png";
import infoIcon from "../../assets/images/info-16-white.png";

const SummaryBlock = ({
  giftLength,
  bottleLevel,
  clubType,
  price,
  disabled,
}) => {
  const renderSummaryText = (type) => {
    switch (type) {
      case "Variety": case "Red": case "White":
        return `A ${type} Club Member may choose to receive either our exclusive Wine of the Month, or swap for a wine of their choice (some restrictions apply)`;
      case "Sweet":
        return "A Sweet Club Member may choose to receive any fruit and sparkling wine that is not a Lux (some restrictions apply).";
    }
  };

  const renderSwitch = (type) => {
    if (disabled) {
      return empty;
    }
    switch (type) {
      case "White":
        return bottleLevel == 1 ? white : whites;
      case "Red":
        return bottleLevel == 1 ? red : reds;
      case "Variety":
        return bottleLevel == 1 ? variety : varieties;
      case "Sweet":
        return bottleLevel == 1 ? sweet : sweets;
    }
  };

  const renderAdditionalInfo = (type) => {
      switch (type) {
        case "Variety": case "Red": case "White":
          return "Choose from our Cooper’s Hawk branded retail wine list based on availability. Some exclusions and additional charges may apply.";
        case "Sweet":
          return "Based upon availability. Exclusions and additional charges may apply. Some Wines of the Month may qualify as well.";
      }
  };

  const clubTypeAdditionalInfo = (type) => {
    return (
      <Popover id="popover-additional">
        <Popover.Content
          style={{
            backgroundColor: "black",
            border: " 1px solid white",
            color: "white",
            fontFamily: "Oswald",
            letterSpacing: "1px",
          }}
        >
          {renderAdditionalInfo(type)}
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <div className="wrap">
      <div className="summary" style={{ minWidth: '85%'}}>
        <div className="inner">
          <svg id="left">
            <line x1="0" y1="8%" x2="8%" y2="0" />
            <line x1="0" y1="16%" x2="16%" y2="0" />
            <line x1="0" y1="24%" x2="24%" y2="0" />
          </svg>

          <AnimateOnChange durationOut={700} animation="fade">
            <div className="content">
              <img
                src={renderSwitch(clubType)}
                alt="bottle icon"
                className="bottle visibledesktop"
              />
              {disabled ? (
                <div className="empty-summary">
                  Make your selections to see the membership price
                </div>
              ) : (
                <div
                  style={{
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      justifyContent: "flex-start",
                      display: 'flex',
                    }}
                  >
                    <img
                      src={renderSwitch(clubType)}
                      alt="bottle icon"
                      id="gift-dynamic-bottle"
                      class="mobile-only"
                      style={{flex: bottleLevel === '2' ? 1 : 0}}
                    />
                    <div id="summary-big-icon-wrap" className="big-icon-wrap icon-shift"> 
                      <div className="summary-little-icon-wrap">
                        <img src={calendarIcon} className="icon" alt="calendar icon" />
                        <div className="summary-block-text">
                          {giftLength} month gift
                        </div>
                      </div>
                      <div className="summary-little-icon-wrap">
                        <img src={bottleIcon} className="icon" alt="bottle icon" />
                        <div className="summary-block-text">
                          {bottleLevel == 1
                            ? "1 bottle per month"
                            : "2 bottles per month"}
                      </div>
                      </div>
                      <div className="summary-little-icon-wrap">
                        <img src={clubIcon} className="icon" alt="club icon" />
                        <div className="summary-block-text"> {clubType} Club</div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-horizontal-wrap">
                    <div id="summary-club-text" className="summary-block-text my-auto">
                      <br/>
                      {renderSummaryText(clubType)}
                    </div>
                    <div id="membership-cost" className="ok">
                      <h6>COST OF MEMBERSHIP</h6>
                      <h5>${price}</h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </AnimateOnChange>
          <svg id="right">
            <line x1="100%" y1="92%" x2="92%" y2="100%" />
            <line x1="100%" y1="84%" x2="84%" y2="100%" />
            <line x1="100%" y1="76%" x2="76%" y2="100%" />
          </svg>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { newMember } = state;
  const data = get(newMember, "data", {});
  const giftLength = get(data, "giftLength", "3");
  const bottleLevel = get(data, "bottleLevel", "1");
  const clubType = get(data, "clubType", "Variety");

  return { giftLength, bottleLevel, clubType };
}

const connectedSummaryBlock = connect(mapStateToProps)(SummaryBlock);

export { connectedSummaryBlock as SummaryBlockGift };