import { appConstants } from "../_constants";

export const staticContentService = {
  getStaticContent
};

function getStaticContent() {
  const requestOptions = {
    method: "GET"
  };

  return fetch(
    `${appConstants.localApiUrl}members/static`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    return data;
  });
}
