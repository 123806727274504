import { settingsConstants } from "../_constants";

export function settings(state = {handoff: "pickup"}, action) {
  switch (action.type) {
    case settingsConstants.SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        handoff: action.handoff,
      };
    default:
      return state;
  }
}
