import { barrelConstants } from "../_constants";
import get from "lodash/get";

export function barrelOrders(state = { loading: false, data: {} }, action) {
  switch (action.type) {
    case barrelConstants.BARREL_LIST_REQUEST:
      return {
        loading: true
      };
    case barrelConstants.BARREL_LIST_SUCCESS:
      const orderHistory = get(action.response.with, "brohs", []);
      const messages = get(action.response.with, "messages", {});
      const remaining = get(action.response.with, "remaining", {});
      return {
        loading: false,
        data: {
          ...state.data,
          orderHistory,
          messages,
          remaining
        }
      };
    case barrelConstants.BARREL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case barrelConstants.BARREL_COST_REQUEST:
      return {
        ...state
      };
    case barrelConstants.BARREL_COST_SUCCESS:
      const barrelReserveOrder = get(
        action.response.with,
        "barrelReserveOrder",
        null
      );
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          barrelReserveOrder
        }
      };
    case barrelConstants.BARREL_COST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case barrelConstants.BARREL_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case barrelConstants.BARREL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          paymentStatus: action.response.with
        }
      };
    case barrelConstants.BARREL_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
