import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motion } from "framer-motion";
import "./styles.css";

const ListMapMobileToggle = ({ onToggle, toggleValue }) => {
  const [index, setIndex] = useState(toggleValue || 0);

  const makeSelection = (i) => {
    if (i !== index) {
      setIndex(i);
    }
    onToggle(i);
  };
  return (
    <Row className="toggle-wrap">
      <Col className={index === 0 ? "toggle-btn-selected" : "toggle-btn"}>
        <div className="btn-txt" onClick={() => makeSelection(0)}>
          LIST
        </div>
      </Col>
      <Col className={index === 1 ? "toggle-btn-selected" : "toggle-btn"}>
        <div className="btn-txt" onClick={() => makeSelection(1)}>
          MAP
        </div>
      </Col>
      <motion.div
        className="selection-bar"
        transition={{ duration: 0.1 }}
        animate={{ left: `${index * 50}%` }}
      />
    </Row>
  );
};

export default ListMapMobileToggle;
