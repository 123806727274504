import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import size from "lodash/size";
import "./styles.css";

const WhatsNew = ({ news }) => {
  if (size(news, 0) === 0) {
    return (
      <div>
        <Row>
          <div className="d-inline-flex list-item">
            <h6 style={{ color: "white" }}>There is no news to display.</h6>
          </div>
        </Row>
      </div>
    );
  }
  return (
    <div>
      <Row>
        {news.map((newsEntry, index) => (
          <div className="d-inline-flex list-item" key={index}>
            <h6>
              <a
                href={newsEntry.CHW_Link__c}
                target="_blank"
                rel="noopener noreferrer"
              >
                {newsEntry.Name}
              </a>
            </h6>
          </div>
        ))}
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { news } = state;
  return {
    news: news.data,
  };
}

const connectedWhatsNew = connect(mapStateToProps)(WhatsNew);
export { connectedWhatsNew as WhatsNew };
