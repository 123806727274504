import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import size from "lodash/size";
import "./styles.css";

const PointsHistory = ({ pointsHistory }) => {
  return size(pointsHistory, 0) === 0 ? (
    <div>
      <Row>
        <Col md={12}>
          <h2 className="log-title">Points History</h2>
        </Col>

        <Col md={12}>
          <Table>
            <thead>
              <tr>
                <td className="like-th">There are no points to display.</td>
              </tr>
            </thead>
          </Table>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="log-title">Points History</h2>
        </Col>

        <Col md={10}>
          <Table borderless size="sm" className="my-md-4">
            <thead>
              <tr>
                <td className="like-th">Points</td>
                <td className="like-th">Date</td>
                <td className="like-th">Location</td>
              </tr>
            </thead>
            <tbody>
              {pointsHistory.map((pointInstance, index) => (
                <tr key={index}>
                  <td>{pointInstance.value}</td>
                  <td>
                    {new Date(pointInstance.creditDate).toLocaleDateString()}
                  </td>
                  <td>{pointInstance.locationName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <br />
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { points } = state;
  return {
    pointsHistory: points.data
  };
}

const connectedPointsHistory = connect(mapStateToProps)(PointsHistory);
export { connectedPointsHistory as PointsHistory };
