import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.css";

const UIButton = 
({
  text,
  onSelect,
  disabled,
  validation,
  customStyle
}) => {
  return (
    <div
      className=
      {`
        ui-btn-wrap 
        ${validation ?
          (disabled ? "ui-btn-invalid" : "ui-btn-enabled") : 
          (disabled ? "ui-btn-disabled" : "ui-btn-enabled")        
        }   
      `}
      onClick={validation ?
        (!disabled ? e => onSelect(e) : null) : 
        e => onSelect(e)
      }
      tabIndex={0}
    >
      <div className={`${customStyle ? 'next-text' : disabled ? 'ui-text' : 'ui-text-enabled'}`}>{text}</div>
    </div>
  );
};

UIButton.defaultProps = 
{
  disabled: false,
  validation: false
};
UIButton.propTypes = 
{
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  validation: PropTypes.bool
};

export default UIButton;
