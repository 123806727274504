import { adminConstants } from "../_constants";
import { adminService } from "../_services";
import { alertActions } from "./";

export const adminActions = {
  clearResults,
  searchForContact
};

function clearResults() {
  return dispatch => {
    dispatch({ type: adminConstants.ADMIN_CLEAR_RESULTS });
  };
}

function searchForContact(email, first, last, phone, card) {
  return dispatch => {
    dispatch(request());
    adminService.searchForContact(email, first, last, phone, card).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: adminConstants.ADMIN_SEARCH_REQUEST };
  }
  function success(results) {
    return { type: adminConstants.ADMIN_SEARCH_SUCCESS, results };
  }
  function failure(error) {
    return { type: adminConstants.ADMIN_SEARCH_FAILURE, error };
  }
}
