import React from "react";
import { find } from "lodash";
import "./styles.scoped.css";

const DropDownSelector = ({
  value,
  items,
  onClick,
  onChange,
  isOpen = false,
  containerClass = "",
  inputStyle = {},
}) => {
  const selectedItem = find(items, { value });
  return (
    <div className={`input-field ${containerClass}`}>
      <input style={inputStyle} value={selectedItem.label} onClick={onClick} readOnly aria-label={selectedItem.label}/>
      <span className={`caret ${isOpen ? "caret-up" : "caret-down"}`} onClick={onClick}/>
      <div className={`select-content${isOpen ? " open" : ""}`}>
        {items.map((item) => (
          <div
            key={item.value}
            className={`item${item.value === value ? " selected" : ""}`}
            onClick={(e) => {
              onChange(item.value);
            }}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropDownSelector;
