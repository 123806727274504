import { rewardsConstants } from "../_constants";
import { rewardsService } from "../_services";
import { alertActions } from "./";

export const rewardsActions = {
  getRewards
};

function getRewards() {
  return dispatch => {
    dispatch(request());
    rewardsService.getRewards().then(
      rewards => {
        dispatch(success(rewards));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: rewardsConstants.REWARDS_REQUEST };
  }
  function success(rewards) {
    return { type: rewardsConstants.REWARDS_SUCCESS, rewards };
  }
  function failure(error) {
    return { type: rewardsConstants.REWARDS_FAILURE, error };
  }
}
