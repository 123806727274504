import React, { useState, useEffect, useRef } from "react";
import { Header, CholoInput, ChoLoader, Footer } from "../../components";
import { Button, Col, Row, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { getQueryParam } from "../../_helpers/parse-query-params";
import backIcon from "../../assets/images/icon-gold-back.png";
import infoIcon from "../../assets/images/icon-info.png";
import get from "lodash/get";
import { Link } from "react-router-dom";
import ThemedBackArrow from "../../components/ThemedBackArrow";


import { newMemberActions, memberActions, alertActions } from "../../_actions";

import "./styles.scoped.css";
import { appConstants } from "../../_constants";
import { useRenameDocument } from "../../context/renameDocument";

const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

const ForgotPasswordOlo = ({
  dispatch,
  history,
  email,
  loggingIn,
  loggedIn,
  loginDestination,
  potentialEmail,
}) => {
  const onBackToBag = () => {
    history.goBack();
  };

  useRenameDocument("Forgot Password");

  const handleSubmit = (event) => {
    setShowInputError(true);
    if (!emailValid) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      dispatch(
        memberActions.forgotPassword({
          username: loggedIn ? email : potentialEmail,
        })
      );
      history.push("/newpassword-olo");  // Does not cancel request (single-page app)
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const [showInputError, setShowInputError] = useState(false);
  const [emailValid, setEmailValid] = useState(
    emailRegex.test(loggedIn ? email : potentialEmail)
  );

  return (
    <>
      <div className="big-wrap">
        <Header onBagClick={() => history.push(loginDestination)} hideLogin />
        <Col className="little-wrap">
          <div className="back-to-my-bag" onClick={onBackToBag}>
            <ThemedBackArrow className="icon-back" width="20px"/>
            <p className="back-to-my-bag-text">
              {loggedIn ? "Back to account details" : "Back to login"}
            </p>
          </div>
          <div className="col-xs-12 col-sm-10 col-lg-6">
            <h2 className="checkout">Password Reset</h2>

            <div className="inner-wrap">
              <p
                style={{
                  fontFamily: "var(--title-font-family)",
                  color: "black",
                  letterSpacing: 1,
                  fontSize: 18,
                  marginBottom: 0,
                }}
              >
                {loggedIn
                  ? "To update your password, click below to get a verification code sent to the email associated with your account."
                  : "Please provide the email address used to sign up for your account."}
              </p>
              <div className="divider" />

              <Form>
                <CholoInput
                  isValid={!showInputError || emailValid}
                  errorMessage="Please enter a valid email address"
                  placeholder="Email"
                  value={loggedIn ? email : potentialEmail}
                  onTextChanged={(value) => {
                    setEmailValid(emailRegex.test(value));
                    dispatch(
                      loggedIn
                        ? memberActions.setEmail(value)
                        : newMemberActions.setPotentialEmail(value)
                    );
                  }}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </Form>
              <div className="log-line clearfix">
                <Button
                  className="button pad"
                  id="send_email"
                  onClick={(e) => handleSubmit(e)}
                >
                  SEND ME A VERIFICATION CODE
                </Button>
              </div>
              <div className="divider" />
              <Row className="horizontal-edges">
                <Col>
                  <Row>
                    <div className="lefty dont-have">Questions?</div>
                    <div className="checkout-as-guest">
                      <a href="tel:7082155674">(708) 215-5674</a>
                    </div>
                  </Row>
                </Col>
                <Col />
              </Row>
            </div>
          </div>

          {loggingIn && <ChoLoader />}
        </Col>
        <Footer />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { loggingIn, loggedIn, error, signinError } = state.authentication;
  const { member, newMember } = state;
  const newLoading = get(newMember, "loading", false);
  const potentialEmail = get(newMember, "data.potentialEmail", "");
  const email = get(member, "data.email", "");
  const potentialResponse = get(newMember, "paymentUrl", "");
  const loginDestination = get(member, "loginDestination");

  return {
    email,
    newLoading,
    potentialEmail,
    loggingIn,
    loggedIn,
    error,
    signinError,
    loginDestination,
    potentialResponse,
  };
}

const connectedForgotPasswordOlo = withRouter(
  connect(mapStateToProps)(ForgotPasswordOlo)
);
export { connectedForgotPasswordOlo as ForgotPasswordOlo };
