import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { stateOptions } from "../../_helpers/state-options";
import { connect } from "react-redux";
import { memberActions, newMemberActions } from "../../_actions";
import get from "lodash/get";
import "./styles.css";

const HomeAddressInfo = ({
  dispatch,
  street,
  city,
  state,
  zipCode,
  signup
}) => {
  return (
    <div>
      <h2 className="log-title">Home Address</h2>

      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Street*
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="textarea"
            rows="2"
            value={street}
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateHomeStreet(e.target.value))
                : dispatch(newMemberActions.setHomeStreet(e.target.value))
            }
            name="street"
            required={true}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid street.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          City*
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            value={city}
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateHomeCity(e.target.value))
                : dispatch(newMemberActions.setHomeCity(e.target.value))
            }
            type="text"
            name="city"
            required={true}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          State*
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateHomeState(e.target.value))
                : dispatch(newMemberActions.setHomeState(e.target.value))
            }
            value={state}
            required={true}
          >
            {stateOptions.map(state => (
              <option key={state.id} value={state.value}>
                {state.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please provide a valid state.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Zip Code*
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="input"
            onChange={e =>
              signup === "profile"
                ? dispatch(memberActions.updateHomeZip(e.target.value))
                : dispatch(newMemberActions.setHomeZip(e.target.value))
            }
            value={zipCode}
            name="zipCode"
            required={true}
            pattern="(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid zip code.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { member, newMember } = state;

  const homeAddress =
    ownProps.signup === "profile"
      ? get(member, "data.homeAddress", "")
      : get(newMember, "data.homeAddress", "");

  const street = get(homeAddress, "street", "");
  const city = get(homeAddress, "city", "");
  const st = get(homeAddress, "state", "");
  const zipCode = get(homeAddress, "zipCode", "");

  return { street, city, state: st, zipCode };
}

const connectedHomeAddressInfo = connect(mapStateToProps)(HomeAddressInfo);
export { connectedHomeAddressInfo as HomeAddressInfo };
